<template>
  <div
    class="button-action" ref="ref"
    :class="{ 'disabled': isDisabled }"
    v-ripple="$theme.secondColorDarkTransparent"
    @click="newAction"
  >
    <i :class="data.icon"/>

    <p>{{ data.label }}</p>
  </div>
</template>

<script>
  export default {
    name: 'ActionButton',

    props: {
      data: {
        type: Object,
        default() {
          return {
            label: 'default',
            value: 'default',
            icon: 'icon-close'
          };
        }
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      newAction() {
        if (!this.isDisabled) {
          this.$emit('newAction', this.data);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .button-action {
    z-index: 1;
    display: flex;
    width: 175px;
    height: 92px;
    cursor: pointer;
    border-radius: 6px;
    margin-right: 1.5rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #EFEFEF;
    background: $white-color;

    &.disabled {
      cursor: not-allowed;

      i {
        background: #EFEFEF;
        color: #6D6F6F;
      }

      p {
        color: #6D6F6F;
      }

      .ripple-container {
        display: none;
      }
    }

    i {
      font-size: 24px;
      background: $second-color;
      color: $white-color;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.icon-deposit:before {
        margin-top: 7px;
      }

      &.icon-pay:before {
        margin-top: 7px;
      }
    }

    p {
      text-align: center;
      margin-top: 0.5rem;
      color: #333;
      font-weight: 400;
    }
  }

  @media (max-width: 700px) {
    .button-action {
      width: 100px;

      i {
        width: 30px;
        height: 30px;
        font-size: 16px;

        &.icon-deposit:before {
          margin-top: 4px;
        }

        &.icon-pay:before {
          margin-top: 4px;
        }
      }

      p {
        font-size: 12px;
        width: 70px;
      }
    }
  }
</style>