<template>
  <div class="appointments-view-container">
    <default-view-component :hasModal="isShowModal || isShowConfirmModal">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <appointment-modal-component
          v-if="isShowModal"
          :appointmentId="currentAppointmentId"
          @close="isShowModal = false"
          @finishRequest="checkDate"
          @snackbarNotification="showSnackBar"
        />

        <confirm-modal-component
          v-if="isShowConfirmModal"
          :data="confirmModalData"
          :hasRequest="hasDeleteRequest"
          @close="isShowConfirmModal = false"
          @click="deleteAppointment"
        />
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
          />

          <div class="header-options">
            <button-component
              class="button-component"
              text="Novo compromisso"
              v-ripple="$theme.whiteColorTransparent"
              :isDisabled="hasRequest"
              @click.native="showModal"
            />
          </div>
        </div>

        <div class="content">
          <mobile-appointment-calendar
            ref="calendar"
            v-if="isMobileCalendar || checkCalendar()"
            :attributes="formatMobileAttributes(appointments)"
            :hasRequest="hasRequest"
            @changePage="changePage"
            @changeDay="changeDay"
            v-model="range"
          />

          <appointment-calendar
            v-else
            ref="calendar"
            :attributes="formatAttributes(appointments)"
            :hasRequest="hasRequest"
            @changePage="changePage"
            @changeDay="changeDay"
            v-model="range"
          />

          <div v-if="!hasRequest || details.length > 0" class="appointment-card-container">
            <div class="header">
              <p class="title">Compromisso</p>

              <p class="date">{{ $service.dateFormat(range.start, 'YYYY-MM-DD', 'DD/MM/YY') }}</p>
            </div>

            <div v-if="details.length > 0" class="wrapper">
              <div v-for="(appointment, index) in details" :key="index" class="row">
                <div class="tag-container">
                  <div class="tag" :style="{ background: appointment.color }"></div>

                  <p class="hour">{{ appointment.hour }}</p>
                </div>

                <p class="label">{{ appointment.label}}</p>

                <div class="action">
                  <i v-if="appointment.hasEditButton" class="icon-pencil" @click="showUpdateModal(appointment.id)" />

                  <i class="icon-trash" @click="showDeleteModal(appointment.id)" />
                </div>
              </div>
            </div>

            <div v-else class="wrapper">
              <div class="no-content">
                <i class="icon-second-calendar" />

                <p>Nenhum compromisso nesta data</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import DefaultView from '@/components/Layout/DefaultView';
  import Header from '@/components/Layout/Header';
  import Button from '@/components/Base/Button';
  import SnackBar from '@/components/Base/SnackBar';
  import AppointmentModal from '@/components/Modal/AppointmentModal';
  import ConfirmModal from '@/components/Modal/ConfirmModal';
  import AppointmentCalendar from '../components/Calendar/AppointmentCalendar';
  import MobileAppointmentCalendar from '../components/Calendar/MobileAppointmentCalendar';

  export default {
    name: 'AppointmentView',

    data() {
      return {
        header: {
          title: 'Agenda',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        hasRequest: false,
        isShowModal: false,

        currentAppointmentId: null,

        hasDeleteRequest: false,

        isShowConfirmModal: false,
        confirmModalData: {
          title: 'Deletar compromisso',
          icon: 'icon-close-circle-outline',
          text: 'Você realmente deseja deletar este compromisso?',
          buttonColor: '#FF5A5A',
          buttonHoverColor: '#e53c3c',
          buttonText: 'Confirmar',
        },

        appointments: [],
        details: [],

        isMobileCalendar: false,

        currentCreationDate: null,

        range: {
          start: null,
          end: null
        },
      };
    },

    components: {
      'default-view-component': DefaultView,
      'header-component': Header,
      'button-component': Button,
      'snackbar-component': SnackBar,
      'appointment-modal-component': AppointmentModal,
      'confirm-modal-component': ConfirmModal,
      'appointment-calendar': AppointmentCalendar,
      'mobile-appointment-calendar': MobileAppointmentCalendar
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerId',
        'getCurrentSellerData',
        'getSideBarState'
      ])
    },

    mounted() {
      this.fetchData();

      window.addEventListener('resize', () => this.checkCalendar());
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;

        this.appointments = [];
        this.details = [];
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.fetchData();
        }
      },

      'getSideBarState.hideSideBar'() {
        this.checkCalendar();
      },
    },

    methods: {
      showDeleteModal(appointmentId) {
        this.currentAppointmentId = appointmentId;

        this.isShowConfirmModal = true;
      },

      showUpdateModal(appointmentId) {
        this.currentAppointmentId = appointmentId;

        this.isShowModal = true;
      },

      async checkDate(date) {
        const toDate = this.$service.dateFormat(date, 'YYYY-MM-DD');
        const currentDate = this.range.start;
        const isSameMonth = moment(currentDate).isSame(toDate, 'month');

        if (isSameMonth) {
          this.fetchData(toDate);
        } else {
          this.currentCreationDate = toDate;

          await this.$refs.calendar.$refs.calendar.move(toDate);
        }

        this.currentAppointmentId = null;
      },

      checkCalendar() {
        const currentWidth = window.innerWidth;
        this.isMobileCalendar = this.getSideBarState.hideSideBar ? currentWidth < 1220 : currentWidth < 1380;
        return this.isMobileCalendar;
      },

      changePage(range) {
        if (this.currentCreationDate) {
          this.fetchData(this.currentCreationDate);
        } else {
          this.getAllAppointments(range.start);
        }

        this.currentCreationDate = null;
      },

      changeDay() {
        this.details = this.findAttributesByCurrentDate(this.appointments);
      },

      async fetchData(date) {
        let currentDate = date || this.$service.currentDateEN();

        this.range.start = this.$service.toDate(currentDate);
        this.range.end = this.$service.toDate(currentDate);

        await this.getAllAppointments(currentDate);

        this.details = this.findAttributesByCurrentDate(this.appointments);
      },

      async deleteAppointment() {
        this.hasDeleteRequest = true;

        await this.$store.dispatch('appointmentModule/deleteAppointment', this.currentAppointmentId)
          .then(() => {
            this.isShowConfirmModal = false;

            this.checkDate(this.range.start);

            this.showSnackBar('Sucesso ao deletar compromissos', '#08B978');
          })
          .catch(() => {
            this.showSnackBar('Erro ao deletar compromissos', '#FF5A5A');
          });

        this.hasDeleteRequest = false;
      },

      async getAllAppointments(date) {
        this.hasRequest = true;

        const month = this.$service.getMonth(date, 'YYYY-MM-DD');
        const year = this.$service.getYear(date, 'YYYY-MM-DD');

        const queryParams = [
          {
            query: 'month',
            param: month
          },

          {
            query: 'year',
            param: year
          },
        ];

        await this.$store.dispatch('appointmentModule/getAllAppointments', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.appointments = res.items;
          })
          .catch(() => {
            this.showSnackBar('Erro ao buscar compromissos', '#FF5A5A');
          });

        this.hasRequest = false;
      },

      formatAttributes(data) {
        return data.map((item, index) => ({
          key: index,
          customData: item.appointments.map(item => ({
            color: this.parseAppointmentColor(item.type),
            hour: moment(item.date).format('HH:mm'),
            label: item.description,
          })),
          dates: this.$service.toDate(item.date),
        }));
      },

      formatMobileAttributes(data) {
        return data.map((item, index) => ({
          key: index,
          dot: {
            style: {
              'background-color': this.parseAppointmentColor(item.appointments[0].type),
            }
          },
          dates: this.$service.toDate(item.date),
        }));
      },

      findAttributesByCurrentDate(attributes) {
        const result = attributes.find(item => item.date === this.$service.dateFormat(this.range.start))?.appointments;

        if (!result) {
          return [];
        }

        return result.map(item => ({
          id: item.id,
          type: item.type,
          color: this.parseAppointmentColor(item.type),
          hour: moment(item.date).format('HH:mm'),
          label: item.description,
          hasEditButton: this.$service.toUpperCase(item.type) == 'CUSTOM'
        }));
      },

      parseAppointmentColor(type) {
        let color;

        switch (this.$service.toUpperCase(type)) {
          case 'RECEIVABLE':
            color = '#08B978';
            break;
          case 'EXPENSE':
            color = '#CE0202';
            break;
          default:
            color = '#006EFF';
        }

        return color;
      },

      showModal() {
        this.isShowModal = true;
      },

      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },
    },

    beforeDestroy() {
      window.removeEventListener('resize', () => this.checkCalendar());
    }
  };
</script>

<style lang="scss" scoped>
  .appointments-view-container {
    .section {
      padding: 1.5rem 1.5rem 0 1.5rem;

      .header-options {
        display: flex;
        margin-bottom: 1.5rem;

        .button-component {
          margin-left: auto;
          height: 48px;
          max-width: 200px;
        }
      }
    }

    .content {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      padding: 0 1.5rem;

      .appointment-card-container {
        margin-left: 1rem;
        border: 1px solid #DADADA;
        height: fit-content;
        border-radius: 6px;
        max-width: 350px;
        width: 100%;

        .header {
          display: flex;
          padding: 0.7rem;
          border-bottom: 1px solid #DADADA;

          .title {
            font-weight: 500;
          }

          .date {
            margin-left: auto;
          }
        }

        .wrapper {
          padding: 0.7rem;

          .row {
            display: flex;
            margin-bottom: 0.5rem;

            &:last-child {
              margin-bottom: 0;
            }

            .tag-container {
              color: #6D6F6F;
              margin-right: 0.5rem;
              display: flex;
              align-items: baseline;

              .tag {
                height: 8px;
                width: 8px;
                border-radius: 50%;
                margin-right: 0.3rem;
                flex-shrink: 0;
              }
            }

            .label {
              margin-right: 1rem;
            }

            .action {
              margin-left: auto;
              display: flex;
              gap: 1rem;

              i {
                cursor: pointer;

                &.icon-trash {
                  color: #FF5A5A;

                  &:hover {
                    color: #D40000;
                  }
                }

                &.icon-pencil {
                  color: #0052CC;

                  &:hover {
                    color: #003E99;
                  }
                }
              }
            }
          }

          .no-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            margin-bottom: 1rem;
            color: #6D6F6F;

            i {
              padding: 1.5rem;
              font-size: 5rem;
              color: #DADADA;
            }
          }
        }
      }
    }
  }

  @media (max-width: 920px) {
    .appointments-view-container {
      .content {
        flex-direction: column;

        .appointment-card-container {
          margin-top: 2rem;
          max-width: unset;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 605px) {
    .appointments-view-container {
      .section {
        .header-options {
          flex-direction: column-reverse;
          margin-bottom: 1rem;

          .button-component {
            height: 32px;
          }
        }
      }
    }
  }
</style>