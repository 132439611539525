<template>
  <simple-default-view-component class="simple-default-view-component">
    <template v-slot:alert>
      <snackbar-component ref="snackbar" />
    </template>

    <template v-slot:header>
      <div class="title">
        <h2>Nova venda</h2>
      </div>

      <div class="icon-container">
        <i class="icon-close" @click="close" />
      </div>
    </template>

    <template v-slot:content>
      <div class="content">
        <div class="column-container fixed">
          <div class="column">
            <h3>Descrição</h3>

            <p>Alguma descrição adicional?</p>

            <textarea-component
              class="textarea-component"
              placeholder="Digite a descrição dessa venda aqui..."
              v-model="resumeDescription"
              maxHeight="200px"
              minHeight="110px"
              maxWidth="455px"
            />

            <div class="image-container">
              <p>Antes de finalizar, confira o resumo da venda...</p>

              <img src="@/assets/images/illustration-description.png" />
            </div>
          </div>
        </div>

        <div class="vertical-line">
          <div class="line"></div>
        </div>

        <div class="column-container scrollable">
          <div class="column">
            <h3>Resumo</h3>

            <div class="items-container">
              <div v-for="(item, index) in resumeData" :key="index" class="item-content">
                <div class="item">
                  <p class="label">{{ item.label }}</p>

                  <div v-if="item.values" class="values">
                    <p class="value" v-for="(item, index) in item.values" :key="index">{{ item.value }}</p>
                  </div>

                  <div v-else-if="item.params" class="params">
                    <p class="label">{{ item.params.label }}</p>

                    <p class="value">{{ item.params.value }}</p>
                  </div>

                  <p v-else class="value">{{ item.value }}</p>
                </div>

                <div v-if="item.items" class="items">
                  <div v-for="(item, index) in item.items" :key="index" class="item">
                    <p class="label">{{ item.label }}</p>

                    <div v-if="Array.isArray(item.params)" class="params text">
                      <p class="value" v-for="(item, index) in item.params" :key="index">{{ item.value }}</p>
                    </div>

                    <div v-else-if="item.params && item.params.label" class="params">
                      <p class="label">{{ item.params.label }}</p>

                      <p class="value">{{ item.params.value }}</p>
                    </div>

                    <p v-else class="value">{{ item.value }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="footer-container">
        <button-component
          class="button-back-component"
          iconLeft="icon-arrow-left"
          styles="secondary"
          text="Voltar"
          @click.native="toNew"
          :isDisabled="hasRequest"
          v-ripple="$theme.secondColorDarkTransparent"
        />

        <button-component
          class="button-component"
          text="Próximo"
          :hasLoader="hasRequest"
          :isDisabled="hasRequest"
          @click.native="toSuccess"
          v-ripple="$theme.whiteColorTransparent"
        />
      </div>
    </template>
  </simple-default-view-component>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SimpleDefaultView from '@/components/Layout/SimpleDefaultView';
  import TextArea from '@/components/Base/TextAreaField';
  import Button from '@/components/Base/Button';
  import SnackBar from '@/components/Base/SnackBar';

  export default {
    name: 'NewSaleResumeView',

    data() {
      return {
        hasRequest: false,

        customerData: {},
        resumeDescription: '',

        saleId: undefined,

        resourceId: null,
        resourceUrl: null,

        payments: [
          {
            label: 'Link pagamento',
            value: 'checkout'
          },

          {
            label: 'Boleto',
            value: 'bank_slip'
          },

          {
            label: 'Dinheiro',
            value: 'money'
          },

          {
            label: 'Débito',
            value: 'debit'
          },

          {
            label: 'Crédito',
            value: 'credit'
          },
        ],

        resumeData: []
      };
    },

    components: {
      'simple-default-view-component': SimpleDefaultView,
      'textarea-component': TextArea,
      'button-component': Button,
      'snackbar-component': SnackBar,
    },

    computed: {
      ...mapGetters({
        getCurrentSellerSaleStatus: 'getCurrentSellerSaleStatus',
        getSaleProgress: 'newSaleModule/getProgressStatus',
        getSaleData: 'newSaleModule/getData',
        getResumeDescription: 'newSaleModule/getResumeDescription'
      })
    },

    mounted() {
      if (!this.$route.params?.hasNewSale || !this.getSaleProgress) {
        this.toNew();
      } else if (!this.getCurrentSellerSaleStatus) {
        this.close();
      } else {
        this.resumeData = this.formatData();
        this.resumeDescription = this.getResumeDescription;
      }
    },

    methods: {
      close() {
        this.$router.push('/dashboard');

        this.$store.commit('newSaleModule/resetData');
      },

      toNew() {
        this.$router.push({
          name: 'new-sale',
          params: {
            hasNewSale: true
          }
        });
      },

      formatData() {
        var saleItem = this.getSaleData.items.map(item => {
          return {
            label: item.description,
            value: this.$service.formatBrlAmountSymbol(item.price)
          };
        });

        var paymentMethodItem = this.getSaleData.checkoutPaymentTypeData.reduce((res, item) => {
          if (item.isActive) {
            let isCredit = item.value == 'credit';

            res.push({
              label: item.text,
              params: {
                label: isCredit ? 'Até' : '',
                value: isCredit ? this.getSaleData.maxInstallments + 'x de ' + this.$service.formatBrlAmountSymbol(this.getSaleData.totalAmount / this.getSaleData.maxInstallments) : ''
              }
            });
          }

          return res;
        }, []);

        var data = [
          {
            key: 'paymentType',
            label: 'Como você quer vender',
            value: this.getPaymentMethodLabel()
          },

          {
            key: 'customer',
            label: 'Cliente',
            values: [
              {
                value: this.getSaleData.selectedCustomer?.name
              },

              {
                value: this.$service.formatTaxpayerId(this.getSaleData.customer.taxpayerId)
              }
            ]
          },

          {
            key: 'saleItem',
            label: 'O que você esta vendendo',

            params: {
              label: 'Valor total:',
              value: this.$service.formatBrlAmountSymbol(this.formatTotalAmount())
            },

            items: saleItem
          },

          {
            key: 'paymentMethod',
            label: 'Qual a opção de pagamento',
            items: paymentMethodItem
          },

          {
            key: 'paymentInterestAndFine',
            label: 'Instruções de cobrança',
            items: [
              {
                label: 'Multa',
                value: this.getSaleData.lateFeeRate ? this.getSaleData.lateFeeRate + '%' : 'Nenhum'
              },

              {
                label: 'Juros',
                value: this.getSaleData.dailyInterestRate ? this.getSaleData.dailyInterestRate + '% ao mês' : 'Nenhum'
              },

              {
                label: 'Desconto',
                value: this.getSaleData.discount ? this.$service.formatBrlAmountSymbol(this.getSaleData.discount) : 'Nenhum'
              }
            ]
          },

          {
            key: 'dueDate',
            label: 'Quando vai vencer',
            value: this.$service.dateFormat(this.getSaleData.dueDate, 'YYYY-MM-DD', 'DD/MM/YYYY')
          }
        ];

        if (!this.isCheckout()) {
          data = data.filter(item => item.key != 'paymentMethod');
        }

        return data;
      },

      getPaymentMethodLabel() {
        return this.payments.find(item => item.value == this.getSaleData.paymentMethod)?.label;
      },

      async createSlip() {
        var data = {
          amount: Math.round(this.formatTotalAmount() * 100),
          metadata: {
            resource: 'gerencia_ai',
            sale_id: this.saleId
          },
          customer: {
            taxpayer_id: this.getSaleData.customer.tin,
            first_name: this.$service.splitFirstName(this.getSaleData.customer.name),
            last_name: this.$service.splitLastName(this.getSaleData.customer.name),
            address: {
              line1: this.getSaleData.customer.addressLine1,
              neighborhood: this.getSaleData.customer.addressNeighborhood,
              city: this.getSaleData.customer.addressCity,
              state_code: this.getSaleData.customer.addressStateCode,
              postal_code: this.getSaleData.customer.addressPostalCode
            }
          },
          payment: {
            method: 'Boleto',
            boleto: {
              due_date: this.$service.dateFormat(this.getSaleData.dueDate),
              billing_instructions: {}
            }
          },
        };

        if (this.getSaleData.lateFeeRate) {
          data.payment.boleto.billing_instructions.late_fee = {
            percentage: this.getSaleData.lateFeeRate
          };
        }

        if (this.getSaleData.dailyInterestRate) {
          data.payment.boleto.billing_instructions.interest = {
            percentage: this.getSaleData.dailyInterestRate
          };
        }

        if (this.getSaleData.discount) {
          data.payment.boleto.billing_instructions.discount = {
            amount: this.getSaleData.discount * 100
          };
        }

        await this.$store.dispatch('postTransaction', JSON.stringify(data))
          .then(res => {
            this.resourceId = res.data.id;
            this.resourceUrl = res.data.payment.boleto.url;

            this.$store.commit('newSaleModule/setUrl', this.resourceUrl);
          })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao gerar boleto', '#FF5A5A');
          });
      },

      async createCheckout() {
        await this.$store.dispatch('paymentModule/postCheckout', JSON.stringify({
          customer_name: this.getSaleData.selectedCustomer.name,
          description: 'Link pagamento',
          metadata: {
            resource: 'gerencia_ai',
            sale_id: this.saleId
          },
          items: this.formatCheckoutItems(),
          is_pix_enabled: this.getSaleData.checkoutPaymentTypeData.find(item => item.value == 'pix')?.isActive,
          is_credit_enabled: this.getSaleData.checkoutPaymentTypeData.find(item => item.value == 'credit')?.isActive,
          due_date: this.$service.dateFormat(this.getSaleData.dueDate),
          late_fee_rate: this.getSaleData.lateFeeRate,
          daily_interest_rate: this.getSaleData.dailyInterestRate
        }))
        .then(res => {
          this.resourceId = res.data.id;
          this.resourceUrl = process.env.VUE_APP_BASE_CHECKOUT_URL + '/checkout/' + res.data.id;

          this.$store.commit('newSaleModule/setUrl', this.resourceUrl);
        })
        .catch(() => {
          this.$refs?.snackbar?.show('Erro ao gerar link de pagamento', '#FF5A5A');
        });
      },

      formatCheckoutItems() {
        return this.getSaleData.items.map(item => ({
          price: item.price,
          quantity: 1,
          description: item.description,
          max_installments: +this.getSaleData.maxInstallments,
        }));
      },

      formatTotalAmount() {
        return this.getSaleData.items.reduce((total, item) => total + item.price, 0);
      },

      async createSale() {
        await this.$store.dispatch('saleModule/postSale', JSON.stringify({
          customer_id: this.getSaleData.selectedCustomer.id,
          description: this.resumeDescription,
          payment_type: this.getSaleData.paymentMethod,
          due_date: this.$service.dateFormat(this.getSaleData.dueDate),
          discount: parseInt(this.getSaleData.discount) || 0,
          late_fee_rate: parseInt(this.getSaleData.lateFeeRate),
          daily_interest_rate: parseInt(this.getSaleData.dailyInterestRate),
          installments: parseInt(this.getSaleData.maxInstallments) || 1,
          items: this.getSaleData.items.map(item => ({
            id: item.itemId,
            price: parseInt(item.price),
            quantity: parseInt(item.quantity),
            description: item.description,
            type: item.type.find(type => type.isActive).value,
          }))
        }))
        .then(res => {
          this.saleId = res.id;
        })
        .catch(() => {
          this.$refs?.snackbar?.show('Erro ao gerar venda', '#FF5A5A');
        });
      },

      async patchSale() {
        const data = {
          resource_id: this.resourceId,
          resource_url: this.resourceUrl,
        };

        await this.$store.dispatch('saleModule/patchSale', { id: this.saleId, data })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao atualizar venda', '#FF5A5A');
          });
      },

      async toSuccess() {
        this.hasRequest = true;
        this.isCreationSuccess = false;

        await this.createSale();

        if (this.isOnline() && this.saleId) {
          if (this.isCheckout()) {
            await this.createCheckout();
          } else {
            await this.createSlip();
          }

          await this.patchSale();
        }

        if (this.saleId && (!this.isOnline() || this.resourceId)) {
          this.$router.push({
            name: 'new-sale-success',
            params: {
              hasNewSale: true
            }
          });
        } else {
          await this.cancelSale();
        }

        this.hasRequest = false;
      },

      async cancelSale() {
        if (!this.saleId) return;

        await this.$store.dispatch('saleModule/cancelSale', this.saleId)
          .then(() => {
            this.saleId = undefined;
          })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao cancelar venda', '#FF5A5A');
          });
      },

      isCheckout() {
        return this.$service.toUpperCase(this.getSaleData.paymentMethod) == 'CHECKOUT';
      },

      isOnline() {
        const method = this.$service.toUpperCase(this.getSaleData.paymentMethod);

        return method == 'CHECKOUT' || method == 'BANK_SLIP';
      }
    },

    beforeDestroy() {
      this.$store.commit('newSaleModule/setResumeDescription', this.resumeDescription);
    }
  };
</script>

<style lang="scss" scoped>
  .simple-default-view-component {
    .title {
      display: flex;
      align-items: center;

      h2 {
        color: #333333;
      }
    }

    .content {
      display: flex;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;

      .vertical-line {
        padding: 1.5rem;

        .line {
          width: 1px;
          height: calc(100% - 210px);
          position: absolute;
          background: #EFEFEF;
        }
      }

      .column-container {
        width: 100%;

        &.fixed {
          padding: 1.5rem;
        }

        &.scrollable {
          position: relative;
          margin: 1.5rem 0;
          width: 100%;

          .column {
            padding: 1.5rem;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            background-color: #fff;
            overflow-y: auto;
          }
        }

        .column {
          width: 100%;

          &:first-child {
            margin-right: 2rem;
          }

          h3 {
            margin-bottom: 0.5rem;
          }

          .image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: #F9F9F9;
            padding: 2rem;
            border-radius: 6px;
            margin-top: 3rem;

            p {
              margin-bottom: 1rem;
              font-weight: 600;
              color: #333;
              font-size: 16px;
              text-align: center;
            }
          }

          .textarea-component {
            margin-top: 1rem;
          }

          .items-container {
            .item-content {
              display: flex;
              flex-direction: column;
              margin-bottom: 3rem;
              margin-top: 1rem;

              .item {
                display: flex;
                width: 100%;

                .label {
                  font-weight: 500;
                }

                .value {
                  display: flex;
                  margin-left: auto;
                }

                .values {
                  flex-direction: column;
                  margin-left: auto;

                  .value {
                    justify-content: end;
                  }
                }

                .params {
                  display: flex;
                  margin-left: auto;

                  .label {
                    font-weight: 300;
                  }

                  .value {
                    margin-left: 0.5rem;
                    font-weight: 500;
                  }
                }
              }

              .items {
                .item {
                  margin-top: 0.5rem;

                  .params {
                    &.text {
                      flex-direction: column;

                      .value {
                        font-weight: 300;
                      }
                    }
                  }

                  .label {
                    font-weight: 300;
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer-container {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 1rem;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);
      width: 100%;
      background: $white-color;
      position: fixed;
      bottom: 0;
      z-index: 10;

      .button-component {
        height: 48px;
        width: 180px;
        margin-left: auto;
      }

      .button-back-component {
        height: 48px;
      }
    }
  }

  @media (max-width: 945px) {
    .simple-default-view-component {
      .title {
        h2 {
          font-size: 20px;
        }
      }

      .content {
        flex-direction: column;
        padding: 1.5rem;

        .vertical-line {
          display: none;
        }

        .column-container {
          &.fixed {
            padding: 0;
          }

          &.scrollable {
            margin: unset;

            .column {
              position: unset;
              padding: 0;
            }
          }

          .column {
            &:first-child {
              margin-right: 0;
              margin-bottom: 1.5rem;
            }

            .image-container {
              display: none;
            }

            .items-container {
              .item-content {
                margin-bottom: 2rem;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .simple-default-view-component {
      .title {
        h2 {
          font-size: 18px;
        }
      }

      .content {
        margin-bottom: 60px;
      }

      .footer-container {
        height: 56px;
        padding: 0 1.5rem;

        .button-component {
          height: 32px;
          width: 120px;
        }
      }
    }
  }

  @media (max-width: 440px) {
    .simple-default-view-component {
      .content {
        .column-container {
          .column {
            .items-container {
              .item-content {
                .item {
                  flex-direction: column;

                  .label {
                    margin-bottom: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>