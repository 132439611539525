<template>
  <div class="account-security-view-container">
    <default-view-component :hideForceResetPassword="true" :hasModal="hasModalUserEdit || hasResetPasswordModal">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <modal
          ref="modal"
          title="Editar dados"
          :showModal="hasModalUserEdit || hasResetPasswordModal"
          @close="closeModalEdit"
        >
          <template v-slot:body>
            <ValidationObserver
              tag="div"
              class="body"
              ref="form"
              v-slot="{ passes }"
            >
              <form @submit.prevent="passes(hasResetPasswordModal ? submitEditPassword : submitEditData)">
                <div class="wrapper">
                  <div class="section">
                    <div v-if="hasResetPasswordModal" class="two-column">
                      <input-field
                        class="input-field"
                        name="currentPassword"
                        placeholder="Senha atual"
                        rules="required|min:6"
                        type="password"
                        v-model="changePasswordData.password"
                      />

                      <input-field
                        class="input-field"
                        name="newPassword"
                        placeholder="Nova senha"
                        :passwordValidation="true"
                        rules="required|min:6|passwordCombination"
                        type="password"
                        v-model="changePasswordData.newPassword"
                      />
                    </div>

                    <div v-else class="two-column">
                      <input-field
                        class="input-field"
                        placeholder="E-mail"
                        type="text"
                        rules="required|mail"
                        v-model="editAccountSortData.email"
                      />

                      <input-field
                        class="input-field"
                        placeholder="Telefone"
                        type="text"
                        rules="required|phone"
                        :mask="['(##) ####-####', '(##) #####-####']"
                        v-model="editAccountSortData.phone"
                      />
                    </div>
                  </div>

                  <div class="button-container">
                    <button-component
                      scale="normal"
                      size="large"
                      v-ripple="$theme.secondColorDarkTransparent"
                      styles="ghost"
                      type="button"
                      :isDisabled="hasRequest"
                      @click.native="closeModalEdit"
                      text="Fechar"
                    />

                    <button-component
                      class="right"
                      scale="normal"
                      size="large"
                      v-ripple="$theme.whiteColorTransparent"
                      styles="primary"
                      :isDisabled="hasRequest"
                      :hasLoader="hasRequest"
                      text="Alterar e salvar"
                    />
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </modal>
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            class="header"
            :data="header"
          />

          <div class="center">
            <default-card-component
              class="card-container"
              :data="cardData"
            />

            <data-component
              class="account-data-container"
              :data="formattedUserData"
              @click="clickEditButton"
            />
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { ValidationObserver } from 'vee-validate';
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';
  import Header from '@/components/Layout/Header';
  import AccountSortData from '@/components/AccountSortData';
  import InputField from '@/components/Base/InputField';
  import DefaultView from '@/components/Layout/DefaultView';
  import AccountDefaultCard from '@/components/AccountDefaultCard';
  import SnackBar from '@/components/Base/SnackBar';

  export default {
    name: 'AccountSecurityView',

    data() {
      return {
        hasRequest: false,
        hasResetPasswordModal: true,
        header: {
          title: 'Segurança e login',
          to: '/account',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        options: {},
        notificationData: {},

        editAccountSortData: {
          email: '',
          phone: ''
        },

        changePasswordData: {
          password: '',
          newPassword: '',
        },

        formattedUserData: [
          {
            sectionTitle: 'Dados de cadastro',
            mutation: 'toogle_account_modal_edit',
            isEditable: true,
            columnLeft: [
              {
                title: 'Nome completo',
                text: 'default'
              },

              {
                title: 'Email',
                text: 'default'
              }
            ],

            columnRight: [
              {
                title: 'CPF',
                text: 'default'
              },

              {
                title: 'Telefone',
                text: 'default'
              }
            ]
          }
        ],

        cardData: {
          icon: 'icon-security',
          title: 'Login e segurança',
          text: 'Mantenha sempre seus dados atualizados'
        }
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'button-component': Button,
      'header-component': Header,
      'data-component': AccountSortData,
      'input-field': InputField,
      'default-card-component': AccountDefaultCard,
      'default-view-component': DefaultView,
      'snackbar-component': SnackBar,
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerStatus',
        'getSideBarState'
      ]),

      ...mapState({
        userData: state => state.userData,
        hasModalUserEdit: state => state.hasModalUserEdit
      })
    },

    created() {
      this.$store.commit('closeModalEdit');

      this.hasResetPasswordModal = !this.$service.isUndefined(this.$route.params.showResetPasswordModal);
    },

    mounted() {
      this.setDefaultValues();

      this.formattedUserData = [
        {
          sectionTitle: 'Dados cadastrais',
          mutation: 'setToggleModalUserEdit',
          isEditable: true,
          columnLeft: [
            {
              title: 'Nome completo',
              text: this.userData.name
            },

            {
              title: 'Email',
              text: this.userData.email
            }
          ],

          columnRight: [
            {
              title: 'CPF',
              text: this.$service.formatTaxpayerId(this.userData.taxpayerId)
            },

            {
              title: 'Telefone',
              text: this.$service.formatPhone(this.userData.phone)
            }
          ]
        },

        {
          sectionTitle: 'Senha',
          isEditable: true,
        }
      ];
    },

    methods: {
      closeModalEdit() {
        this.setDefaultValues();

        this.$store.commit('closeModalEdit');

        this.hasResetPasswordModal = false;
      },

      clickEditButton() {
        this.hasResetPasswordModal = true;
        this.changePasswordData.password = '';
        this.changePasswordData.newPassword = '';
      },

      async submitEditData() {
        this.hasRequest = true;

        let formData = {};
        let editAccountSortDataObj = JSON.stringify({
          email: this.editAccountSortData.email,
          phone_number: this.$service.removeCharacter(this.editAccountSortData.phone),
        });

        JSON.parse(editAccountSortDataObj, (key, value) => {
          if (!!value != '' && !!Object.values(value).length) {
            formData[key] = value;
          }
        });

        if(!this.$service.isEmptyObject(formData)) {
          await this.$store.dispatch('putUser', { formData })
            .catch(() => {
              this.$refs?.snackbar?.show('Erro ao editar os dados cadastrais', '#FF5A5A');
            });

          await this.$store.dispatch('getUser')
            .then(res => {
              this.formattedUserData[0].columnLeft[1].text = res.data.email;
              this.formattedUserData[0].columnRight[1].text = this.$service.formatPhone(res.data.phone_number);
              this.editAccountSortData.email = res.data.email;
              this.editAccountSortData.phone = res.data.phone_number;

              this.$refs?.snackbar?.show('Dados editados com sucesso', '#08B978');
            })
            .catch(() => {
              this.$refs?.snackbar?.show('Erro ao atualizar os dados', '#FF5A5A');
            });

          this.closeModalEdit();
          this.hasRequest = false;
        } else {
          this.$refs?.snackbar?.show('Erro ao editar os dados cadastrais', '#FF5A5A');

          this.closeModalEdit();
        }
      },

      async submitEditPassword() {
        this.hasRequest = true;

        let data = JSON.stringify({
          password: this.changePasswordData.password,
          new_password: this.changePasswordData.newPassword
        });

        await this.$store.dispatch('authModule/putChangePassword', data)
          .then(() => {
            this.$refs?.snackbar?.show('Dados editados com sucesso', '#08B978');

            this.closeModalEdit();
          })
          .catch(err => {
            if (err.data.status === 403) {
              this.$refs.form.setErrors({
                currentPassword: 'Senha incorreta',
              });
            } else {
              this.$refs?.snackbar?.show('Erro ao atualizar os dados', '#FF5A5A');
            }
          });

        this.hasRequest = false;
      },

      hasValue(field) {
        return field.length > 0;
      },

      setDefaultValues() {
        this.editAccountSortData.email = this.userData.email;
        this.editAccountSortData.phone = this.userData.phone;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .account-security-view-container {
    .modal {
      .body {
        h2 {
          text-align: center;
          margin-bottom: 1.5rem;
        }

        .wrapper {
          .section {
            h3 {
              color: #333;
              margin-bottom: 1rem;
            }

            .two-column {
              display: flex;

              .input-field {
                &:last-child {
                  margin-left: 1rem;
                }
              }
            }
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: flex-end;

        .right {
          margin-left: 1rem;
          min-width: 156px;
        }
      }
    }

    .section {
      padding: 1.5rem;

      .header {
        margin-bottom: 3rem;
      }

      .center {
        display: flex;
        justify-content: center;

        .card-container {
          margin-right: 2rem;
        }

        .account-data-container {
          width: 700px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .account-security-view-container {
      .modal {
        h2 {
          font-size: 16px;
        }
      }

      .section {
        .center {
          display: unset;

          .card-container {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .account-security-view-container {
      .modal {
        .body {
          .wrapper {
            overflow-y: scroll;
            max-height: 72vh;
            margin-bottom: 4rem;

            &::-webkit-scrollbar {
              display: none;
            }

            .section {
              padding: 1.5rem 0;

              .two-column {
                flex-direction: column;

                .input-field {
                  &:last-child {
                    margin-left: 0;
                  }
                }
              }
            }

            .button-container {
              justify-content: center;
            }
          }
        }
      }
    }
  }
</style>