import { instance } from '../../config/axios';
import store from '../index';

const actions = {
  postService(_, data) {
    return new Promise((resolve, reject) => {
      instance().post(`/sellers/${store.state.currentSellerId}/services`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllServices(_, params) {
    return new Promise((resolve, reject) => {
      instance().get(`/sellers/${store.state.currentSellerId}/services` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  enableService(_, id) {
    return new Promise((resolve, reject) => {
      instance().put(`/sellers/${store.state.currentSellerId}/services/${id}/active`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  disableService(_, id) {
    return new Promise((resolve, reject) => {
      instance().put(`/sellers/${store.state.currentSellerId}/services/${id}/disable`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
};

export default {
  namespaced: true,
  actions
};