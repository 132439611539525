import { instance } from '../../config/axios';
import store from '../index';

const actions = {
  postCustomer(_, customer) {
    return new Promise((resolve, reject) => {
      instance().post(`/sellers/${store.state.currentSellerId}/customers`, customer.data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getCustomerId(_, customerId) {
    return new Promise((resolve, reject) => {
      instance().get(`/sellers/${store.state.currentSellerId}/customers/${customerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  patchCustomer(_, customer) {
    return new Promise((resolve, reject) => {
      instance().patch(`/sellers/${store.state.currentSellerId}/customers/${customer.id}`, customer.data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllCustomers(_, params) {
    return new Promise((resolve, reject) => {
      instance().get(`/sellers/${store.state.currentSellerId}/customers` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllCustomersFind(_, search) {
    return new Promise((resolve, reject) => {
      instance().get(`/sellers/${store.state.currentSellerId}/customers/find?search=${search}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

export default {
  namespaced: true,
  actions
};