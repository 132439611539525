<template>
  <div
    class="view-container"
    :class="{
      'has-modal': hasModal || showSellerStatusInformationModal || getIsResetPassword && !hideForceResetPassword,
      'has-sidebar': !getSideBarState.hideSideBar
    }"
  >
    <slot name="alert"/>

    <slot name="modal"/>

    <force-reset-password-modal v-if="getIsResetPassword && !hideForceResetPassword"/>

    <seller-status-information-modal
      v-if="showSellerStatusInformationModal"
      @close="showSellerStatusInformationModal = false"
    />

    <notice-card-component
      v-if="showNotice"
      :options="noticeCardData"
      @click="noticeAction"
    />

    <div class="view-content">
      <side-bar-component/>

      <div class="content" :class="{
        'hide-sidebar': getSideBarState.hideSideBar,
        'show-sidebar': !getSideBarState.hideSideBar
      }">
        <nav-bar-component
          :hasSelect="true"
        />

        <div class="section-container">
          <slot name="content"/>
        </div>
      </div>
    </div>

    <floating-button-component
      icon="icon-wpp"
      bgColor="#25d366"
      @click="redirectToWpp"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SideBar from '@/components/Layout/SideBar';
  import NavBar from '@/components/Layout/NavBar';
  import NoticeCard from '@/components/NoticeCard';
  import FloatingButton from '@/components/Base/FloatingButton';
  import ForceResetPasswordModal from '@/components/Modal/ForceResetPasswordModal';
  import SellerStatusInformationModal from '@/components/Modal/SellerStatusInformationModal';

  export default {
    name: 'DefaultView',

    data() {
      return {
        showNotice: false,
        showSellerStatusInformationModal: false,

        noticeCardData: {
          backgroundColor: '',
          textColor: '',
          iconName: '',
          title: '',
          text: ''
        }
      };
    },

    props: {
      hasModal: {
        type: Boolean,
        default: false
      },

      hideForceResetPassword: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapGetters({
        getSideBarState: 'getSideBarState',
        getCurrentSellerId: 'getCurrentSellerId',
        getCurrentSellerData: 'getCurrentSellerData',
        getCurrentSellerStatus: 'getCurrentSellerStatus',
        getIsSellerActive: 'getIsSellerActive',
        getIsResetPassword: 'authModule/getIsResetPassword'
      })
    },

    components: {
      'side-bar-component': SideBar,
      'nav-bar-component': NavBar,
      'notice-card-component': NoticeCard,
      'force-reset-password-modal': ForceResetPasswordModal,
      'seller-status-information-modal': SellerStatusInformationModal,
      'floating-button-component': FloatingButton
    },

    watch: {
      getCurrentSellerId() {
        this.showNotice = false;
      },

      'getCurrentSellerData.id'() {
        this.showNotice = !this.getIsSellerActive;

        this.handleSellerNotice();
      }
    },

    mounted() {
      if (this.getCurrentSellerData.status) {
        this.showNotice = !this.getIsSellerActive;

        this.handleSellerNotice();
      }

      this.showSellerStatusInformationModal = !!this.$route.meta.showStatusInfoModal
        && this.$service.toUpperCase(this.getCurrentSellerStatus) == 'PENDING';
    },

    methods: {
      noticeAction(event) {
        const eventType = this.$service.toUpperCase(event);
        if (eventType == 'INFO') {
          this.toggleSellerStatusModal();
          return;
        }
      },

      toggleSellerStatusModal() {
        this.showSellerStatusInformationModal = !this.showSellerStatusInformationModal;
      },

      handleSellerNotice() {
        const sellerStatus = this.$service.toUpperCase(this.getCurrentSellerStatus);
        if (sellerStatus == 'PENDING') {
          this.noticeCardData = {
            backgroundColor: '#DFF0F7',
            textColor: '#1B87B1',
            iconName: 'icon-information-circle',
            title: 'Conta pendente.',
            text: 'Enquanto a conta não for verificada seu acesso poderá ser limitado.',
            button: {
              action: 'info',
              text: 'Saiba mais'
            }
          };
          return;
        }
      },

      redirectToWpp() {
        const text = `Olá, estou tendo dúvidas sobre o ${this.$service.getPlatformTitle()}. Podem me ajudar?`;
        this.$service.openWpp(text);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .view-container {
    min-height: 100vh;
    min-width: 320px;
    overflow-y: hidden;
    font-size: 14px;
    color: #333;

    &.has-modal {
      overflow: hidden;
      position: fixed;
      width: 100vw;
      min-width: 320px;
    }

    &.has-modal > *:not(.modal) {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    .view-content {
      display: flex;

      .content {
        width: 100%;

        &.hide-sidebar {
          width: calc(100% - 80px);
        }

        &.show-sidebar {
          width: calc(100% - 240px);
        }
      }
    }
  };

  @media (max-width: 480px) {
    .view-container {
      &.has-sidebar {
        overflow: hidden;
        height: 100vh;
      }

      .view-content {
        .content {
          &.hide-sidebar {
            width: 100%;
          }

          &.show-sidebar {
            width: 100%;
          }
        }
      }
    }
  }
</style>