<template>
  <div class="dashboard-view-container">
    <default-view-component :hasModal="withdrawModal">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <withdraw-modal-component
          v-if="withdrawModal"
          @snackbarNotification="showSnackBar"
          @close="closeWithdrawModal"
        />
      </template>

      <template v-slot:content>
        <div class="section" :class="{ 'hide-sidebar': getSideBarState.hideSideBar }">
          <div class="image-container">
            <div class="image-background">
              <div class="image" :style="{ 'background-image': 'url(' + $service.getPlatformImagesPath('dashboard-banner.png') + ')' }"></div>
            </div>
          </div>

          <div class="dashboard-header">
            <button-component
              class="button-component"
              scale="normal"
              text="Nova venda"
              :isDisabled="hasCurrentBalanceRequest || getIsSellerPending || !getCurrentSellerSaleStatus"
              pathView="/sales/new"
              v-ripple="$theme.whiteColorTransparent"
            />

            <div class="actions-container">
              <div class="actions-content">
                <action-button-component
                  v-for="(item, index) in shortcutButtons"
                  :key="index" :data="item"
                  class="action-button-component"
                  :isDisabled="hasCurrentBalanceRequest || item.isDisabled"
                  @newAction="resolveShortcutAction(item)"
                />
              </div>

              <div class="balance-card">
                <div class="content">
                  <h3>Saldo <br> disponível</h3>

                  <div class="value">
                    <skeleton-loader-component
                      v-if="hasCurrentBalanceRequest"
                      :hasDashboard="true"
                      class="default"
                    />

                    <div
                      v-else
                      :class="{ 'blur': getDashboardBalanceValueState }"
                    >
                      <h2 v-if="getDashboardBalanceValueState">R$ *****</h2>

                      <h2 v-else>{{ getSellerBalance() }}</h2>
                    </div>

                    <i :class="getDashboardBalanceValueState ? 'icon-eye' : 'icon-eye-slash'" @click="toggleBalanceStatus"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="metrics-container">
            <div class="metrics-content">
              <div class="metrics">
                <metrics-card-component
                  title="Faturamento"
                  :isMoney="true"
                  :value="getTotalSalesBilling"
                  :hasRequest="hasCurrentBalanceRequest || hasMetricsRequest"
                />

                <metrics-card-component
                  title="Novos clientes"
                  :value="getTotalNewCustomers"
                  :hasRequest="hasCurrentBalanceRequest || hasMetricsRequest"
                />

                <metrics-card-component
                  title="Vendas"
                  :value="getTotalSalesSucceeded"
                  :hasRequest="hasCurrentBalanceRequest || hasMetricsRequest"
                />
              </div>

              <div class="cards">
                <ranking-card-component
                  title="Mais vendidos"
                  :data="getSalesRanking"
                  buttonText="Minhas vendas"
                  buttonPath="/sales-receivables"
                  :hasRequest="hasCurrentBalanceRequest || hasMetricsRequest"
                />

                <sales-payment-type-card-component
                  title="Recebimentos"
                  :data="getSalesByPaymentType"
                  :hasRequest="hasCurrentBalanceRequest || hasMetricsRequest"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import SkeletonLoader from '@/components/Base/SkeletonLoader';
  import MetricsCard from '@/components/Dashboard/MetricsCard';
  import RankingCard from '@/components/Dashboard/RankingCard';
  import SalesPaymentTypeCard from '@/components/Dashboard/SalesPaymentTypeCard';
  import ActionButton from '@/components/Dashboard/ActionButton';
  import WithdrawModal from '@/components/Modal/WithdrawModal';
  import Button from '@/components/Base/Button';
  import SnackBar from '@/components/Base/SnackBar';

  export default {
    name: 'DashboardView',

    data() {
      return {
        hasCurrentBalanceRequest: true,
        hasMetricsRequest: false,

        withdrawModal: false,

        shortcutButtons: [
          {
            icon: 'icon-bank-dollar',
            label: 'Adicionar conta',
            value: 'new_bank_account'
          },

          {
            icon: 'icon-pay',
            label: 'Sacar dinheiro',
            value: 'withdraw'
          },

          {
            icon: 'icon-question',
            label: 'Dúvidas',
            value: 'help'
          },
        ]
      };
    },

    components: {
      'skeleton-loader-component': SkeletonLoader,
      'button-component': Button,
      'metrics-card-component': MetricsCard,
      'ranking-card-component': RankingCard,
      'sales-payment-type-card-component': SalesPaymentTypeCard,
      'default-view-component': DefaultView,
      'withdraw-modal-component': WithdrawModal,
      'action-button-component': ActionButton,
      'snackbar-component': SnackBar,
    },

    computed: {
      ...mapGetters({
        getCurrentSellerData: 'getCurrentSellerData',
        getCurrentSellerBankingStatus: 'getCurrentSellerBankingStatus',
        getCurrentSellerSaleStatus: 'getCurrentSellerSaleStatus',
        getCurrentSellerId: 'getCurrentSellerId',
        getDashboardBalanceValueState: 'getDashboardBalanceValueState',
        getAccountBalance: 'getAccountBalance',
        getSubBalance: 'getSubBalance',
        getSideBarState: 'getSideBarState',
        getIsSellerActive: 'getIsSellerActive',
        getIsSellerPending: 'getIsSellerPending',
        getCurrentSellerStatus: 'getCurrentSellerStatus',
        getTotalSalesSucceeded: 'metricModule/getTotalSalesSucceeded',
        getTotalSalesBilling: 'metricModule/getTotalSalesBilling',
        getTotalNewCustomers: 'metricModule/getTotalNewCustomers',
        getSalesByPaymentType: 'metricModule/getSalesByPaymentType',
        getSalesRanking: 'metricModule/getSalesRanking'
      })
    },

    watch: {
      getCurrentSellerId() {
        this.hasCurrentBalanceRequest = true;
        this.hasMetricsRequest = true;

        this.resetShortcutButtonsState();

        this.$store.commit('bankAccountModule/resetState');
      },

      'getCurrentSellerData.id'(value, oldValue) {
        if (value && oldValue) {
          this.fetchData();
        }
      },
    },

    created() {
      this.fetchData();
    },

    methods: {
      resetShortcutButtonsState() {
        this.shortcutButtons.forEach((item) => {
          item.isDisabled = false;
        });
      },

      resolveShortcutAction(item) {
        if (this.$service.toUpperCase(item.value) == 'NEW_BANK_ACCOUNT') {
          this.$router.push('/bank');
          return;
        }

        if (this.$service.toUpperCase(item.value) == 'WITHDRAW') {
          this.showWithdrawModal();
          return;
        }

        if (this.$service.toUpperCase(item.value) == 'HELP') {
          this.$router.push('/account/faq');
          return;
        }
      },

      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },

      showWithdrawModal() {
        this.withdrawModal = true;
      },

      closeWithdrawModal() {
        this.withdrawModal = false;
      },

      async toggleBalanceStatus() {
        if (this.getDashboardBalanceValueState) {
          await this.fetchBalance();
        }

        this.$store.commit('toggleDashboardBalanceValueState');
      },

      fetchData() {
        if (!this.getCurrentSellerData.id) {
          this.fetchSellerData();
        }

        this.fetchBalance();
        this.fetchMetrics();
      },

      async fetchSellerData() {
        await this.$store.dispatch('getSellerId')
          .catch(() => {
            this.showSnackBar('Não foi possível carregar dados do vendedor', '#FF5A5A');
          });
      },

      async fetchMetrics() {
        this.hasMetricsRequest = true;

        await this.$store.dispatch('metricModule/getAllMetrics')
          .catch(() => {
            this.$store.commit('metricModule/resetState');

            this.showSnackBar('Não foi possível carregar as métricas', '#FF5A5A');
          });

        this.hasMetricsRequest = false;
      },

      async fetchBalance() {
        this.hasCurrentBalanceRequest = true;

        await this.$store.dispatch('getSellerBalances')
          .catch(() => {
            this.showSnackBar('Não foi possível atualizar o saldo', '#FF5A5A');

            this.toggleBalanceStatus();
          });

        this.hasCurrentBalanceRequest = false;
      },

      getSellerBalance() {
        return this.$service.formatBrlAmountSymbol(this.getCurrentSellerBankingStatus ? this.getAccountBalance : this.getSubBalance);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .dashboard-view-container {
    .section {
      .image-container {
        background: rgba(67, 89, 112, 0.6);

        .image-background {
          background: linear-gradient(273.78deg, $first-color 0%, $first-color-dark-3 92.25%);
          opacity: 0.8;
          width: 100%;

          .image {
            width: 100%;
            opacity: 0.5;
            height: 120px;
            background-size: cover;
          }
        }
      }

      .dashboard-header {
        margin-top: -6.5rem;

        .button-component {
          z-index: 2;
          margin-left: auto;
          margin-right: 1.5rem;
          margin-bottom: 1rem;
          height: 40px;
        }

        .actions-container {
          z-index: 1;
          display: flex;
          justify-content: center;
          user-select: none;
          margin-left: 1.5rem;
          margin-right: 1.5rem;

          .actions-content {
            z-index: 1;
            display: flex;
          }

          .balance-card {
            background: $white-color;
            z-index: 1;
            height: 92px;
            border-radius: 6px;
            border: 1px solid #EFEFEF;
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 1rem;
            width: 385px;

            .content {
              display: flex;
              align-items: center;

              .value {
                display: flex;
                align-items: center;
                margin-left: auto;

                .default {
                  height: 30px;
                  width: 150px;
                  margin-right: 1rem;
                }
              }

              h3 {
                user-select: none;
                color: #333333;
              }

              h2 {
                color: $first-color;
                margin-left: 3rem;
                margin-right: 1rem;
              }

              i {
                font-size: 20px;
                color: #8E8E8E;
                cursor: pointer;
              }

              .blur {
                -webkit-filter: blur(10px);
                filter: blur(10px);
              }
            }
          }
        }
      }


      .metrics-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 1.5rem;

        .metrics-content {
          max-width: 975px;
          width: 100%;

          .metrics {
            display: flex;
            margin-bottom: 1.5rem;
            gap: 1.5rem;

            .metrics-card-component {
              width: 100%;
            }
          }

          .cards {
            display: flex;
            gap: 1.5rem;
          }
        }
      }
    }
  }


  @media (max-width: 1270px) {
    .dashboard-view-container {
      .section {
        .image-container {
          .image-background {
            .image {
              height: 145px;
            }
          }
        }

        .dashboard-header {
          margin-top: -4rem;

          .button-component {
            top: -3.7rem;
          }

          .actions-container {
            flex-direction: column-reverse;
            margin-top: -8.5rem;

            .action-button-component:last-child {
              margin-right: 0;
            }

            .balance-card {
              padding: 0;
              width: auto;
              background: transparent;
              border: none;

              .content {
                flex-direction: column;
                align-items: unset;

                h3 {
                  color: $white-color;

                  br {
                    display: none;
                  }
                }

                .value {
                  margin-left: 0;

                  h2 {
                    color: $white-color;
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 875px) {
    .dashboard-view-container {
      .section {
        .metrics-container {
          .metrics-content {
            .cards {
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    .dashboard-view-container {
      .section {
        .dashboard-header {
          .button-component {
            margin-right: 1rem;
          }

          .actions-container {
            margin-left: 1rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .dashboard-view-container {
      .section {
        .dashboard-header {
          .button-component {
            height: 35px;
          }
        }

        .metrics-container {
          .metrics-content {
            .metrics {
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .dashboard-view-container {
      .section {
        .dashboard-header {
          .actions-container {
            .actions-content {
              justify-content: center;
            }

            .balance-card {
              .content {
                .value {
                  .default {
                    width: 115px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>