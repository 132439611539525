<template>
  <div class="terms-view-container">
    <div v-if="hasRender" class="spinner-container">
      <spinner color="#333333"/>
    </div>

    <vue-pdf-embed source="/terms.pdf" @rendered="rendered"/>
  </div>
</template>

<script>
  import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
  import Spinner from '@/components/Base/Spinner';

  export default {
    name: 'TermView',

    data() {
      return {
        hasRender: true,
      };
    },

    components: {
      VuePdfEmbed,
      Spinner
    },

    methods: {
      rendered() {
        this.hasRender = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .terms-view-container {
    height: 100vh;
    width: 100%;

    .spinner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
</style>