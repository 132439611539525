<template>
  <div class="spinner-container" :style="styleObject">
    <i class="icon-spinner" :style="{ 'font-size': fontSize }"></i>
  </div>
</template>

<script>
  export default {
    name: 'CustomSpinner',

    props: {
      color: {
        type: String,
        default: '#000'
      },

      fontSize: {
        type: String,
        default: '2rem'
      }
    },

    computed: {
      styleObject: function() {
        return {
          'color': this.color,
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .spinner-container {
    animation: loading 1.2s linear infinite;
    width: fit-content;
  }

  @keyframes loading {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>