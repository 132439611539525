<template>
  <div class="faq-view-container">
    <default-view-component>
      <template v-slot:content>
        <div class="wrapper">
          <header-component
            :data="header"
            class="header-container"
          />

          <div class="center">
            <default-card-component
              class="card-container"
              :data="cardData"
            />

            <div class="content">
              <div v-for="(item, index) in items" :key="index" class="item-container">
                <div class="title" @click="item.isExpanded = !item.isExpanded">
                  {{ item.title}}

                  <div class="icon-container">
                    <i :class="item.isExpanded ? 'icon-keyboard-arrow-up' : 'icon-keyboard-arrow-down'" />
                  </div>
                </div>

                <div v-show="item.isExpanded" class="body">
                  <div v-for="(body, index) in item.body" :key="index" class="section">
                    <p class="title">
                      {{ `${index + 1}. ${body.title}` }}
                    </p>

                    <div v-if="body.videoUrl" class="video-container">
                      <iframe
                        :src="`${body.videoUrl}?title=0&warningTitle=0&muted=0&peertubeLink=0`"
                        allowfullscreen="1"
                        sandbox="allow-same-origin allow-scripts allow-popups"
                        frameborder="0"
                      ></iframe>
                    </div>

                    <p v-if="body.text" class="text">
                      {{ body.text }}
                    </p>

                    <divider-component v-if="index !== item.body.length - 1" class="divider"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <floating-button-component
            v-if="isScrolling"
            icon="icon-keyboard-arrow-up"
            iconSize="12px"
            bottom="75px"
            :bgColor="$theme.secondColor"
            @click="$service.scrollToTop()"
          />
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import DefaultView from '@/components/Layout/DefaultView';
  import AccountDefaultCard from '@/components/AccountDefaultCard';
  import FloatingButton from '@/components/Base/FloatingButton';
  import Header from '@/components/Layout/Header';
  import Divider from '@/components/Base/Divider';

  export default {
    name: 'FaqView',

    data() {
      return {
        header: {
          title: 'Perguntas frequentes',
          to: '/account',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        cardData: {
          icon: 'icon-question',
          title: 'Perguntas frequentes',
          text: 'Tire suas dúvidas sobre o aplicativo',
        },

        expanded: false,

        isScrolling: false,

        items: [
          {
            title: 'Contas e acesso',
            isExpanded: false,
            body: [
              {
                title: 'Como faço para recuperar / alterar minha senha?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/6f3c3276-6d9f-4556-bfe8-bbd68fe97444'
              },

              {
                title: 'Existe alguma taxa de inscrição ou mensalidade para usar o GERENCIA AI?',
                text: 'O GERENCIA AI é disponibilizado de maneira totalmente gratuita durante os seis primeiros meses após a conclusão do curso pelo aluno, sem qualquer custo de inscrição ou mensalidade.'
              },

              {
                title: 'Como cadastrar minha conta bancária?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/043c37f9-aef7-4806-a4dd-9a21435c1fc0'
              },

              {
                title: 'Minha conta está com status pendente, como faço para validar?',
                text: 'Para validar sua conta, basta adicionar uma conta bancária na plataforma. Confira o passo 3 deste tópico para orientações detalhadas sobre como realizar esse processo.'
              },

              {
                title: 'Quanto tempo demora a validação da conta?',
                text: 'A validação da conta geralmente é concluída em um curto período de tempo, podendo levar apenas 30 segundos. No entanto, em alguns casos, o processo pode demorar até 48 horas. Este intervalo de tempo varia devido a verificações de segurança e procedimentos necessários para garantir a integridade e segurança das contas no GERENCIA AI.'
              }
            ]
          },

          {
            title: 'Extrato e saldo',
            isExpanded: false,
            body: [
              {
                title: 'Onde posso visualizar o extrato da minha conta?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/245f0881-8aa3-4c0b-9f9c-911b7c80ae44'
              },

              {
                title: 'Como é calculado o saldo disponível?',
                text: 'O saldo disponível é determinado pela diferença entre as entradas e saídas registradas nas suas transações pelo GERENCIA AI.'
              }
            ]
          },

          {
            title: 'Recebíveis',
            isExpanded: false,
            body: [
              {
                title: 'Como funciona a agenda de recebíveis (quais vendas contabilizam em recebíveis)?',
                text: 'A agenda de recebíveis considera apenas as vendas efetuadas por meio de crédito para contabilização. Certifique-se de que todas as transações relacionadas a créditos estejam registradas para garantir uma agenda de recebíveis precisa e atualizada.'
              },

              {
                title: 'Como visualizar os recebíveis de cada período?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/e368d27f-3c4e-452d-b1e3-4d4f5d81250e'
              }
            ]
          },

          {
            title: 'Vendas',
            isExpanded: false,
            body: [
              {
                title: 'Como registro uma venda no GERENCIA AI?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/d76b8444-8d52-41df-ac61-732861c41687'
              },

              {
                title: 'Qual a diferença entre uma venda e um checkout?',
                text: 'A diferença entre uma venda e um checkout no GERENCIA AI reside nos métodos de pagamento e na gestão financeira associada. As vendas podem ser concluídas com uma variedade de métodos de pagamento, no entanto, os valores gerados por essas transações não estão imediatamente disponíveis para saque na plataforma. Por outro lado, os checkouts são processos totalmente controlados pelo GERENCIA AI, caracterizados pela automação e GERENCIAmento financeiro eficiente, proporcionando uma experiência ágil e integrada para facilitar suas transações.'
              },

              {
                title: 'Como personalizar minha tela de checkout?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/0294dd10-351f-4215-8428-87eec1d7c395'
              },
            ]
          },

          {
            title: 'Saques',
            isExpanded: false,
            body: [
              {
                title: 'Como solicitar um saque no GERENCIA AI?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/400f44df-f0c2-48a2-8d91-1c043f35fcd5'
              },

              {
                title: 'Quais são as opções de saque disponíveis?',
                text: 'Oferecemos duas opções para saques: a primeira é o saque manual, permitindo que você escolha o valor e o dia desejado para a retirada. A segunda opção é o saque automático, processado todos os dias úteis, desde que haja saldo disponível. No entanto, se preferir, você também pode comandar manualmente o saque do valor desejado.'
              },

              {
                title: 'Existe alguma taxa associada aos saques?',
                text: 'Os saques no GERENCIA AÍ são totalmente isentos de taxas. Garantimos que você possa retirar seus fundos sem nenhuma cobrança adicional, proporcionando uma experiência financeira livre de encargos.'
              },

              {
                title: 'Como funciona a janela de transferência?',
                text: 'A janela de transferência no GERENCIA AI está disponível até às 10h59 da manhã em dias úteis. Caso os saques sejam realizados dentro desse período, é possível que o valor sacado seja creditado ainda no mesmo dia, por volta das 15h. É importante observar que, embora a solicitação de saque possa ocorrer antes desse horário, pode levar até 48 horas para que o montante esteja disponível em sua conta bancária, dependendo das políticas e processos do banco receptor.'
              }
            ]
          },

          {
            title: 'Gestão de clientes',
            isExpanded: false,
            body: [
              {
                title: 'Como adiciono novos clientes à minha lista?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/0acffb33-0051-4520-b157-d316ab216b07'
              },

              {
                title: 'Como atualizo dados de um cliente?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/ef60904e-b0a5-44a8-8b2c-88c1471ed090'
              }
            ]
          },

          {
            title: 'Gestão de fornecedores',
            isExpanded: false,
            body: [
              {
                title: 'Como cadastrar um novo fornecedor?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/44cb797a-eb0c-482e-af56-980cbfa5448c'
              },
            ]
          },

          {
            title: 'Produtos e serviços',
            isExpanded: false,
            body: [
              {
                title: 'Como adiciono novos produtos ou serviços ao meu catálogo?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/52a6e80b-a28a-4377-afc7-c763ae343878'
              },

              {
                title: 'É possível monitorar o estoque de produtos no GERENCIA AI?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/f10e178c-3289-499b-b1d8-62e3b2190725'
              }
            ]
          },

          {
            title: 'Fluxo de caixa',
            isExpanded: false,
            body: [
              {
                title: 'Para o que serve o fluxo de caixa?',
                text: 'O fluxo de caixa oferece uma visualização gráfica fácil de todas as transações da sua empresa, incluindo faturamento, contas a receber e despesas. Essa ferramenta possibilita uma gestão financeira assertiva e eficiente, fornecendo insights valiosos para tomar decisões informadas sobre o seu negócio.'
              },

              {
                title: 'Como visualizado o fluxo de um determinado período?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/e0db8d16-4d09-4518-b799-be63091d9b86'
              },

              {
                title: 'Como vejo o saldo de caixa do dia?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/fa35d9a9-01bc-4f79-8882-8598d91a2670'
              },
            ]
          },

          {
            title: 'Agenda de compromissos',
            isExpanded: false,
            body: [
              {
                title: ' Para que serve a agenda de compromissos do GERENCIA AI?',
                text: 'A agenda de compromissos no GERENCIA AI funciona como uma ferramenta organizacional, permitindo que você registre e gerencie suas tarefas de forma estruturada. Ela atua como uma agenda digital, consolidando todos os seus compromissos, proporcionando a flexibilidade de edição ou exclusão conforme necessário.'
              },

              {
                title: 'Como adiciono um novo compromisso?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/ad3da446-71fd-4e66-b206-fdcb2c5aeff0'
              },

              {
                title: 'Posso deletar um compromisso?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/5a9ddacb-3408-4551-af11-a1968e175a15'
              },

              {
                title: 'Posso editar um compromisso?',
                videoUrl: 'https://a.video.imlabs.com.br/videos/embed/d45df3b3-ae54-4892-8e71-ae190939ee1d'
              }
            ]
          },

          {
            title: 'Segurança e privacidade',
            isExpanded: false,
            body: [
              {
                title: 'Como o GERENCIA AI protege minhas informações financeiras?',
                text: 'No GERENCIA AI, implementamos rigorosas medidas de segurança para resguardar suas informações financeiras. Utilizamos tecnologia de ponta e protocolos de criptografia avançados para garantir a confidencialidade e integridade dos seus dados. Sua segurança financeira é nossa prioridade máxima.'
              },

              {
                title: 'Quais medidas de segurança são implementadas para garantir a privacidade dos dados?',
                text: 'No GERENCIA AI, adotamos um escudo intransponível de segurança, protegendo suas informações como um cofre virtual. Nossa criptografia de última geração e protocolos de segurança são a chave para assegurar a confidencialidade absoluta dos seus dados. Sua privacidade é nossa missão.'
              }
            ]
          }
        ]
      };
    },

    components: {
      'default-view-component': DefaultView,
      'default-card-component': AccountDefaultCard,
      'header-component': Header,
      'divider-component': Divider,
      'floating-button-component': FloatingButton
    },

    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },

    methods: {
      handleScroll() {
        this.isScrolling = window.scrollY > 400;
      },
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  };
</script>

<style lang="scss" scoped>
  .faq-view-container {
    .wrapper {
      padding: 1.5rem;

      .header-component {
        display: flex;
        align-items: center;
        margin-bottom: 1rem !important;

        h2 {
          color: #333;
        }

        p {
          margin-left: auto;
          font-weight: 600;
          color: $second-color;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .center {
        display: flex;
        justify-content: center;

        .card-container {
          margin-right: 3rem;
        }

        .content {
          max-width: 40rem;
          width: 100%;

          .item-container {
            padding: 1rem;
            border: 1px solid #EFEFEF;
            border-radius: 6px;
            margin-bottom: 1rem;

            .title {
              font-weight: 500;
              display: flex;
              align-items: center;
              font-size: 16px;
              cursor: pointer;

              .icon-container {
                margin-left: auto;

                i {
                  font-size: 10px;
                  color: #6D6F6F;
                }
              }
            }

            .body {
              margin-top: 1rem;
              color: #6D6F6F;

              p {
                text-align: justify;
              }

              .section {
                .title {
                  font-size: 14px;
                  color: #333;
                }

                .text {
                  margin-top: 0.5rem;
                }

                .video-container {
                  margin-top: 0.5rem;

                  iframe {
                    height: 340px;
                    width: 100%;
                  }
                }

                .divider {
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 976px) {
    .faq-view-container{
      .wrapper {
        .center {
          .card-container {
            display: none;
          }
        }
      }
    }
  }
</style>