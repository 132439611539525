<template>
  <div>
    <div class="account-data" v-for="(item, index) in data" :key="index">
      <div class="header">
        <p class="title">{{ item.sectionTitle }}</p>

        <div v-if="item.isEditable" class="edit-button-container" >
          <div class="edit-button" v-ripple="'rgba(18, 97, 204, 0.2)'" @click="enableEditModalForm(item.mutation, item.isOwnerAddress)">
            <i class="icon-pencil"/>

            Editar
          </div>
        </div>
      </div>

      <div v-if="item.columnLeft || item.columnRight" class="wrapper">
        <div v-if="item.columnLeft" class="content">
          <div class="item" v-for="(item, index) in item.columnLeft" :key="index">
            <skeleton-loader-component v-if="item.text == 'default'" class="default"/>

            <div v-else-if="!!item.text">
              <div class="title">
                {{ item.title }}
              </div>

              <div class="value">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="item.columnRight" class="content">
          <div class="item" v-for="(item, index) in item.columnRight" :key="index">
            <skeleton-loader-component v-if="item.text == 'default'" class="default"/>

            <div v-else-if="!!item.text">
              <div class="title">
                {{ item.title }}
              </div>

              <div class="value">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SkeletonLoader from '@/components/Base/SkeletonLoader';

  export default {
    name: 'AccountSortData',

    props: {
      data: {
        type: Array,
        default: () => [
          {
            sectionTitle: 'Section title',
            isEditable: true,
            mutation: '',
            columnLeft: [
              {
                title: 'Option',
                text:  null
              },

              {
                title: 'Option',
                text:  null
              }
            ],

            columnRight: [
              {
                title: 'Option',
                text:  null
              },

              {
                title: 'Option',
                text:  null
              }
            ]
          },

          {
            sectionTitle: 'Section title',
            isEditable: false,
            columnLeft: [
              {
                title: 'Option',
                text:  null
              },
            ],

            columnRight: [
              {
                title: 'Option',
                text:  null
              }
            ]
          }
        ]
      }
    },

    components: {
      'skeleton-loader-component': SkeletonLoader
    },

    methods: {
      enableEditModalForm(mutation, sellerType) {
        if (!this.$service.isUndefined(mutation)) {
          this.$store.commit(mutation);

          if(sellerType != undefined && sellerType != null && sellerType != '') {
            this.$store.commit('setOwnerAddressEditStatus', true);
          } else {
            this.$store.commit('setOwnerAddressEditStatus', false);
          }
        } else {
          this.$emit('click');
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .account-data {
    width: 100%;

    .header {
      display: flex;

      .title {
        color: #333;
        margin-bottom: 2rem;
        font-weight: 600;
        font-size: 16px;
      }

      .edit-button-container {
        margin-left: auto;

        .edit-button {
          display: flex;
          user-select: none;
          padding: 0.3rem 1rem;
          align-items: center;
          color: #8E8E8E;
          cursor: pointer;
          border-radius: 6px;
          font-weight: 500;

          i {
            margin-right: 0.5rem;
            color: #0052CC;
          }
        }
      }
    }

    .wrapper {
      display: flex;

      .content {
        margin-right: 2rem;
        max-width: 320px;
        width: 100%;

        .item {
          margin-bottom:  2rem;

          .title {
            margin-bottom: 0.5rem;
            color: #8E8E8E;
            font-size: 12px;
            font-weight: 400;
          }

          .value {
            max-width: 30rem;
            font-size: 14px;
            color: #333;
          }

          .default {
            width: 100%;
            height: 1.5rem;
          }
        }

        &:last-child {
          margin-left: auto;
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .account-data {
      .wrapper {
        flex-direction: column;

        .content {
          width: 100%;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
</style>
