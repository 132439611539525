<template>
  <modal
    class="seller-status-information-modal"
    :showModal="true"
    @close="close"
  >
    <template v-slot:body>
      <div class="body">
        <i class="icon-information-account" />

        <h3>
          Sua conta está pendente!
        </h3>

        <p>
          Para garantir a ativação, forneça os detalhes da sua conta bancária. Lembre-se de que, em alguns casos, a aprovação pode ser negada. Certifique-se de fornecer informações precisas para evitar possíveis problemas.
        </p>

        <div class="buttons-container">
          <button-component
            class="button-component"
            text="Fechar"
            styles="ghost"
            @click.native="close"
            v-ripple="$theme.whiteColorTransparent"
          />

          <button-component
            class="button-component"
            text="Adicionar conta"
            pathView="/bank"
            v-ripple="$theme.whiteColorTransparent"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';

  export default {
    name: 'SellerStatusInformationModal',

    components: {
      'modal': Modal,
      'button-component': Button
    },

    methods: {
      close() {
        this.$emit('close');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .seller-status-information-modal {
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 550px;
      margin-top: 1rem;

      i {
        font-size: 4rem;
        color: #8E8E8E;
        margin-bottom: 2rem;
      }

      h3 {
        color: #333;
        margin-bottom: 1rem;
      }

      p {
        text-align: center;
      }

      .buttons-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 2rem;

        .button-component {
          width: 190px;
          height: 40px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .seller-status-information-modal {
      .body {
        width: 100%;
        text-align: center;
      }
    }
  }

  @media (max-width: 520px) {
    .seller-status-information-modal {
      .body {
        .buttons-container {
          flex-direction: column-reverse;
        }
      }
    }
  }
</style>

