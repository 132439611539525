import cards from './cards';
import moment from 'moment';

export default {
  getPlatformTitle() {
    return process.env.VUE_APP_PLATFORM_TITLE || 'IM Pay';
  },

  getPlatform() {
    return process.env.VUE_APP_PLATFORM || 'default';
  },

  isDefaultPlatform() {
    return this.getPlatform() == 'default';
  },

  getPlatformImagesPath(imageName) {
    return require(`@/assets/images/${this.getPlatform()}/${imageName}`);
  },

  openWpp(text) {
    const phone = '48992219481';

    window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${text}`, '_blank');
  },

  currentDateEN(format = 'YYYY-MM-DD') {
    let date = new Date();

    return moment(date).format(format);
  },

  setArray(arr, receiveArr) {
    arr.forEach(item => {
      receiveArr.push(item);
    });
  },

  isEquals(str1, str2) {
    return this.toUpperCase(str1) == this.toUpperCase(str2);
  },

  isBlank(value) {
    return value == '';
  },

  currentMonth() {
    return moment().format('M');
  },

  formatStringPlural(count, str) {
    if (count > 1) {
      str = str + 's';
    }

    return str;
  },

  toLowerCase(str) {
    return str.toLowerCase();
  },

  toCapitalize(str) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, (firstLetter) => {
      return firstLetter.toUpperCase();
    });
  },

  toUpperCase(str) {
    return str.toUpperCase();
  },

  getMonth(data, format) {
    return moment(data, format).format('M');
  },

  getYear(data, format) {
    return moment(data, format).format('Y');
  },

  getStartDateMonth(month, format) {
    return moment(month, format).startOf('month').format('YYYY-MM-DD');
  },

  getEndDateMonth(month, format) {
    return moment(month, format).endOf('month').format('YYYY-MM-DD');
  },

  getDateDay(date, day) {
    return moment(date).add(day, 'day').format('YYYY-MM-DD');
  },

  dateFormat(date, currentFormat = 'YYYY-MM-DD', toFormat = 'YYYY-MM-DD') {
    if (!date) {
      return;
    }

    return moment(date, currentFormat).format(toFormat);
  },

  isSameDate(firstDate, secondDate) {
    return moment(firstDate).isSame(secondDate);
  },

  toDate(date) {
    return new Date(date + 'T00:00:00');
  },

  formatEin(ein) {
    return ein.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  },

  formatTaxpayerId(taxpayerId) {
    return taxpayerId?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  },

  formatPhone(phone) {
    let phoneLength = phone?.length - 2;
    let result = null;

    if (phoneLength == 8) {
      result = phone.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } else if (phoneLength == 9) {
      result = phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }

    return result;
  },

  formatPostalCode(postalCode) {
    return postalCode?.replace(/^(\d{5})(\d{3})/, "$1-$2");
  },

  formatBarCodeNumber(number) {
    return number?.replace(/^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})/, '$1.$2 $3.$4 $5.$6 $7 $8');
  },

  formatIntAmountToFloat(amount = 0) {
    let amountStr = amount.toString();
    let strLength = amountStr.length;
    let formattedAmount = amountStr.substring(0, strLength - 2) + '.' + amountStr.substring(strLength - 2, strLength);

    return parseFloat(formattedAmount);
  },

  splitFirstName(completedName) {
    if (completedName == null) {
      return null;
    }

    return completedName.trim().split(' ').shift();
  },

  splitLastName(completedName) {
    if (completedName == null) {
      return null;
    }

    return completedName.trim().substring(this.splitFirstName(completedName).length).trim();
  },

  isPdf(b64) {
    let type = 'JVBERi0';

    return b64.indexOf(type) === 0;
  },

  isEmptyArray(array) {
    return array.length == 0;
  },

  isEmptyObject(object) {
    return !!Object.values(object).length == 0;
  },

  formatAccountNumber(number) {
    let length = number.length;
    let last = length - 1;

    return length >= 6 ? number.substring(0, last) + '-' + number[last] : number;
  },

  bytesToSize(bytes) {
    var sizes = ['bytes', 'kB', 'MB', 'G', 'T', 'P'];

    for (var i = 0; i < sizes.length; i++) {
      if (bytes <= 1024) {
        return bytes + ' ' + sizes[i];
      } else {
        bytes = parseFloat(bytes / 1024).toFixed(2);
      }
    }

    return bytes + ' P';
  },

  formatBrlAmountSymbol(amount) {
    return parseFloat(amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },

  formatBrlAmountNoSymbol(amount) {
    return parseFloat(amount).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  },

  formatAmount(amount) {
    let formattedAmount = '';

    if (amount != '') {
      let floatFixed = amount.toFixed(2);
      let amountStr = floatFixed.toString();

      formattedAmount = amount != 0 ? amountStr.replace('.', '') : '0';
    }

    return formattedAmount;
  },

  isNull(value) {
    return value === null;
  },

  isUndefined(value) {
    return value === undefined;
  },

  isEqual(firstValue, secondValue) {
    return firstValue === secondValue;
  },

  isObject(value) {
    return typeof value === 'object' && value !== null;
  },

  scrollToBottom() {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
  },

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },

  formatQueryParam(queryParamsArr) {
    let formattedQuery = '?';

    queryParamsArr.forEach(item => {
      if (item.param != null && item.param != '') {
        formattedQuery += `${formattedQuery.slice(-1) != '?' ? '&' : ''}` + item.query + '=' + item.param;
      }
    });

    return formattedQuery;
  },

  dateIntervalDays(date1, date2, format) {
    let interval = moment(date2, format).diff(moment(date1, format));
    let intervalDays = moment.duration(interval).asDays();

    return intervalDays;
  },

  removeCharacter(str) {
    return str.replace(/[^0-9]/g, '');
  },

  isEin(ein) {
    return this.removeCharacter(ein).length == 14;
  },

  isTaxpayerId(taxpayerId) {
    return this.removeCharacter(taxpayerId).length == 11;
  },

  formatTaxPayerOrEin(id) {
    if (this.isTaxpayerId(id)) {
      id = this.formatTaxpayerId(id);
    } else {
      id = this.formatEin(id);
    }

    return id;
  },

  isIndividual(type) {
    return this.toUpperCase(type) == 'INDIVIDUAL';
  },

  formatPaymentMethodName(paymentMethod, installments) {
    let formattedPaymentMethodName;

    switch (this.toUpperCase(paymentMethod)) {
      case 'PIX':
        formattedPaymentMethodName = 'Pix';
        break;
      case 'BOLETO':
        formattedPaymentMethodName = 'Boleto';
        break;
      case 'CREDITCARD_INSTALLMENT':
        formattedPaymentMethodName = `Crédito parcelado (${installments}x)`;
        break;
      case 'CREDITCARD':
        formattedPaymentMethodName = 'Crédito à vista';
        break;
      case 'DEBIT':
        formattedPaymentMethodName = 'Débito';
        break;
      case 'COMMISSION':
        formattedPaymentMethodName = 'Comissão';
        break;
      default:
        formattedPaymentMethodName = 'Outro';
    }

    return formattedPaymentMethodName;
  },

  formatPaymentTypeName(paymentType, paymentMethod) {
    var formattedPaymentTypeName;
    var upperPaymentType = this.toUpperCase(paymentType);
    var upperPaymentMethod = this.toUpperCase(paymentMethod);

    if (upperPaymentType == 'CHIP') {
      formattedPaymentTypeName = 'CHIP';
    } else if (upperPaymentType == 'CONTACTLESS_CHIP') {
      formattedPaymentTypeName = 'Aproximação';
    } else if (upperPaymentType == 'MGSTRIP_FALLBACK') {
      formattedPaymentTypeName = 'Tarja magnética';
    } else if (upperPaymentType == 'BARCODE' && upperPaymentMethod == 'BOLETO') {
      formattedPaymentTypeName = 'Cód. Barras';
    } else if (upperPaymentType == 'BARCODE' && upperPaymentMethod == 'PIX') {
      formattedPaymentTypeName = 'QR Code';
    } else if (upperPaymentType == 'MANUALLY_KEYED' && upperPaymentMethod == 'CREDITCARD') {
      formattedPaymentTypeName = 'Online';
    }

    return formattedPaymentTypeName;
  },

  cardValidation(number) {
    if (number != null) {
      let formattedNumber = this.removeCharacter(number);

      for (var card in cards) if (formattedNumber.match(cards[card].bins)) return cards[card];
      return false;
    }
  },

  hexColorBrightness(hex) {
    hex = hex.replace(/^#/, '');

    var r = parseInt(hex.substring(0, 2), 16) / 255;
    var g = parseInt(hex.substring(2, 4), 16) / 255;
    var b = parseInt(hex.substring(4, 6), 16) / 255;

    var luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance > 0.5 ? 'dark' : 'light';
  }
};