var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services-view-container"},[_c('default-view-component',{attrs:{"hasModal":_vm.isShowServiceModal},scopedSlots:_vm._u([{key:"alert",fn:function(){return [_c('snackbar-component',{ref:"snackbar"})]},proxy:true},{key:"modal",fn:function(){return [(_vm.isShowServiceModal)?_c('service-modal-component',{on:{"close":function($event){_vm.isShowServiceModal = false},"snackbarNotification":_vm.showSnackBar,"finishRequest":_vm.fetchData}}):_vm._e(),(_vm.isShowStatusModal)?_c('confirm-modal-component',{attrs:{"data":{
          title: 'Atenção',
          icon: 'icon-warning',
          text: 'Deseja realmente alterar o status do serviço?',
          buttonText:
            _vm.currentService?.type == 'disable' ? 'Desativar' : 'Ativar',
          buttonColor: '#2196f3'
        },"hasRequest":_vm.hasRequest},on:{"close":function($event){_vm.isShowStatusModal = false},"click":function($event){return _vm.updateStatusProduct(_vm.currentService?.id, _vm.currentService?.type)}}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"section"},[_c('header-component',{attrs:{"data":_vm.header}}),_c('div',{staticClass:"header-options"},[_c('button-component',{directives:[{name:"ripple",rawName:"v-ripple",value:(_vm.$theme.whiteColorTransparent),expression:"$theme.whiteColorTransparent"}],staticClass:"button-component",attrs:{"text":"Novo serviço","isDisabled":_vm.hasRequest},nativeOn:{"click":function($event){return _vm.showModal.apply(null, arguments)}}})],1),(_vm.tableData.rows.length != 0)?_c('table-header-component',{staticClass:"table-header-component",attrs:{"hasRequest":_vm.hasRequest && !_vm.paginationData.totalCountItems,"data":[
            {
              label: `${_vm.paginationData.totalCountItems}
                    ${_vm.$service.formatStringPlural(
                      _vm.paginationData.totalCountItems,
                      'cadastrado'
                    )}`,
              hasLastChild: false
            }
          ]}}):_vm._e(),_c('table-component',{staticClass:"table-component",attrs:{"hasRequest":_vm.hasRequest && !_vm.isMoreData,"table":_vm.tableData},on:{"actionClick":_vm.showModal}}),(_vm.hasTableRows())?_c('pagination-table-component',{staticClass:"pagination-table-button-component",attrs:{"isDisabled":!_vm.haveMoreData(),"data":_vm.paginationData,"hasRequest":_vm.hasRequest},on:{"onClick":_vm.moreData}}):_vm._e()],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }