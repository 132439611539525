<template>
  <div>
    <div v-if="hasRequest" class="loader-container">
      <spinner-component color="#DADADA" />
    </div>

    <table v-else-if="hasRow()">
      <thead>
        <tr>
          <th v-if="table.hasIcon" :class="{ 'has-border': table.headers.hasBorder }">
            Tipo
          </th>

          <th
            v-for="(header, index) in table.headers.items"
            :key="index"
            :class="{ 'has-border': table.headers.hasBorder, 'is-action': header.isAction }"
            :style="{ 'text-align': header.align }"
          >
            {{ header.label }}
          </th>
        </tr>
      </thead>

      <tbody v-for="(row, rowIndex) in table.rows" :key="rowIndex" :class="{ 'has-append': row.append, 'show': row.append && row.append.showAppend }">
        <tr :class="{ 'has-hover': !hasActionOption }" @click="columnClick(row)">
          <td
            v-if="table.hasIcon"
            :class="{ 'has-border': !row.icon.status }"
            @mouseover="mouseOver(row, 'iconTooltip')"
            @mouseleave="mouseLeave(row, 'iconTooltip')"
          >
            <div v-if="row.iconTooltip && row.iconTooltip.show" class="tooltip-container">
              <div class="tooltip">
                {{row.iconTooltip.text}}
              </div>
            </div>

            <div class="icon-container">
              <div class="icon" :class="{ 'has-status': row.icon.status }">
                <i :class="`${row.icon.name} ${row.icon.status}`" />
              </div>

              <div v-if="row.icon.status" class="status" :class="row.icon.status">
                <i v-if="row.icon.status === 'received'" class="icon-arrow-top"/>
                <i v-else class="icon-arrow-down"/>
              </div>
            </div>
          </td>

          <td
            v-for="(header, headerIndex) in table.headers.items"
            :key="headerIndex"
            :class="{ 'is-action': header.isAction, 'has-border': !row.noBorder }"
            :style="{ 'min-width': header.minWidth ? header.minWidth : 'unset', 'text-align': !isObject(row[header.field]) ? header.align : '' }"
            @mouseover="mouseOver(row, header.tooltip)"
            @mouseleave="mouseLeave(row, header.tooltip)"
          >
            <div v-if="header?.tooltip && row[header.tooltip]?.show" class="tooltip-container">
              <div class="tooltip">
                {{ row[header.tooltip].text }}
              </div>
            </div>

            <div v-if="header?.isStatus || row[header.field]?.isStatus" class="card-container">
              <transaction-status-component
                :customStatus="row['customStatus']"
                :status="row[header.field]?.isStatus ? row[header.field].status : row[header.field]"
              />
            </div>

            <div v-else-if="header?.isChip || row[header.field]?.isChip" class="chip-container">
              <div class="chip-content" :style="{ 'background': row[header.field]?.background, 'color': row[header.field]?.textColor }">
                {{ row[header.field].label }}
              </div>
            </div>

            <div v-else-if="header?.isAction" class="action-container" :class="{ 'is-disable': row[header.field]?.isDisabled }" @click.stop>
              <div v-if="row[header.field]?.icon" class="icon-container" @click.stop="toggleActionOptions(row)">
                <i :class="row[header.field].icon" />
              </div>

              <div v-if="row.append && row.append.items" class="icon-container" @click="toggleExpandItem(row)">
                <i v-if="row.append.showAppend" class="icon-keyboard-arrow-up arrow"/>
                <i v-else class="icon-keyboard-arrow-down arrow"/>
              </div>

              <div v-if="row.actions.showAction" class="action-content" v-click-outside="disableAllActions">
                <div v-for="(action, index) in row[header.field].items" :key="index" class="action" @click="actionClick(action, row)">
                  <i :class="action.icon" />

                  {{ action.label }}
                </div>
              </div>
            </div>

            <div v-else-if="isObject(row[header.field])" class="value-container" :class="{ 'is-bold': row.isBold  }">
              <div class="value-content">
                {{ row[header.field].value }}

                <div class="label">
                  {{ row[header.field].label }}
                </div>
              </div>
            </div>

            <div class="row" v-else>
              {{ row[header.field] }}
            </div>
          </td>
        </tr>

        <template v-if="row?.append && row.append?.items">
          <tr
            v-show="row.append.showAppend"
            v-for="(rowAppend, rowIndex) in row.append.items"
            :key="rowIndex"
            :class="{ 'has-hover': !hasActionOption }"
            @click="columnClick(rowAppend)"
          >
            <td
              v-for="(header, headerIndex) in table.headers.items" :key="headerIndex"
              class="is-append"
              :class="{ 'is-action': header?.isAction, 'has-border': !rowAppend?.noBorder }"
              :style="{ 'text-align': header?.align }"
            >
              <div v-if="header?.isStatus || rowAppend[header.field]?.isStatus" class="card-container">
                <transaction-status-component
                  class="transaction-status-component"
                  :customStatus="rowAppend['customStatus']"
                  :status="rowAppend[header.field]?.isStatus ? rowAppend[header.field].status : rowAppend[header.field]"
                />
              </div>

              <div :class="{ 'is-bold': rowAppend?.isBold }" v-else>
                {{ rowAppend[header.field] }}
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <simple-message-component
      v-else
      class="empty-data-container"
      :data="emptyTableRowsData"
    />
  </div>
</template>

<script>
  import SimpleMessage from '@/components/SimpleMessage';
  import Spinner from '@/components/Base/Spinner';
  import TransactionStatus from '@/components/TransactionStatus';

  export default {
    name: 'CustomTable',

    data() {
      return {
        emptyTableRowsData: {
          icon: 'icon-receipts',
          text: 'Não foram encontrados registros'
        },
        hasActionOption: false
      };
    },

    props: {
      hasRequest: {
        type: Boolean,
        default: false
      },

      table: {
        type: Object,
        default() {
          return {
            // hasIcon: true,
            headers: {
              hasBorder: true,
              items: [
                {
                  label: 'Data',
                  field: 'date',
                  align: 'left',
                },

                {
                  label: 'Descrição',
                  field: 'description',
                  align: 'center'
                },

                {
                  label: 'Valor bruto',
                  field: 'value',
                  align: 'right'
                }
              ]
            },

            rows: [
              {
                icon: {
                  name: 'icon-transfer',
                  status: 'received'
                },
                date: '15/05/21',
                description: 'Nome operação',
                value: 'R$ 15,00'
              },

              {
                icon: {
                  name: 'icon-transfer',
                  status: 'received'
                },
                date: '15/05/21',
                description: 'Nome operação',
                value: 'R$ 15,00',
              },

              {
                icon: {
                  name: 'icon-transfer',
                  status: 'received'
                },
                date: '15/05/21',
                description: 'Nome operação',
                value: 'R$ 15,00',
                append: {
                  showApeend: false,
                  items: [
                    {
                      icon: {
                        name: 'icon-transfer',
                        status: 'received'
                      },
                      date: '15/05/21',
                      description: 'Nome operação',
                      value: 'R$ 15,00',
                    },

                    {
                      icon: {
                        name: 'icon-transfer',
                        status: 'received'
                      },
                      date: '15/05/21',
                      description: 'Nome operação',
                      value: 'R$ 15,00',
                    }
                  ]
                }
              },
            ]
          };
        }
      }
    },

    components: {
      'simple-message-component': SimpleMessage,
      'transaction-status-component': TransactionStatus,
      'spinner-component': Spinner
    },

    methods: {
      hasRow() {
        return !this.$service.isEmptyArray(this.table.rows);
      },

      toggleActionOptions(item) {
        this.disableAllActions();

        if (!item.actions.isDisabled) {
          this.hasActionOption = true;
          item.actions.showAction = true;
        }
      },

      disableAllActions() {
        this.hasActionOption = false;

        this.table.rows.forEach(item => {
          item.actions.showAction = false;
        });
      },

      actionClick(action, data) {
        this.$emit('actionClick', action);

        data.actions.showAction = false;
      },

      columnClick(item) {
        this.$emit('columnClick', item);

        if (item.append) {
          this.toggleExpandItem(item);
        }
      },

      toggleExpandItem(item) {
        item.append.showAppend = !item.append.showAppend;
      },

      mouseOver(item, field) {
        if (!this.$service.isUndefined(item[field])) {
          item[field].show = true;
        }
      },

      mouseLeave(item, field) {
        if (!this.$service.isUndefined(item[field])) {
          item[field].show = false;
        }
      },

      isObject(item) {
        return this.$service.isObject(item);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  table {
    cursor: default;
    color: #333;
    border-spacing: unset;
    font-size: 14px;
    width: 100%;

    thead {
      tr {
        th {
          height: 60px;
          white-space: nowrap;
          padding: 0.5rem 1rem;
          font-weight: 500;

          &.has-border {
            border-bottom: 1px solid #EFEFEF;
          }
        }
      }
    }

    tbody {
      &.has-append {
        &.show {
          tr {
            td {
              border-bottom: 1px solid #DADADA;
            }
          }
        }

        tr {
          border-left: 2px solid $first-color;

          td {
            &.is-append {
              background: #EFEFEF;

              .card-container {
                position: relative;
                padding: 0 3rem;

                .transaction-status-component {
                  position: absolute;
                }
              }
            }

            &:first-child {
              border-left: 2px solid $first-color;
            }
          }
        }
      }

      tr {
        cursor: pointer;

        .tooltip-container {
          position: relative;
          display: flex;
          justify-content: center;

          .tooltip {
            box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
            background: $white-color;
            margin-top: -50px;
            padding: 0.5rem;
            position: absolute;
            border-radius: 0.5rem;
          }
        }

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: $second-color;

          .icon {
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.has-status {
              height: 45px;
              width: 45px;
              border: 3px solid $second-color-light-1;
              border-radius: 100%;
            }

            i {
              font-size: 1.5rem;
              margin-bottom: 2px;

              &.icon-barcode {
                font-size: 1.2rem;
              }

              &.sent {
                color: #FF5A5A;
              }

              &.received {
                color: $second-color;
              }
            }
          }

          .status {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white-color;
            background: #08B978;
            border-radius: 100%;
            height: 17px;
            width: 17px;
            margin-top: -15px;
            margin-left: 35px;

            &.sent {
              background: #FF5A5A;
            }

            &.received {
              background: #08B978;
            }

            i {
              font-size: 9px;
            }
          }
        }

        &.has-hover {
          &:hover {
            background: #F6F7F7;
          }
        }

        td {
          height: 63px;
          padding: 0 1rem;

          &.has-border {
            border-bottom: 1px solid #EFEFEF;
          }

          &.is-action {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .row {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .value-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .label {
              font-size: 12px;
              color: #6D6F6F;
            }
          }

          .is-bold {
            font-weight: 500;
          }

          .chip-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .chip-content {
              font-weight: 500;
              border-radius: 6px;
              padding: 0.2rem 0.7rem;
              width: fit-content;
              font-size: 14px;
              border: 1px solid transparent;
            }
          }

          .card-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            a {
              margin-top: 0.1rem;
            }
          }

          .action-container  {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;

            &.is-disable {
              .icon-container {
                i {
                  color: #DADADA;
                  cursor: not-allowed;
                }

                &:hover {
                  background: unset;
                }
              }
            }

            .icon-container {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;

              i {
                font-size: 1.3rem;
                color: #8E8E8E;
                cursor: pointer;

                &.icon-keyboard-arrow-down {
                  margin-top: 4px;
                }
                &.arrow {
                  font-size: 0.5rem;
                }
              }

              &:hover {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background: #E4E4E4;
              }
            }

            .action-content {
              background: $white-color;
              position: absolute;
              box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
              border-radius: 6px;
              font-weight: 300;
              font-size: 14px;
              right: 4.5rem;
              min-width: 180px;
              padding: 8px 0;

              .action {
                padding: 0.5rem 1rem;
                width: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;

                i {
                  margin-right: 0.5rem;
                  font-size: 0.9rem;
                  color: #8E8E8E;

                  &.icon-configure {
                    font-size: 1rem;
                  }
                }

                &:hover {
                  background: #EFEFEF;
                }
              }
            }
          }
        }
      }
    }
  }

  .empty-data-container {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 645px) {
    table {
      tbody {
        tr {
          td {
            max-width: 400px;
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    table {
      thead {
        tr {
          th {
            font-size: 12px;
          }
        }
      }

      tbody {
        tr {
          font-size: 12px;

          td {
            padding: 0 0.5rem;
          }
        }
      }
    }
  }
</style>