<template>
  <div
    class="tootltip-container"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
    v-click-outside="mouseLeave"
  >
    <div v-if="hasTooltip" class="tooltip-content">
      <div class="tooltip">
        <slot name="tooltipContent"/>
      </div>
    </div>

    <div class="content" @click="mouseOver()">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CustomTooltip',

    data() {
      return {
        hasTooltip: false
      };
    },

    methods: {
      mouseOver() {
        this.hasTooltip = true;
      },

      mouseLeave() {
        this.hasTooltip = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .tootltip-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .tooltip-content {
      position: absolute;
      display: flex;
      justify-content: center;
      z-index: 1000;

      .tooltip {
        box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
        background: $white-color;
        padding: 1rem;
        border-radius: 0.5rem;
      }
    }

    .content {
      align-items: center;
      display: flex;
    }
  }

  @media (max-width: 600px) {
    .tootltip-container {
      justify-content: center;
    }
  }
</style>