<template>
  <div class="divider" :style="{ 'background': color }"></div>
</template>

<script>
  export default {
    name: 'CustomDivider',

    props: {
      color: {
				type: String,
				default: '#EFEFEF'
			},
    }
  };
</script>

<style lang="scss" scoped>
  .divider {
    height: 1px;
    width: 100%;
  }
</style>