<template>
  <div class="transfers-view-container">
    <default-view-component v-if="!hasDetail" :hasModal="withdrawModal || moreFilterModal">
      <template v-slot:modal>
        <withdraw-modal-component
          v-if="withdrawModal"
          :bankAccounts="bankAccounts"
          @close="closeWithdrawModal"
        />

        <filter-modal-component
          v-if="moreFilterModal"
          :data="filterData"
          @close="closeMoreFilterModal"
          @clickApplyFilter="updateFilterData"
        />
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
          />

          <div class="header-options">
            <div class="filters">
              <select-radio-field
                :data="filterStatusData"
                :isDisabled="hasRequest"
                @clickApplyFilter="fetchDataFilter"
              />

              <filter-button-component
                @open="showMoreFiltersModal"
                :data="filterButtonData"
                :isDisabled="hasRequest"
              />
            </div>

            <button-component
              class="button-component"
              text="Fazer saque"
              v-ripple="$theme.whiteColorTransparent"
              :isDisabled="hasRequest"
              @click.native="showCustomerModal"
            />
          </div>

          <table-header-component
            class="table-header-component"
            :hasRequest="hasRequest"
            :data="[{
              label: 'Valor total:',
              value: totalTransfers
            }]"
          />

          <table-component
            class="table-component"
            :table="tableData"
            :hasRequest="hasRequest && !isMoreData"
            @columnClick="showTransferDetail"
            @actionClick="showTransferDetail"
          />

          <pagination-table-component
            v-if="hasTableRows() && !isFilter"
            class="pagination-table-component"
            :isDisabled="!haveMoreData()"
            :data="paginationTableButtonData"
            :hasRequest="hasRequest"
            @onClick="moreData"
          />
        </div>
      </template>
    </default-view-component>

    <div v-else>
      <transfer-detail-component :detailId="currentDetailId" @close="closeDetail" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import Header from '@/components/Layout/Header';
  import Button from '@/components/Base/Button';
  import Table from '@/components/Table/Table';
  import TableHeader from '@/components/Table/TableHeader';
  import PaginationTableButton from '@/components/Table/PaginationTableButton';
  import SelectRadioField from '@/components/SelectRadioField';
  import WithdrawModal from '@/components/Modal/WithdrawModal';
  import FilterModal from '@/components/Modal/FilterModal';
  import FilterButton from '@/components/FilterButton';
  import TransferDetail from '@/components/TransferDetail';

  export default {
    name: 'TransferView',

    data() {
      return {
        header: {
          title: 'Saques',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        totalCountItems: 0,
        countItems: 0,
        isMoreData: false,
        isFilter: false,
        hasDetail: false,
        currentDetailId: '',

        totalTransfers: 'R$ 0,00',

        moreFilterModal: false,
        withdrawModal: false,
        hasRequest: false,

        filterButtonData: {
          text: 'Mais filtros'
        },

        filterData: {
          amountRange: {
            min: 0,
            max: 0
          },
          dateRange: {
            start: '',
            end: ''
          }
        },

        filterStatusData: [
          {
            label: 'Aprovadas',
            name: 'Succeeded',
            isChecked: false
          },
          {
            label: 'Falhadas',
            name: 'Failed',
            isChecked: false
          },
          {
            label: 'Pendentes',
            name: 'Pending',
            isChecked: false
          }
        ],

        tableData: this.initialTableData(),

        paginationTableButtonData: {
          countItems: 'x',
          totalCountItems: '2x'
        },

        bankAccounts: [],
      };
    },

    components: {
      'default-view-component': DefaultView,
      'header-component': Header,
      'button-component': Button,
      'table-component': Table,
      'table-header-component': TableHeader,
      'pagination-table-component': PaginationTableButton,
      'select-radio-field': SelectRadioField,
      'withdraw-modal-component': WithdrawModal,
      'filter-modal-component': FilterModal,
      'filter-button-component': FilterButton,
      'transfer-detail-component': TransferDetail
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerData',
        'getCurrentSellerId',
      ])
    },

    created() {
      this.fetchData();
    },

    watch: {
      totalCountItems(value) {
        this.paginationTableButtonData.totalCountItems = value;
      },

      countItems(value) {
        this.paginationTableButtonData.countItems = value;
      },

      getCurrentSellerId() {
        this.hasRequest = true;
        this.$store.commit('bankAccountModule/resetState');
        this.tableData = this.initialTableData();
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.fetchAllBankTransfers();
        }
      },
    },

    methods: {
      showCustomerModal() {
        this.withdrawModal = true;
      },

      showTransferDetail(item) {
        if (!this.$service.isNull(item.id)) {
          this.currentDetailId = item.id;
          this.hasDetail = true;
        }
      },

      closeDetail() {
        this.hasDetail = false;
      },

      closeWithdrawModal() {
        this.withdrawModal = false;
      },

      showMoreFiltersModal() {
        this.moreFilterModal = true;
      },

      closeMoreFilterModal() {
        this.moreFilterModal = false;
      },

      async updateFilterData(data) {
        this.filterData = data;

        await this.fetchDataFilter();
      },

      async fetchDataFilter() {
        this.isFilter = true;

        await this.fetchAllBankTransfers();

        this.isFilter = false;
      },

      async fetchData() {
        this.hasRequest = true;

        await this.fetchAllBankTransfers();

        this.hasRequest = false;
      },

      async fetchAllBankTransfers() {
        var currentFilterStatus = this.filterStatusData.filter(item => item.isChecked)[0];

        if (!this.isMoreData) {
          this.allValues = [];
        }

        this.hasRequest = true;

        let queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'offset',
            param: this.allValues.length
          },

          {
            query: 'status',
            param: !this.$service.isUndefined(currentFilterStatus) ? currentFilterStatus.name : ''
          },

          {
            query: 'date_range_start',
            param: this.filterData.dateRange.end ? this.$service.dateFormat(this.filterData.dateRange.start) : ''
          },

          {
            query: 'date_range_end',
            param: this.filterData.dateRange.end ? this.$service.dateFormat(this.filterData.dateRange.end) : ''
          },

          {
            query: 'amount_range_start',
            param: this.filterData.amountRange.min * 100 || ''
          },

          {
            query: 'amount_range_end',
            param:  this.filterData.amountRange.max * 100 || ''
          }
        ];

        await this.$store.dispatch('getAllBankTransfers', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.totalCountItems = res.total_count;

            this.$service.setArray(res.data, this.allValues);
            this.tableData.rows = this.formatTableAllRows(this.allValues);
            this.totalTransfers = this.$service.formatBrlAmountSymbol(this.formatTotalAmount(this.allValues));

            this.countItems = this.allValues.length;
          })
          .catch(err => {
            console.log(err);
          });

          this.hasRequest = false;
          this.isMoreData = false;
      },

      formatTableAllRows(arr) {
        let currentArr = [];

        arr.forEach(item => {
          currentArr.push(this.formatTableRow(item));
        });

        return currentArr;
      },

      formatTotalAmount(arr) {
        let totalAmount = 0;

        arr.forEach(item => {
          totalAmount += parseFloat(item.amount);
        });

        return totalAmount;
      },

      moreData() {
        if(!this.haveMoreData()) {
          this.isMoreData = true;

          this.fetchAllBankTransfers();
        }
      },

      haveMoreData() {
        return this.countItems == this.totalCountItems;
      },

      formatTableRow(item) {
        return {
          id: item.id,
          date: this.$service.dateFormat(item.transfer_date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY - HH:mm:ss'),
          favored: {
            value: item.bank_account ? item.bank_account.holder_name : '-',
            label: item.bank_account ? this.$service.formatTaxPayerOrEin(item.bank_account.taxpayer_id) : '-',
          },
          amount: this.$service.formatBrlAmountSymbol(item.amount),
          type: this.$service.toUpperCase(item.type) === 'PAYOUT_MANUAL' ?  'Manual' : 'Automático',
          status: item.status,
          actions: {
            icon: 'icon-more',
            items: [
              {
                id: item.id,
                isDetail: true,
                icon: 'icon-file',
                label: 'Ver detalhes'
              }
            ]
          }
        };
      },

      hasTableRows() {
        return this.tableData.rows.length != 0;
      },


      initialTableData() {
        return {
          hasIcon: false,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Data',
                field: 'date',
                align: 'center'
              },

              {
                label: 'Favorecido',
                field: 'favored',
                align: 'center'
              },

              {
                label: 'Valor',
                field: 'amount',
                align: 'center'
              },

              {
                label: 'Tipo',
                field: 'type',
                align: 'center'
              },

              {
                label: 'Status',
                field: 'status',
                align: 'center',
                isStatus: true
              },

              {
                label: 'Ações',
                align: 'center',
                field: 'actions',
                isAction: true
              }
            ]
          },

          rows: []
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .transfers-view-container {
    .section {
      padding: 1.5rem;

      .header-options {
        display: flex;
        margin-bottom: 1.5rem;

        .filters {
          display: flex;

          .input-field-component {
            width: 255px;
            margin-right: 1rem;
          }

          .more-filters {
            display: flex;
            height: 48px;
            border: 1px solid #DADADA;
            border-radius: 6px;
            align-items: center;
            font-size: 14px;
            padding: 0 1rem;
            margin-right: 1rem;
            cursor: pointer;
            color: #333;
            text-align: center;

            &:hover {
              background: #EFEFEF;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .button-component {
          max-width: 160px;
          margin-left: auto;
        }
      }

      .value {
        p {
          span {
            font-weight: 500;
          }
        }
      }

      .table-component {
        margin-top: 1.5rem;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .pagination-table-component {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
    }
  }

  @media (max-width: 605px) {
    .transfers-view-container {
      .section {
        .header-options {
          flex-direction: column-reverse;

          .filters {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .transfers-view-container {
      .section {
        .header-options {
          .filters {
            .more-filters {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
</style>