<template>
  <modal
    ref="modal"
    class="appointment-modal"
    title="Novo compromisso"
    :showModal="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <ValidationObserver
        v-slot="{ passes }"
        ref="form"
      >
        <form id="form" @submit.prevent="passes(submit)">
          <div class="body">
            <div class="section">
              <div class="fields">
                <div class="field">
                  <input-field-component
                    class="input-field-component"
                    placeholder="Compromisso"
                    rules="required"
                    v-model="data.description"
                  />

                  <input-date-field
                    class="input-field-component"
                    placeholder="Data pagamento"
                    type="date"
                    placement="right"
                    :time="true"
                    :minDate="new Date()"
                    v-model="data.date"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template v-slot:footer>
      <div class="footer-slot-container">
        <div class="button-container">
          <button-component
            class="button-component"
            styles="ghost"
            text="Fechar"
            :isDisabled="hasRequest"
            @click.native="closeModal"
            v-ripple="$theme.secondColorDarkTransparent"
          />

          <button-component
            class="button-component"
            text="Salvar"
            form="form"
            :hasLoader="hasRequest"
            :isDisabled="hasRequest"
            v-ripple="$theme.whiteColorTransparent"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import moment from 'moment';
  import Modal from '@/components/Modal/Base';
  import InputField from '@/components/Base/InputField';
  import Button from '@/components/Base/Button';
  import InputDateField from '@/components/Base/InputDateField';

  export default {
    name: 'AppointmentModal',

    data() {
      return {
        hasRequest: false,

        isUpdate: false,

        data: {
          description: '',
          date: '',
        }
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'input-field-component': InputField,
      'input-date-field': InputDateField,
      'button-component': Button,
    },

    props: {
      appointmentId: {
        type: String,
        required: false
      },
    },

    mounted() {
      this.isUpdate = !!this.appointmentId;

      if (this.isUpdate) {
        this.fetchData();
      }
    },

    methods: {
      async fetchData() {
        this.hasFetchRequest = true;

        await this.$store.dispatch('appointmentModule/getOneAppointment', this.appointmentId)
          .then(res => {
            this.data.description = res.description;
            this.data.date = res.date;
          })
          .catch(() => {
            this.snackbarNotification('Erro ao buscar compromisso', '#FF5A5A');
          });

        this.hasFetchRequest = false;
      },

      async submit() {
        this.hasRequest = true;

        let isSuccessful = false;

        const data = {
          description: this.data.description,
          date: moment(this.data.date).toISOString()
        };

        if (this.isUpdate) {
          await this.$store.dispatch('appointmentModule/patchAppointment', { id: this.appointmentId, data })
            .then(() => {
              isSuccessful = true;
            })
            .catch(() => {
              this.snackbarNotification('Erro ao atualizar compromisso', '#FF5A5A');
            });
        } else {
          await this.$store.dispatch('appointmentModule/postAppointment', JSON.stringify(data))
            .then(() => {
              isSuccessful = true;

              this.snackbarNotification('Compromisso cadastrado com sucesso', '#08B978');
            })
            .catch(() => {
              this.snackbarNotification('Erro ao cadastrar compromisso', '#FF5A5A');
            });
        }

        if (isSuccessful) {
          this.$emit('finishRequest', this.data.date);
        }

        this.closeModal();

        this.hasRequest = false;
      },

      closeModal() {
        this.$emit('close');
      },

      snackbarNotification(text, color) {
        this.$emit('snackbarNotification', text, color);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .appointment-modal {
    form {
      .body {
        margin-top: 1.5rem;
        padding: 0 1.5rem;
        width: 560px;
        overflow-y: auto;

        .section {
          padding: 0.5rem;

          .fields {
            .field {
              display: flex;
              gap: 0.5rem;

              .input-field-component {
                width: 100%;

                &.medium {
                  max-width: 250px;
                }

                &.small {
                  max-width: 175px;
                }
              }
            }
          }
        }
      }
    }

    .footer-slot-container {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);

      .button-container {
        display: flex;

        .button-component {
          width: 100px;
          height: 48px;
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .appointment-modal {
      form {
        .body {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .appointment-modal {
      form {
        .body {
          .fields {
            .field {
              flex-direction: column;
              margin-bottom: 1rem;
              gap: unset !important;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>