<template>
  <modal
    ref="modal"
    title="Editar endereço"
    @close="closeModalEdit"
    :showModal="hasModalAddressEdit"
  >
    <template v-slot:body>
      <ValidationObserver
        tag="div"
        class="form-container"
        ref="form"
        v-slot="{ passes }"
      >
        <form @submit.prevent="passes(submitAddressFormData)">
          <div class="wrapper">
            <div class="section">
              <div class="column">
                <input-field
                  class="input-field"
                  placeholder="CEP"
                  name="postalCode"
                  rules="cep|required"
                  mask="#####-###"
                  @blur="requestPostalCode"
                  v-model="addressData.postalCode"
                />
              </div>

              <div class="two-column">
                <input-field
                  class="input-field"
                  placeholder="Rua"
                  rules="required"
                  :isDisabled="disableStreetAndNeighborhood"
                  v-model="addressData.street"
                />

                <input-field
                  class="input-field right"
                  placeholder="Número"
                  rules="required"
                  :isDisabled="disableNumberAndComplement"
                  v-model="addressData.number"
                />
              </div>

              <div class="two-column">
                <input-field
                  class="input-field"
                  placeholder="Complemento"
                  :isDisabled="disableNumberAndComplement"
                  v-model="addressData.complement"
                />

                <input-field
                  class="input-field right"
                  placeholder="Bairro"
                  rules="required"
                  :isDisabled="disableStreetAndNeighborhood"
                  v-model="addressData.neighborhood"
                />
              </div>

              <div class="two-column">
                <input-field
                  class="input-field left"
                  placeholder="Estado"
                  rules="required"
                  :isDisabled="disableStateAndCity"
                  v-model="addressData.state"
                />

                <input-field
                  class="input-field"
                  placeholder="Cidade"
                  rules="required"
                  :isDisabled="disableStateAndCity"
                  v-model="addressData.city"
                />
              </div>
            </div>

            <div class="button-container">
              <button-component
                scale="normal"
                size="large"
                v-ripple="$theme.secondColorDarkTransparent"
                styles="ghost"
                type="button"
                text="Fechar"
                :isDisabled="hasRequest"
                @click.native="closeModalEdit"
              />

              <button-component
                class="right"
                scale="normal"
                size="large"
                :isDisabled="hasRequest || isButtonDisable"
                v-ripple="$theme.whiteColorTransparent"
                styles="primary"
                :hasLoader="hasRequest"
                text="Alterar e salvar"
              />
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { ValidationObserver } from 'vee-validate';
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';
  import InputField from '@/components/Base/InputField';

  export default {
    name: 'EditAddressModal',

    data() {
      return {
        addressData: {
          postalCode: '',
          neighborhood: '',
          street: '',
          city: '',
          state: '',
          complement: '',
          number: '',
        },

        hasRequest: false,
        isButtonDisable: true,
        disableStateAndCity: true,
        disableStreetAndNeighborhood: true,
        disableNumberAndComplement: true,
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'input-field': InputField,
      'button-component': Button
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerType'
      ]),

      ...mapState({
        sellerData: state => state.sellerData,
        currentSellerId: state => state.currentSellerId,
        hasModalAddressEdit: state => state.hasModalAddressEdit,
        isOwnerAddressEdit: state => state.isOwnerAddressEdit,
      }),
    },

    watch: {
      'addressData.postalCode'() {
        if(this.addressData.postalCode) {
          if(this.addressData.postalCode.length == 0) {
            this.defaultAddressData();
            this.disableAllFields();
          }
        }
      },
    },

    mounted() {
      this.setAddressData();
      this.isButtonDisable = true;
    },

    methods: {
      submitAddressFormData() {
        let formData;

        if(this.getCurrentSellerType === 'Individual') {
          formData = JSON.stringify({
            address_line1: this.addressData.street,
            address_line2: this.addressData.number,
            address_line3: this.addressData.complement,
            address_neighborhood: this.addressData.neighborhood,
            address_city: this.addressData.city,
            address_state: this.addressData.state,
            address_postal_code: this.addressData.postalCode,
          });

          this.submitRequestToEditSeller('putIndividualSeller', formData);
        } else if (this.getCurrentSellerType == 'Company' && this.isOwnerAddressEdit) {
          formData = JSON.stringify({
            business_address_line1: this.sellerData.business_address_line1,
            business_address_line2: this.sellerData.business_address_line2,
            business_address_line3: this.sellerData.business_address_line3,
            business_address_neighborhood: this.sellerData.business_address_neighborhood,
            business_address_city: this.sellerData.business_address_city,
            business_address_state: this.sellerData.business_address_state,
            business_address_postal_code: this.sellerData.business_address_postal_code,

            owner_address_line1: this.addressData.street,
            owner_address_line2: this.addressData.number,
            owner_address_line3: this.addressData.complement,
            owner_address_neighborhood: this.addressData.neighborhood,
            owner_address_city: this.addressData.city,
            owner_address_state: this.addressData.state,
            owner_address_postal_code: this.addressData.postalCode,
          });

          this.submitRequestToEditSeller('putCompanySeller', formData);
        } else if(this.getCurrentSellerType == 'Company') {
          formData = JSON.stringify({
            business_address_line1: this.addressData.street,
            business_address_line2: this.addressData.number,
            business_address_line3: this.addressData.owner_address_line3,
            business_address_neighborhood: this.addressData.neighborhood,
            business_address_city: this.addressData.city,
            business_address_state: this.addressData.state,
            business_address_postal_code: this.addressData.postalCode,

            owner_address_line1: this.sellerData.owner_address_line1,
            owner_address_line2: this.sellerData.owner_address_line2,
            owner_address_line3: this.sellerData.owner_address_line3,
            owner_address_neighborhood: this.sellerData.owner_address_neighborhood,
            owner_address_city: this.sellerData.owner_owner_address_city,
            owner_address_state: this.sellerData.owner_address_state,
            owner_address_postal_code: this.sellerData.owner_address_postal_code,
          });

          this.submitRequestToEditSeller('putCompanySeller', formData);
        }
      },

      requestPostalCode() {
        this.defaultAddressData();
        this.disableAllFields();
        this.isButtonDisable = true;
        this.hasRequest = true;

        let postalCode = this.$service.removeCharacter(this.addressData.postalCode);

        if (postalCode.length == 8) {
          this.$store.dispatch('getAddress', postalCode)
            .then(res => {
              this.addressData.city = res.data.city;
              this.addressData.state = res.data.state_code;

              if (res.data.neighborhood != '' && res.data.address_line_1 != '') {
                this.addressData.neighborhood = res.data.neighborhood;
                this.addressData.street = res.data.address_line_1;

                this.enableNumberAndComplement();
              } else {
                this.enableAllFields();
              }

              this.isButtonDisable = false;
              this.hasRequest = false;
            })
            .catch(() => {
              this.hasRequest = false;

              this.$refs.form.setErrors({
                postalCode: 'CEP não encontrado',
              });
            });
        }
      },

      async submitRequestToEditSeller(action, formData) {
        this.hasRequest = true;

        await this.$store.dispatch(action, { formData })
          .then(() => {
            this.snackbarNotification('Dados editados com sucesso', '#08B978');
          })
          .catch(() => {
            this.snackbarNotification('Erro ao editar os dados cadastrais', '#FF5A5A');
          });

        this.closeModalEdit();

        await this.$store.dispatch('getSellerId')
          .catch(() => {
            this.snackbarNotification('Erro ao atualizar os dados', '#FF5A5A');
          });

        this.isButtonDisable = true;
        this.hasRequest = false;
      },

      enableAllFields() {
        this.disableStreetAndNeighborhood = false;
        this.disableNumberAndComplement = false;
      },

      enableNumberAndComplement() {
        this.disableNumberAndComplement = false;
      },

      disableAllFields() {
        this.disableStreetAndNeighborhood = true;
        this.disableNumberAndComplement = true;
      },

      defaultAddressData() {
        this.addressData.neighborhood = '';
        this.addressData.street = '';
        this.addressData.city = '';
        this.addressData.state = '';
        this.addressData.complement = '';
        this.addressData.number = '';
      },

      setAddressData() {
        if (this.getCurrentSellerType == 'Individual') {
          this.setDefaultIndividualAddressData();
        } else if (this.getCurrentSellerType == 'Company' && this.isOwnerAddressEdit) {
          this.setDefaultCompanyOwnerAddressData();
        } else if (this.getCurrentSellerType == 'Company') {
          this.setDefaultCompanyAddressData();
        }
      },

      setDefaultIndividualAddressData() {
        this.addressData.postalCode = this.sellerData.address_postal_code;
        this.addressData.neighborhood = this.sellerData.address_neighborhood;
        this.addressData.street = this.sellerData.address_line1;
        this.addressData.number = this.sellerData.address_line2;
        this.addressData.complement = this.sellerData.address_line3;
        this.addressData.city = this.sellerData.address_city;
        this.addressData.state = this.sellerData.address_state;
      },

      setDefaultCompanyAddressData() {
        this.addressData.postalCode = this.sellerData.business_address_postal_code;
        this.addressData.neighborhood = this.sellerData.business_address_neighborhood;
        this.addressData.street = this.sellerData.business_address_line1;
        this.addressData.number = this.sellerData.business_address_line2;
        this.addressData.complement = this.sellerData.business_address_line3;
        this.addressData.city = this.sellerData.business_address_city;
        this.addressData.state = this.sellerData.business_address_state;
      },

      setDefaultCompanyOwnerAddressData() {
        this.addressData.postalCode = this.sellerData.owner_address_postal_code;
        this.addressData.neighborhood = this.sellerData.owner_address_neighborhood;
        this.addressData.street = this.sellerData.owner_address_line1;
        this.addressData.number = this.sellerData.owner_address_line2;
        this.addressData.complement = this.sellerData.owner_address_line3;
        this.addressData.city = this.sellerData.owner_address_city;
        this.addressData.state = this.sellerData.owner_address_state;
      },

      closeModalEdit() {
        this.$store.commit('closeModalEdit');
      },

      snackbarNotification(text, color) {
        this.$emit('snackbarNotification', text, color);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form-container {
    h2 {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .wrapper {
      margin-top: 1.5rem;

      .section {
        .two-column {
          display: flex;

          .input-field {
            max-width: 325px;

            &.left {
              margin-right: 1rem;
              max-width: 215px;
            }

            &.right {
              margin-left: 1rem;
              max-width: 215px;
            }
          }
        }

        .column {
          width: 305px;
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;

      .right {
        margin-left: 1rem;
        min-width: 156px;
      }
    }
  }

  @media (max-width: 767px) {
    .form-container {
      h2 {
        font-size: 16px;
      }

      .wrapper {
        .section {
          .two-column {
            .input-field  {
              max-width: unset;

              &.right {
                max-width: unset;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .form-container {
      .wrapper {
        overflow-y: scroll;
        max-height: 72vh;
        margin-bottom: 4rem;

        &::-webkit-scrollbar {
          display: none;
        }

        .section {
          .column {
            width: 100%;
          }

          .two-column {
            flex-direction: column;

            .input-field {
              max-width: unset;

              &.right {
                max-width: unset;
                margin-left: 0;
              }

              &.left {
                max-width: unset;
                margin-right: 0;
              }
            }
          }
        }

        .button-container {
          justify-content: center;
        }
      }
    }
  }
</style>