<template>
  <div class="extract-view-container">
    <default-view-component v-if="!isTransactionDetail">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:content>
        <div class="section" :class="{ 'has-sidebar': !getSideBarState.hideSideBar }">
          <header-component
            :data="header"
          />

          <div class="table-buttons-container">
            <div class="buttons-content">
              <scroll-card-component
                :data="scrollCardData"
                :isDisabled="hasRequest"
              />

              <div class="filters-container">
                <filter-card-component
                  class="filter-card-container"
                  :data="filterDate"
                  :isDisabled="hasRequest"
                />

                <filter-card-component
                  class="filter-card-container"
                  :data="filterTransactionType"
                  :isDisabled="hasRequest"
                />
              </div>
            </div>

            <div class="buttons-content">
              <div class="transaction-cards">
                <transaction-table-card
                  :data="positiveTransactionCard"
                  :isDisabled="hasRequest"
                  @onClick="filterPositiveValues"
                />

                <transaction-table-card
                  :data="negativeTransactionCard"
                  :isDisabled="hasRequest"
                  @onClick="filterNegativeValues"
                />
              </div>
            </div>
          </div>

          <navigation-bar-component
            v-show="isSellerBanking()"
            class="navigation-bar-component"
            :data="navigationData"
            :isDisabled="hasRequest"
          />

          <table-component
            class="table-component"
            :class="{ 'no-scroll': hasRequest || !hasTableRows() }"
            :table="tableData"
            :hasRequest="hasRequest && !isMoreData"
            @columnClick="showTransactionDetail"
          />

          <div v-if="!isBankingSection()" class="pagination-table-button-container">
            <div v-if="hasRequest && !isMoreData">
              <skeleton-loader-component class="default-data"/>
            </div>

            <pagination-table-component
              v-else-if="hasTableRows()"
              class="pagination-table-button-component"
              :isDisabled="!haveMoreData()"
              :data="paginationTableButtonData"
              :hasRequest="hasRequest"
              @onClick="moreData"
            />
          </div>
        </div>
      </template>
    </default-view-component>

    <transaction-detail-component v-else :detailId="currentTransactionId" @close="closeTransactionDetail" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import Header from '@/components/Layout/Header';
  import Table from '@/components/Table/Table';
  import TransactionTableCard from '@/components/Table/TransactionTableCard';
  import ScrollCard from '@/components/Table/ScrollCard';
  import FilterCard from '@/components/Table/FilterCard';
  import PaginationTableButton from '@/components/Table/PaginationTableButton';
  import SkeletonLoader from '@/components/Base/SkeletonLoader';
  import NavigationBar from '@/components/Base/NavigationBar';
  import TransactionDetail from '@/components/TransactionDetail';
  import SnackBar from '@/components/Base/SnackBar';

  export default {
    name: 'ExtractView',

    data() {
      return {
        hasExtract: false,
        hasRequest: false,
        arrCountItems: 0,
        countItems: 0,
        totalCountItems: 0,
        allValues: [],
        isMoreData: false,
        isTransactionDetail: false,
        currentTransactionId: '',

        simpleMessageComponentData: {
          icon: 'icon-receipts',
          text: 'Não foram encontrados registros de extrato'
        },

        paginationTableButtonData: {
          countItems: 'x',
          totalCountItems: '2x'
        },

        navigationData: {
          current: 'banking',
          items: [
            {
              label: 'Conta',
              isActive: true,
              name: 'banking'
            },

            {
              label: 'Adquirência',
              isActive: false,
              name: 'payment'
            }
          ]
        },

        filterDate: {
          filterType: 'date',
          icon: 'icon-calendar',
          title: 'Personalizar período',
          text: 'Personalizar',
          isActive: false,
          items: [
            {
              label: 'Último dia',
              isChecked: false,
              value: -1
            },

            {
              label: 'Últimos 3 dias',
              isChecked: false,
              value: -3
            },

            {
              label: 'Últimos 7 dias',
              isChecked: false,
              value: -7
            },

            {
              label: 'Últimos 30 dias',
              isChecked: false,
              value: -30
            },

            {
              label: 'Últimos 60 dias',
              isChecked: false,
              value: -60
            }
          ]
        },

        filterTransactionType: {
          filterType: 'transactionType',
          icon: 'icon-align',
          title: 'Filtrar por tipo de operação',
          text: 'Filtrar',
          isActive: false,
          items: [
            {
              label: 'Depósitos',
              // icon: 'icon-transfer',
              // color: 'orange',
              isChecked: true,
              value: 'deposit',
              type: 'default'
            },

            {
              label: 'Depósitos estornados',
              // icon: 'icon-transfer',
              // color: 'orange',
              isChecked: true,
              value: 'deposit_refunded',
              type: 'default'
            },

            {
              label: 'Vendas',
              // icon: 'icon-deposits',
              // color: 'orange',
              isChecked: true,
              value: 'charge',
              type: 'default'
            },

            {
              label: 'Vendas estornadas',
              // icon: 'icon-deposits',
              // color: 'red',
              isChecked: true,
              value: 'charge_refunded',
              type: 'default'
            },

            {
              label: 'Pagamentos de conta',
              // icon: 'icon-deposits',
              // color: 'red',
              isChecked: true,
              value: 'bill_payment',
              type: 'default'
            },

            {
              label: 'Pagamentos de conta estonados',
              // icon: 'icon-deposits',
              // color: 'red',
              isChecked: true,
              value: 'bill_payment_refunded',
              type: 'default'
            },

            {
              label: 'Transferências',
              // icon: 'icon-deposits',
              // color: 'red',
              isChecked: true,
              value: 'transfer',
              type: 'default'
            },

            {
              label: 'Transferências estornadas',
              // icon: 'icon-deposits',
              // color: 'red',
              isChecked: true,
              value: 'transfer_refunded',
              type: 'default'
            },

            {
              label: 'Ajustes',
              // icon: 'icon-deposits',
              // color: 'red',
              isChecked: true,
              value: 'adjustment',
              type: 'default'
            },
          ]
        },

        tableData: {
          hasIcon: true,
          headers: {
            items: [
              {
                label: 'Data',
                field: 'date',
                align: 'left'
              },

              {
                label: 'Descrição',
                field: 'description',
                align: 'left',
                minWidth: '375px'
              },

              {
                label: 'Valor bruto',
                field: 'grossAmount',
                align: 'left'
              },

              {
                label: 'Taxa',
                field: 'fee',
                align: 'center'
              },

              {
                label: 'Valor líquido',
                field: 'amount',
                align: 'left'
              },

              {
                label: 'Saldo',
                field: 'currentBalance',
                align: 'left'
              }
            ]
          },
          rows: []
        },

        scrollCardData: [
          {
            text: 'Jan',
            isActive: false,
            value: 1
          },

          {
            text: 'Fev',
            isActive: false,
            value: 2
          },

          {
            text: 'Mar',
            isActive: false,
            value: 3
          },

          {
            text: 'Abr',
            isActive: false,
            value: 4
          },

          {
            text: 'Mai',
            isActive: false,
            value: 5
          },

          {
            text: 'Jun',
            isActive: false,
            value: 6
          },

          {
            text: 'Jul',
            isActive: false,
            value: 7
          },

          {
            text: 'Ago',
            isActive: false,
            value: 8
          },

          {
            text: 'Set',
            isActive: false,
            value: 9
          },

          {
            text: 'Out',
            isActive: false,
            value: 10
          },

          {
            text: 'Nov',
            isActive: false,
            value: 11
          },

          {
            text: 'Dez',
            isActive: false,
            value: 12
          }
        ],

        positiveValues: {
          total: '',
          items: []
        },

        negativeValues: {
          total: '',
          items: []
        },

        header: {
          title: 'Extrato',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        positiveTransactionCard: {
          baseColor: '#08B978',
          icon: 'icon-arrow-up-circle',
          status: 'Entradas',
          value: 0,
          valueIsNegative: false,
          isActive: false,
        },

        negativeTransactionCard: {
          baseColor: '#FF5A5A',
          icon: 'icon-arrow-down-circle',
          status: 'Saídas',
          value: 0,
          valueIsNegative: true,
          isActive: false,
        }
      };
    },

    components: {
      'default-view-component': DefaultView,
      'transaction-table-card': TransactionTableCard,
      'table-component': Table,
      'header-component': Header,
      'scroll-card-component': ScrollCard,
      'filter-card-component': FilterCard,
      'pagination-table-component': PaginationTableButton,
      'skeleton-loader-component': SkeletonLoader,
      'navigation-bar-component': NavigationBar,
      'transaction-detail-component': TransactionDetail,
      'snackbar-component': SnackBar,
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerData',
        'getCurrentSellerBankingStatus',
        'getCurrentSellerId',
        'getExtractFilter',
        'getSideBarState',
        'getExtractPerTransactionFilter'
      ])
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
        this.countItems = 0;

        this.resetTableRowsArr();
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.checkNavigation();

          this.setInitialFilter();
          this.fetchData();
          this.resetFilterDateItems();
          this.resetTransactionModeState();
        }
      },

      arrCountItems(value) {
        this.paginationTableButtonData.countItems = value;
      },

      totalCountItems(value) {
        this.paginationTableButtonData.totalCountItems = value;
      },

      getExtractPerTransactionFilter(value) {
        if (value) {
          this.setTableRowsFormatted();
        }

        this.$store.commit('setExtractPerTransactionFilter', false);
      },

      getExtractFilter(value) {
        if (!this.hasRequest) {
          if (value.type == 'allMonth') {
            this.resetFilterDateItems();
            this.resetTransactionModeState();
          }

          this.fetchData();
        }
      },

      'navigationData.current'(value) {
        this.countItems = 0;

        this.navigationData.items.map(item => {
          item.isActive = false;

          if (item.name == value) {
            item.isActive = true;
          }
        });

        if (!this.hasRequest) {
          this.fetchData();
        }
      },
    },

    created() {
      this.setInitialFilter();
      this.resetTransactionModeState();
    },

    mounted()  {
      this.checkNavigation();

      this.fetchData();
    },

    methods: {
      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },

      checkNavigation() {
        this.navigationData.current = 'banking';

        if (!this.isSellerBanking()) {
          this.navigationData.current = 'payment';
        }
      },

      isSellerBanking() {
        return this.getCurrentSellerBankingStatus;
      },

      setInitialFilter() {
        let currentMonth = this.$service.currentMonth();

        this.$store.commit('setExtractFilter', {
          type: 'allMonth',
          startDate: this.$service.getStartDateMonth(currentMonth, 'M'),
          endDate: this.$service.getEndDateMonth(currentMonth, 'M')
        });

        this.scrollCardData.map(item => item.isActive = false);
        this.scrollCardData.filter(item => item.value == currentMonth)[0].isActive = true;
      },

      showTransactionDetail(item) {
        if (!this.$service.isNull(item.id)) {
          this.currentTransactionId = item.id;
          this.isTransactionDetail = true;
        }
      },

      closeTransactionDetail() {
        this.isTransactionDetail = false;
      },

      moreData() {
        if (!this.haveMoreData()) {
          this.isMoreData = true;
          this.fetchData();
        }
      },

      haveMoreData() {
        return this.countItems == this.totalCountItems;
      },

      isBankingSection() {
        return this.$service.toUpperCase(this.navigationData.current) == 'BANKING';
      },

      async fetchData() {
        if (!this.isMoreData) {
          this.allValues = [];
          this.arrCountItems = 0;
          this.countItems = 0;
          this.totalCountItems = 0;
        }

        this.filterTransactionType.isActive = false;
        this.hasRequest = true;

        switch(this.$service.toUpperCase(this.navigationData.current)) {
          case 'PAYMENT':
            await this.fetchPayment();
            break;
          case 'BANKING':
            await this.fetchBanking();
        }

        this.hasRequest = false;
      },

      async fetchBanking() {
        let queryParams = [
          {
            query: 'date_range_start',
            param: this.getExtractFilter.startDate
          },

          {
            query: 'date_range_end',
            param: this.getExtractFilter.endDate
          }
        ];

        await this.$store.dispatch('getAccountOperation', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.allValues = res.data.reverse();

            this.positiveTransactionCard.value = this.formatMaxAmountItems(this.allValues).totalAmount;
            this.negativeTransactionCard.value = this.formatMinAmountItems(this.allValues).totalAmount;

            this.setTableRowsFormatted();
          });
      },

      async fetchPayment() {
        const queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'date_range_start',
            param: this.getExtractFilter.startDate
          },

          {
            query: 'date_range_end',
            param: this.getExtractFilter.endDate
          },

          {
            query: 'offset',
            param: this.countItems
          }
        ];

        await this.$store.dispatch('getExtract', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.totalCountItems = res.total_count;

            this.allValues = this.allValues.concat(res.data);
            this.positiveTransactionCard.value = this.formatMaxAmountItems(this.allValues).totalAmount;
            this.negativeTransactionCard.value = this.formatMinAmountItems(this.allValues).totalAmount;

            this.setTableRowsFormatted();

            this.arrCountItems = this.tableData.rows.length;
            this.countItems = this.allValues.length;
          })
          .catch(() => {
            this.showSnackBar('Erro ao carregar os dados', '#FF5A5A');
          });

          if (this.isMoreData) {
            this.$service.scrollToBottom();
            this.isMoreData = false;
          }
      },

      setTableRowsFormatted() {
        this.resetTableRowsArr();
        let formattedTableRows = [];

        if (this.positiveTransactionCard.isActive) {
          formattedTableRows = this.formatTableAllRows(this.formatMaxAmountItems(this.allValues).items);
        } else if (this.negativeTransactionCard.isActive) {
          formattedTableRows = this.formatTableAllRows(this.formatMinAmountItems(this.allValues).items);
        } else {
          formattedTableRows = this.formatTableAllRows(this.allValues);
        }

        this.setArray(formattedTableRows, this.tableData.rows);
      },

      filterPositiveValues() {
        this.positiveTransactionCard.isActive = !this.positiveTransactionCard.isActive;
        this.negativeTransactionCard.isActive = false;

        this.setTableRowsFormatted();

        this.arrCountItems = this.tableData.rows.length;
      },

      filterNegativeValues() {
        this.negativeTransactionCard.isActive = !this.negativeTransactionCard.isActive;
        this.positiveTransactionCard.isActive = false;

        this.setTableRowsFormatted();

        this.arrCountItems = this.tableData.rows.length;
      },

      formatMinAmountItems(arr, currentArr = []) {
        let formattedMinAmount = {
          totalAmount: 0,
          items: currentArr
        };

        arr.forEach(item => {
          if (this.valueIsNegative(item.amount)) {
            formattedMinAmount.totalAmount += parseFloat(item.amount);
            formattedMinAmount.items.push(item);
          }
        });

        return formattedMinAmount;
      },

      formatMaxAmountItems(arr, currentArr = []) {
        let formattedMaxAmount = {
          totalAmount: 0,
          items: currentArr
        };

        arr.forEach(item => {
          if (!this.valueIsNegative(item.amount)) {
            formattedMaxAmount.totalAmount += parseFloat(item.amount);
            formattedMaxAmount.items.push(item);
          }
        });

        return formattedMaxAmount;
      },

      valueIsNegative(value) {
        return value.toString()[0] === '-';
      },

      setArray(arr, receiveArr) {
        arr.forEach(item => {
          this.filterTransactionType.items.forEach(filter => {
            if (filter.isChecked && item.type == filter.value) {
              receiveArr.push(item);
            }
          });
        });
      },

      formatTableAllRows(arr) {
        return arr.map(item => item = this.formatTableRow(item));
      },

      formatTableRow(item) {
        return {
          icon: {
            name: this.setTableIcon(item),
            status: this.valueIsInputOrOutput(item.amount)
          },
          id: item.transaction ? item.transaction.id : null,
          amount: this.$service.formatBrlAmountSymbol(item.amount),
          description: item.description,
          currentBalance: this.$service.formatBrlAmountSymbol(item.current_balance),
          type: item.type,
          objType: item?.object_type,
          date: this.$service.dateFormat(item.date, 'YYYY-MM-DD', 'DD/MM/YYYY'),
          fee: item.fee > 0 ? this.$service.formatBrlAmountSymbol(item.fee) : '-',
          grossAmount: this.$service.formatBrlAmountSymbol(item.gross_amount)
        };
      },

      valueIsInputOrOutput(value) {
        return this.valueIsNegative(value) ? 'sent' : 'received';
      },

      setTableIcon(item) {
        let icon;

        switch (this.$service.toUpperCase(item.type)) {
          case 'DEPOSIT':
            icon = 'icon-deposit-error';
            break;
          case 'DEPOSIT_REFUNDED':
            icon = 'icon-deposits';
            break;
          case 'TRANSFER':
            icon = 'icon-transfer';
            break;
          case 'RECEIVABLE':
            icon = 'icon-receivable';
            break;
          default:
            icon = 'icon-statement';
        }

        return icon;
      },

      hasTableRows() {
        return this.tableData.rows.length > 0;
      },

      hasFilter() {
        return this.getExtractFilter.startDate != null && this.getExtractFilter.endDate != null;
      },

      resetTransactionModeState() {
        this.positiveTransactionCard.isActive = false;
        this.negativeTransactionCard.isActive = false;
      },

      resetTableRowsArr() {
        this.tableData.rows = [];
      },

      resetFilterDateItems() {
        this.filterDate.items.map(item => item.isChecked = false);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .extract-view-container {
    .section {
      padding: 1.5rem;

      .table-buttons-container {
        margin-top: 2.5rem;
        margin-bottom: 2rem;

        .buttons-content {
          margin-top: 1rem;
          display: flex;

          .filters-container {
            display: flex;
            margin-left: auto;

            .filter-card-container {
              margin-left: 0.5rem;
            }
          }

          .transaction-cards {
            display: flex;

            .card-container {
              margin-right: 1rem;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      .table-component {
        overflow-x: auto;
        overflow-y: hidden;
      }

      .pagination-table-button-component {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        .default-data {
          width: 20rem;
          height: 1.5rem;
        }
      }
    }
  }


  @media (max-width: 968px) {
    .extract-view-container {
      .section {
        .table-buttons-container {
          margin-top: unset;
          margin-bottom: 1.5rem;

          .buttons-content {
            flex-direction: column-reverse;

            .filters-container {
              margin-left: unset;
              margin-bottom: 1rem;

              .filter-card-container {
                margin-left: 0;
                margin-right: 1rem;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }

          .download-results {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .extract-view-container {
      .section {
        .table-buttons-container {
          .buttons-content {
            .transaction-cards {
              .card-container {
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 370px) {
    .extract-view-container {
      .section {
        .table-buttons-container {
          .buttons-content {
            .transaction-cards {
              flex-direction:  column;

              .card-container {
                margin-right: 0;

                &:last-child {
                  margin-top: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
</style>