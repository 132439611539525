<template>
  <button
    :disabled="isDisabled"
    :class="isDisabled ? `${styles} disabled ${size} ${scale} ${hasLoader} ${hasLoader ? 'spinner' : ''}` : `${styles} ${size} ${scale} ${hasLoader ? 'spinner' : ''}`"
    :type="pathView ? 'button' : 'submit'"
    @click="redirect"
  >
    <spinner-component v-if="hasLoader" :fontSize="buttonFontSize" :color="isDisabled ? '#8E8E8E' : '#F36C01'" />

    <div v-else class="content">
      <div v-if="!!iconLeft" class="icon-left-container">
        <i :class="iconLeft"/>
      </div>

      {{ text }}

      <div v-if="!!iconRight" class="icon-right-container">
        <i :class="iconRight"/>
      </div>
    </div>
  </button>
</template>

<script>
  import Spinner from '@/components/Base/Spinner';

  export default {
    name: 'CustomButton',

    props: {
      text: {
        type: String,
        default: 'text',
			},

      isDisabled: {
        type: Boolean,
        default: false,
      },

      hasLoader: {
        type: Boolean,
        default: false,
      },

      iconLeft: {
        type: String,
        required: false,
      },

      iconRight: {
        type: String,
        required: false,
      },

      pathView: {
        type: String,
        default: null,
      },

      scale: {
        type: String,
        default: 'full'
      },

      size: {
        type: String,
        default: '',
      },

      buttonFontSize: {
        type: String,
        required: false
      },

      styles: {
        type: String,
        default: 'primary',
      }
    },

    components: {
      'spinner-component': Spinner
    },

    methods: {
      redirect() {
        if (window.location.pathname != this.pathView && this.pathView != null) this.$router.push(this.pathView);
        else if (window.location.pathname == this.pathView) console.error('Error: Cannot redirect to the same view');
      }
    }
  };
</script>

<style lang="scss" scoped>
	button {
    position: relative;
    font-size: 14px;
    font-weight: 500;
		min-height: 32px;
    max-height: 48px;
		border: none;
    border-radius: 6px;
    outline: none;
    cursor: pointer;

    justify-content: center;
    align-items: center;
    display: flex;

    &.full {
      width: 100%;
    }

    &.primary {
      color: $white-color;
      background: $second-color;

      &:hover {
        background-color: $second-color-dark-1
      }

      &:active {
        background-color: $second-color-dark-1;
      }
    }

    &.secondary {
      width: auto;
      color: $second-color;
      background: transparent;

      &:hover {
        color: $second-color;
        background: $second-color-transparent;
      }

      &:active {
        color: $second-color;
        background: $second-color-transparent;
      }
    }

    &.ghost {
      color: $second-color;
      background: transparent;
      border: 1px solid $second-color;

      &:hover {
        color: $second-color;
        background: $second-color-transparent;
        border: 1px solid $second-color;
      }

      &:active {
        color: $second-color;
        background: $second-color-transparent;
        border: 1px solid $second-color;
      }

      &.disabled {
        border-color: #8E8E8E;

        &:hover {
          color: #8E8E8E;
        }
      }
    }

    &.spinner {
      min-width: 106px;
    }

    &.disabled {
      opacity: 0.6;
      background: #EFEFEF !important;
      color: #8E8E8E;
      cursor: not-allowed;
    }

    &.large {
      height: 48px;
    }

    &.medium {
      height: 40px;
    }

    &.small {
      height: 32px;
    }

    .content {
      display: flex;
      padding: 0 1rem;
      font-weight: 500;

      .icon-left-container {
        margin-right: 10px;
      }

      .icon-right-container {
        margin-left: 10px;
      }
    }

    .spinner {
      display: inline-block;
      position: relative;
      width: 30px;
      height: 30px;

      div {
        position: absolute;
        background: $white-color;
        width: 7px;
        height: 7px;
        border-radius: 10px;
        animation: spin 1.2s linear infinite;
      }

      div:nth-child(1) {
        left: 0;
        top: 12px;
        animation-delay: -1.05s;
      }

      div:nth-child(2) {
        left: 3px;
        top: 3px;
        animation-delay: -0.90s;
      }

      div:nth-child(3) {
        top: 0;
        left: 12px;
        animation-delay: -0.75s;
      }

      div:nth-child(4) {
        right: 3px;
        top: 3px;
        animation-delay: -0.60s;
      }

      div:nth-child(5) {
        right: 0;
        top: 12px;
        animation-delay: -0.45s;
      }

      div:nth-child(6) {
        right: 3px;
        bottom: 3px;
        animation-delay: -0.30s;
      }

      div:nth-child(7) {
        left: 12px;
        bottom: 0;
        animation-delay: -0.15s;
      }

      div:nth-child(8) {
        left: 3px;
        bottom: 3px;
        animation-delay: 0s;
      }

      &.disabled {
        div {
          background: #8E8E8E;
        }
      }

      &.ghost {
        div {
          background: $second-color;
        }
      }
    }

    @keyframes spin {
      0%, 20%, 80%, 100% {
        transform: scale(0.5);
      }

      50% {
        transform: scale(1);
      }
    }
	}

  @media (max-width: 420px) {
    button {
      .content {
        .icon-left-container {
          display: flex;
          align-items: center;
          margin-right: 5px;
        }
      }
    }
  }
</style>