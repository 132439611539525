<template>
  <modal
    ref="modal"
    title="Editar dados"
    @close="closeModalEdit"
    :showModal="hasModalCompanyEdit"
  >
    <template v-slot:body>
      <ValidationObserver
        tag="div"
        class="body"
        ref="form"
        v-slot="{ passes, pristine }"
      >
        <form @input="toggleButtonState(pristine)" @submit.prevent="passes(submitCompanyFormData)">
          <div class="wrapper">
            <div class="section">
              <div class="column">
                <input-field
                  placeholder="E-mail"
                  name="email"
                  rules="required|mail"
                  v-model="companyData.email"
                />
              </div>

              <div class="two-column">
                <input-field
                  placeholder="Nome fantasia"
                  name="statementDescriptor"
                  rules="required"
                  v-model="companyData.statementDescriptor"
                />

                <input-field
                  class="right"
                  placeholder="Telefone"
                  name="phone"
                  rules="required|phone"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  v-model="companyData.businessPhoneNumber"
                />
              </div>
            </div>

            <div class="button-container">
              <button-component
                scale="normal"
                size="large"
                v-ripple="$theme.secondColorDarkTransparent"
                styles="ghost"
                type="button"
                :isDisabled="hasRequest"
                text="Fechar"
                @click.native="closeModalEdit"
              />

              <button-component
                class="right"
                scale="normal"
                size="large"
                v-ripple="$theme.whiteColorTransparent"
                styles="primary"
                :isDisabled="hasRequest || isButtonDisable"
                :hasLoader="hasRequest"
                text="Alterar e salvar"
              />
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import { mapState } from 'vuex';
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';
  import InputField from '@/components/Base/InputField';

  export default {
    name: 'EditCompanyModal',

    data() {
      return {
        hasRequest: false,
        isButtonDisable: true,
        companyData: {
          email: '',
          statementDescriptor: '',
          businessphoneNumber: '',
          businessAddressLine: '',
          businessAddressNumber: '',
          businessAddressComplement: '',
          businessAddressNeighborhood: '',
          businessAddressCity: '',
          businessAddressState: '',
          businessAddressPostalCode: '',
          ownerAddressLine: '',
          ownerAddressNumber: '',
          ownerAddressComplement: '',
          ownerAddressNeighborhood: '',
          ownerAddressCity: '',
          ownerAddressState: '',
          ownerAddressPostalCode: '',
        },
      };
    },

    components: {
      ValidationObserver,
      'button-component': Button,
      'input-field': InputField,
      'modal': Modal
    },

    computed: {
      ...mapState({
        hasModalCompanyEdit: state => state.hasModalCompanyEdit,
        sellerData: state => state.sellerData,
      })
    },

    watch: {
      sellerData() {
        this.setDefaultCompanyValues();
      },

      hasModalCompanyEdit() {
        this.setDefaultCompanyValues();
      }
    },

    mounted() {
      this.setDefaultCompanyValues();
    },

    methods: {
      async submitCompanyFormData() {
        this.hasRequest = true;

        let formData = JSON.stringify({
          business_email: this.companyData.email,
          statement_descriptor: this.companyData.statementDescriptor,

          business_phone_number: this.$service.removeCharacter(this.companyData.businessPhoneNumber),
          business_address_line1: this.companyData.businessAddressLine,
          business_address_line2: this.companyData.businessAddressNumber,
          business_address_line3: this.companyData.businessAddressComplement,
          business_address_neighborhood: this.companyData.businessAddressNeighborhood,
          business_address_city: this.companyData.businessAddressCity,
          business_address_state: this.companyData.businessAddressState,
          business_address_postal_code: this.$service.removeCharacter(this.companyData.businessAddressPostalCode),

          owner_address_line1: this.companyData.ownerAddressLine,
          owner_address_line2: this.companyData.ownerAddressNumber,
          owner_address_line3: this.companyData.ownerAddressComplement,
          owner_address_neighborhood: this.companyData.ownerAddressNeighborhood,
          owner_address_city: this.companyData.ownerAddressCity,
          owner_address_state: this.companyData.ownerAddressState,
          owner_address_postal_code: this.$service.removeCharacter(this.companyData.ownerAddressPostalCode),
        });

        await this.$store.dispatch('putCompanySeller', { formData })
          .then(() => {
            this.snackbarNotification('Dados editados com sucesso', '#08B978');
          })
          .catch(() => {
            this.snackbarNotification('Erro ao editar os dados cadastrais', '#FF5A5A');
          });

        this.closeModalEdit();

        await this.$store.dispatch('getSellerId')
          .catch(() => {
            this.snackbarNotification('Erro ao atualizar os dados', '#FF5A5A');
          });

        this.isButtonDisable = true;
        this.hasRequest = false;
      },

      setDefaultCompanyValues() {
        this.companyData.email = this.sellerData.business_email;
        this.companyData.statementDescriptor = this.sellerData.statement_descriptor;

        this.companyData.businessPhoneNumber = this.sellerData.business_phone;
        this.companyData.businessAddressLine = this.sellerData.business_address_line1;
        this.companyData.businessAddressNumber = this.sellerData.business_address_line2;
        this.companyData.businessAddressComplement = this.sellerData.business_address_line3;
        this.companyData.businessAddressNeighborhood = this.sellerData.business_address_neighborhood;
        this.companyData.businessAddressCity = this.sellerData.business_address_city;
        this.companyData.businessAddressState = this.sellerData.business_address_state;
        this.companyData.businessAddressPostalCode = this.sellerData.business_address_postal_code;

        this.companyData.ownerAddressLine = this.sellerData.owner_address_line1;
        this.companyData.ownerAddressNumber = this.sellerData.owner_address_line2;
        this.companyData.ownerAddressComplement = this.sellerData.owner_address_line3;
        this.companyData.ownerAddressNeighborhood = this.sellerData.owner_address_neighborhood;
        this.companyData.ownerAddressCity = this.sellerData.owner_address_city;
        this.companyData.ownerAddressState = this.sellerData.owner_address_state;
        this.companyData.ownerAddressPostalCode = this.sellerData.owner_address_postal_code;
      },

      closeModalEdit() {
        this.$store.commit('closeModalEdit');
      },

      toggleButtonState(pristine) {
        this.isButtonDisable = pristine;

        if (this.hasRequest) {
          this.isButtonDisable = true;
        }
      },

      snackbarNotification(text, color) {
        this.$emit('snackbarNotification', text, color);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modal {
    .body {
      .wrapper {
        margin-top: 1.5rem;

        .section {
          .two-column {
            display: flex;

            .right {
              margin-left: 1rem;
              max-width: 225px;
            }
          }

          .column {
            max-width: 305px;
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;

      .right {
        margin-left: 1rem;
        min-width: 156px;
      }
    }
  }

  @media (max-width: 767px) {
    .modal {
      h2 {
        font-size: 16px;
      }
    }
  }


  @media (max-width: 520px) {
    .modal {
      .body {
        .wrapper {
          overflow-y: scroll;
          max-height: 72vh;
          margin-bottom: 4rem;

          &::-webkit-scrollbar {
            display: none;
          }

          .section {
            .two-column {
              flex-direction: column;

              .right {
                margin-left: 0;
                max-width: initial;
              }
            }

            .column {
              width: 100%;
              max-width: unset;
            }
          }

          .button-container {
            justify-content: center;
          }
        }
      }
    }
  }
</style>