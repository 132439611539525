<template>
  <div class="sale-plan-view-container">
    <default-view-component>
      <template v-slot:content>
        <div class="wrapper">
          <header-component
            :data="header"
            class="header-container"
          />

          <div class="center">
            <default-card-component
              class="card-container"
              :data="cardData"
            />

            <div v-if="hasSalePlan" class="content">
              <div class="section">
                <div class="title">
                  Pix

                  <span v-if="!hasRequest">| Recebimento em <b>1 dia</b></span>
                </div>

                <div v-if="!hasRequest" class="text">
                  Taxa: <span>{{ pixFee }}</span>
                </div>

                <skeleton-loader-component class="default" v-else />
              </div>

              <div class="section">
                <div class="title">
                  Boleto

                  <span v-if="!hasRequest">| Recebimento em <b>1 dia</b></span>
                </div>

                <div v-if="!hasRequest" class="text">
                  Tarifa: <span>{{ boletoAmount }}</span>
                </div>

                <skeleton-loader-component class="default" v-else />
              </div>

              <div class="section">
                <div class="title">
                  Crédito Online

                  <span v-if="!hasRequest">| Recebimento em <b>{{ salesPlanLabel }}</b></span>
                </div>

                <div v-if="!hasRequest" class="table-container">
                  <table>
                    <tr>
                      <th></th>
                      <th v-for="(item, index) in onlineCreditFee" :key="index" class="header">
                        <div v-if="item.flag != 'default'" class="icon-container">
                          <img
                            :src="require(`@/assets/brands/${item.flag}.svg`)"
                            alt="Bandeira do cartão"
                          >
                        </div>

                        <p v-else>Outras</p>
                      </th>
                    </tr>

                    <tr v-for="(_, index) in maxCreditInstallments" :key="index">
                      <td>
                        {{ index == 0 ? 'À vista' : `${index + 1}x` }}
                      </td>
                      <td v-for="(item, itemIndex) in onlineCreditFee" :key="itemIndex">{{ item.percents[index] }}</td>
                    </tr>
                  </table>
                </div>

                <skeleton-loader-component v-else v-for="(_, index) in 10" :key="index" class="default full" />
              </div>

              <div class="section">
                <div class="title">
                  Crédito Físico

                  <span v-if="!hasRequest">| Recebimento em <b>{{ salesPlanLabel }}</b></span>
                </div>

                <div v-if="!hasRequest" class="table-container">
                  <table>
                    <tr>
                      <th></th>
                      <th v-for="(item, index) in chipCreditFee" :key="index" class="header">
                        <div v-if="item.flag != 'default'" class="icon-container">
                          <img
                            :src="require(`@/assets/brands/${item.flag}.svg`)"
                            alt="Bandeira do cartão"
                          >
                        </div>

                        <p v-else>Outras</p>
                      </th>
                    </tr>

                    <tr v-for="(_, index) in maxCreditInstallments" :key="index">
                      <td>
                        {{ index == 0 ? 'À vista' : `${index + 1}x` }}
                      </td>
                      <td v-for="(item, itemIndex) in chipCreditFee" :key="itemIndex">{{ item.percents[index] }}</td>
                    </tr>
                  </table>
                </div>

                <skeleton-loader-component v-else v-for="(_, index) in 10" :key="index" class="default full" />
              </div>

              <div class="section">
                <div class="title">
                  Débito

                  <span v-if="!hasRequest">| Recebimento em <b>1 dia</b></span>
                </div>

                <div v-if="!hasRequest" class="table-container">
                  <table>
                    <tr>
                      <th></th>
                      <th v-for="(item, index) in debitFee" :key="index" class="header">
                        <div v-if="item.flag != 'default'" class="icon-container">
                          <img
                            :src="require(`@/assets/brands/${item.flag}.svg`)"
                            alt="Bandeira do cartão"
                          >
                        </div>

                        <p v-else>Outras</p>
                      </th>
                    </tr>

                    <tr>
                      <td>
                        À vista
                      </td>
                      <td v-for="(item, index) in debitFee" :key="index">{{ item.percent }}</td>
                    </tr>
                  </table>
                </div>

                <skeleton-loader-component  v-for="(_, index) in 2" :key="index" class="default full" v-else />
              </div>
            </div>

            <div v-else class="empty-data">
              <div class="icon-container">
                <i class="icon-error-circle-outline" />
              </div>

              <p>Não encontramos nenhum plano de vendas, ative sua conta ou entre em contato com suporte para maiores informações.</p>
            </div>
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import AccountDefaultCard from '@/components/AccountDefaultCard';
  import Header from '@/components/Layout/Header';
  import SkeletonLoader from '@/components/Base/SkeletonLoader';

  export default {
    name: 'SalePlanView',

    data() {
      return {
        hasRequest: false,
        hasSalePlan: true,

        maxCreditInstallments: 0,

        header: {
          title: 'Taxas',
          to: '/account',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        cardData: {
          icon: 'icon-payments',
          title: 'Taxas',
          text: 'Visualize taxas do seu plano de venda',
        },

        salesPlanLabel: '',

        onlineCreditFee: [],
        chipCreditFee: [],
        debitFee: [],
        pixFee: '',
        boletoAmount: '',
      };
    },

    components: {
      'default-view-component': DefaultView,
      'default-card-component': AccountDefaultCard,
      'skeleton-loader-component': SkeletonLoader,
      'header-component': Header,
    },

    computed: {
      ...mapGetters({
        getCurrentSellerData: 'getCurrentSellerData',
        getCurrentSellerId: 'getCurrentSellerId',
        getCurrentSellerSaleStatus: 'getCurrentSellerSaleStatus'
      })
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.fetchSellerSalesPlan();
        }
      },
    },

    mounted() {
      this.fetchSellerSalesPlan();
    },

    methods: {
      async fetchSellerSalesPlan() {
        this.hasRequest = true;

        this.onlineCreditFee = [];
        this.chipCreditFee = [];
        this.debitFee = [];

        await this.$store.dispatch('getSellerSalesPlan')
          .then(res => {
            const salesPlan = res.data;
            const planId = salesPlan.plan_details.id;
            const isProPlan = planId.includes('d1');

            this.salesPlanLabel = isProPlan ? '1 dia' : '30 dias';

            for (const flag in salesPlan.credit) {
              this.onlineCreditFee.push({
                flag,
                percents: salesPlan.credit[flag].map(item => `${(item.online.percent_amount / 100).toFixed(2)}%`)
              });

              this.chipCreditFee.push({
                flag,
                percents: salesPlan.credit[flag].map(item => `${(item.chip.percent_amount / 100).toFixed(2)}%`)
              });
            }

            this.maxCreditInstallments = this.onlineCreditFee[0].percents.length;

            const preferredCreditBrands = ['mastercard', 'visa', 'hipercard', 'elo', 'american-express', 'hiper'];
            this.onlineCreditFee = this.sortBrands(this.onlineCreditFee, preferredCreditBrands);
            this.chipCreditFee = this.sortBrands(this.chipCreditFee, preferredCreditBrands);

            for (const flag in salesPlan.debit) {
              this.debitFee.push({
                flag,
                percent: `${(salesPlan.debit[flag].percent_amount / 100).toFixed(2)}%`
              });
            }

            const preferredDebitBrands = ['maestro', 'visa-electron', 'hipercard', 'elo', 'hiper'];
            this.debitFee = this.sortBrands(this.debitFee, preferredDebitBrands);

            this.pixFee = `${(salesPlan.pix.percent_amount / 100).toFixed(2)}%`;
            this.boletoAmount = this.$service.formatBrlAmountSymbol(salesPlan.boleto.dollar_amount / 100);
          })
          .catch(() => {
            this.hasSalePlan = false;
          });

        this.hasRequest = false;
      },

      sortBrands(brands, preferredBrands) {
        let preferredBrandsFees = brands.filter(item => preferredBrands.includes(item.flag));
        preferredBrandsFees.sort((a, b) => preferredBrands.indexOf(a.flag) - preferredBrands.indexOf(b.flag));

        let otherBrandsFees = brands.filter(item => !preferredBrands.includes(item.flag));
        return preferredBrandsFees.concat(otherBrandsFees);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .sale-plan-view-container {
    .wrapper {
      padding: 1.5rem;

      .header-component {
        display: flex;
        align-items: center;
        margin-bottom: 1rem !important;

        h2 {
          color: #333;
        }

        p {
          margin-left: auto;
          font-weight: 600;
          color: $second-color;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .center {
        display: flex;
        justify-content: center;

        .card-container {
          margin-right: 2rem;
        }

        .content {
          border: 1px solid #EFEFEF;
          border-radius: 6px;
          padding: 0 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 750px;
          min-width: 200px;

          .section {
            border-bottom: 1px solid #EFEFEF;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:last-child {
              border: none;
            }

            .default {
              margin-top: 0.5rem;
              height: 24px;
              width: 150px;

              &.full {
                height: 48px;
                width: 100%;
              }
            }

            .title {
              text-align: center;
              font-weight: 500;

              b {
                font-weight: 500;
              }
            }

            .text {
              font-size: 14px;

              span {
                font-weight: 500;
              }
            }

            .title {
              font-size: 18px;

              span {
                font-size: 14px;
                font-weight: 300;
              }
            }

            .table-container {
              overflow: auto;
              margin-top: 0.5rem;
              width: 100%;

              table {
                width: 100%;
                border-collapse: collapse;

                .header {
                  background: #FFF !important;

                  p {
                    font-size: 12px;
                    font-weight: 500;
                  }
                }

                td:nth-child(even), th:nth-child(even) {
                  background-color: #F2F2F2;
                }

                tr {
                  th {
                    padding: 0.5rem;
                    text-align: center;

                    &:first-child {
                      font-weight: 500;
                    }
                  }

                  td {
                    padding: 0.75rem 0.25rem;
                    text-align: center;

                    &:first-child {
                      font-weight: 500;
                    }

                    &.title {
                      font-weight: 500;
                      white-space: nowrap;
                    }

                    .icon-container {
                      display: flex;
                      align-items: center;
                    }
                  }
                }
              }
            }
          }
        }

        .empty-data {
          justify-content: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 750px;
          min-width: 200px;
          text-align: center;

          .icon-container {
            display: flex;
            justify-content: center;

            i {
              color: #dadada;
              font-size: 5rem;
            }
          }

          p {
            margin-top: 2rem;
            color: #6d6f6f;
            max-width: 500px;
          }
        }
      }
    }
  }

  @media (max-width: 976px) {
    .sale-plan-view-container {
      .wrapper {
        .center {
          .card-container {
            display: none;
          }

          .content {
            .section {
              .title {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
</style>