<template>
  <div class="custom-calendar-content">
    <v-date-picker
      :masks="masks"
      :first-day-of-week="1"
      color="gray"
      :columns="$screens(columnAttr)"
      :rows="$screens(rowAttr)"
      is-range
      is-expanded
      v-model="range"
    />
  </div>
</template>

<script>
  export default {
    name: 'CustomCalendar',

    data() {
      return {
        masks: {
          title: 'MMMM YYYY',
          weekdays: 'W'
        },

        columnAttr: {
          default: 2
        },

        rowAttr: {
          default: 1
        },

        range: {
          start: null,
          end: null
        }
      };
    },

    props: {
      value: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      }
    },

    watch: {
      'range.start'() {
        this.$emit('input', this.range);
      },

      'range.end'() {
        this.$emit('input', this.range);
      },
    },

    created() {
      if (this.value) {
        this.range = this.value;
      }
    },

    mounted() {
      this.onResize();

      window.addEventListener('resize', () => {
        this.onResize();
      });
    },

    methods: {
      onResize() {
        if (window.innerWidth < 780) {
          this.columnAttr.default = 1;
          this.rowAttr.default = 2;
        } else {
          this.columnAttr.default = 2;
          this.rowAttr.default = 1;
        }
      },

      beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
      }
    }
  };
</script>

<style lang="scss">
  .custom-calendar-content {
    .vc-container {
      font-family: 'Poppins', sans-serif;
      border-color: transparent;
      background-color: transparent;

      .vc-pane-container {
        .vc-header {
          .vc-title {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
          }
        }

        .vc-weeks {
          .vc-weekday {
            color: #333;
            font-weight: 600;
            padding: 1rem;
            font-size: 12px;
          }

          .vc-day {
            min-height: 40px;

            .vc-day-content  {
              background-color: unset;
            }

            .vc-highlights {
              .vc-day-layer {
                &.vc-day-box-center-center {
                  .vc-highlight {
                    background-color: $second-color!important;
                    border: none !important;

                    &.vc-highlight-base-middle {
                      background-color: $second-color-light-1 !important;
                    }
                  }
                }

                &.vc-day-box-right-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }

                &.vc-day-box-left-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }
              }
            }

            span {
              font-weight: 400 !important;
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
</style>