var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-container",class:{
    'has-modal': _vm.hasModal || _vm.showSellerStatusInformationModal || _vm.getIsResetPassword && !_vm.hideForceResetPassword,
    'has-sidebar': !_vm.getSideBarState.hideSideBar
  }},[_vm._t("alert"),_vm._t("modal"),(_vm.getIsResetPassword && !_vm.hideForceResetPassword)?_c('force-reset-password-modal'):_vm._e(),(_vm.showSellerStatusInformationModal)?_c('seller-status-information-modal',{on:{"close":function($event){_vm.showSellerStatusInformationModal = false}}}):_vm._e(),(_vm.showNotice)?_c('notice-card-component',{attrs:{"options":_vm.noticeCardData},on:{"click":_vm.noticeAction}}):_vm._e(),_c('div',{staticClass:"view-content"},[_c('side-bar-component'),_c('div',{staticClass:"content",class:{
      'hide-sidebar': _vm.getSideBarState.hideSideBar,
      'show-sidebar': !_vm.getSideBarState.hideSideBar
    }},[_c('nav-bar-component',{attrs:{"hasSelect":true}}),_c('div',{staticClass:"section-container"},[_vm._t("content")],2)],1)],1),_c('floating-button-component',{attrs:{"icon":"icon-wpp","bgColor":"#25d366"},on:{"click":_vm.redirectToWpp}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }