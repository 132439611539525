<template>
  <div class="not-found-view-container">
    <image-view-component :showImage="$service.isDefaultPlatform()" :image="$service.getPlatformImagesPath('illustration-404.png')">
      <template v-slot:form>
        <div class="wrapper">
          <div class="content">
            <h2>Erro 404</h2>

            <p>
              Pedimos desculpa, o endereço inserido
            </p>

            <p>
              não está disponível.
            </p>
          </div>
        </div>
      </template>
    </image-view-component>
  </div>
</template>

<script>
  import ImageView from '@/components/Layout/ImageView';

  export default {
    name: 'NotFoundView',

    components: {
      'image-view-component': ImageView,
    }
  };
</script>

<style style lang="scss" scoped>
  .not-found-view-container {
    .wrapper {
      color: #333;
      width: 100%;
      max-width: 395px;
      align-items: center;
      display: flex;

      .content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;

        h2 {
          margin-bottom: 16px;
        }

        p {
          text-align: center;
        }
      }
    }
  }
</style>