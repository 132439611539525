<template>
  <div class="sales-payment-type-card-container">
    <div class="title-container">
      <h2>{{ title }}</h2>
    </div>

    <div class="content">
      <div v-if="hasRequest" class="default-data-container">
        <skeleton-loader-component class="chart-default-data" />

        <skeleton-loader-component class="legend-default-data" />
      </div>

      <simple-message-component
        v-else-if="series.length === 0"
        class="empty-data-content"
        :data="emptyData"
      />

      <apexchart
         v-else
         type="polarArea"
         width="300"
         :options="chartOptions"
         :series="series"
        ></apexchart>
    </div>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';
  import SkeletonLoader from '@/components/Base/SkeletonLoader';
  import SimpleMessage from '@/components/SimpleMessage';

  export default {
    name: 'DashboardPaymentType',

    data() {
      return {
        series: [],

        emptyData: {
          icon: 'icon-receipts',
          text: 'Não foram encontrados registros'
        },

        chartOptions: {
          labels: [],
          fill: {
            opacity: 1
          },
          yaxis: {
            show: false
          },
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0
              },
              spokes: {
                strokeWidth: 0
              },
            }
          },
          theme: {
            monochrome: {
              enabled: true,
              shadeTo: 'dark',
              shadeIntensity: 0.6
            }
          }
        },
      };
    },

    components: {
      'apexchart': VueApexCharts,
      'simple-message-component': SimpleMessage,
      'skeleton-loader-component': SkeletonLoader,
    },

    props: {
      hasRequest: {
        type: Boolean,
        default: false
      },

      title: {
        type: String,
        default: 'Title'
      },

      data: {
        type: Array,
        default: () => []
      }
    },

    watch: {
      hasRequest(value) {
        if (!value) {
          const filterData = this.data.filter(item => item.total > 0);

          this.series = filterData.map(item => item.total);
          this.chartOptions.labels = filterData.map(item => item.label);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .sales-payment-type-card-container{
    border: 1px solid #EFEFEF;
    border-radius: 6px;
    width: 100%;
    padding: 1rem;
    min-height: 310px;

    .title-container {
      display: flex;
      justify-content: center;

      h2 {
        color: #333;
        font-size: 16px;
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      .empty-data-content {
        margin-top: 2.5rem;
      }

      .default-data-container  {
        .chart-default-data {
          width: 170px;
          height: 170px;
          border-radius: 50%;
        }

        .legend-default-data {
          width: 100%;
          height: 24px;
          margin-top: 1rem;
        }
      }
    }
  }

  @media (max-width: 980px) {
    .sales-payment-type-card-container {

      .title-container {
        h2 {
          font-size: 14px;
        }
      }
    }
  }
</style>