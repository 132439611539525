<template>
  <div
    v-if="isShow"
    class="snackbar-container"
    :style="{ 'background': bgColor }"
  >
    <div class="card">
      <p>{{ text }}</p>

       <i class="icon-close" @click="hide" />
    </div>

    <div class="progress-bar"></div>
  </div>
</template>

<script>
  export default {
    name: 'SnackBar',

    data() {
      return {
        isShow: false,

        text: 'This is a snackbar',
        bgColor: '#08B978',
      };
    },

    methods: {
      show(text, backgroundColor, time = 1800) {
        this.isShow = true;

        this.text = text;
        this.bgColor = backgroundColor;

        setTimeout(() => {
          this.hide();
        }, time);
      },

      hide() {
        this.isShow = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .snackbar-container {
    width: 250px;
    position: absolute;
    right: 0;
    margin-top: 100px;
    z-index: 1000;

    .card {
      display: flex;
      min-height: 4rem;
      padding: 1rem;

      p {
        color: $white-color;
        margin-right: 1.5rem;
        font-weight: 400;
      }

      i {
        color: #FFFFFF80;
        margin-left: auto;
      }
    }

    .progress-bar {
      height: 6px;
      animation: progress 2s;
      background: #FFFFFF64;
    }

    @keyframes progress {
      from {
        width: 0%;
      }

      to {
        width: 100%;
      }
    }
  }
</style>