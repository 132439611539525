<template>
  <div class="receivable-card-container">
    <div class="card active">
      <div v-if="!hasRequest" class="card-container">
        <div class="card-content">
          <div class="label">
            {{ primaryCard.item.label }}
          </div>

          <div class="value">
            {{ primaryCard.item.value }}
          </div>
        </div>

        <div class="value">
          <span>R$</span>

          {{ primaryCard.totalAmount }}
        </div>
      </div>

      <div v-else class="default-container">
        <div>
          <skeleton-loader-component :colors="activeDefaultDataColor" class="default" />

          <skeleton-loader-component :colors="activeDefaultDataColor" class="default" />
        </div>

        <div class="default-content">
          <skeleton-loader-component :colors="activeDefaultDataColor" class="default"/>
        </div>
      </div>
    </div>

    <div class="card">
      <div v-if="!hasRequest" class="card-container">
        <div class="card-content">
          <div class="label">
            {{ secondCard.item.label }}
          </div>

          <div class="value">
            {{ secondCard.item.value }}
          </div>
        </div>

        <div class="value">
          <span>R$</span>

          {{ secondCard.totalAmount }}
        </div>
      </div>

      <div v-else class="default-container">
        <div>
          <skeleton-loader-component class="default" />

          <skeleton-loader-component class="default" />
        </div>

        <div class="default-content">
          <skeleton-loader-component class="default"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SkeletonLoader from '@/components/Base/SkeletonLoader';

  export default {
    name: 'ReceivableCard',

    data() {
      return {
        activeDefaultDataColor: {
          primaryColor: '',
          secondColor: ''
        }
      };
    },

    props: {
      primaryCard: {
        type: Object,
        default() {
          return {
            item: {
              label: 'text 1',
              value: 'value 1'
            },
            totalAmount: '0,00',
          };
        }
      },

      secondCard: {
        type: Object,
        default() {
          return {
            item: {
              label: 'text 2',
              value: 'value 1'
            },
            totalAmount: '0,00',
          };
        }
      },

      hasRequest: {
        type: Boolean,
        default: false
      }
    },

    components: {
      'skeleton-loader-component': SkeletonLoader
    },

    created() {
      this.activeDefaultDataColor = {
        primaryColor: this.$theme.secondColorLight2,
        secondColor: this.$theme.secondColorLight3
      };
    }
  };
</script>

<style lang="scss" scoped>
  .receivable-card-container {
    display: flex;
    color: #333;

    .card {
      min-height: 90px;
      padding: 1rem;
      border: 1px solid #DADADA;
      display: flex;
      align-items: center;
      max-width: 500px;
      width: 100%;
      min-width: 350px;

      .default-container {
        display: flex;
        width: 100%;

        .default {
          height: 20px;
          width: 200px;

          &:last-child {
            margin-top: 0.5rem;
          }
        }

        .default-content {
          display: flex;
          align-items: center;
          margin-left: auto;

          .default {
            margin-top: 0;
            width: 150px;
            margin-left: 1rem;
          }
        }
      }

      &:first-child {
        border-radius: 6px 0 0 6px;
        border-right: none;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
        border-left: none;
      }

      &.active {
        border: 2px solid $second-color;
        background: $second-color-light-1;
      }

      .card-container {
        align-items: center;
        display: flex;
        width: 100%;

        .card-content {
          .label {
            font-weight: 400;
            margin-bottom: 0.4rem;
          }

          .value {
            font-size: 16px;
            font-weight: 500;
          }
        }

        .value {
          margin-left: auto;
          font-size: 24px;
          font-weight: 600;

          span {
            font-size: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 1070px) {
    .receivable-card-container {
      .card {
        .default-container {
          .default-content {
            .default {
              width: 95px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .receivable-card-container {
      flex-direction: column;

      .card {
        max-width: unset;

        &:first-child {
          border-radius: 6px 6px 0 0;
        }

        &:last-child {
          border-radius: 0 0 6px 6px;
          border: 1px solid #DADADA;
          border-top: none;
        }
      }
    }
  }

  @media (max-width: 835px) {
    .receivable-card-container {
      .card {
        .default-container {
          .default-content {
            .default {
              width: 125px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 550px) {
    .receivable-card-container {
      .card {
        .default-container {
          .default {
            height: 20px;
            width: 125px;
          }

          .default-content {
            .default {
              width: 80px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .receivable-card-container {
      .card {
        min-width: 320px;

        .card-container {
          .card-content {
            .value {
              font-size: 14px;
            }
          }

          .value {
            font-size: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 370px) {
    .receivable-card-container {
      .card {
        min-width: unset;

        .card-container {
          .card-content {
            .label {
              font-size: 12px;
            }

            .value {
              font-size: 12px;
            }
          }

          .value {
            font-size: 16px;
          }
        }
      }
    }
  }
</style>