<template>
  <div class="ranking-card-container">
    <div class="title-container">
      <h2>{{ title }}</h2>
    </div>

    <div class="content">
      <div v-if="hasRequest" class="items-wrapper">
        <skeleton-loader-component
          v-for="index in 3"
          :key="index"
          class="skeleton-loader-component"
        />
      </div>

      <simple-message-component
        v-else-if="!data.length"
        class="empty-data-content"
        :data="emptyData"
      />

      <div v-else class="items-wrapper">
        <div class="item" v-for="(item, index) in data" :key="index" >
          <p class="title">
            {{ item.label }}
          </p>

          <p>
            {{ `${item.quantity} ${formatItemType(item.type)} - ${$service.formatBrlAmountSymbol(item.total)}` }}
          </p>
        </div>
      </div>

      <div v-if="hasRequest || data.length" class="button-container">
        <skeleton-loader-component
          v-if="hasRequest"
          class="skeleton-loader-component"
        />

        <button-component
          v-else
          class="button"
          styles="ghost"
          :text="buttonText"
          :pathView="buttonPath"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import SkeletonLoader from '@/components/Base/SkeletonLoader';
  import Button from '@/components/Base/Button';
  import SimpleMessage from '@/components/SimpleMessage';

  export default {
    name: 'DashboardRankingCard',

    data() {
      return {
        emptyData: {
          icon: 'icon-receipts',
          text: 'Não foram encontrados registros'
        },
      };
    },

    props: {
      title: {
        type: String,
        default: 'Title'
      },

      hasRequest: {
        type: Boolean,
        default: false
      },

      buttonPath: {
        type: String,
        default: '/'
      },

      data: {
        type: Array,
        default: () => []
      },

      buttonText: {
        type: String,
        default: 'Ver mais'
      }
    },

    components: {
      'simple-message-component': SimpleMessage,
      'button-component': Button,
      'skeleton-loader-component': SkeletonLoader,
    },

    methods: {
      formatItemType(type) {
        if (!type) return 'produto/serviço';

        return `${this.$service.toUpperCase(type) == 'SERVICE' ? 'serviço' : 'produto'}(s)`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ranking-card-container {
    border: 1px solid #EFEFEF;
    border-radius: 6px;
    width: 100%;
    padding: 1rem;

    .title-container {
      display: flex;
      justify-content: center;

      h2 {
        color: #333;
        font-size: 16px;
      }
    }

    .content {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;

      .empty-data-content {
        margin-top: 2.5rem;
      }

      .items-wrapper {
        .skeleton-loader-component {
          width: 100%;
          height: 42px;
          margin-bottom: 0.5rem;
        }

        .item {
          padding: 0.5rem;
          border-bottom: 1px solid #DADADA;

          .title {
            font-size: 14px;
            font-weight: 400;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {
            font-size: 12px;
            font-weight: 400;
            color: #8E8E8E
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .skeleton-loader-component {
          max-width: 250px;
          width: 100%;
          height: 48px;
        }

        .button {
          height: 40px;
          max-width: 250px;
        }
      }
    }
  }

  @media (max-width: 980px) {
    .ranking-card-container {

      .title-container {
        h2 {
          font-size: 14px;
        }
      }

      .content {
        .items-wrapper {
          .item {
            .title {
              font-size: 12px;
            }

            p {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
</style>