<template>
  <div class="account-view-container">
    <default-view-component>
      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
            class="header"
          />

          <div v-if="!hasRequest" class="status" :style="{ 'color': sellerStatus.color }">
            <span class="text">
              <i :class="sellerStatus.icon" />

              {{ sellerStatus.text }}
            </span>

            <span
              v-if="sellerStatus.action"
              class="action"
              @click="redirectToWppReviewAccount()"
            >
              Pedir verificação
            </span>
          </div>

         <skeleton-loader-component v-else class="status"/>

          <div class="account-section">
            <account-card-component
              :items="itemsCard"
            />
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Header from '@/components/Layout/Header';
  import SkeletonLoader from '@/components/Base/SkeletonLoader';
  import DefaultView from '@/components/Layout/DefaultView';
  import AccountSectionCard from '@/components/AccountSectionCard';

  export default {
    name: 'AccountView',

    data() {
      return {
        hasRequest: false,
        itemsCard: [
          {
            resource: 'information',
            icon: 'icon-information-account',
            title: 'Informações da conta',
            text: 'Dados pessoais, negócio, contato...',
            redirectPagePath: '/account/informations/data',
          },
          {
            resource: 'bank',
            icon: 'icon-bank',
            title: 'Conta bacária',
            text: 'Escolha em qual conta quer receber o seu dinheiro',
            redirectPagePath: '/bank',
          },
          {
            resource: 'security',
            icon: 'icon-security',
            title: 'Segurança e login',
            text: 'Atualize a sua senha e proteja a sua conta',
            redirectPagePath: '/account/security',
          },
          {
            resource: 'customization',
            icon: 'icon-default',
            title: 'Personalização',
            text: 'Personalize nossos recursos com a cara do seu negócio',
            redirectPagePath: '/account/customization',
            isDisabled: true,
          },
          {
            resource: 'sales_plan',
            icon: 'icon-payments',
            title: 'Taxas',
            text: 'Visualize taxas do seu plano de venda',
            redirectPagePath: '/account/sales-plan',
            isDisabled: true,
          },
          {
            resource: 'help',
            icon: 'icon-question',
            title: 'Perguntas frequentes',
            text: 'Visite a nossa central de ajuda',
            redirectPagePath: '/account/faq',
          },
        ],

        header: {
          title: 'Conta',
          button: {
            text: 'Nova Negócio'
          }
        },

        sellerStatus: {
          color: '',
          icon: '',
          text: '',
          action: false
        }
      };
    },

    components: {
      'skeleton-loader-component': SkeletonLoader,
      'account-card-component': AccountSectionCard,
      'default-view-component': DefaultView,
      'header-component': Header
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerStatus',
        'getCurrentSellerSaleStatus',
        'getCurrentSellerData',
        'getCurrentSellerId',
        'getSideBarState',
        'getIsSellerActive'
      ]),
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.setSellerStatus();
        }
      }
    },

    mounted() {
      this.setSellerStatus();
    },

    methods: {
      setSellerStatus() {
        switch (this.$service.toUpperCase(this.getCurrentSellerStatus)) {
          case 'ACTIVE':
          case 'ENABLED':
            this.sellerStatus.color = '#08B978';
            this.sellerStatus.icon = 'icon-check-circle';
            this.sellerStatus.text = 'Conta verificada';
            break;
          case 'PENDING':
            this.sellerStatus.color = '#1B87B1';
            this.sellerStatus.icon = 'icon-information-circle';
            this.sellerStatus.text = 'Conta pendente';
            break;
          case 'DENIED':
            this.sellerStatus.color = '#FF5A5A';
            this.sellerStatus.icon = 'icon-error-circle';
            this.sellerStatus.text = 'Conta previamente negada';
            this.sellerStatus.action = true;
            break;
        }

        const customizationCard = this.itemsCard.find(item => item.resource == 'customization');
        customizationCard.isDisabled = this.getIsSellerActive && !this.getCurrentSellerSaleStatus;

        const salesPlanCard = this.itemsCard.find(item => item.resource == 'sales_plan');
        salesPlanCard.isDisabled = !this.getIsSellerActive;

        this.hasRequest = false;
      },

      redirectToWppReviewAccount() {
        const text = `Olá, preciso de revisão na minha conta do ${this.$service.getPlatformTitle()} (ID: ${this.getCurrentSellerId}).`;
        this.$service.openWpp(text);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .account-view-container {
    .section {
      padding: 1.5rem;

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem !important;

        h2 {
          color: #333;
        }

        p {
          margin-left: auto;
          font-weight: 600;
          color: $second-color;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .status {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        height: 1.5rem;

        i {
          margin-right: 0.5rem;
        }

        .text {
          display: flex;
          align-items: center;
        }

        .action {
          font-size: 12px;
          margin-left: 0.5rem;
          color: #0052CC;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .account-section {
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 480px) {
    .account-view-container {
      .section {
        padding: 1rem;

        .status {
          font-size: 14px;
          flex-direction: column;
          align-items: flex-start;

          i {
            font-size: 12px;
          }

          .action {
            font-size: 10px;
          }
        }
      }
    }
  }
</style>