import { instance } from '../../config/axios';
import store from '../index';

const actions = {
  postExpense(_, data) {
    return new Promise((resolve, reject) => {
      instance().post(`/sellers/${store.state.currentSellerId}/expenses`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  patchExpense(_, expense) {
    return new Promise((resolve, reject) => {
      instance().patch(`/sellers/${store.state.currentSellerId}/expenses/${expense.id}`, expense.data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllExpenses(_, params) {
    return new Promise((resolve, reject) => {
      instance().get(`/sellers/${store.state.currentSellerId}/expenses` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

export default {
  namespaced: true,
  actions
};