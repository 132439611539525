<template>
  <modal
    class="cookie-consent-modal"
    title="Política de Cookies"
    @close="close"
    :showModal="true"
  >
    <template v-slot:body>
      <div class="body">
        <div class="content">
          <div class="section">
            <h1>1. Objetivo</h1>

            <p>
              Definir regras e diretrizes para o sobre cookies (Aviso) e explicar como o GERENCIA AI usa estes cookies e outras tecnologias de rastreamento nos seguintes websites https://gerenciaai.com.br/ e seus subdomínios.
            </p>
          </div>

          <div class="section">
            <h1>2. Abrangência</h1>

            <p>
              Todas as pessoas que acessam os websites https://gerenciaai.com.br/ e seus subdomínios.
            </p>
          </div>

          <div class="section">
            <h1>3. Definições</h1>

            <h2>3.1 Terminologia</h2>

            <p>
              <b>Pixel Tag:</b> pequenos blocos de código de uma página da web que permite que elas realizem ações como ler e armazenar cookies e transmitir informações para GERENCIA AI. A conexão resultante pode incluir informações com endereço de IP de um dispositivo, a hora em que uma pessoa visualizou o pixel, um identificador associado ao navegador ou dispositivo e o tipo de navegador em uso.
            </p>

            <p>
              <b>Identificador de dispositivo:</b> códigos que possibilitam a identificação do um dispositivo móvel, seja de maneira persistente ou transitória, tais como o ID de Publicidade ou o ID do sistema operacional.
            </p>

            <p>
              <b>Tratamento de dados pessoais:</b> toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
            </p>
          </div>

          <div class="section">
            <h1>4. Considerações sobre Cookies</h1>

            <h2>4.1. O que são Cookies e quais suas classificações?</h2>

            <p>
              Cookies são arquivos que contém um identificador (uma sequência de letras e números) enviados por um servidor para determinado navegador (browser) que o armazena. O identificador é então enviado de volta ao servidor sempre que o navegador tenta acessar uma página do servidor.
            </p>

            <p>
              Além disso, podemos classificar os cookies como “persistentes” ou “de sessão”. Um Cookie persistente será armazenado por um navegador e permanecerá válido até sua data de validade, salvo se deletado pelo usuário antes desta data. Um Cookie de sessão, por outro lado, irá expirar ao final de uma sessão de navegação do usuário, quando o navegador é encerrado.
            </p>

            <p>
              O GERENCIA AI trata as informações obtidas por meio de cookies e outras tecnologias como informações não-pessoais. Entretanto, na medida que o endereço IP ou identificadores semelhantes sejam considerados informações pessoais pela legislação aplicável, o GERENCIA AI tratará esses indicadores como informação pessoal.
            </p>

            <h2>
              4.2. Para qual finalidade os Cookies são utilizados?
            </h2>

            <p>
              O GERENCIA AI utiliza cookies e outras tecnologias com as seguintes finalidades:
            </p>

            <p>
              a) <b>Autenticação:</b> Indica que o usuário está conectado, para que seja possível oferecer os recursos apropriados, bem como entender como utiliza e acessa os websites do GERENCIA AI;
            </p>

            <p>
              b) <b>Anúncios e medição:</b> ajuda a analisar comportamentos de navegação, a fim de analisar o conteúdo e uso de produtos e serviços;
            </p>

            <p>
              c) <b>Segurança e integridade:</b> ajuda a manter a segurança dos serviços, oferecendo suporte ou ativando recursos de segurança e ajudando a detectar atividades que violam nossas políticas legais;
            </p>

            <p>
              d) <b>Recursos e serviços:</b> ajuda a fornecer produtos e serviços e conteúdo personalizado;
            </p>

            <p>
              e) <b>Desempenho:</b> ajuda a proporcionar a melhor experencia possível, por exemplo, ajudando a rotear tráfego entre servidores e perceber com velocidade o serviço é carregado para diversas pessoas. Ocasionalmente, poderão ser armazenadas informações no navegador ou dispositivo para que os recursos em uso carreguem e respondam rapidamente.
            </p>

            <p>
              O usuário poderá gerenciar as suas preferências de cookies e outras tecnologias semelhantes a partir da configuração de seu navegador ou dispositivo, recusando ou excluindo determinados cookies e outras tecnologias. Contudo, isso poderá comprometer a prestação dos serviços ou impedir o funcionamento de determinadas funcionalidades que os integram.
            </p>

            <h2>
              4.3 Utilizamos tecnologia de rastreamento?
            </h2>

            <p>
              O GERENCIA AI poderá utilizar outras tecnologias de rastreamento, como web beacons (às vezes chamados de “pixels de rastreamento”, “pixel tags” ou “gifs nítidos”), usados geralmente para contar a quantidade de usuários que acessam determinadas páginas de nosso website, oferecer serviços e ajudar a avaliar a eficácia de campanhas. Em muitos casos, essas tecnologias dependem de cookies para funcionar corretamente.
            </p>
          </div>

          <div class="section">
            <h1>5. Disposições legais</h1>

            <p>Ao navegar pelos websites do GERENCIA AI e utilizar suas funcionalidades, os usuários aceitam todo o disposto nesta Política e demais políticas legais que se encontram vigentes na data de acesso. Por isso, é recomendável que os usuários se mantenham atualizados.</p>

            <p>
              A presente Política de cookies está sujeita a constante melhoria e aprimoramento. Assim, o GERENCIA AI se reserva o direito de modificá-la a qualquer momento, conforme a finalidade dos websites GERENCIA AI, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo aos usuários verificá-la sempre que efetuar o acesso aos websites do GERENCIA AI.
            </p>

            <p>
              A eventual tolerância quanto a qualquer violação dos termos e condições do contido neste domínio será considerada mera liberalidade e não será interpretada como novação, precedente invocável, renúncia a direitos, alteração tácita dos termos contratuais, direito adquirido ou alteração contratual.
            </p>

            <p>
              Caso alguma disposição desta Política for julgada inaplicável ou sem efeito, o restante das normas continua a viger, sem a necessidade de medida judicial que declare tal assertiva. Os termos aqui descritos serão interpretados segundo a legislação brasileira.
            </p>
          </div>
        </div>

        <div class="button-container">
          <button-component
            class="button-component"
            text="Entendi"
            @click.native="close"
            v-ripple="$theme.whiteColorTransparent"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';

  export default {
    name: 'CookieConsentModalVue',

    components: {
      'modal': Modal,
      'button-component': Button
    },

    methods: {
      close() {
        this.$emit('close');
      },

      click() {
        this.$emit('click');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .cookie-consent-modal {
    .body {
      display: flex;
      flex-direction: column;
      width: 767px;
      margin-top: 1rem;

      .content {
        height: 500px;
        overflow-y: auto;
        padding: 0 1.5rem;

        .section {
          h1 {
            color: #333;
            font-size: 20px;
            margin-bottom: 1rem;
          }

          h2 {
            color: #333;
            font-size: 16px;
            margin-bottom: 1rem;
          }

          p {
            margin-bottom: 1rem;
            text-align: justify;

            b {
              font-weight: 500;
            }
          }
        }
      }

      .button-container {
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;

        .button-component {
          width: 190px;
          height: 40px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .cookie-consent-modal {
      .body {
        width: 100%;
      }
    }
  }

  @media (max-width: 480px) {
    .cookie-consent-modal {
      .body {
        .content {
          .section {
            h1 {
              font-size: 16px;
            }

            h2 {
              font-size: 14px;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
</style>

