<template>
  <modal
    class="terms-modal"
    title="Termos de uso da aplicação"
    @close="close"
    :showModal="true"
  >
    <template v-slot:body>
      <div class="body">
        <div class="content" ref="content" @scroll="handleScroll">
          <div class="section">
            <p>Seja bem-vindo(a)!</p>

            <p>
              Obrigado por nos escolher no caminho da sua evolução pessoal e profissional!
            </p>

            <p>
              Este documento visa informar os termos regulatório da contratação e utilização do sistema “Gerencia AI”.
              Nossas ações são pautadas no interesse dos nossos alunos e futuros alunos, buscamos constantemente
              mecanismos para colaborar com o desenvolvimento profissional destes.
            </p>

            <p>
              <b>
                IMPORTANTE LEMBRAR, QUE AO UTILIZAR NOSSAS PÁGINAS, FORNECER SEUS DADOS PARA CONTATO
                OU INTERAGIR CONOSCO, VOCÊ CONCORDA AUTOMATICAMENTE COM ESTE REGULAMENTO,
                RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS PRATICADOS POR VOCÊ.
                CASO VOCÊ NÃO CONCORDE COM QUALQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS,
                VOCÊ NÃO DEVE UTILIZAR ESTA APLICAÇÃO, NEM PARTICIPAR DA NOSSA PROMOÇÃO.
              </b>
            </p>

            <p>
              Para mais informações sobre o tratamento dos seus dados pessoais acesse o nosso portal de privacidade
              em: <a :href="imPrivacyLink" target="_blank">{{ imPrivacyLink }}</a>
            </p>
          </div>

          <div class="section">
            <h1>Quem somos?</h1>

            <p>
              O <b>Instituto Mix de Profissões</b> é a principal rede de cursos profissionalizantes do Brasil, ofertamos cursos
              em mais de onze áreas de ensino, como beleza, saúde, administração, tecnologia, idiomas, construção civil,
              gastronomia, moda, hotelaria e turismo, indústria e mecânica. Nós acreditamos que a educação é o melhor
              caminho para transformar vidas, qualificando profissionais para o mercado de trabalho.
              Compreendemos a relevância não apenas de fornecer conhecimento sobre uma profissão, mas também de
              oferecer soluções para capacitar você a se tornar um empreendedor bem-sucedido, disponibilizando
              ferramentas que facilitem a gestão do seu negócio. O desenvolvimento do sistema "Gerencia AI" reflete o
              propósito do Instituto Mix de Profissões: transformar vidas!
            </p>
          </div>

          <div class="section">
            <h1>Quais informações que coletamos de você?</h1>

            <p>
              O Sistema Gerencia AI é ofertado para nossos alunos, ao aderir à utilização dos nossos serviços, você já faz
              parte do nosso banco de dados, utilizamos os seguintes dados para criança da sua conta:
            </p>

            <ul>
              <li>CPF</li>
              <li>Nome completo</li>
              <li>E-mail</li>
              <li>Telefone</li>
              <li>Data nascimento</li>
              <li>Endereço</li>
            </ul>
          </div>

          <div class="section">
            <h1>Do sistema Gerencia AI</h1>

            <p>
              O GERENCIA AI foi pensado para que o aluno do Instituto Mix de Profissões, saia da nossa rede não
              somente com uma profissão, mas também com uma ferramenta que possibilite a implementação e gestão
              de um negócio
            </p>

            <p>
              O sistema será oferecido de maneira gratuíta por até 6 meses após a conclusão do curso. Após esse período inicial,
              a continuidade da utilização do GERENCIA AI implicará na renovaçãoda contratação,
              e será cobrado uma mensalidade a partir desse ponto.
            </p>

            <p>
              Em caso de dúvidas, suporte ou de encerramento de conta o aluno poderá entrar em contato com a
              unidade franqueada.
            </p>
          </div>

          <div class="section">
            <h1>Como funciona a adesão aos nossos serviços?</h1>

            <p>
              Quando um aluno manifesta interesse em utilizar os serviços do <b>Gerencia AI</b>, a criação da conta pode
              ocorrer de duas maneiras distintas:
            </p>

            <p>
              1) A unidade franqueada tem a opção de criar a conta, fornecendo ao aluno as credenciais de login e senha.
            </p>

            <p>
              2) O próprio aluno pode abrir sua conta e contratar os serviços do Gerencia AI através da plataforma Mundo IM.
            </p>

            <p>
              <b>
                AO ADERIR OS SERVIÇOS DO GERENCIA AI O USUÁRIO ESTARÁ ABRINDO UMA CONTA NO IM PAY: O
                IM PAY É UMA PLATAFORMA DE SOLUÇÕES FINANCEIRAS, QUE VIABILIZA COBRANÇAS DE FORMA
                AUTOMATIZADA COM INSTRUMENTOS QUE MAXIMIZAM OS SEUS RESULTADOS. TUDO ISSO DE UMA
                FORMA INTEGRADA PARA TRANSFORMAR E SIMPLIFICAR O SEU CONTROLE FINANCEIRO. ALIADO AOS
                SERVIÇOS DO GERENCIA AI, O USUÁRIO DETÉM UMA PLATAFORMA COMPLETA DE GERENCIAMENTO
                FINANCEIRO.
              </b>
            </p>

            <p>
              <b>
                Cumpre destacar que a criação da conta no IM PAY está sujeita a cobrança de taxas de manutenção e
                operação, ainda que o GERENCIA AI seja disponibilizado sem mensalidade, a utilização da plataforma do
                IM PAY como meio de pagamento estará sujeita as taxas bancárias.
              </b>
            </p>

            <p>
              Confira a política de privacidade do IM PAY: <a :href="imPayPrivacyLink" target="_blank">{{ imPayPrivacyLink }}</a> e os
              serviços de terceiros utilizados.
            </p>

            <p>
              Ao aderir os serviços fornecidos pelo nosso sistema, conforme os itens acima mencionados, você estará
              consentindo que o <b>Instituto Mix de Profissões</b> realize o tratamento de dados e operacionalize a relação.
              Lembrando que na nossa <b>Política de Privacidade</b> você confere a forma com que realizamos o tratamento
              dos seus dados pessoais.
            </p>

            <p>
              Em caso de suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita, ou pelo não
              cumprimento de quaisquer condições previstas neste Termo, reservamo-nos o direito de suspender ou
              cancelar, a qualquer momento, o contato, não sendo devido qualquer indenização a você.
            </p>
          </div>

          <div class="section">
            <h1>Privacidadee dos usuários</h1>

            <p>
              O <b>Instituto Mix de Profissões</b> se compromete a processar suas informações e
              seus dados pessoais segundo a Lei Geral de Proteção de Dados (Lei n.º
              13.709/2018, LGPD). Você pode se informar com relação ao tratamento dos
              seus dados pessoais em nosso portal de privacidade:
              <a :href="imPrivacyLink" target="_blank">{{ imPrivacyLink }}</a>.
            </p>
          </div>

          <div class="section">
            <h1>
              Responsabilidade dos usuários
            </h1>

            <p>
              O usuário é exclusivamente responsável pelo uso de nossas páginas, bem como pela utilização do nosso
              sistema, portanto, deverá respeitar as regras deste Termo, bem como a legislação aplicável.
            </p>

            <p>
              O usuário garante a veracidade de todos os dados informados e é responsável por manter as suas
              informações pessoais atualizadas.
            </p>

            <p>
              É responsabilidade do usuário as informações fornecidas dentro do sistema Gerencia AI, inclusive pelos
              dados e informações de terceiros eventualmente cadastrados.
            </p>

            <p>
              O usuário compromete-se a manter a confidencialidade de seu login e senha únicos, sendo inteiramente
              responsável por qualquer atividade realizada em sua conta.
            </p>

            <p>
              O usuário concorda em utilizar o sistema GERENCIA AI exclusivamente para fins legítimos relacionados à
              gestão de sua empresa.
            </p>

            <p>
              Fica proibido o uso do sistema para atividades ilegais, fraudulentas ou que possam prejudicar terceiros.
            </p>

            <p>
              A empresa provedora do sistema não assume responsabilidade por perda, corrupção ou acesso não
              autorizado aos dados do usuário.
            </p>

            <p>
              O usuário compromete-se a tomar as medidas necessárias para manter a segurança de sua conta e
              prevenir acessos não autorizados.
            </p>

            <p>
              O usuário deve informar imediatamente à empresa provedora sobre qualquer suspeita de
              comprometimento da segurança de sua conta.
            </p>

            <p>
              O usuário é responsável por manter seu navegador e demais tecnologias utilizadas atualizados para
              garantir a compatibilidade com o sistema GERENCIA AI.
            </p>

            <p>
              A empresa provedora reserva-se o direito de realizar atualizações e manutenções programadas no
              sistema, visando aprimorar sua eficiência e segurança.
            </p>

            <p>
              Em caso de rescisão, o usuário compromete-se a cessar imediatamente o uso do sistema.
            </p>

            <p>
              É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o
              upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do conteúdo em nossas páginas.
              Qualquer uso não autorizado do conteúdo deste site será considerado violação dos direitos autorais e de
              propriedade intelectual.
            </p>

            <p>
              Todos os direitos, títulos e interesses sobre os serviços disponibilizados por nós, permanecerão sob a
              nossa titularidade e qualquer disponibilização de conteúdo deverá ser tratada mediante instrumento
              particular entre as partes.
            </p>
          </div>

          <div class="section">
            <h1>Agradecimento</h1>

            <p>
              <b>Muito obrigado por nos escolher no caminho da sua evolução profissional!</b>
            </p>
          </div>
        </div>

        <div class="button-container">
          <button-component
            class="button-component"
            text="Li e concordo"
            :isDisabled="disableButton"
            @click.native="agree"
            v-ripple="$theme.whiteColorTransparent"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';

  export default {
    name: 'TermsModal',

    data() {
      return {
        disableButton: true,

        imPrivacyLink: 'https://institutomix.com.br/privacidade/',
        imPayPrivacyLink: 'https://www.impay.com.br/politicas-de-privacidade/'
      };
    },

    components: {
      'modal': Modal,
      'button-component': Button
    },

    methods: {
      click() {
        this.$emit('click');
      },

      handleScroll(event) {
        const totalHeight = event.target.scrollHeight;
        const scrollPosition = event.target.scrollTop;
        const visibleHeight = event.target.clientHeight;
        const buffer = 550;

        if (scrollPosition + visibleHeight >= totalHeight - buffer) {
          this.disableButton = false;
        }
      },

      agree() {
        this.$emit('agree');
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .terms-modal {
    .body {
      display: flex;
      flex-direction: column;
      width: 767px;
      margin-top: 1rem;

      .content {
        height: 500px;
        overflow-y: auto;
        padding: 0 1.5rem;

        .section {
          a {
            color: #0052cc;
          }

          h1 {
            color: #333;
            font-size: 18px;
            margin-bottom: 1rem;
          }

          h2 {
            color: #333;
            font-size: 16px;
            margin-bottom: 1rem;
          }

          p {
            margin-bottom: 1rem;
            text-align: justify;

            b {
              font-weight: 500;
            }
          }

          ul {
            margin-left: 1.5rem;
            margin-bottom: 1rem;

            li {
              font-size: 14px;
            }
          }
        }
      }

      .button-container {
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;

        .button-component {
          width: 190px;
          height: 40px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .terms-modal {
      .body {
        width: 100%;
      }
    }
  }

  @media (max-width: 480px) {
    .terms-modal {
      .body {
        .content {
          height: calc(100vh - 200px);

          .section {
            h1 {
              font-size: 16px;
            }

            h2 {
              font-size: 14px;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
</style>

