<template>
  <div class="receivables-view-container">
    <default-view-component>
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:content>
        <div class="section" :class="{ 'hide-sidebar': getSideBarState.hideSideBar }">
          <header-component
            :data="header"
          />

          <receivable-card-container
            :primaryCard="receivableCardData.primary"
            :secondCard="receivableCardData.second"
            :hasRequest="hasReceivablesDetail || hasFutureReceivablesRequest"
          />

          <div class="options-container">
            <navigation-bar-component
              class="navigation-bar-component"
              :data="navigationData"
              :isDisabled="hasReceivablesDetail || hasMonthReceivablesRequest"
            />
          </div>

          <div v-show="currentSection === 'details'" class="details-container">
            <div v-show="!hasReceivablesDetail" class="filter-container">
              <p>Filtrar por:</p>

              <input-range-date-field
                @changeRange="fetchInputNewRange"
                v-model="range"
              />
            </div>

            <div class="table-container">
              <p v-show="!hasReceivablesDetail">
                Nesta seção, confira os pagamentos das vendas previstas no período selecionado.
              </p>

              <section-table-component
                class="table"
                :class="{ 'no-scroll': hasReceivablesDetail || !hasReceivablePendingData }"
                :hasRequest="hasReceivablesDetail"
                :table="tableData"
              />
            </div>
          </div>

          <div v-show="currentSection === 'calendar'">
            <div class="calendar-container">
              <receivable-calendar
                v-if="isPrimaryCalendar"
                class="calendar"
                :data="calendarData"
                :hasRequest="hasMonthReceivablesRequest"
                @changePage="fetchNewMonth"
                @changeDay="fetchCalendarNewRange"
                v-model="calendarRange"
              />

              <mobile-receivable-calendar
                v-else
                :data="calendarData"
                :hasRequest="hasMonthReceivablesRequest"
                @changePage="fetchNewMonth"
                @changeDay="fetchCalendarNewRange"
                v-model="calendarRange"
              />

              <div v-if="!hasMonthReceivablesRequest" class="details-card-container">
                <div class="details-header">
                  <p class="title">Detalhes</p>

                  <p class="range-date">{{ rangeText }}</p>
                </div>

                <div v-if="!hasReceivablesDetail" ref="calendarDetailsReceivables" class="details-content">
                  <div v-if="detailsArr.length > 0" class="details">
                    <div v-for="(item, index) in detailsArr" :key="index" class="detail">
                      <div class="content">
                        <div class="icon">
                          <i :class="item.status.icon" :style="{ 'color': item.status.color }"/>
                        </div>

                        {{ item.text }}
                      </div>

                      <div class="amount">
                        {{ item.amount }}
                      </div>
                    </div>
                  </div>

                  <div v-else class="empty">
                    Nenhum recebimento previsto
                  </div>
                </div>

                <div v-else class="details-content">
                  <div v-for="index in 3" :key="index" class="default-data-container">
                    <skeleton-loader-component class="default-data"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import Header from '@/components/Layout/Header';
  import ReceivableCard from '@/components/ReceivableCard';
  import NavigationBar from '@/components/Base/NavigationBar';
  import InputRangeDateField from '@/components/Base/InputRangeDateField';
  import SectionTable from '@/components/Table/SectionTable';
  import SkeletonLoader from '@/components/Base/SkeletonLoader';
  import SnackBar from '@/components/Base/SnackBar';
  import ReceivableCalendar from '@/components/Calendar/ReceivableCalendar';
  import MobileReceivableCalendar from '@/components/Calendar/MobileReceivableCalendar';

  export default {
    name: 'ReceivableView',

    data() {
      return {
        rangeText: '',
        currentSection: 'details',
        isPrimaryCalendar: true,
        hasReceivablePendingData: false,
        hasReceivablesDetail: false,
        hasFutureReceivablesRequest: false,
        hasMonthReceivablesRequest: false,
        detailsArr: [],

        range: {
          start: null,
          end: null
        },

        calendarRange: {
          start: null,
          end: null
        },

        monthRange: {
          start: null,
          end: null
        },

        calendarData: [],

        tableData: {
          headers: {
            items: [
              {
                label: 'Data de venda',
                field: 'date',
                align: 'center'
              },

              {
                label: 'ID Transação',
                field: 'transactionId',
                align: 'center'
              },

              {
                label: 'Valor bruto',
                field: 'grossAmount',
                align: 'center'
              },

              {
                label: 'Taxa',
                field: 'fee',
                align: 'center'
              },

              {
                label: 'Valor líquido',
                field: 'amount',
                align: 'center'
              }
            ]
          },

          body: []
        },

        header: {
          title: 'Recebíveis',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        receivableCardData: {
          primary: {
            item: {
              label: 'Recebimento de',
              value: ''
            },
            totalAmount: '0,00',
          },

          second: {
            item: {
              label: 'Recebimentos',
              value: 'Futuros'
            },
            totalAmount: '0,00',
          }
        },

        navigationData: {
          current: '',
          items: [
            {
              label: 'Detalhes',
              isActive: true,
              name: 'details'
            },

            {
              label: 'Calendário',
              isActive: false,
              name: 'calendar'
            }
          ]
        }
      };
    },

    components: {
      'default-view-component': DefaultView,
      'header-component': Header,
      'receivable-card-container': ReceivableCard,
      'navigation-bar-component': NavigationBar,
      'input-range-date-field': InputRangeDateField,
      'section-table-component': SectionTable,
      'skeleton-loader-component': SkeletonLoader,
      'snackbar-component': SnackBar,
      'receivable-calendar': ReceivableCalendar,
      'mobile-receivable-calendar': MobileReceivableCalendar,
    },

    computed: {
      ...mapGetters([
        'getSideBarState',
        'getCurrentSellerData',
        'getCurrentSellerId',
      ])
    },

    created() {
      this.setRangeDate();
    },

    mounted() {
      this.checkCalendar();
      this.fetchFutureReceivables();
      this.fetchMonthReceivables();

      window.addEventListener('resize', () => this.checkCalendar());
    },

    watch: {
      'navigationData.current'(value) {
        this.currentSection = value;
      },

      'getSideBarState.hideSideBar'() {
        this.checkCalendar();
      },

      getCurrentSellerId() {
        this.hasReceivablesDetail = true;
        this.hasFutureReceivablesRequest = true;
        this.hasMonthReceivablesRequest = true;

        this.setRangeDate();
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.fetchReceivablesDetail();
          this.fetchFutureReceivables();
          this.fetchMonthReceivables();
        }
      }
    },

    methods: {
      fetchInputNewRange() {
        this.setRangeText();
        this.fetchReceivablesDetail();
      },

      fetchNewMonth(newRange) {
        this.monthRange.start = newRange.start;
        this.monthRange.end = newRange.end;

        this.fetchMonthReceivables();
      },

      fetchCalendarNewRange() {
        this.range.start = this.calendarRange.start;
        this.range.end = this.calendarRange.end;

        this.setRangeText();
        this.fetchReceivablesDetail();
        this.fetchFutureReceivables();
      },

      setRangeDate() {
        let currentDate = this.$service.currentDateEN();

        if (!this.$service.isUndefined(this.$route.params.date)) {
          this.range.start = this.$service.toDate(this.$route.params.date);
          this.range.end = this.$service.toDate(this.$route.params.date);
        }

        if (this.$service.isNull(this.range.start) && this.$service.isNull(this.range.end)) {
          this.range.start = this.$service.toDate(currentDate);
          this.range.end = this.$service.toDate(currentDate);
        }

        this.calendarRange.start = this.$service.toDate(currentDate);
        this.calendarRange.end = this.$service.toDate(currentDate);

        this.monthRange.start = this.$service.getStartDateMonth(currentDate, 'YYYY-MM');
        this.monthRange.end = this.$service.getEndDateMonth(currentDate, 'YYYY-MM');

        this.setRangeText();
      },

      showSnackBar(text, color) {
        this.$refs?.snackbar.show(text, color);
      },

      checkCalendar() {
        let currentWidth = window.innerWidth;

        this.isPrimaryCalendar = currentWidth > 885;

        if (!this.getSideBarState.hideSideBar && currentWidth < 1040) {
          this.isPrimaryCalendar = false;
        }
      },

      async fetchFutureReceivables() {
        this.hasFutureReceivablesRequest = true;

        await this.$store.dispatch('receivableModule/getFutureReceivables')
          .then(res => {
            this.receivableCardData.second.totalAmount = this.$service.formatBrlAmountNoSymbol(res.data.total_amount);
          });

        this.hasFutureReceivablesRequest = false;
      },

      async fetchReceivablesDetail() {
        this.hasReceivablesDetail = true;

        let expectedOnStart = this.$service.getDateDay(this.$service.dateFormat(this.range.start), -1) + 'T21:00:00';
        let expectedOnEnd = this.$service.dateFormat(this.range.end) + 'T20:59:59';

        await this.$store.dispatch('receivableModule/getReceivables', '?expected_on_start=' + expectedOnStart
          + '&expected_on_end=' + expectedOnEnd + '&grouped=1&status=pending')
          .then(res => {
            this.hasReceivablePendingData = !this.$service.isEmptyArray(res.data);
            this.tableData.body = this.formatTableData(res.data);
          })
          .catch(() => {
            this.showSnackBar('Erro ao carregar os dados', '#FF5A5A');
          });

        await this.$store.dispatch('receivableModule/getReceivables', '?expected_on_start=' + expectedOnStart
          + '&expected_on_end=' + expectedOnEnd)
          .then(res => {
            this.receivableCardData.primary.totalAmount = this.$service.formatBrlAmountNoSymbol(this.amountDetails(res.data, 'paid'));
            this.detailsArr = this.formatCalendarDetails(res.data);
          })
          .catch(() => {
            this.showSnackBar('Erro ao carregar os dados', '#FF5A5A');
          });

        this.hasReceivablesDetail = false;
      },

      async fetchMonthReceivables() {
        this.hasMonthReceivablesRequest = true;

        let expectedOnStart = this.$service.dateFormat(this.monthRange.start) + 'T00:00:00';
        let expectedOnEnd = this.$service.dateFormat(this.monthRange.end) + 'T23:59:59';

        await this.$store.dispatch('receivableModule/getReceivables', '?expected_on_start=' + expectedOnStart + '&expected_on_end=' + expectedOnEnd + '&grouped=1')
          .then(res => {
            this.calendarData = this.formatCalendarData(res.data);
          })
          .catch(() => {
            this.showSnackBar('Erro ao carregar os dados', '#FF5A5A');
          });

        this.hasMonthReceivablesRequest = false;
      },

      getTotalAmountReceivables(obj) {
        let totalAmount = 0;

        for (var index in obj) {
          totalAmount += obj[index].total_amount;
        }

        return totalAmount;
      },

      formatCalendarData(data) {
        let formattedCalendarData = [];

        if (data.length != 0) {
          for (var index in data) {
            formattedCalendarData.push({
              date: index,
              status: this.setCalendarReceivableStatus(data[index].items),
              totalAmount: this.$service.formatBrlAmountNoSymbol(data[index].total_amount)
            });
          }
        }

        return formattedCalendarData;
      },

      setIconStatus(status) {
        let obj = {
          icon: '',
          color: ''
        };

        switch (this.$service.toUpperCase(status)) {
          case 'PAID':
            obj.icon = 'icon-check';
            obj.color = '#08B978';
            break;
          case 'PENDING':
            obj.icon = 'icon-warning';
            obj.color = '#BA8C03';
            break;
          case 'SCHEDULED':
            obj.icon = 'icon-calendar';
            obj.color = '#FF8B00';
            break;
          case 'CANCELED':
            obj.icon = 'icon-cancel-circle';
            obj.color = '#FF5A5A';
            break;
          case 'REFUNDED':
            obj.icon = 'icon-information-circle';
            obj.color = '#006EFF';
            break;
          default:
            obj.icon = 'icon-spinner';
            obj.color = '#1B87B1';
        }

        return obj;
      },

      formatCalendarDetails(arr) {
        return arr.map(item => ({
          amount: this.$service.formatBrlAmountSymbol(item.amount),
          status: this.setIconStatus(item.status),
          text: 'Criado: ' + this.$service.dateFormat(item.created_at, 'YYYY-MM-DD', 'DD/MM/YY HH:MM')
        }));
      },

      amountDetails(arr, status) {
        let amount = 0;
        let itemsFilter = arr.filter(item => this.$service.toUpperCase(item.status) == this.$service.toUpperCase(status));

        amount = itemsFilter.reduce((total, item) => total + parseFloat(item.amount), 0);

        return amount;
      },

      setCalendarReceivableStatus(arr) {
        let status = '';

        arr.forEach(item => {
          status = item.status;
        });

        return status;
      },

      formatTableData(data) {
        let formattedTableData = [];

        for (var row in data) {
          let currentRowTableData = {
            rows: [],
            abstractRow: {
              primaryContent: {
                label: 'Pagamento previsto:',
                value: this.$service.dateFormat(row, 'YYYY-MM-DD', 'DD/MM/YYYY')
              },

              secondContent: {
                label: 'Total:',
                value: this.$service.formatBrlAmountSymbol(data[row].total_amount)
              }
            }
          };

          data[row].items.forEach(item => {
            currentRowTableData.rows.push({
              date: this.$service.dateFormat(item.transaction_created_at, 'YYYY/MM/DD', 'DD/MM/YY - hh:mm'),
              transactionId: item.transaction,
              grossAmount: this.$service.formatBrlAmountSymbol(item.gross_amount),
              fee: this.$service.formatBrlAmountSymbol(item.gross_amount - item.amount),
              amount: this.$service.formatBrlAmountSymbol(item.amount)
            });
          });

          formattedTableData.push(currentRowTableData);
        }

        return formattedTableData;
      },

      setRangeText() {
        this.receivableCardData.primary.item.value = this.formatRangeText();
        this.rangeText = this.formatRangeText();
      },

      formatRangeText() {
        return this.$service.dateFormat(this.range.start, 'YYYY-MM-DD', 'DD/MM/YY') + ' - ' + this.$service.dateFormat(this.range.end, 'YYYY-MM-DD', 'DD/MM/YY');
      },
    },

    beforeDestroy() {
      window.removeEventListener('resize', () => this.checkCalendar);
    }
  };
</script>

<style lang="scss" scoped>
  .receivables-view-container {
    .section {
      padding: 1.5rem;

      .options-container {
        margin-top: 2rem;
        display: flex;

        .navigation-bar-component {
          margin-right: 1.5rem;
          width: 100%;
        }
      }

      .details-container {
        margin-top: 1.5rem;

        .filter-container {
          p {
            margin-bottom: 0.5rem;
            font-weight: 400;
          }
        }

        .table-container {
          margin-top: 3rem;

          p {
            margin-bottom: 1rem;
            font-weight: 400;
          }

          .table {
            &.no-scroll {
              overflow-x: unset !important;
            }
          }
        }
      }

      .calendar-container {
        display: flex;
        margin-top: 1.5rem;
        justify-content: center;

        .details-card-container {
          border: 1px solid #DADADA;
          border-radius: 6px;
          margin-left: 1rem;
          min-width: 375px;
          height: fit-content;

          .details-header {
            display: flex;
            border-bottom: 1px solid #DADADA;
            padding: 1rem;
            width: 100%;

            .title {
              font-weight: 500;
            }

            .range-date {
              margin-left: auto;
            }
          }

          .details-content {
            padding: 0.5rem;

            .empty {
              padding: 0.5rem;
            }

            .default-data-container {
              padding: 0.5rem;

              .default-data {
                height: 20px;
                margin-bottom: 1rem;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .details {
              overflow-y: auto;
              max-height: 185px;

              .detail {
                display: flex;
                padding: 0.5rem;

                .content {
                  display: flex;

                  i {
                    margin-right: 0.5rem;
                  }
                }

                .amount {
                  margin-left: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .receivables-view-container {
      .section {
        .calendar-container {
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          .details-card-container {
            border: none;
            margin-left: unset;
            max-width: 750px;
            width: 100%;

            .details-header {
              border-bottom: none;
              padding: 0;
              margin-top: 1rem;
            }

            .details-content {
              padding: 0;
              margin-top: 1rem;

              .empty {
                padding: 0;
              }

              .default-data-container {
                padding: 0.5rem 0;
              }

              .details {
                .detail {
                  padding: 0.5rem 0;
                  padding-right: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .receivables-view-container {
      .section {
        &.hide-sidebar {
          .calendar-container {
            flex-direction: column;
          }
        }
      }
    }
  }

  @media (max-width: 1075px) {
    .receivables-view-container {
      .section {
        .details-container {
          .table-container {
            width: calc(100vw - 300px);

            .table {
              overflow-x: auto;
            }
          }
        }

        &.hide-sidebar {
          .details-container {
            .table-container {
              width: calc(100vw - 130px);
            }
          }
        }
      }
    }
  }

  @media (max-width: 890px) {
    .receivables-view-container {
      .section {
        .details-container {
          width: 100%;
        }

        .calendar-container {
          .details-card-container {
            width: 100%;
            min-width: unset;
          }
        }
      }
    }
  }

  @media (max-width: 585px) {
    .receivables-view-container {
      .section {
        .options-container {
          .navigation-bar-component {
            margin: 0;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .receivables-view-container {
      .section {
        padding: 1rem;

        .options-container {
          margin-top: 1rem;
        }

        .details-container {
          .filter-container {
            p {
              font-size: 12px;
              margin-bottom: 0.5rem;
            }
          }

          .table-container {
            margin-top: 1rem;
            width: 100% !important;

            p {
              font-size: 12px;
            }
          }
        }

        .calendar-container {
          .details-card-container {
            .details-header {
              .title {
                display: none;
              }

              .range-date {
                margin-left: unset;
              }
            }
          }
        }

        .details-container {
          .table-container {
            .table {
              overflow-x: none;
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>