import { render, staticRenderFns } from "./SalesPaymentTypeCard.vue?vue&type=template&id=f1facaca&scoped=true"
import script from "./SalesPaymentTypeCard.vue?vue&type=script&lang=js"
export * from "./SalesPaymentTypeCard.vue?vue&type=script&lang=js"
import style0 from "./SalesPaymentTypeCard.vue?vue&type=style&index=0&id=f1facaca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1facaca",
  null
  
)

export default component.exports