<template>
  <div class="cash-flow-view-container">
    <default-view-component>
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
          />

          <div class="content">
            <div class="filter-container">
              <p>Filtrar por:</p>

              <input-range-date-field
                class="input-range-date-field"
                :isDisabled="hasRequest"
                @changeRange="fetchData"
                v-model="range"
              />
            </div>

            <div class="header">
              <metrics-card-component
                title="Faturamento"
                :isMoney="true"
                :value="totalSalesBilling"
                :hasRequest="hasRequest"
              />

              <metrics-card-component
                title="Despesas"
                :isMoney="true"
                :value="totalExpenses"
                :hasRequest="hasRequest"
              />

              <metrics-card-component
                title="A receber"
                :isMoney="true"
                :value="totalSalesReceivables"
                :hasRequest="hasRequest"
              />

              <metrics-card-component
                title="Saldo"
                :isMoney="true"
                :value="totalBalance"
                :hasRequest="hasRequest"
              />
            </div>

            <div v-if="!hasRequest" class="chart-container">
              <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>

            <div v-else class="spinner-container">
              <spinner-component color="#DADADA"/>
            </div>
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import InputRangeDateField from '@/components/Base/InputRangeDateField';
  import Header from '@/components/Layout/Header';
  import SnackBar from '@/components/Base/SnackBar';
  import MetricsCard from '@/components/Dashboard/MetricsCard';
  import Spinner from '@/components/Base/Spinner';
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 'CashFlowView',

    data() {
      return {
        header: {
          title: 'Fluxo de caixa',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        hasRequest: false,

        range: {
          start: null,
          end: null
        },

        totalSalesBilling: 0,
        totalExpenses: 0,
        totalSalesReceivables: 0,
        totalBalance: 0,

        series: [],

        chartOptions: {
          chart: {
            height: 350,
            type: 'area',
            zoom: {
              enabled: false
            },
          },

          dataLabels: {
            enabled: false
          },

          stroke: {
            curve: 'smooth'
          },

          xaxis: {
            type: 'datetime',
            categories: []
          },

          tooltip: {
            x: {
              format: 'dd/MM/yy'
            },
          },

          fill: {
            colors: ['#08B978', '#CE0202', '#006EFF']
          },

          colors: ['#08B978', '#CE0202', '#006EFF']
        },
      };
    },

    components: {
      'default-view-component': DefaultView,
      'input-range-date-field': InputRangeDateField,
      'header-component': Header,
      'snackbar-component': SnackBar,
      'metrics-card-component': MetricsCard,
      'spinner-component': Spinner,
      'apexchart': VueApexCharts,
    },

    computed: {
      ...mapGetters({
        getCurrentSellerId: 'getCurrentSellerId',
        getCurrentSellerData: 'getCurrentSellerData'
      })
    },

    created() {
      const currentDate = this.$service.currentDateEN();
      const start = this.$service.getStartDateMonth(currentDate, 'YYYY-MM');
      const end = this.$service.getEndDateMonth(currentDate, 'YYYY-MM');

      this.range.start = this.$service.toDate(start);
      this.range.end = this.$service.toDate(end);

      this.fetchData();
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.fetchData();
        }
      }
    },

    methods: {
      async fetchData() {
        this.hasRequest = true;

        const queryParams = [
          {
            query: 'date_range_start',
            param: this.$service.dateFormat(this.range.start)
          },

          {
            query: 'date_range_end',
            param: this.$service.dateFormat(this.range.end)
          },
        ];

        await this.$store.dispatch('metricModule/getAllCashFlowMetrics', this.$service.formatQueryParam(queryParams))
          .then(res => {
            const { sale_billing_total, expense_total, sale_receivable_total, items } = res;

            this.totalSalesBilling = sale_billing_total;
            this.totalExpenses = expense_total;
            this.totalSalesReceivables = sale_receivable_total;
            this.totalBalance = sale_billing_total - expense_total;

            this.chartOptions.xaxis.categories = items.map(item => item.date);
            this.series = [
              {
                name: 'Faturamento',
                data: items.map(item => item.sale_billing_total)
              }, {
                name: 'Despesas',
                data: items.map(item => item.expense_total)
              }, {
                name: 'A receber',
                data: items.map(item => item.sale_receivable_total)
              }
            ];
          })
          .catch(() => {
            this.$store.commit('metricModule/resetState');

            this.showSnackBar('Não foi possível carregar os dados', '#FF5A5A');
          });

        this.hasRequest = false;
      },

      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .cash-flow-view-container {
    .section {
      padding: 1.5rem;

      .content {
        .filter-container {
          p {
            margin-bottom: 0.5rem;
            font-weight: 400;
          }
        }

        .header {
          display: flex;
          gap: 1rem;
        }

        .chart-container {
          padding: 2rem 2.5rem 0 2.5rem;
        }

        .spinner-container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 2rem;
        }
      }
    }
  }

  @media (max-width: 675px) {
    .cash-flow-view-container {
      .section {
        .content {
          .header {
            flex-direction: column;
          }

          .chart-container {
            padding: 0;
            margin-top: 2rem;
          }
        }
      }
    }
  }
</style>