<template>
  <modal
    ref="modal"
    class="product-modal"
    :title="`${isUpdate ? 'Gerenciar' : 'Novo'} produto`"
    :showModal="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <ValidationObserver
        v-slot="{ passes }"
        ref="form"
      >
        <form id="form" @submit.prevent="passes(submit)">
          <div class="body">
            <div v-if="!isUpdate" class="section">
              <!-- <h3>Sobre</h3> -->

              <div class="fields">
                <div class="field">
                  <input-field-component
                    class="input-field-component"
                    placeholder="Descrição do produto"
                    rules="required"
                    v-model="data.description"
                  />

                  <input-currency-field
                    class="input-field-component small"
                    placeholder="Valor de venda"
                    type="money"
                    rules="required"
                    v-model="data.price"
                  />
                </div>

                <!-- <div class="field">
                  <input-field-component
                    class="input-field-component"
                    placeholder="Nomenclatura Comum do Mercosul (NCM)"
                    rules="required"
                  />
                </div> -->

                <div class="field">
                  <input-field-component
                    class="input-field-component medium"
                    placeholder="Código de referência"
                    v-model="data.referenceId"
                  />
                </div>
              </div>
            </div>

            <divider-component v-if="!isUpdate" class="divider" />

            <div class="section">
              <h3>Estoque</h3>

              <div class="fields">
                <div class="field">
                  <input-field-component
                    class="input-field-component small"
                    placeholder="Qtd. disponível"
                    type="number"
                    :min="0"
                    :max="9999"
                    rules="required"
                    v-model="data.inventory.available"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template v-slot:footer>
      <div class="footer-slot-container">
        <div class="button-container">
          <button-component
            class="button-component"
            styles="ghost"
            text="Fechar"
            :isDisabled="hasRequest"
            @click.native="closeModal"
            v-ripple="$theme.secondColorDarkTransparent"
          />

          <button-component
            class="button-component"
            text="Salvar"
            form="form"
            :hasLoader="hasRequest"
            :isDisabled="hasRequest"
            v-ripple="$theme.whiteColorTransparent"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import Modal from '@/components/Modal/Base';
  import InputField from '@/components/Base/InputField';
  import InputCurrencyField from '@/components/Base/InputCurrencyField';
  import Button from '@/components/Base/Button';
  import Divider from '@/components/Base/Divider';

  export default {
    name: 'ProductModal',

    data() {
      return {
        hasRequest: false,

        isUpdate: false,

        data: {
          description: '',
          price: '',
          referenceId: '',
          inventory: {
            available: 0
          }
        }
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'input-field-component': InputField,
      'input-currency-field': InputCurrencyField,
      'button-component': Button,
      'divider-component': Divider
    },

    props: {
      productId: {
        type: String,
        required: false
      },
    },

    mounted() {
      this.isUpdate = !!this.productId;
    },

    methods: {
      async submit() {
        this.hasRequest = true;

        if (this.isUpdate) {
          await this.updateProductInventory();
        } else {
          await this.createProduct();
        }

        this.closeModal();

        this.hasRequest = false;
      },

      async createProduct() {
        const data = {
          description: this.data.description,
          price: this.data.price,
          reference_id: this.data.referenceId,
          inventory: {
            available: parseInt(this.data.inventory.available)
          }
        };

        await this.$store.dispatch('productModule/postProduct', JSON.stringify(data))
          .then(() => {
            this.snackbarNotification('Produto cadastrado com sucesso', '#08B978');

            this.$emit('finishRequest');
          })
          .catch(() => {
            this.snackbarNotification('Erro ao cadastrar produto', '#FF5A5A');
          });
      },

      async updateProductInventory() {
        const data = {
          available: parseInt(this.data.inventory.available)
        };

        await this.$store.dispatch('productModule/patchProductInventory', { id: this.productId, data })
          .then(() => {
            this.snackbarNotification('Estoque atualizado com sucesso', '#08B978');

            this.$emit('finishRequest');
          })
          .catch(() => {
            this.snackbarNotification('Erro ao atualizar estoque do produto', '#FF5A5A');
          });
      },

      closeModal() {
        this.$emit('close');
      },

      snackbarNotification(text, color) {
        this.$emit('snackbarNotification', text, color);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .product-modal {
    form {
      .body {
        margin-top: 1.5rem;
        padding: 0 1.5rem;
        // height: 500px;
        width: 560px;
        overflow-y: auto;

        .divider {
          margin-bottom: 0.5rem;
        }

        .section {
          padding: 0.5rem;

          h3 {
            margin-bottom: 1rem;
          }

          .fields {
            .field {
              display: flex;
              gap: 0.5rem;

              .input-field-component {
                width: 100%;

                &.large {
                  max-width: 350px;
                }

                &.medium {
                  max-width: 250px;
                }

                &.small {
                  max-width: 175px;
                }
              }
            }
          }
        }
      }
    }

    .footer-slot-container {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);

      .button-container {
        display: flex;

        .button-component {
          width: 100px;
          height: 48px;
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .product-modal {
      form {
        .body {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .product-modal {
      form {
        .body {
          // height: 400px;

          .fields {
            .field {
              flex-direction: column;
              margin-bottom: 1rem;
              gap: unset !important;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>