<template>
<div class="payment-type-card-container" :class="{ 'active': this.data.isActive }">
  <div class="icon-container">
    <div class="icon-content">
      <i :class="data.icon"/>
    </div>
  </div>

  <div class="text-container">
    {{ data.text }}
  </div>
</div>
</template>

<script>
  export default {
    name: 'PaymentTypeCard',

    props: {
      data: {
        type: Object,
        default() {
          return {
            icon: 'icon-credit-card',
            text: 'Default',
            isActive: true
          };
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.payment-type-card-container {
  border: 1px solid #DADADA;
  border-radius: 6px;
  width: 130px;
  cursor: pointer;

  &.active {
    .icon-container {
      background: $second-color;
      border-radius: 6px 6px 0 0;

      .icon-content {
        i {
          color: $second-color;
        }
      }
    }
  }

  .icon-container {
    background: #EFEFEF;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-content {
      width: 40px;
      height: 40px;
      background: $white-color;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: #8E8E8E;

        &.icon-signature {
          font-size: 1.5rem;
        }

        &.icon-pix-outline {
          font-size: 1.5rem;
        }
      }
    }
  }

  .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0;
    font-size: 14px;
    color: #333333;
  }
}
</style>