<template>
  <div class="suppliers-view-container">
    <default-view-component :hasModal="isShowCreateOrUpdateModal">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <supplier-modal-component
          v-if="isShowCreateOrUpdateModal"
          :supplierId="currentSupplierId"
          @close="isShowCreateOrUpdateModal = false"
          @finishCreateOrUpdate="fetchData"
          @snackbarNotification="showSnackBar"
        />
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
          />

          <div class="header-options">
            <input-field-component
              class="input-field"
              :lastIcon="hasSearch() ? 'icon-close' : 'icon-search'"
              placeholder="Nome, documento..."
              @iconClick="resetSearch"
              @fetch="fetchData"
              :isDisabled="hasRequest"
              :hasIconFetch="true"
              :disableFloatingLabel="true"
              v-model="search"
            />

            <button-component
              class="button-component"
              text="Novo fornecedor"
              v-ripple="$theme.whiteColorTransparent"
              @click.native="showModal"
            />
          </div>

          <table-header-component
            v-if="tableData.rows.length != 0"
            :hasRequest="hasRequest && !paginationData.totalCountItems"
            :data="[{
              label: `${paginationData.totalCountItems}
                      ${isSearch ? $service.formatStringPlural(paginationData.totalCountItems, 'encontrado') : $service.formatStringPlural(paginationData.totalCountItems, 'registro')}`,
              hasLastChild: false
            }]"
          />

          <table-component
            class="table-component"
            :hasRequest="hasRequest && !isMoreData"
            :table="tableData"
            @actionClick="showModal"
          />

          <pagination-table-component
            v-if="hasTableRows() && !isSearch"
            class="pagination-table-button-component"
            :isDisabled="!haveMoreData()"
            :data="paginationData"
            :hasRequest="hasRequest"
            @onClick="moreData"
          />
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import Header from '@/components/Layout/Header';
  import TableHeader from '@/components/Table/TableHeader';
  import Button from '@/components/Base/Button';
  import InputField from '@/components/Base/InputField';
  import Table from '@/components/Table/Table';
  import SupplierModal from '@/components/Modal/SupplierModal';
  import SnackBar from '@/components/Base/SnackBar';
  import PaginationTableButton from '@/components/Table/PaginationTableButton';

  export default {
    name: 'SupplierView',

    data() {
      return {
        header: {
          title: 'Fornecedores',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        search: '',

        hasRequest: false,
        isShowCreateOrUpdateModal: false,
        currentSupplierId: '',

        isMoreData: false,
        allRows: [],

        tableData: this.initialTableData(),
        paginationData: this.initialPaginationData()
      };
    },

    components: {
      'default-view-component': DefaultView,
      'input-field-component': InputField,
      'header-component': Header,
      'button-component': Button,
      'table-component': Table,
      'table-header-component': TableHeader,
      'supplier-modal-component': SupplierModal,
      'pagination-table-component': PaginationTableButton,
      'snackbar-component': SnackBar
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerId',
        'getCurrentSellerData',
      ])
    },

    created() {
      this.fetchData();
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
        this.paginationData = this.initialPaginationData();
        this.tableData = this.initialTableData();
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.fetchData();
        }
      }
    },

    methods: {
      hasSearch() {
        return !this.$service.isBlank(this.search);
      },

      resetSearch() {
        this.search = '';
      },

      async fetchData() {
        this.isSearch = this.hasSearch();

        if (this.isSearch) {
          this.fetchSearch();
        } else {
          this.fetchAll();
        }
      },

      async fetchAll() {
        this.hasRequest = true;

        if (!this.isMoreData) {
          this.allRows = [];
          this.tableData.rows = [];
        }

        const queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'offset',
            param: this.allRows.length
          }
        ];

        await this.$store.dispatch('supplierModule/getAllSuppliers', this.$service.formatQueryParam(queryParams))
          .then(res => {
            const items = res.items;

            this.paginationData.totalCountItems = res.total_count;

            this.allRows = this.allRows.concat(items);
            this.tableData.rows = this.parseTableRow();

            this.paginationData.countItems = this.allRows.length;
          })
          .catch(() => {
            this.showSnackBar('Erro ao carregar informações', '#FF5A5A');
          });

        this.isMoreData = false;
        this.hasRequest = false;
      },

      parseTableRow() {
        return this.allRows.map(item => {
          return {
            id: item.id,
            name: item.name,
            email: item?.email ? item.email : '-',
            phone: item?.phone_number ? this.$service.formatPhone(item.phone_number) : '-',
            taxpayerId: this.$service.formatTaxPayerOrEin(item.tin),
            actions: {
              showAction: false,
              icon: 'icon-more',
              items: [
                {
                  id: item.id,
                  type: 'update',
                  icon: 'icon-pencil',
                  label: 'Atualizar dados',
                }
              ]
            }
          };
        });
      },

      async fetchSearch() {
        this.hasRequest = true;

        await this.$store.dispatch('supplierModule/getAllSuppliersFind', this.search)
          .then(res => {
            const items = res.items;

            this.allRows = items;
            this.tableData.rows = this.parseTableRow();

            this.paginationData.totalCountItems = this.allRows.length;
          })
          .catch(() => {
            this.showSnackBar('Erro ao carregar informações', '#FF5A5A');
          });

        this.isMoreData = false;
        this.hasRequest = false;
      },

      moreData() {
        if (!this.haveMoreData()) {
          this.isMoreData = true;

          this.fetchData();
        }
      },

      haveMoreData() {
        return this.paginationData.countItems == this.paginationData.totalCountItems;
      },

      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },

      hasTableRows() {
        return this.tableData.rows.length != 0;
      },

      initialPaginationData() {
        return {
          countItems: 0,
          totalCountItems: 0
        };
      },

      showModal(item) {
        this.currentSupplierId = item.id;
        this.isShowCreateOrUpdateModal = true;
      },

      initialTableData() {
        return {
          hasIcon: false,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Nome',
                field: 'name',
                align: 'center'
              },

              {
                label: 'CPF/CNPJ',
                field: 'taxpayerId',
                align: 'center'
              },

              {
                label: 'E-mail',
                field: 'email',
                align: 'center'
              },

              {
                label: 'Contato',
                field: 'phone',
                align: 'center'
              },

              {
                label: 'Ações',
                align: 'center',
                field: 'actions',
                isAction: true
              }
            ]
          },

          rows: []
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .suppliers-view-container {
    .section {
      padding: 1.5rem;

      .header-options {
        display: flex;
        margin-bottom: 1.5rem;

        .input-field {
          max-width: 255px;
        }

        .button-component {
          margin-left: auto;
          height: 48px;
          max-width: 160px;
        }
      }

      .table-component {
        margin-top: 1.5rem;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .pagination-table-button-component {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
    }
  }

  @media (max-width: 605px) {
    .suppliers-view-container {
      .section {
        .header-options {
          flex-direction: column-reverse;

          .input-field {
            width: 100%;
            max-width: unset;
          }

          .button-component {
            margin-bottom: 1rem;
            height: 32px;
          }
        }
      }
    }
  }
</style>