<template>
  <div>
    <div v-if="hasRequest" class="default-input-field">
      <skeleton-loader-component v-if="!!label" class="title"/>

      <skeleton-loader-component class="file-field"/>

      <skeleton-loader-component class="date"/>
    </div>

    <div v-else>
      <div v-if="hasUploadFile()" class=input-file-container>
        <label class="title">
          {{ label }}

          <span v-if="!!subLabel">({{ subLabel }})</span>
        </label>

        <a :download="'IMPay.' + currentFile.fileName" :href="isPdf ? 'data:application/pdf;base64,' + currentFile.b64File : 'data:image/' + currentFile.fileType + ';base64,' + currentFile.b64File" class="upload status">
          <div class="icon-container" :class="currentFile.isPdf ? 'unbackground' : ''">
            <img v-if="currentFile.isPdf" src="@/assets/icons/pdf.svg"/>
            <img v-else v-bind:src="'data:image/jpeg;base64,' + currentFile.b64File"/>
          </div>

          <div class="success">
            <div class="header">
              <p class="file-name">{{ currentFile.fileName }}</p>

              <p class="file-size">{{ currentFile.fileSize }}</p>
            </div>

            <div class="icon status">
              <i class="icon-check"/>
            </div>
          </div>
        </a>

        <label class="date">Enviado {{ currentFile.modifiedDate }}</label>
      </div>

      <ValidationProvider
        tag="div"
        v-else
        :name="name"
        :rules="mountedRules"
        class="input-file-container"
        v-slot="{ errors }"
      >
        <label class="title">
          {{ label }}

          <span v-if="!!subLabel">
            ({{ subLabel }})
          </span>
        </label>

        <div v-if="progress === 0" ref="div" class="upload" :class="error || !!errors[0] ? 'error' : 'start'" @click="inputCard">
          <i class="icon-upload"/>

          <div class="label-input">
            <p>
              Arraste e solte

              <span v-if="side" class="side-text">
                ({{ side }})
              </span>
            </p>
          </div>

          <input ref="input" type="file" :accept="accept" @change="onFileChange"/>
        </div>

        <div v-else class="upload status">
          <div v-if="progress > 0 && progress < 100" class="icon-container unbackground">
            <img src="@/assets/icons/default-file.svg"/>
          </div>

          <div v-else class="icon-container" :class="isPdf ? 'unbackground' : ''">
            <img v-if="isPdf" src="@/assets/icons/pdf.svg"/>
            <img v-else :src="imageUrl"/>
          </div>

          <div v-if="progress > 0 && progress < 100" class="progress">
            <p class="file-name">
              {{ fileName }}
            </p>

            <div class="progress-bar">
              <div class="progress-status" :style="`width: ${progress}%`"></div>
            </div>

            <p class="percentage-progress">
              {{ progress }}%
            </p>
          </div>

          <div v-else class="success">
            <div class="header">
              <p class="file-name">
                {{ fileName }}
              </p>

              <p class="file-size">
                {{ fileSize }}
              </p>
            </div>

            <div class="icon" @click="removeFile">
              <i class="icon-trash"/>
            </div>
          </div>
        </div>

        <div class="error">
          <label v-if="!!error">
            {{ error }}
          </label>

          <label v-else-if="!file">
            {{ errors[0] }}
          </label>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import SkeletonLoader from '@/components/Base/SkeletonLoader';

  export default {
    name: 'InputFile',

    props: {
      label: {
        type: String,
        required: false,
      },

      subLabel: {
        type: String,
        required: false,
      },

      side: {
        type: String,
        required: false,
      },

      size: {
        type: Number,
        default: 3000000,
      },

      name: {
        type: String,
        default: '',
      },

      rules: {
        type: [Object, String],
        default: '',
      },

      accept: {
        type: String,
        default: '*',
      },

      currentFile: {
        type: Object,
        required: false,
      },

      hasRequest: {
        type: Boolean,
        default: false,
      }
    },

    components: {
      ValidationProvider,
      'skeleton-loader-component': SkeletonLoader
    },

    data () {
      return {
        file: null,
        mountedRules: '',
        fileName: null,
        error: null,
        fileFormat: null,
        imageUrl: null,
        fileSize: null,
        progress: 0,
        isPdf: false,
        dragAndDropCapable: true,
      };
    },

    mounted () {
      const { div } = this.$refs;

      if(!this.hasUploadFile()) {
        this.dragAndDropCapable = this.isDragAndDropCapable(div);

        if(this.dragAndDropCapable) {
          ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(event => {
            document.body.addEventListener(event, e => {
              e.preventDefault();
              e.stopPropagation();
            });
          });

          div.addEventListener('drop', e => {
            this.onFileChange(e);
          });
        }

        this.mountedRules = this.rules;
      }
    },

    watch: {
      file() {
        if(this.file != null) {
          this.mountedRules = '';
        }
      },

      currentFile() {
        if (!this.hasUploadFile()) this.removeFile();
      }
    },

    updated() {
      const { div } = this.$refs;

      if(!this.hasUploadFile()) {
        this.dragAndDropCapable = this.isDragAndDropCapable(div);

        if(this.dragAndDropCapable) {
          ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(event => {
            document.body.addEventListener(event, e => {
              e.preventDefault();
              e.stopPropagation();
            });
          });

          div.addEventListener('drop', e => {
            this.onFileChange(e);
          });
        }

        this.mountedRules = this.rules;
      }
    },

    methods: {
      hasUploadFile() {
        return this.currentFile != null;
      },

      inputCard() {
        const { input } = this.$refs;

        input.click();
      },

      isDragAndDropCapable(div) {
        if (div != undefined) return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
      },

      onFileChange(e) {
        const files = e.target.files || e.dataTransfer.files;

        if (!files.length) return;

        this.fileSize = this.bytesToSize(files[0].size);

        if (files[0].size <= this.size) {
          this.imageUrl = URL.createObjectURL(files[0]);

          if (this.accept == '*') {
            this.createFile(files[0]);
          } else {
            if (this.accept.split(', ').indexOf(files[0].type) != -1) {
              this.isPdf = files[0].type == 'application/pdf';

              this.createFile(files[0]);

              this.$emit('input', files[0]);
            } else {
              this.error = 'Formato de arquivo não suportado.';
              return;
            }
          }
        } else {
          this.error = 'Tamanho de arquivo não suportado.';
          return;
        }
      },

      bytesToSize(bytes) {
        var sizes = ['bytes', 'kB', 'MB', 'G', 'T', 'P'];

        for (var i = 0; i < sizes.length; i++) {
          if (bytes <= 1024) {
            return bytes + ' ' + sizes[i];
          } else {
            bytes = parseFloat(bytes / 1024).toFixed(2);
          }
        }

        return bytes + ' P';
      },

      createFile(file) {
        this.error = null;

        const reader = new FileReader();

        reader.onprogress = e => {
          if (e.lengthComputable) {
            this.trackProgress(e.loaded, e.total);
          }
        };

        reader.onloadend = e => {
          const { error } = e.target;

          if (error != null) {
            switch (error.code) {
              case error.ENCODING_ERR:
                this.error = 'Erro no encoding.';
                break;
              case error.NOT_FOUND_ERR:
                this.error = 'Arquivo inexistente.';
                break;
              case error.NOT_READABLE_ERR:
                this.error = 'Erro ao ler o arquivo.';
                break;
              case error.SECURITY_ERR:
                this.error = 'Problema de segurança com o arquivo.';
                break;
              default:
                this.error = 'Erro ao processar o arquivo.';
            }
          }

          this.trackProgress(e.loaded, e.total);
        };

        reader.onload = e => {
          const { result } = e.target;

          this.file = result;
          this.fileName = file.name;
        };

        reader.readAsBinaryString(file);
      },

      trackProgress (loaded, total) {
        this.progress = parseInt(((loaded / total) * 100), 10);
      },

      removeFile () {
        this.progress = 0;
        this.file = '';
        this.fileName = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  input[type="file"] {
    display: none;
  }

  .default-input-field {
    margin-top: 1.5rem;

    .title {
      height: 1.5rem;
      width: 100%;
    }

    .file-field {
      height: 57px;
      width: 100%;
      margin-top: 0.5rem
    }

    .date {
      margin-top: 0.5rem;
      height: 1.5rem;
      width: 100%;
    }
  }

  .input-file-container {
    label {
      font-size: 14px;

      &.title {
        color: #333;
      }

      &.date {
        color: #8E8E8E;
        font-weight: 600;
      }

      span {
        font-size: 12px;
      }
    }

    .upload {
      padding: 1rem;
      border-radius: 6px;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      text-decoration: none;

      i {
        margin-right: 1rem;
        color: #8E8E8E;
      }

      &.start {
        border: 2px dashed #DADADA;
        color: #6D6F6F;
        cursor: pointer;

        justify-content: center;
        align-items: center;
        display: flex;

        img {
          margin-right: 1rem;
        }

        .label-input {
          .side-text {
            font-weight: 400;
            color: #333;
          }
        }
      }

      &.status {
        background: #F9F9F9;
        padding: 9px 16px;
        display: flex;


        .icon-container {
          margin-right: 15px;
          background: #333;
          height: 40px;
          width: 40px;

          display: flex;
          align-items: center;
          justify-content: center;

          &.unbackground {
            background: transparent;
          }

          img {
            max-width: 40px;
            max-height: 40px;
            overflow: hidden;
            padding: 2px;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
            }
          }
        }

        .progress {
          width: 100%;

          .file.name {
            color: #333;
          }

          .percentage-progress {
            color: #979797;
          }

          .progress-bar {
            height: 5px;
            margin-top: 0.5rem;
            border-radius: 6px;
            background: #DADADA;

            .progress-status {
              height: 5px;
              border-radius: 6px;
              background: #08B978;
            }
          }

          .percentage-progress {
            margin-top: 0.5rem;
          }
        }

        .success {
          display: flex;
          width: calc(100% - 65px);

          .header {
            width: calc(100% - 35px);

            .file-name {
              color: #333;
              overflow: hidden;
              position: relative;
              white-space: nowrap;
            }

            .file-size {
              color: #979797;
            }
          }

          .icon {
            margin-left: auto;
            display: flex;
            opacity: 0.25;

            i {
              cursor: pointer;
              font-size: 1.3rem;
              margin: 0;
              display: flex;
              align-items: center;
            }

            &.status {
              opacity: 1;

              i {
                cursor: default;
                color: #08B978;
              }
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      &.error {
        border: 2px dashed #FF5A5A;
        color: #6D6F6F;
        cursor: pointer;

        justify-content: center;
        align-items: center;
        display: flex;

        img {
          margin-right: 1rem;
        }
      }
    }

    .error {
      margin-top: 0.5rem;
      font-weight: 500;
      color: #FF5A5A;
    }
  }
</style>