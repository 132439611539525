<template>
  <div class="navigation-container">
    <div class="navigation" v-for="(item, index) in styles" :key="index">
      <router-link
        v-if="item.isEnable"
        :to="item.to"
      >
        <div class="item" :class="{ selected: currentSection == item.param }">
          {{ item.title }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AccountInformationsNavigation',

    props: {
      styles: {
        type: Array,
        default: () => [
          {
            to: '/',
            param: '/',
            title: 'Title',
            isEnable: false,
          }
        ]
      }
    },

    data() {
      return {
        currentSection: ''
      };
    },

    watch: {
      $route(to) {
        this.currentSection = to.params.section;
      }
    },

    mounted() {
      this.currentSection = this.$route.params.section;
    },
  };
</script>

<style lang="scss" scoped>
  .navigation-container {
    .navigation {
      a {
        text-decoration: none;
      }

      .item {
        cursor: pointer;
        padding: 10px 16px;
        min-width: 210px;
        border-radius: 6px;
        height: 45px;
        display: flex;
        align-items: center;
        color: #333;
        margin-top: 0.5rem;

        i {
          margin-left: 0.5rem;
        }

        &.selected {
          background: $second-color;
          color: $white-color;

          &:hover {
            background: $second-color;
          }
        }

        &:hover {
          background: #F6F7F7;
        }
      }
    }
  }

  @media (max-width: 760px) {
    .navigation-container {
      overflow: scroll;
      display: flex;
      margin-bottom: 2rem;
      width: calc(100vw - 135px);

      .navigation {
        .item {
          height: 40px;
          justify-content: center;
          margin-right: 1rem;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    .navigation-container {
      width: 100%;
      margin-bottom: 1rem;

      .navigation {
        .item {
          height: 35px;
          font-size: 14px;
          min-width: 205px;
        }
      }
    }
  }
</style>
