<template>
  <modal
    class="modal"
    :title="data.title"
    :showModal="true"
    :style="styleObject"
    @close="close"
  >
    <template v-slot:body>
      <div class="body">
        <i :class="data.icon" />

        <p>
          {{ data.text }}
        </p>

        <button-component
          class="button-component"
          :text="data.buttonText"
          :hasLoader="hasRequest"
          :isDisabled="hasRequest"
          @click.native="click"
          v-ripple="$theme.whiteColorTransparent"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';

  export default {
    name: 'ConfirmModal',

    components: {
      'modal': Modal,
      'button-component': Button
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            title: 'Default title',
            icon: 'icon-close',
            text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            buttonColor: '#FF5A5A',
            buttonHoverColor: '#e53c3c',
            buttonText: 'Default button text',
          };
        }
      },

      hasRequest: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      styleObject: function () {
        return {
          '--color': this.data.buttonColor,
          '--color-hover': this.data.buttonHoverColor ? this.data.buttonHoverColor : this.data.buttonColor
        };
      }
    },

    methods: {
      close() {
        this.$emit('close');
      },

      click() {
        this.$emit('click');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modal {
    .body {
      display: flex;
      align-items: center;
      flex-direction: column;

      h3 {
        color: #333;
      }

      p {
        text-align: center;

        b {
          font-weight: 500;
        }

        span {
          text-transform: lowercase;
          font-weight: 500;
        }
      }

      i {
        font-size: 3.2rem;
        color: #8E8E8E;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      .button-component {
        margin-top: 2rem;
        width: 190px;
        height: 40px;
        background: var(--color);

        &:hover {
          background: var(--color-hover);
        }
      }
    }
  }
</style>

