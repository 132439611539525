<template>
  <simple-default-view-component class="simple-default-view-component" :hasModal="isCustomerModalShow || isServiceModalShow || isProductModalShow">
    <template v-slot:alert>
      <snackbar-component ref="snackbar" />
    </template>

    <template v-slot:header>
      <div class="title">
        <h2>Nova venda</h2>
      </div>

      <div class="icon-container">
        <i class="icon-close" @click="close" />
      </div>
    </template>

    <template v-slot:modal>
      <customer-modal-component
        v-if="isCustomerModalShow"
        :hasEditButton="false"
        @finishRequest="getAllCustomers"
        @close="isCustomerModalShow = false"
      />

      <service-modal-component
        v-if="isServiceModalShow"
        :hasEditButton="false"
        @finishRequest="getAllServices"
        @close="isServiceModalShow = false"
      />

      <product-modal-component
        v-if="isProductModalShow"
        :hasEditButton="false"
        @finishRequest="getAllProducts"
        @close="isProductModalShow = false"
      />
    </template>

    <template v-slot:content>
      <ValidationObserver
        tag="div"
        class="content"
        ref="form"
        v-slot="{ passes }"
      >
        <form id="form" @submit.prevent="passes(toResume)">
          <div class="column">
            <div class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Como você quer vender?</h3>

                  <span class="required">*</span>
                </div>
              </div>

              <div class="row">
                <select-field
                  class="select-field"
                  placeholder="Selecione o tipo de pagamento"
                  :options="paymentMethodData"
                  v-model="paymentMethod"
                />
              </div>
            </div>

            <div class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Quem é o cliente?</h3>

                  <span class="required">*</span>
                </div>
              </div>

              <div class="row">
                <select-fetch-field
                  class="select-fetch-field"
                  name="customerSelect"
                  placeholder="Selecione o cliente"
                  rules="required"
                  :options="customers"
                  :hasSearch="true"
                  :hasFetch="true"
                  :hasButton="true"
                  buttonText="Cadastrar cliente"
                  :hasRequest="hasCustomerRequest"
                  @fetch="getCustomerByQuery"
                  @buttonClick="isCustomerModalShow = true"
                  v-model="customerId"
                />
              </div>
            </div>

            <div class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>O que você está vendendo?</h3>

                  <span class="required">*</span>
                </div>

                <div class="information ml-auto">
                  Valor total: <span>{{ $service.formatBrlAmountSymbol(getTotalItemsPrice()) }}</span>
                </div>
              </div>

              <div class="row">
                <div v-for="(item, index) in items" :key="index" class="items">
                  <div class="item-wrapper">
                    <div class="type-container">
                      <action-button-component
                        v-for="(type, index) in item.type"
                        :key="index"
                        :data="type"
                        :isActive="type.isActive"
                        @click.native="toggleItemType(item, index)"
                      />
                    </div>

                    <div class="actions-wrapper">
                      <div class="fields">
                        <select-fetch-field
                          class="select-fetch-field service-product"
                          :name="`itemSelect${index}`"
                          :placeholder="`Selecione o ${resolveSaleItemPlaceholder(item.type)}`"
                          rules="required"
                          :options="isProductSaleItemActive(item.type) ? productOptions : serviceOptions"
                          :hasButton="true"
                          :buttonText="`Cadastrar ${resolveSaleItemPlaceholder(item.type)}`"
                          :hasRequest="hasProductRequest || hasServiceRequest"
                          @buttonClick="showSaleItemModal(item.type)"
                          v-model="item.itemId"
                        />

                        <input-number-field
                          class="input-number-field"
                          :max="isProductSaleItemActive(item.type) ? getInventoryProductAvailable(item.itemId) : 99"
                          v-model="item.quantity"
                        />
                      </div>

                      <div class="options-container">
                        <div
                          class="option"
                          v-ripple="item.isAddItem ? $theme.secondColorTransparent : 'rgba(255, 90, 90, 0.1)'"
                          @click="item.isAddItem ? addNewItem() : removeItem(index)"
                        >
                          <i
                            :class="item.isAddItem ? 'icon-add' : 'icon-remove'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="vertical-line">
            <div class="line"></div>
          </div>

          <div class="column">
            <div v-if="isCheckoutPaymentMethod()" class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Qual o tipo pagamento?</h3>

                  <span class="required">*</span>
                </div>
              </div>

              <div class="row">
                <div class="items">
                  <payment-type-card-component
                    class="payment-type-card-component"
                    v-for="(item, index) in checkoutPaymentTypeData"
                    :key="index"
                    :data="item"
                    @click.native="toggleCheckoutPaymentTypeCard(item)"
                  />
                </div>
              </div>
            </div>

            <div v-if="isCheckoutPaymentMethod() && isCheckoutCreditPaymentEnabled() || isCreditPaymentEnabled()" class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Aceita parcelar no cartão?</h3>
                </div>

                <div class="information">
                  Até <span>{{ maxInstallments + 'x ' + $service.formatBrlAmountSymbol(getTotalItemsPrice() / maxInstallments) }}</span>
                </div>
              </div>

              <div class="row">
                <range-field
                  class="range-field"
                  :min="1"
                  :max="12"
                  :initialValue="maxInstallments"
                  v-model="maxInstallments"
                />
              </div>
            </div>

            <div v-if="isCheckoutPaymentMethod() || isSlipPaymentMethod()" class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Adicionar instruções de cobrança?</h3>
                </div>
              </div>

              <div class="row">
                <div class="item">
                  <span>
                    Cobrar multa de
                  </span>

                  <input-currency-field
                    class="input-field percentage"
                    :min="0"
                    :max="2"
                    type="percent"
                    v-model="lateFeeRate"
                  />
                </div>

                <div class="item">
                  <span>
                    Cobrar juros de
                  </span>

                  <input-currency-field
                    class="input-field percentage"
                    :min="0"
                    :max="1"
                    type="percent"
                    v-model="dailyInterestRate"
                  />

                  <span>
                   ao mês
                  </span>
                </div>

                <div v-if="isSlipPaymentMethod()" class="item">
                  <span>
                    Conceder desconto de
                  </span>

                  <input-currency-field
                    class="input-field amount"
                    :min="0"
                    :max="2"
                    placeholder="R$"
                    :rules="inputMoneyRules()"
                    type="money"
                    v-model="discount"
                  />
                </div>
              </div>
            </div>

            <div class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Quando vai vencer?</h3>

                  <span class="required">*</span>
                </div>
              </div>

              <div class="row">
                <input-date-field
                  class="input-field date"
                  type="date"
                  rules="required"
                  :minDate="new Date()"
                  placeholder="Vencimento"
                  v-model="dueDate"
                />
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template v-slot:footer>
      <div class="footer-container">
        <button-component
          class="button-component"
          text="Próximo"
          form="form"
          :hasLoader="hasRequest"
          :isDisabled="hasRequest"
          v-ripple="$theme.whiteColorTransparent"
        />
      </div>
    </template>
  </simple-default-view-component>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ValidationObserver } from 'vee-validate';
  import SimpleDefaultView from '@/components/Layout/SimpleDefaultView';
  import PaymentTypeCard from '@/components/PaymentTypeCard';
  import Button from '@/components/Base/Button';
  import ActionButton from '@/components/ActionButton';
  import SelectFetchField from '@/components/SelectFetchField';
  import SelectField from '@/components/Base/SelectField';
  import InputNumberField from '@/components/Base/InputNumberField';
  import InputDateField from '@/components/Base/InputDateField';
  import InputCurrencyField from '@/components/Base/InputCurrencyField';
  import RangeField from '@/components/Base/RangeField';
  import CustomerModal from '@/components/Modal/CustomerModal';
  import ServiceModal from '@/components/Modal/ServiceModal';
  import ProductModal from '@/components/Modal/ProductModal';
  import SnackBar from '@/components/Base/SnackBar';

  export default {
    name: 'NewSaleView',

    data() {
      return {
        hasRequest: false,

        queryParams: [
          {
            query: 'limit',
            param: '500'
          },
          {
            query: 'status',
            param: 'active'
          }
        ],

        isCustomerModalShow: false,
        hasCustomerRequest: false,
        customers: [],

        isProductModalShow: false,
        hasProductRequest: false,
        products: [],
        productOptions: [],

        isServiceModalShow: false,
        hasServiceRequest: false,
        services: [],
        serviceOptions: [],

        paymentMethod: 'money',
        paymentMethodData: [
          {
            isActive: false,
            label: 'Dinheiro',
            value: 'money'
          }
        ],

        checkoutPaymentTypeData: this.initialCheckoutPaymentTypeData(),

        items: [
          this.initialItemData()
        ],

        customerData: {
          name: '',
          email: '',
          tin: '',
          addressLine1: '',
          addressCity: '',
          addressNeighborhood: '',
          addressPostalCode: '',
          addressStateCode: ''
        },

        customerId: null,
        maxInstallments: '',
        dueDate: '',
        discount: '',
        dailyInterestRate: '',
        lateFeeRate: '',
      };
    },

    components: {
      ValidationObserver,
      'simple-default-view-component': SimpleDefaultView,
      'payment-type-card-component': PaymentTypeCard,
      'button-component': Button,
      'action-button-component': ActionButton,
      'select-fetch-field': SelectFetchField,
      'select-field': SelectField,
      'input-number-field': InputNumberField,
      'range-field': RangeField,
      'input-date-field': InputDateField,
      'input-currency-field': InputCurrencyField,
      'customer-modal-component': CustomerModal,
      'product-modal-component': ProductModal,
      'service-modal-component': ServiceModal,
      'snackbar-component': SnackBar,
    },

    computed: {
      ...mapGetters({
        getCurrentSellerSaleStatus: 'getCurrentSellerSaleStatus',
        getProgressStatus: 'newSaleModule/getProgressStatus',
        getIsSellerActive: 'getIsSellerActive',
        getSaleData: 'newSaleModule/getData'
      })
    },

    created() {
      if (this.getIsSellerActive) {
        this.paymentMethod = 'checkout';
        this.paymentMethodData = [
        {
            isActive: false,
            label: 'Link pagamento',
            value: 'checkout'
          },

          {
            isActive: false,
            label: 'Boleto',
            value: 'bank_slip'
          },
          ...this.paymentMethodData
        ];
      }
    },

    mounted() {
      if (!this.getCurrentSellerSaleStatus) {
        this.close();
      } else {
        if (this.getProgressStatus && this.$route.params?.hasNewSale) {
          this.syncData();
        } else {
          this.getAllCustomers();
          this.getAllProducts();
          this.getAllServices();
        }
      }
    },

    methods: {
      showSaleItemModal(itemType) {
        if (this.isProductSaleItemActive(itemType)) {
          this.isProductModalShow = true;
        } else {
          this.isServiceModalShow = true;
        }
      },

      isProductSaleItemActive(itemType) {
        const activeType = itemType.find(item => item.isActive);

        return this.$service.toUpperCase(activeType.value) == 'PRODUCT';
      },

      resolveSaleItemPlaceholder(itemType) {
        const activeType = itemType.find(item => item.isActive);

        return this.$service.toLowerCase(activeType.text);
      },

      toggleItemType(item, index) {
        item.itemId = null;
        item.quantity = 1;

        item.type.map(item => item.isActive = false);
        item.type[index].isActive = true;
      },

      close() {
        this.$router.push('/dashboard');

        this.$store.commit('newSaleModule/resetData');
      },

      syncData() {
        this.maxInstallments = this.getSaleData.maxInstallments;
        this.items = this.getSaleData.items;
        this.dueDate = this.getSaleData.dueDate;
        this.dailyInterestRate = this.getSaleData.dailyInterestRate;
        this.lateFeeRate = this.getSaleData.lateFeeRate;
        this.discount = this.getSaleData.discount;
        this.checkoutPaymentTypeData = this.getSaleData.checkoutPaymentTypeData;
        this.paymentMethod = this.getSaleData.paymentMethod;
        this.paymentMethodData = this.getSaleData.paymentMethodData;
        this.customerId = this.getSaleData.selectedCustomer?.id;
        this.customers = this.getSaleData.customers;
        this.products = this.getSaleData.products;
        this.productOptions = this.getSaleData.productOptions;
        this.services = this.getSaleData.services;
        this.serviceOptions = this.getSaleData.serviceOptions;
      },

      async getAllCustomers() {
        this.hasCustomerRequest = true;

        await this.$store.dispatch('customerModule/getAllCustomers', this.$service.formatQueryParam(this.queryParams))
          .then(res => {
            this.customers = this.formatSelectArr(res.items);
          })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao buscar clientes', '#FF5A5A');
          });

        this.hasCustomerRequest = false;
      },

      async getAllProducts() {
        this.hasProductRequest = true;

        await this.$store.dispatch('productModule/getAllProducts', this.$service.formatQueryParam(this.queryParams))
          .then(res => {
            this.products = res.items;
            this.productOptions = this.products
              .filter(item => item.inventory.available > 0)
              .map(item => ({
                isActive: false,
                label: `${item.description} - ${this.$service.formatBrlAmountSymbol(item.price)} (qtd. máx: ${item.inventory.available})`,
                value: item.id
              }));
          })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao buscar produtos', '#FF5A5A');
          });

        this.hasProductRequest = false;
      },

      async getAllServices() {
        this.hasServiceRequest = true;

        await this.$store.dispatch('serviceModule/getAllServices', this.$service.formatQueryParam(this.queryParams))
          .then(res => {
            this.services = res.items;
            this.serviceOptions = this.services.map(item => ({
              isActive: false,
              label: `${item.description} - ${this.$service.formatBrlAmountSymbol(item.price)}`,
              value: item.id
            }));
          })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao buscar serviços', '#FF5A5A');
          });

        this.hasServiceRequest = false;
      },

      async getCustomerByQuery(customerSearch) {
        this.hasCustomerRequest = true;

        await this.$store.dispatch('customerModule/getAllCustomersFind', customerSearch)
          .then(res => {
            this.customers = this.formatSelectArr(res.items);
          })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao pesquisar cliente', '#FF5A5A');
          });

        this.hasCustomerRequest = false;
      },

      formatSelectArr(data) {
        return data.map(item => ({
          isActive: false,
          label: item.name,
          value: item.id
        }));
      },

      addNewItem() {
        this.items.map(item => item.isAddItem = false);

        this.items.unshift(this.initialItemData());
      },

      removeItem(index) {
        if (this.items.length > 1) {
          this.items.splice(index, 1);
        }

        this.items[0].isAddItem = true;
      },

      togglePaymentMethodCard(item) {
        this.paymentMethodData.map(item => item.isActive = false);

        item.isActive = true;

        this.checkoutPaymentTypeData = this.initialCheckoutPaymentTypeData();

        if (this.isSlipPaymentMethod()) {
          this.dailyInterestRate = 1;
          this.lateFeeRate = 2;
        } else {
          this.dailyInterestRate = '';
          this.lateFeeRate = '';
        }
      },

      toggleCheckoutPaymentTypeCard(item) {
        var activeItems = this.getCheckoutPaymentType();

        if (activeItems.length > 1 || activeItems.length == 1 && activeItems[0].value != item.value) {
          item.isActive = !item.isActive;
        }
      },

      isCheckoutPaymentMethod() {
        return this.paymentMethod == 'checkout';
      },

      isSlipPaymentMethod() {
        return this.paymentMethod == 'bank_slip';
      },

      getCheckoutPaymentType() {
        return this.checkoutPaymentTypeData.filter(item => item.isActive);
      },

      isCheckoutCreditPaymentEnabled() {
        return this.checkoutPaymentTypeData.find(item => item.value == 'credit').isActive;
      },

      isCreditPaymentEnabled() {
        return this.paymentMethod == 'credit';
      },

      initialCheckoutPaymentTypeData() {
        return [
          {
            icon: 'icon-credit-card',
            text: 'Crédito',
            value: 'credit',
            isActive: true
          },

          {
            icon: 'icon-pix-outline',
            text: 'Pix',
            value: 'pix',
            isActive: true
          }
        ];
      },

      inputMoneyRules() {
        return 'maxMoney:' + this.getTotalItemsPrice() + ':' + this.discount;
      },

      getTotalItemsPrice() {
        return this.items.reduce((total, item) => {
          let price = 0;

          if (item.itemId) {
            if (this.isProductSaleItemActive(item.type)) {
              price = this.products.find(product => product.id == item.itemId)?.price;
            } else {
              price = this.services.find(service => service.id == item.itemId)?.price;
            }

            price = price * item.quantity;
          }

          return total + (price || 0);
        }, 0);
      },

      getCurrentCustomer() {
        return this.customers.find(item => item.value == this.customerId);
      },

      async toResume() {
        this.hasRequest = true;

        var currentCustomer = this.getCurrentCustomer();

        await this.$store.dispatch('customerModule/getCustomerId', currentCustomer?.value)
          .then(res => {
            this.customerData.name = res.name,
            this.customerData.email = res.email,
            this.customerData.tin = res.tin,
            this.customerData.addressLine1 = res.address.line1,
            this.customerData.addressCity = res.address.city,
            this.customerData.addressNeighborhood = res.address.neighborhood,
            this.customerData.addressPostalCode = res.address.postal_code,
            this.customerData.addressStateCode = res.address.state_code;

            this.setData();
            this.$store.commit('newSaleModule/setProgressStatus', true);
          })
          .catch(() => {
            this.$refs?.snackbar?.show('Cliente não encontrado/deletado', '#FF5A5A');
          });

        if (this.getProgressStatus) {
          this.$router.push({
            name: 'new-sale-resume',
            params: {
              hasNewSale: true
            }
          });
        }

        this.hasRequest = false;
      },

      getInventoryProductAvailable(id) {
        return this.products.find(product => product.id == id)?.inventory?.available;
      },

      setData() {
        var currentCustomer = this.getCurrentCustomer();

        this.$store.commit('newSaleModule/setData', {
          dueDate: this.dueDate,
          maxInstallments: this.maxInstallments,
          totalAmount: this.getTotalItemsPrice(),
          dailyInterestRate: this.dailyInterestRate,
          lateFeeRate: this.lateFeeRate,
          discount: this.discount,
          paymentMethod: this.paymentMethod,
          checkoutPaymentTypeData: this.checkoutPaymentTypeData,
          paymentMethodData: this.paymentMethodData,
          items: this.formatResumeItems(this.items),
          selectedCustomer: {
            id: currentCustomer?.value,
            name: currentCustomer?.label
          },
          customer: this.customerData,
          customers: this.customers,
          services: this.services,
          serviceOptions: this.serviceOptions,
          products: this.products,
          productOptions: this.productOptions
        });
      },

      formatResumeItems() {
        return this.items.map(item => {
          var currentSaleItem = null;

          if (this.isProductSaleItemActive(item.type)) {
            currentSaleItem = this.products.find(product => product.id == item.itemId);
          } else {
            currentSaleItem = this.services.find(service => service.id == item.itemId);
          }

          return {
            ...item,
            price: currentSaleItem?.price * item.quantity,
            description: `${currentSaleItem?.description} (${item.quantity} x ${this.$service.formatBrlAmountSymbol(currentSaleItem?.price)})`
          };
        });
      },

      initialItemData() {
        return {
          itemId: null,
          quantity: 1,
          description: '',
          price: 0,
          isAddItem: true,
          type: [
            {
              text: 'Produto',
              value: 'product',
              isActive: true
            },
            {
              text: 'Serviço',
              value: 'service',
              isActive: false,
            }
          ]
        };
      },
    },

    beforeDestroy() {
      this.setData();
    }
  };
</script>

<style lang="scss" scoped>
  .simple-default-view-component {
    .title {
      display: flex;
      align-items: center;

      .d-flex {
        display: flex;

        h2 {
          color: #333333;
        }
      }

      .information {
        font-size: 14px;
        margin-left: 1rem;

        &.ml-auto {
          margin-left: auto;
        }

        span {
          font-weight: 500;
        }
      }
    }

    .content {
      padding: 1.5rem;
      overflow-y: auto;
      height: 100%;

      form {
        display: flex;
        width: 100%;
        margin-bottom: 80px;

        .column {
          width: 100%;

          .section {
            margin-bottom: 3rem;

            &:last-child {
              margin-bottom: 0;
            }

            .title {
              color: #333333;

              .required {
                color: $red-color;
                margin-left: 5px;
              }
            }

            .row {
              margin-top: 1rem;

              .select-field {
                max-width: 325px;
              }

              .select-fetch-field {
                max-width: 450px;
              }

              .input-field {
                &.date {
                  width: 180px;
                }

                &.amount {
                  width: 150px;
                }
              }

              .range-field {
                max-width: 500px;
              }

              .item {
                display: flex;
                gap: 10px;
                align-items: center;
                font-size: 14px;
                margin-bottom: 1rem;

                span {
                  margin-top: -1rem;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                .input-field {
                  &.percentage {
                    width: 75px;
                  }
                }
              }

              .items {
                display: flex;
                margin-bottom: 1rem;
                max-width: 675px;
                gap: 1rem;

                .item-wrapper {
                  width: 100%;
                  gap: 1rem;
                  display: flex;
                  flex-direction: column;

                  .type-container {
                    display: flex;
                    gap: 1rem;
                  }

                  .actions-wrapper {
                    display: flex;

                    .fields {
                      display: flex;
                      width: 100%;
                      gap: 1rem;

                      .select-fetch-field {
                        max-width: unset;

                        &.service-product {
                          width: 100%;
                        }
                      }

                      .input-number-field {
                        max-width: 120px;
                        width: 100%;
                      }
                    }
                  }
                }

                .options-container {
                  justify-content: center;
                  align-items: center;
                  display: flex;
                  height: 48px;
                  margin-left: 1rem;

                  .option {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                      cursor: pointer;

                      &.icon-add {
                        color: $second-color;
                      }

                      &.icon-remove {
                        color: $red-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .vertical-line {
        padding: 0 2rem;

        .line {
          width: 1px;
          height: calc(100% - 210px);
          position: absolute;
          background: #EFEFEF;
        }
      }
    }

    .footer-container {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 1rem;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);
      width: 100%;
      background: $white-color;
      position: fixed;
      bottom: 0;
      z-index: 10;

      .button-component {
        height: 48px;
        width: 180px;
        margin-left: auto;
      }

      .button-back-component {
        height: 48px;
      }
    }
  }

  @media (max-width: 945px) {
    .simple-default-view-component {
      .title {
        h2 {
          font-size: 20px;
        }
      }

      .content {
        form {
          flex-direction: column;

          .vertical-line {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 610px) {
    .simple-default-view-component {
      .content {
        form {
          .column {
            .section {
              .title {
                flex-direction: column;
                align-items: unset;

                .information {
                  margin-top: 0.5rem;
                  margin-left: auto;
                }
              }

              .row {
                .item {
                  font-size: 12px;
                }

                .items {
                  align-items: center;

                  .item-wrapper {
                    flex-direction: column;

                    .actions-wrapper {
                      .fields {
                        flex-direction: column;
                      }

                      .options-container {
                        height: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .simple-default-view-component {
      .title {
        h2 {
          font-size: 18px;
        }
      }

      .content {
        margin-bottom: 60px;

        form {
          margin-bottom: 0;
        }
      }

      .footer-container {
        height: 56px;
        padding: 0 1.5rem;

        .button-component {
          height: 32px;
          width: 120px;
        }
      }
    }
  }
</style>