<template>
  <div>
    <div v-if="hasRequest" class="loader-container">
      <spinner-component color="#DADADA" />
    </div>

    <table v-else-if="hasRow()">
      <thead>
        <tr>
          <th v-for="(header, index) in table.headers.items"
            :key="index"
            :class="{ 'border': table.headers.hasBorder }"
            :style="{ 'text-align': header.align }"
          >
            {{ header.label }}
          </th>
        </tr>
      </thead>

      <tbody v-for="(item, index) in table.body" :key="index">
        <tr v-for="(row, index) in item.rows" :key="index">
          <td v-for="(header, index) in table.headers.items"
            :key="index"
            :style="{ 'text-align': header.align }"
          >
            {{ row[header.field] }}
          </td>
        </tr>

        <tr>
          <td class="abstract" colspan="100%">
            <div class="wrapper">
              <div class="content">
                <p class="label">{{ table.body[index].abstractRow.primaryContent.label }}</p>

                <p>{{ table.body[index].abstractRow.primaryContent.value }}</p>
              </div>

              <div class="content">
                <p class="label">{{ table.body[index].abstractRow.secondContent.label }}</p>

                <p>{{ table.body[index].abstractRow.secondContent.value }}</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <simple-message-component
      v-else
      class="empty-data-container"
      :data="emptyTableRowsData"
    />
  </div>
</template>

<script>
  import SimpleMessage from '@/components/SimpleMessage';
  import Spinner from '@/components/Base/Spinner';

  export default {
    name: 'SectionTable',

    data() {
      return {
        hasData: false,
        emptyTableRowsData: {
          icon: 'icon-receipts',
          text: 'Não foram encontrados registros'
        },
      };
    },

    props: {
      hasRequest: {
        type: Boolean,
        default: false
      },

      table: {
        type: Object,
        default() {
          return {
            headers: {
              hasBorder: true,
              items: [
                {
                  label: 'Data de venda',
                  field: 'date',
                  align: 'left'
                },

                {
                  label: 'ID Transação',
                  field: 'transactionId',
                  align: 'center'
                },

                {
                  label: 'Valor bruto',
                  field: 'grossAmount',
                  align: 'center'
                },

                {
                  label: 'Taxa',
                  field: 'fee',
                  align: 'center'
                },

                {
                  label: 'Valor líquido',
                  field: 'amount',
                  align: 'center'
                }
              ]
            },

            body: [
              {
                rows: [
                  {
                    date: '10/02/21 - 15:00',
                    transactionId: 'abghfdt5676agvdr45753iur4673rd2a',
                    grossAmount: 'R$ 200,00',
                    fee: 'R$ 2,00',
                    amount: 'R$ 195,00',
                  },

                  {
                    date: '10/02/21 - 15:00',
                    transactionId: 'abghfdt5676agvdr45753iur4673rd2a',
                    grossAmount: 'R$ 200,00',
                    fee: 'R$ 2,00',
                    amount: 'R$ 195,00',
                  },

                  {
                    date: '10/02/21 - 15:00',
                    transactionId: 'abghfdt5676agvdr45753iur4673rd2a',
                    grossAmount: 'R$ 200,00',
                    fee: 'R$ 2,00',
                    amount: 'R$ 195,00',
                  }
                ],

                abstractRow: {
                  primaryContent: {
                    label: 'Pagamento previsto:',
                    value: '13/02/21'
                  },

                  secondContent: {
                    label: 'Total:',
                    value: 'R$ 240,00'
                  }
                }
              },

              {
                rows: [
                  {
                    date: '10/02/21 - 15:00',
                    transactionId: 'abghfdt5676agvdr45753iur4673rd2a',
                    grossAmount: 'R$ 200,00',
                    fee: 'R$ 2,00',
                    amount: 'R$ 195,00',
                  }
                ],

                abstractRow: {
                  primaryContent: {
                    label: 'Pagamento previsto',
                    value: '13/02/21'
                  },

                  secondContent: {
                    label: 'Total',
                    value: 'R$ 240,00'
                  }
                }
              }
            ]
          };
        }
      }
    },

    components: {
      'simple-message-component': SimpleMessage,
      'spinner-component': Spinner
    },

    methods: {
      hasRow() {
        return !this.$service.isEmptyArray(this.table.body);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  table {
    cursor: default;
    color: #333;
    border-spacing: unset;
    font-size: 14px;
    width: 100%;

    thead {
      tr {
        th {
          height: 60px;
          white-space: nowrap;
          padding: 0.5rem 1rem;
          font-weight: 500;

          &.border {
            border-bottom: 1px solid #EFEFEF;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background: #F6F7F7;
        }

        td {
          height: 63px;
          white-space: nowrap;
          padding: 0.5rem 1rem;
          border-bottom: 1px solid #EFEFEF;

          &.abstract {
            background: #F9F9F9;

            .wrapper {
              display: flex;

              .content {
                display: flex;

                p {
                  font-weight: 500;

                  &.label {
                    margin-right: 0.5rem;
                  }
                }

                &:last-child {
                  margin-left: auto;

                  p {
                    &.label {
                      font-weight: 300;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .empty-data-container {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 520px) {
    table {
      thead {
        tr {
          th {
            font-size: 12px;
          }
        }
      }

      tbody {
        tr {
          font-size: 12px;

          td {
            .wrapper {
              .content {
                p {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>