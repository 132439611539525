import { instance, imPayInstance } from '../../config/axios';
import store from '../index';

function initialState() {
  return {
    accreditations: []
  };
}

const actions = {
  getAllAccreditations({commit}) {
    return new Promise((resolve, reject) => {
      imPayInstance().get('/sellers/accreditations')
        .then(res => {
          const resData = res.data;

          commit('setAccreditations', resData.data);

          resolve(resData);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  createSellerByAccreditation(_, accreditationId) {
    return new Promise((resolve, reject) => {
      imPayInstance().post(`/sellers/accreditations/${accreditationId}/sync`)
        .then(res => {
          const resData = res.data;

          store.commit('setCurrentSellerId', resData.data.id);

          resolve(resData);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  createSellerSync(_, sellerId) {
    return new Promise((resolve, reject) => {
      instance().post(`/sellers/${sellerId}/sync`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
};

const getters = {
  getAccreditations(state) {
    return state.accreditations;
  }
};

const mutations = {
  setAccreditations(state, payload) {
    state.accreditations = payload;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations
};