<template>
  <div class="image-view-container" :class="{ 'show-image': showImage, 'has-modal': showCookieConsentModal }">
    <slot name="alert"/>

    <cookie-consent-modal
      v-if="showCookieConsentModal"
      @click="closeCookieConsetModal"
      @close="closeCookieConsetModal"
    />

    <div v-if="showImage" class="image-container">
      <img :src="image">
    </div>

    <div class="form-container">
      <div class="form-content">
        <div class="logo-container">
          <img class="logo" :src="$service.getPlatformImagesPath('logo-full.svg')">
        </div>

        <slot name="form" />

        <slot name="options" />
      </div>
    </div>

    <div v-if="hasCokieConsent && showCookieConsent" class="cookie-consent-container">
      <div class="cookie-consent-content">
        <div class="icon-container">
          <i class="icon-close" @click="showCookieConsent = false" />
        </div>

        <p>
          Utilizamos cookies para melhorar sua experiência. Ao continuar navegando, você concorda com nossa <a @click="showCookieConsetModal">Política de Cookies</a>.
        </p>
      </div>
    </div>

    <floating-button-component
      icon="icon-wpp"
      bgColor="#25d366"
      @click="redirectToWpp"
    />
  </div>
</template>

<script>
  import CookieConsentModal from '../Modal/CookieConsentModal';
  import FloatingButton from '@/components/Base/FloatingButton';

  export default {
    name: 'ImageView',

    data() {
      return {
        showCookieConsent: true,
        showCookieConsentModal: false
      };
    },

    components: {
      'cookie-consent-modal': CookieConsentModal,
      'floating-button-component': FloatingButton
    },

    props: {
      image: {
        type: String,
        default: '@/assets/images/image-login.png'
      },

      showImage: {
        type: Boolean,
        default: true
      },

      hasCokieConsent: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      showCookieConsetModal() {
        this.showCookieConsentModal = true;
      },

      closeCookieConsetModal() {
        this.showCookieConsentModal = false;
      },

      redirectToWpp() {
        const text = `Olá, estou tendo dúvidas sobre o ${this.$service.getPlatformTitle()}. Podem me ajudar?`;
        this.$service.openWpp(text);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .image-view-container {
    height: 100vh;

    &.has-modal {
      overflow: hidden;
      position: fixed;
      width: 100vw;
      min-width: 320px;
    }

    &.show-image {
      grid-template-columns: 65% 35%;
      display: grid;
      display: flex;

      .image-container {
        background: linear-gradient(305.95deg, $first-color 0%, $first-color-dark-3 99.04%);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: 60rem;
        }
      }
    }

    &.has-modal > *:not(.modal) {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    .form-container {
      padding: 1.5rem;
      background: $white-color;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
      height: 100%;

      .form-content {
        flex-direction: column;
        align-items: center;
        display: flex;
        width: 100%;

        .logo-container {
          margin-bottom: 2rem;

          .logo {
            width: 250px;
            height: 130px;
          }
        }
      }
    }

    .cookie-consent-container {
      max-width: 1024px;
      position: fixed;
      width: 100%;
      left: 0px;
      right: 0px;
      bottom: 1.5rem;
      margin: 0px auto;
      padding: 0 1.5rem;

      .cookie-consent-content {
        background: $white-color;
        padding: 1.5rem;
        box-shadow: rgb(235, 235, 235) 0px 3px 7px;
        width: 100%;
        text-align: center;
        border-radius: 12px;

        .icon-container {
          display: flex;

          i {
            color: #333;
            margin-left: auto;
            font-size: 10px;
            cursor: pointer;
          }
        }

        p {
          color: #333;

          a {
            cursor: pointer;
            margin-left: auto;
            color: #0052CC;
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .image-view-container {
      &.show-image {
        .image-container {
          img {
            width: 55rem;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .image-view-container {
      &.show-image {
        grid-template-columns: 1fr 100%;
      }

      .form-container {
        border-top: 10px solid $first-color;

        .form-content {
          .form {
            width: 60%;
          }

          .options {
            width: 60%;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .image-view-container {
      .form-container {
        .form-content {
          .form {
            width: 100%;
          }

          .options {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .image-view-container {
      .form-container {
        display: block;

        .form-content {
          .logo-container {
            margin-top: 0.5rem;

            .logo {
              width: 200px;
              height: 100px;
            }
          }
        }
      }

      .cookie-consent-container {
        .cookie-consent-content  {
          padding: 1rem;

          p {
            font-size: 12px;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
</style>