<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="inputName"
    :rules="rules"
    class="input-number-container"
    v-slot="{ errors }"
  >
    <div>
      <div class="input-wrapper">
        <i class="icon-remove" @click="remove()" />

        <input
          type="number"
          placeholder="0"
          min="1"
          autocomplete="off"
          :class="{ 'error': !!errors[0], 'disabled': isDisabled }"
          v-model="innerValue"
        />

        <i class="icon-add" @click="add()" />
      </div>

      <div v-if="!isDisabled" class="errors">
        <span>{{ errors[0] }}</span>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'InputNumberField',

    props: {
      vid: {
        type: String,
        default: undefined
      },

      isDisabled: {
        type: Boolean,
        default: false,
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      value: {
        type: Number,
        default: 0,
      },

      maxLength: {
        type: Number,
        required: false,
      },

      name: {
        type: String,
        required: false
      },

      max:  {
        type: Number,
        default: 999
      },

      min: {
        type: Number,
        default: 1
      }
    },

    components: {
      ValidationProvider,
    },

    data() {
      return {
        innerValue: 0,
        inputName: 'quantidade',
      };
    },

    created() {
      this.innerValue = this.value;
    },

    watch: {
      value(value) {
        this.innerValue  = value;
      },

      innerValue(value) {
        if (value < this.min) {
          this.innerValue = this.min;
        }

        if (value > this.max) {
          this.innerValue = this.max;
        }

        this.$emit('input', value);
      },
    },

    methods: {
      add() {
        if (!this.isDisabled && this.innerValue < this.max) {
          this.innerValue++;
        }
      },

      remove() {
        if (!this.isDisabled && this.innerValue > this.min) {
          this.innerValue--;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.input-number-container {
  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .icon-add,
    .icon-remove {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 0.5rem;
      color: #979797;
      cursor: pointer;
    }

    .icon-add {
      right: 0;
      margin-right: 1rem;
    }

    .icon-remove {
      left: 0;
      margin-left: 1rem;
    }

    input {
      border: 1px solid #DADADA;
      border-radius: 6px;
      padding: 0.3rem 1rem;
      text-align: center;
      color: #333;
      cursor: pointer;
      height: 48px;
      min-width: 85px;
      width: 100%;
      caret-color: $second-color;

      &.error {
        border-color: $red-color;
        box-shadow: 0 0 0 1px $red-color, 0 0 0 3px $red-color-light;
      }

      -moz-appearance: textfield;
      appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .errors {
      span {
        color: $red-color;
        font-size: 12px;
        margin-top: 0.5rem;
        font-weight: 400;
      }
    }
  }
}
</style>