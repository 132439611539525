<template>
  <modal
    ref="modal"
    class="expense-modal"
    :title="`${isUpdate ? 'Baixar' : 'Nova'} despesa`"
    :showModal="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <ValidationObserver
        v-slot="{ passes }"
        ref="form"
      >
        <form v-if="!hasSupplierRequest" id="form" @submit.prevent="passes(submit)">
          <div class="body" :class="{ full: !isUpdate }">
            <div class="section">
              <div class="fields">
                <div v-if="!isUpdate" class="field">
                  <input-field-component
                    class="input-field-component"
                    placeholder="Descrição da despesa"
                    rules="required"
                    v-model="data.description"
                  />
                </div>

                <div v-if="!isUpdate" class="field">
                  <select-fetch-field
                    class="input-field-component large"
                    name="supplierSelect"
                    placeholder="Selecione o fornecedor"
                    rules="required"
                    :options="suppliers"
                    :hasSearch="true"
                    :hasFetch="true"
                    :hasRequest="hasSupplierRequest"
                    @fetch="findSupplier"
                    v-model="data.supplierId"
                  />
                </div>

                <div class="field">
                  <select-field
                    class="input-field-component medium"
                    placeholder="Tipo pagamento"
                    :options="paymentType"
                    :rules="!isUpdate ? 'required' : ''"
                    v-model="data.paymentType"
                  />

                  <input-currency-field
                    v-if="!isUpdate"
                    class="input-field-component"
                    placeholder="Valor da despesa"
                    type="money"
                    rules="required"
                    v-model="data.amount"
                  />
                </div>

                <div v-if="!isUpdate" class="field">
                  <input-date-field
                    class="input-field-component"
                    type="date"
                    rules="required"
                    :maxDate="new Date()"
                    placeholder="Data emissão"
                    v-model="data.issuanceDate"
                  />

                  <input-date-field
                    class="input-field-component"
                    type="date"
                    rules="required"
                    :minDate="new Date()"
                    placeholder="Data vencimento"
                    v-model="data.dueDate"
                  />
                </div>
              </div>
            </div>

            <divider-component v-if="!isUpdate" class="divider" />

            <div v-if="!isUpdate" class="section">
              <h3>Cobranças adicionais</h3>

              <div class="fields">
                <div class="field">
                  <input-currency-field
                    class="input-field-component"
                    placeholder="Juros"
                    type="money"
                    v-model="data.dailyInterestAmount"
                  />

                  <input-currency-field
                    class="input-field-component"
                    placeholder="Multa"
                    type="money"
                    v-model="data.lateFeeAmount"
                  />
                </div>
              </div>
            </div>

            <divider-component class="divider" />

            <div class="section">
              <h3>Pagamento</h3>

              <div class="fields">
                <div class="field">
                  <input-currency-field
                    class="input-field-component"
                    placeholder="Valor pago"
                    type="money"
                    :minDate="new Date()"
                    :rules="isUpdate ? 'required' : ''"
                    v-model="data.paidAmount"
                  />

                  <input-date-field
                    class="input-field-component"
                    placeholder="Data pagamento"
                    type="date"
                    :minDate="data.issuanceDate"
                    :rules="isUpdate || data.paidAmount > 0 ? 'required' : ''"
                    v-model="data.paidAt"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <div v-else class="body full has-request">
          <spinner-component color="#DADADA" />
        </div>
      </ValidationObserver>
    </template>

    <template v-slot:footer>
      <div class="footer-slot-container">
        <div class="button-container">
          <button-component
            class="button-component"
            styles="ghost"
            text="Fechar"
            :isDisabled="hasRequest || hasSupplierRequest"
            @click.native="closeModal"
            v-ripple="$theme.secondColorDarkTransparent"
          />

          <button-component
            class="button-component"
            text="Salvar"
            form="form"
            :hasLoader="hasRequest"
            :isDisabled="hasRequest || hasSupplierRequest"
            v-ripple="$theme.whiteColorTransparent"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import Modal from '@/components/Modal/Base';
  import Divider from '@/components/Base/Divider';
  import InputField from '@/components/Base/InputField';
  import InputCurrencyField from '@/components/Base/InputCurrencyField';
  import Button from '@/components/Base/Button';
  import SelectFetchField from '@/components/SelectFetchField';
  import SelectField from '@/components/Base/SelectField';
  import Spinner from '@/components/Base/Spinner';
  import InputDateField from '@/components/Base/InputDateField';

  export default {
    name: 'ExpenseModal',

    data() {
      return {
        hasRequest: false,

        hasSupplierRequest: false,

        suppliers: [],

        isUpdate: false,

        paymentType: [
          {
            label: 'Dinheiro',
            value: 'money',
          },

          {
            label: 'Boleto',
            value: 'bank_slip',
          },

          {
            label: 'Débito',
            value: 'debit',
          },

          {
            label: 'Pix',
            value: 'pix',
          },

          {
            label: 'Crédito',
            value: 'credit',
          }
        ],

        data: {
          description: '',
          supplierId: null,
          amount: '',
          paidAmount: null,
          paymentType: null,
          dueDate: '',
          issuanceDate: '',
          dailyInterestAmount: null,
          lateFeeAmount: null,
          paidAt: '',
        }
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'input-field-component': InputField,
      'input-currency-field': InputCurrencyField,
      'button-component': Button,
      'divider-component': Divider,
      'input-date-field': InputDateField,
      'select-fetch-field': SelectFetchField,
      'select-field': SelectField,
      'spinner-component': Spinner
    },

    props: {
      expenseId: {
        type: String,
        required: false
      },
    },

    mounted() {
      this.isUpdate = !!this.expenseId;

      if (!this.isUpdate) {
        this.getAllSuppliers();
      }
    },

    methods: {
      async submit() {
        this.hasRequest = true;

        if (this.isUpdate) {
          await this.updateExpense();
        } else {
          await this.createExpense();
        }

        this.closeModal();

        this.hasRequest = false;
      },

      async createExpense() {
        const data = {
          description: this.data.description,
          supplier_id: this.data.supplierId,
          amount: this.data.amount,
          paid_amount: this.data.paidAmount,
          payment_type: this.data.paymentType,
          due_date: this.data.dueDate,
          issuance_date: this.data.issuanceDate,
          paid_at: this.data.paidAt,
          create_appointment: true,
          daily_interest_amount: this.data.dailyInterestAmount,
          late_fee_amount: this.data.lateFeeAmount
        };

        await this.$store.dispatch('expenseModule/postExpense', JSON.stringify(data))
          .then(() => {
            this.snackbarNotification('Despesa cadastrado com sucesso', '#08B978');

            this.$emit('finishRequest');
          })
          .catch(() => {
            this.snackbarNotification('Erro ao cadastrar despesa', '#FF5A5A');
          });
      },

      async updateExpense() {
        const data = {
          paid_amount: this.data.paidAmount,
          payment_type: this.data.paymentType,
          paid_at: this.data.paidAt,
        };

        await this.$store.dispatch('expenseModule/patchExpense', { id: this.expenseId, data })
          .then(() => {
            this.snackbarNotification('Despesa baixada com sucesso', '#08B978');

            this.$emit('finishRequest');
          })
          .catch(() => {
            this.snackbarNotification('Erro ao baixar despesa', '#FF5A5A');
          });
      },

      async findSupplier(search) {
        await this.$store.dispatch('supplierModule/getAllSuppliersFind', search)
          .then(res => {
            this.suppliers = this.formatSuppliers(res.items);
          });
      },

      async getAllSuppliers() {
        this.hasSupplierRequest = true;

        const queryParams = [
          {
            query: 'limit',
            param: '500'
          },
        ];

        await this.$store.dispatch('supplierModule/getAllSuppliers', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.suppliers = this.formatSuppliers(res.items);
          })
          .catch(() => {
            this.snackbarNotification('Erro ao carregar fornecedores', '#FF5A5A');

            this.closeModal();
          });

        this.hasSupplierRequest = false;
      },

      formatSuppliers(items) {
        return items.map(item => ({
          isActive: false,
          label: `${item.name} (${this.$service.formatTaxPayerOrEin(item.tin)})`,
          value: item.id
        }));
      },

      setFinishRequest() {
        this.$emit('finishRequest');
      },

      closeModal() {
        this.$emit('close');
      },

      snackbarNotification(text, color) {
        this.$emit('snackbarNotification', text, color);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .expense-modal {
    .body {
      margin-top: 1.5rem;
      padding: 0 1.5rem;
      width: 560px;

      &.full {
        height: 500px;
        overflow-y: auto;
      }

      &.has-request {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }

      .section {
        padding: 0.5rem;

        .fields {
          margin-top: 1rem;

          .field {
            display: flex;
            gap: 0.5rem;

            .input-field-component {
              width: 100%;

              &.medium {
                max-width: 250px;
              }

              &.small {
                max-width: 175px;
              }
            }
          }
        }
      }
    }

    .footer-slot-container {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);

      .button-container {
        display: flex;

        .button-component {
          width: 100px;
          height: 48px;
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .expense-modal {
      .body {
        width: 100%;
      }
    }
  }

  @media (max-width: 520px) {
    .expense-modal {
      form {
        .body {
          .fields {
            .field {
              flex-direction: column;
              gap: unset !important;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>