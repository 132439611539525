<template>
  <div class="header-container">
    <h2>{{ data.title }}</h2>

    <router-link
      v-if="!!data.to"
      class="header-button-container"
      :to="data.to"
    >
      <button-component
        v-if="hasButton()"
        v-ripple="$theme.secondColorDarkTransparent"
        styles="secondary"
        :iconLeft="data.button.icon"
        :text="data.button.text"
      />
    </router-link>
  </div>
</template>

<script>
  import Button from '@/components/Base/Button';

  export default {
    name: 'CustomHeader',

    props: {
      data: {
        type: Object,
        default() {
          return {
            title: 'Title',
            to: '/',
            button: {
              icon: 'icon-arrow-left',
              text: 'Text'
            }
          };
        }
      }
    },

    components: {
      'button-component': Button,
    },

    methods: {
      hasButton() {
        return !!this.data.button;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .header-container {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    h2 {
      color: #333;
    }

    .header-button-container {
      margin-left: auto;
      font-weight: 500;
      color: $second-color;
      font-size: 16px;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    .header-container {
      margin-bottom: 2rem;

      h2 {
        font-size: 16px;
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 480px) {
    .header-container {
      margin-bottom: 1rem;
    }
  }
</style>