<template>
  <div class="account-view-container">
    <default-view-component :hideForceResetPassword="true" :hasModal="hasModalIndividualEdit || hasModalCompanyEdit || hasModalOwnerEdit || hasModalAddressEdit">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <edit-individual-modal-component
          v-if="sellerType == 'Individual' && hasModalIndividualEdit"
          @snackbarNotification="showSnackBar"
        />

        <edit-company-modal-component
          v-if="sellerType == 'Company' && hasModalCompanyEdit"
          @snackbarNotification="showSnackBar"
        />

        <edit-owner-component
          v-if="sellerType == 'Company' && hasModalOwnerEdit"
          @snackbarNotification="showSnackBar"
        />

        <edit-address-modal-component
          v-if="hasModalAddressEdit"
          @snackbarNotification="showSnackBar"
        />
      </template>

      <template v-slot:content>
        <div class="section-container">
          <div class="content">
            <header-component
              class="header"
              :data="header"
            />

            <div class="center">
              <div class="section">
                <navigation-informations-component
                  :styles="navigation"
                />

                <div class="line"></div>

                <div class="content">
                  <sort-data-component
                    v-if="currentSection == 'data'"
                    :data="formattedSellerData"
                  />

                  <sort-data-component
                    v-if="currentSection == 'address'"
                    :data="formattedAddressData"
                  />

                  <sort-data-component
                    v-if="currentSection == 'owners'"
                    :data="formattedOwnersData"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import Header from '@/components/Layout/Header';
  import DefaultView from '@/components/Layout/DefaultView';
  import NavigationInformations from '@/components/AccountInformationsNavigation';
  import AccountSortData from '@/components/AccountSortData';
  import EditIndividualModal from '@/components/Seller/Individual/EditIndividualModal';
  import EditCompanyModal from '@/components/Seller/Company/EditCompanyModal';
  import EditAddressModal from '@/components/Seller/EditAddressModal';
  import EditOwnerModal from '@/components/Seller/Company/EditOwnerModal';
  import SnackBar from '@/components/Base/SnackBar';

  export default {
    name: 'AccountInformationView',

    data() {
      return {
        currentSection: '',
        hasRequest: false,
        hasNotification: false,
        sellerType: '',
        header: {
          title: 'Informações da conta',
          to: '/account',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        formattedSellerData: [],
        formattedAddressData: [],
        formattedOwnersData: [],

        navigation: [
          {
            isEnable: true,
            to: '/account/informations/data',
            param: 'data',
            title: 'Dados'
          },

          {
            isEnable: true,
            to: '/account/informations/address',
            param: 'address',
            title: 'Endereço'
          },

          {
            isEnable: false,
            to: '/account/informations/owners',
            param: 'owners',
            title: 'Dados dos sócios'
          },
        ]
      };
    },

    components: {
      'header-component': Header,
      'navigation-informations-component': NavigationInformations,
      'default-view-component': DefaultView,
      'edit-individual-modal-component': EditIndividualModal,
      'edit-company-modal-component': EditCompanyModal,
      'edit-address-modal-component': EditAddressModal,
      'edit-owner-component': EditOwnerModal,
      'sort-data-component': AccountSortData,
      'snackbar-component': SnackBar,
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerStatus'
      ]),

      ...mapState({
        mcc: state => state.mcc,
        sellerData: state => state.sellerData,
        currentSellerId: state => state.currentSellerId,
        hasModalIndividualEdit: state => state.hasModalIndividualEdit,
        hasModalAddressEdit: state => state.hasModalAddressEdit,
        hasModalCompanyEdit: state => state.hasModalCompanyEdit,
        hasModalOwnerEdit: state => state.hasModalOwnerEdit,
        hasModalOwnerAddressEdit: state => state.hasModalOwnerAddressEdit,
      })
    },

    created() {
      this.setDefaultData();

      this.$store.commit('closeModalEdit');

      this.fetchCurrentSellerData();
    },

    mounted() {
      this.currentSection = this.$route.params.section;
    },

    watch: {
      $route(to) {
        this.currentSection = to.params.section;
      },

      currentSellerId() {
        this.setDefaultData();
      },

      sellerData() {
        this.sellerType = this.sellerData.type;

        if (this.sellerType == 'Company') {
          this.navigation[2].isEnable = true;

          this.formattedSellerData = [
            {
              sectionTitle: 'Dados do negócio',
              mutation: 'setToggleModalCompanyEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'CNPJ',
                  text: this.$service.formatEin(this.sellerData.ein)
                },

                {
                  title: 'Razão social',
                  text: this.sellerData.business_name
                },

                {
                  title: 'Categoria do negócio',
                  text: this.getMccDescription(this.sellerData.mcc)
                },

                {
                  title: 'Nome fantasia',
                  text: this.sellerData.statement_descriptor
                },

                {
                  title: 'E-mail empresarial',
                  text: this.sellerData.business_email
                },
              ],

              columnRight: [
                {
                  title: 'Data de constituição',
                  text: this.$service.dateFormat(this.sellerData.business_opening_date, 'YYYY-MM-DD', 'DD/MM/YYYY')
                },

                {
                  title: 'Formato',
                  text: '-'
                },

                {
                  title: 'CNAE',
                  text: '-'
                },

                {
                  title: 'Receita méd. mensal',
                  text: '-'
                },

                {
                  title: 'Telefone empresarial',
                  text: this.$service.formatPhone(this.sellerData.business_phone)
                }
              ]
            }
          ];

          this.formattedAddressData = [
            {
              sectionTitle: 'Endereço do negócio',
              mutation: 'setToggleModalAddressEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'CEP',
                  text: this.$service.formatPostalCode(this.sellerData.business_address_postal_code)
                },

                {
                  title: 'Rua',
                  text: this.sellerData.business_address_line1
                },

                {
                  title: 'Número',
                  text: this.sellerData.business_address_line2
                },

                {
                  title: 'Complemento',
                  text: this.sellerData.business_address_line3
                }
              ],

              columnRight: [
                {
                  title: 'Bairro',
                  text: this.sellerData.business_address_neighborhood
                },

                {
                  title: 'Cidade',
                  text: this.sellerData.business_address_city
                },

                {
                  title: 'Estado',
                  text: this.sellerData.business_address_state
                }
              ]
            }
          ],

          this.formattedOwnersData = [
            {
              sectionTitle: 'Dados do sócio',
              mutation: 'setToggleModalOwnerEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'Nome completo',
                  text: this.sellerData.owner_first_name + ' ' + this.sellerData.owner_last_name
                },

                {
                  title: 'CPF',
                  text: this.$service.formatTaxpayerId(this.sellerData.owner_taxpayer_id)
                },

                {
                  title: 'Data de nascimento',
                  text: this.$service.dateFormat(this.sellerData.owner_birthdate, 'YYYY-MM-DD', 'DD/MM/YYYY')
                }
              ],

              columnRight: [
                {
                  title: 'Email',
                  text: this.sellerData.owner_email
                },

                {
                  title: 'Telefone',
                  text: this.$service.formatPhone(this.sellerData.owner_phone_number)
                }
              ]
            },

            {
              sectionTitle: 'Endereço',
              mutation: 'setToggleModalAddressEdit',
              isOwnerAddress: true,
              isEditable: true,
              columnLeft: [
                {
                  title: 'CEP',
                  text: this.$service.formatPostalCode(this.sellerData.owner_address_postal_code)
                },

                {
                  title: 'Rua',
                  text: this.sellerData.owner_address_line1
                },

                {
                  title: 'Número',
                  text: this.sellerData.owner_address_line2
                },

                {
                  title: 'Complemento',
                  text: this.sellerData.owner_address_line3
                }
              ],

              columnRight: [
                {
                  title: 'Bairro',
                  text: this.sellerData.owner_address_neighborhood
                },

                {
                  title: 'Cidade',
                  text: this.sellerData.owner_address_city
                },

                {
                  title: 'Estado',
                  text: this.sellerData.owner_address_state
                }
              ]
            },
          ];
        } else if (this.sellerType == 'Individual') {
          this.navigation[2].isEnable = false;

          if (this.$route.params.section == 'owners') {
            this.$router.push('/account/informations/data');
          }

          this.formattedSellerData = [
            {
              sectionTitle: 'Dados',
              mutation: 'setToggleModalIndividualEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'CPF',
                  text: this.$service.formatTaxpayerId(this.sellerData.taxpayer_id)
                },

                {
                  title: 'Nome',
                  text: this.sellerData.first_name + ' ' + this.sellerData.last_name
                },

                {
                  title: 'Categoria do negócio',
                  text: this.getMccDescription(this.sellerData.mcc)
                },

                {
                  title: 'E-mail',
                  text: this.sellerData.email
                }
              ],

              columnRight: [
                {
                  title: 'Data de nascimento',
                  text: this.$service.dateFormat(this.sellerData.birthdate, 'YYYY-MM-DD', 'DD/MM/YYYY')
                },

                {
                  title: 'Nome fantasia',
                  text: this.sellerData.statement_descriptor
                },

                {
                  title: 'Receita méd. mensal',
                  text: '-'
                },

                {
                  title: 'Telefone',
                  text: this.$service.formatPhone(this.sellerData.phone_number)
                }
              ]
            }
          ],

          this.formattedAddressData = [
            {
              sectionTitle: 'Endereço',
              mutation: 'setToggleModalAddressEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'CEP',
                  text: this.$service.formatPostalCode(this.sellerData.address_postal_code)
                },

                {
                  title: 'Rua',
                  text: this.sellerData.address_line1
                },

                {
                  title: 'Número',
                  text: this.sellerData.address_line2
                },

                {
                  title: 'Complemento',
                  text: this.sellerData.address_line3
                }
              ],

              columnRight: [
                {
                  title: 'Bairro',
                  text: this.sellerData.address_neighborhood
                },

                {
                  title: 'Cidade',
                  text: this.sellerData.address_city
                },

                {
                  title: 'Estado',
                  text: this.sellerData.address_state
                }
              ]
            }
          ];
        }

        this.navigation[0].title = this.formattedSellerData[0].sectionTitle;
        this.navigation[1].title = this.formattedAddressData[0].sectionTitle;
      }
    },

    methods: {
      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },

      setDefaultData() {
        this.defaultSellerData();
        this.defaultAddressData();
        this.defaultOwnersData();
      },

      fetchCurrentSellerData() {
        this.$store.dispatch('getSellerId');
      },

      defaultSellerData() {
        this.formattedSellerData = [
          {
            sectionTitle: 'Dados',
            isEditable: false,
            columnLeft: [
              {
                title: 'CNPJ',
                text: 'default'
              },

              {
                title: 'Razão social',
                text: 'default'
              },

              {
                title: 'Categoria do negócio',
                text: 'default'
              },


              {
                title: 'Nome fantasia',
                text: 'default'
              },

              {
                title: 'E-mail empresarial',
                text: 'default'
              },
            ],

            columnRight: [
              {
                title: 'Data de constituição',
                text: 'default'
              },

              {
                title: 'Formato',
                text: 'default'
              },

              {
                title: 'CNAE',
                text: 'default'
              },

              {
                title: 'Receita méd. mensal',
                text: 'default'
              },

              {
                title: 'Telefone empresarial',
                text: 'default'
              }
            ]
          }
        ];
      },

      defaultAddressData() {
        this.formattedAddressData = [
          {
            sectionTitle: 'Endereço',
            isEditable: false,
            columnLeft: [
              {
                title: 'CEP',
                text: 'default'
              },

              {
                title: 'Rua',
                text: 'default'
              },

              {
                title: 'Número',
                text: 'default'
              },

              {
                title: 'Complemento',
                text: 'default'
              }
            ],

            columnRight: [
              {
                title: 'Bairro',
                text: 'default'
              },

              {
                title: 'Cidade',
                text: 'default'
              },

              {
                title: 'Estado',
                text: 'default'
              }
            ]
          }
        ];
      },

      defaultOwnersData() {
        this.formattedOwnersData = [
          {
            sectionTitle: 'Dados dos sócios',
            isEditable: false,
            columnLeft: [
              {
                title: 'Nome completo',
                text: 'default'
              },

              {
                title: 'CPF',
                text: 'default'
              },

              {
                title: 'Email',
                text: 'default'
              }
            ],

            columnRight: [
              {
                title: 'Data de nascimento',
                text: 'default'
              },

              {
                title: 'Telefone',
                text: 'default'
              }
            ]
          },

          {
            sectionTitle: 'Endereço',
            isEditable: false,
            columnLeft: [
              {
                title: 'CEP',
                text: 'default'
              },

              {
                title: 'Rua',
                text: 'default'
              },

              {
                title: 'Cidade',
                text: 'default'
              }
            ],

            columnRight: [
              {
                title: 'Número',
                text: 'default'
              },

              {
                title: 'Bairro',
                text: 'default'
              },

              {
                title: 'Estado',
                text: 'default'
              }
            ]
          }
        ];
      },

      getMccDescription(id) {
        return this.mcc.find(item => item.id === id)?.description;
      },

      hasModal() {
        return this.hasModalIndividualEdit || this.hasModalAddressEdit || this.hasModalCompanyEdit || this.hasModalOwnerEdit;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .account-view-container {
    .content {
      padding: 1.5rem;

      .center {
        display: flex;
        justify-content: center;

        .section {
          display: flex;

          .line {
            width: 1px;
            background: #EFEFEF;
            margin-left: 2rem;
            margin-right: 2rem;
          }

          .content {
            padding: 0;
            width: 700px;

            .header {
              margin-top: 1rem;
              margin-bottom: 2rem;

              p {
                font-size: 16px;
                font-weight: 600;
                color: #333;
              }

              .button {
                margin-left: auto;
                color: #8E8E8E;
                font-weight: 600;

                i {
                  margin-right: 0.5rem;
                  color: #0052CC;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .account-view-container {
      .content {
        .center {
          .section {
            .content {
              width: 500px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .account-view-container {
      .content {
        .center {
          .section {
            width: 100%;

            .content {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 760px) {
    .account-view-container {
      .content {
        .center {
          display: block;

          .section {
            flex-direction: column;

            .line {
              display: none;
            }
          }
        }
      }
    }
  }
</style>