import axios from 'axios';
import router from '@/router';
import store from '@/store';
import * as Sentry from '@sentry/vue';
import { encryptStorage } from '../config/encryptStorage';

function handleResponseError(error) {
  if (error.response && error.response.status === 401) {
    encryptStorage.removeItem('token');
    store.dispatch('logout');

    const currentPath = router.currentRoute.path;
    if (currentPath !== '/') {
      store.commit('authModule/setRedirectPath', currentPath);
    }

    router.push('/').catch(() => {});
  } else {
    Sentry.setContext('extra', {
      'uri': error.response.config.url,
      'full_response': JSON.stringify(error.response.data)
    });

    Sentry.captureException(error);
  }
}

export const instance = () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${encryptStorage.getItem('token')}`
    }
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      handleResponseError(error);
      return Promise.reject(error);
    }
  );

  return instance;
};

export const imPayInstance = () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_IMPAY_API_URL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      'X-Access-Token': encryptStorage.getItem('token')
    }
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      handleResponseError(error);
      return Promise.reject(error);
    }
  );

  return instance;
};

export default { instance, imPayInstance };