<template>
  <div class="sidebar-container">
    <div
      v-if="!sidebar.hideSideBar"
      class="sidebar"
    >
      <div class="header">
        <router-link to="/dashboard" class="logo-container">
          <img :src="$service.getPlatformImagesPath('logo-light.svg')">
        </router-link>

        <div class="icon-container" @click="toggleSideBarStatus">
          <i
            v-if="sidebar.disableToggleSidebar"
            class="icon-sidebar-toggle"
          />

          <i v-else class="icon-sidebar-close"/>
        </div>
      </div>

      <div class="options">
        <div v-for="(item, index) in sidebar.sections" :key="index" class="option">
          <div v-if="!item.items">
            <div
              class="item-one"
              :class="{ 'active': currentRoutePath == item.path }"
              @click="toView(item.path)"
            >
              <i :class="item.icon"/>

              <div class="text">
                {{ item.label }}
              </div>
            </div>
          </div>

          <div v-else class="option" :class="{ 'expanded': item.state }">
            <div class="item" :class="{ 'retracted': !item.state }" @click="toggleSectionState(index)">
              <i :class="item.icon"/>

              {{ item.label }}

              <div class="icon-container">
                <i v-if="item.state" class="icon-keyboard-arrow-up"/>
                <i v-else class="icon-keyboard-arrow-down"/>
              </div>
            </div>

            <div v-if="item.state" class="items">
              <div
                v-for="(row, rowIndex) in item.items"
                :key="rowIndex"
                @click="toView(row.path)"
              >
                <div v-if="row.isShow || $service.isUndefined(row.isShow)" class="item" :class="{ 'active': currentRoutePath == row.path }">
                  <i :class="row.icon"/>

                  {{ row.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="sidebar-disable">
      <div class="header">
        <i v-if="sidebar.disableToggleSidebar" class="icon-sidebar-toggle"/>
        <i
          v-else class="icon-sidebar-open"
          @click="toggleSideBarStatus"
        />
      </div>

      <div class="options">
        <div v-for="(item, index) in sidebar.sections" :key="index" class="option">
          <div
            v-if="!item.items"
            class="icon-container"
            :class="{ 'active': currentRoutePath == item.path }"
            @click="toView(item.path)"
          >
            <i :class="item.icon"/>
          </div>

          <div v-else class="option">
            <div
              class="icon-container"
              :class="{ 'active': item.hasActive, 'select': item.openSection }"
              @click="toggleSectionStatus(index)"
            >
              <i :class="item.icon"/>
            </div>

            <div class="items-container">
              <div v-if="item.openSection" class="items">
                <div class="item" @click="toggleSectionState(index)">
                  {{ item.label }}

                  <div class="icon-container">
                    <i v-if="item.state" class="icon-keyboard-arrow-up"/>
                    <i v-else class="icon-keyboard-arrow-down"/>
                  </div>
                </div>

                <div v-if="item.state" class="expanded" v-click-outside="toggleSectionStatus">
                  <div
                    v-for="(row, rowIndex) in item.items"
                    :key="rowIndex"
                    class="item"
                    :class="{ 'active': currentRoutePath == row.path }"
                    @click="toView(row.path)"
                  >
                    <i :class="row.icon"/>

                    {{ row.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'SideBar',

    data() {
      return {
        currentRoutePath: '',
        windowWidth: window.innerWidth,
      };
    },

    computed: {
      ...mapState({
        sidebar: state => state.sidebar
      }),
    },

    watch: {
      windowWidth() {
        this.sideBarActions(this.windowWidth);
      }
    },

    mounted() {
      this.currentRoutePath = this.$route.path;

      this.toView(this.currentRoutePath);
      this.hideSidebarOnPhone();

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    },

    methods: {
      toggleSideBarStatus() {
        if (!this.sidebar.disableToggleSidebar) this.sidebar.hideSideBar = !this.sidebar.hideSideBar;

        if (this.sidebar.hideSideBar) {
          this.sidebar.sections.forEach(item => {
            item.openSection = false;
          });
        }
      },

      toggleSectionState(currentIndex) {
        let currentItem = this.sidebar.sections[currentIndex];
        currentItem.state = !currentItem.state;
      },

      toggleSectionStatus(currentIndex) {
        if (Number.isInteger(currentIndex)) {
          let currentItem = this.sidebar.sections[currentIndex];

          currentItem.openSection = true;

          this.sidebar.sections.forEach((item, index) => {
            if (currentIndex != index) {
              item.openSection = false;
            }
          });
        } else {
          this.sidebar.sections.forEach(item => {
            item.openSection = false;
          });
        }
      },

      toView(path) {
        if (!this.$service.isBlank(path)) {
          let currentColumnIndex = null;
          this.$router.push(path).catch(() => {});

          this.sidebar.sections.forEach((column, columnIndex) => {
            column.openSection = false;
            column.hasActive = false;

            if (!column.items) {
              if (column.path === path) {
                currentColumnIndex = columnIndex;
              }
            } else {
              column.items.forEach(row => {
                if (row.path === path) {
                  currentColumnIndex = columnIndex;
                }
              });
            }
          });

          if (currentColumnIndex != null) {
            this.sidebar.sections[currentColumnIndex].hasActive = true;
          }
        }
      },

      onResize() {
        this.windowWidth = window.innerWidth;
      },

      sideBarActions(width) {
        if (width <= 835) {
          this.sidebar.hideSideBar = true;

          if (width >= 480) {
            this.sidebar.disableToggleSidebar = true;
          } else {
            this.sidebar.disableToggleSidebar = false;
          }
        } else {
          this.sidebar.disableToggleSidebar = false;
        }
      },

      hideSidebarOnPhone() {
        if (this.windowWidth <= 480) {
          this.sidebar.hideSideBar = true;
        }
      }
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }
  };
</script>

<style lang="scss" scoped>
  .sidebar-container {
    z-index: 1000;
    background: $first-color;

    .sidebar {
      width: 240px;
      position: relative;
      user-select: none;
      font-size: 14px;
      min-height: 100vh;
      white-space: nowrap;
      background: $first-color;

      .header {
        height: 80px;
        padding: 20px;
        margin-bottom: -10px;
        align-items: center;
        display: flex;
        background: linear-gradient(180deg, $first-color-dark-3 3.75%, rgba(0, 0, 0, 0) 100%);

        .logo-container {
          justify-content: center;
          align-items: center;
          display: flex;

          img {
            width: 125px;
            height: 30px;
          }
        }
        .icon-container {
          margin-left: auto;

          i {
            cursor: pointer;
            color: #FFFFFF50;
          }
        }
      }

      .options {
        padding: 10px;

        .option {
          color: $white-color;
          margin-bottom: 15px;

          a {
            text-decoration: none;
            color: $white-color;
          }

          &.expanded {
            background: $first-color-dark-1;
            border-radius: 6px;

            .items {
              .item {
                &:hover {
                  background: $first-color-dark-2;

                  &:last-child {
                    border-radius: 0 0 6px 6px;
                  }
                }
              }
            }
          }

          .item-one {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px 15px;

            i {
              color: #FFFFFF50;
              font-size: 1.2rem;
              margin-right: 0.5rem;
            }

            &.active {
              background: $second-color !important;
              border-radius: 6px;

              i {
                color: $white-color;
              }
            }

            &:hover {
              background: $first-color-dark-2;
              border-radius: 6px;
            }
          }

          .item {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px 15px;

            &:last-child {
              border-radius: 0 0 6px 6px;
            }

            i {
              color: #FFFFFF50;
              font-size: 1.2rem;
              margin-right: 0.5rem;
            }

            .icon-container {
              font-size: 0.5rem;
              margin-left: auto;
              display: flex;

              i {
                font-size: 0.5rem;
              }
            }

            &.open {
              margin-bottom: 10px;
            }

            &.retracted {
              padding: 10px 15px;
            }

            &.active {
              background: $second-color !important;

              i {
                color: $white-color;
              }
            }
          }
        }
      }
    }

    .sidebar-disable {
      height: 100%;
      width: 80px;
      font-size: 14px;
      transition: width 100ms;
      min-height: 100vh;
      background: $first-color;
      white-space: nowrap;

      .header {
        height: 80px;
        padding: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        background: linear-gradient(180deg, $first-color-dark-3 3.75%, rgba(0, 0, 0, 0) 100%);

        i {
          cursor: pointer;
          color: #FFFFFF50;

          &.icon-sidebar-toggle {
            cursor: default;
          }
        }
      }

      .options {
        .option {
          height: 48px;

          .icon-container {
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              color: #FFFFFF50;
              font-size: 1.2rem;
            }

            &.active {
              background: $second-color !important;

              i {
                color: $white-color;
              }
            }

            &.select {
              background: $first-color-dark-1;

              i {
                color: $white-color;
              }
            }

            &:hover {
              background: $first-color-dark-2;
            }
          }

          .items-container {
            left: 80px;
            margin-top: -48px;
            position: absolute;

            .expanded {
              border-radius: 0 0 6px 0;
              background: $first-color-dark-1;

              .item {
                i {
                  margin-right: 0.5rem;
                }

                &:hover {
                  background: $first-color-dark-2;

                  &:last-child {
                    border-radius: 0 0 6px 6px;
                  }
                }
              }
            }

            .items {
              width: 215px;
              background: $first-color-dark-1;
              position: absolute;
              z-index: 1;
              border-radius: 0 6px 6px 0;

              .item {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 10px 15px;
                color: $white-color;
                height: 48px;

                i {
                  color: #FFFFFF50;
                  font-size: 1.2rem;
                }

                .icon-container {
                  font-size: 0.5rem;
                  margin-left: auto;
                  display: flex;

                  i {
                    font-size: 0.5rem;
                  }
                }

                &.active {
                  background: $second-color !important;

                  &:last-child {
                    border-radius: 0 0 6px 0;
                  }

                  i {
                    color: $white-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .sidebar-container {
      .sidebar-disable {
        .header {
          height: 80px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .sidebar-container {
      position: absolute;
      width: 100%;

      .sidebar {
        min-width: 320px;
        transition: none;
        position: absolute;
        width: 100%;
        z-index: 1;

        .header {
          background: none;
          height: 80px;

          .logo-container {
            width: 100%;

            img {
              width: 90px;
              height: 30px;
            }
          }

          .icon-container {
            position: absolute;
          }
        }
      }

      .sidebar-disable {
        transition: none;
        background: transparent;
        display: none;
      }
    }
  }
</style>