import { instance } from '../../config/axios';
import axios from 'axios';
import store from '../index';

function initialState() {
  return {
    totalSalesSucceeded: 0,
    totalSalesBilling: 0,
    totalNewCustomers: 0,
    totalSalesReceivables: 0,
    totalExpenses: 0,
    salesRanking: [],
    salesByPaymentType: []
  };
}

const actions = {
  getAllMetrics({commit}) {
    return new Promise((resolve, reject) => {
      const getSalesByPaymentType = instance().get(`/sellers/${store.state.currentSellerId}/metrics/sales-by-payment-type`);
      const getSalesBilling = instance().get(`/sellers/${store.state.currentSellerId}/metrics/sales-billing`);
      const getSalesSucceeded = instance().get(`/sellers/${store.state.currentSellerId}/metrics/sales-succeeded`);
      const getSalesRanking = instance().get(`/sellers/${store.state.currentSellerId}/metrics/sales-ranking`);
      const getNewCustomers = instance().get(`/sellers/${store.state.currentSellerId}/metrics/new-customers`);

      axios.all([getSalesByPaymentType, getSalesBilling, getSalesSucceeded, getSalesRanking, getNewCustomers])
        .then(([resSalesByPaymentType, resSalesBilling, resSalesSucceeded, resSalesRanking, resNewCustomers]) => {
          const resSalesSucceededData = resSalesSucceeded.data;
          const resSalesBillingData = resSalesBilling.data;
          const resNewCustomersData = resNewCustomers.data;
          const resSalesRankingData = resSalesRanking.data;
          const resSalesByPaymentTypeData = resSalesByPaymentType.data;

          commit('setTotalSalesSucceeded', resSalesSucceededData.total);
          commit('setTotalSalesBilling', resSalesBillingData.total);
          commit('setTotalNewCustomers', resNewCustomersData.total);
          commit('setSalesRanking', resSalesRankingData.items);
          commit('setSalesByPaymentType', resSalesByPaymentTypeData.items);

          resolve();
        })
        .catch(errors => {
          reject(errors.response);
        });
    });
  },

  getAllCashFlowMetrics(_, params) {
    return new Promise((resolve, reject) => {
      instance().get(`/sellers/${store.state.currentSellerId}/metrics/cash-flow-per-day` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

const getters = {
  getTotalSalesSucceeded(state) {
    return state.totalSalesSucceeded;
  },

  getTotalSalesBilling(state) {
    return state.totalSalesBilling;
  },

  getTotalSalesReceivables(state) {
    return state.totalSalesReceivables;
  },

  getTotalExpenses(state) {
    return state.totalExpenses;
  },

  getTotalNewCustomers(state) {
    return state.totalNewCustomers;
  },

  getSalesRanking(state) {
    return state.salesRanking;
  },

  getSalesByPaymentType(state) {
    return state.salesByPaymentType;
  }
};

const mutations = {
  setTotalSalesSucceeded(state, total) {
    state.totalSalesSucceeded = total;
  },

  setTotalSalesBilling(state, total) {
    state.totalSalesBilling = total;
  },

  setTotalSalesReceivables(state, total) {
    state.totalSalesReceivables = total;
  },

  setTotalExpenses(state, total) {
    state.totalExpenses = total;
  },

  setTotalNewCustomers(state, total) {
    state.totalNewCustomers = total;
  },

  setSalesRanking(state, ranking) {
    state.salesRanking = ranking;
  },

  setSalesByPaymentType(state, payload) {
    state.salesByPaymentType = payload;
  },

  resetState(state) {
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations
};