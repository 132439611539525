import { render, staticRenderFns } from "./SkeletonLoader.vue?vue&type=template&id=7dfc8ca4&scoped=true"
import script from "./SkeletonLoader.vue?vue&type=script&lang=js"
export * from "./SkeletonLoader.vue?vue&type=script&lang=js"
import style0 from "./SkeletonLoader.vue?vue&type=style&index=0&id=7dfc8ca4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dfc8ca4",
  null
  
)

export default component.exports