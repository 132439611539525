import { extend } from 'vee-validate';
import Service from '@/services/service';
import { required, email, confirmed, min } from 'vee-validate/dist/rules';
import moment from 'moment';

extend('min', {
  ...min,
  message: 'Campo deve ter {length} caracteres'
});

extend('required', {
  ...required,
  message: 'Campo obrigatório'
});

extend('email', {
  ...email,
  message: 'E-mail inválido'
});

extend('taxpayerId', {
  message: 'CPF inválido',
  validate: (value) => {
    if (value != null) {
      let cpf = Service.removeCharacter(value);

      if (cpf.length != 11) return false;

      const invalidCpfs = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999'
      ];

      if (invalidCpfs.includes(cpf)) return false;

      let sum = 0;
      let remainder;

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }

      remainder = (sum * 10) % 11;
      if (remainder == 10 || remainder == 11) remainder = 0;
      if (remainder != parseInt(cpf.substring(9, 10))) return false;

      sum = 0;

      for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }

      remainder = (sum * 10) % 11;
      if (remainder == 10 || remainder == 11) remainder = 0;
      if (remainder != parseInt(cpf.substring(10, 11))) return false;

      return true;
    }
  }
});

extend('ein', {
  message: 'CNPJ inválido',
  validate: (value) => {
    if (value != null) {
      let cnpj = Service.removeCharacter(value);

      if (cnpj.length != 14) return false;

      if (/^\d{14}$/.test(cnpj)) {
        const invalidCnpjs = [
          '00000000000000',
          '11111111111111',
          '22222222222222',
          '33333333333333',
          '44444444444444',
          '55555555555555',
          '66666666666666',
          '77777777777777',
          '88888888888888',
          '99999999999999'
        ];

        if (invalidCnpjs.includes(cnpj)) return false;

        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0, size);
        let digits = cnpj.substring(size);
        let sum = 0;
        let pos = size - 7;

        for (let i = size; i >= 1; i--) {
          sum += numbers.charAt(size - i) * pos--;
          if (pos < 2) pos = 9;
        }

        let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result != digits.charAt(0)) return false;

        size = size + 1;
        numbers = cnpj.substring(0, size);
        sum = 0;
        pos = size - 7;

        for (let i = size; i >= 1; i--) {
          sum += numbers.charAt(size - i) * pos--;
          if (pos < 2) pos = 9;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result != digits.charAt(1)) return false;

        return true;
      }
    }
  }
});

extend('mail', {
  message: 'E-mail inválido',
  validate: (value) => {
    if (value != null) {
      let regEmail =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

      return !!value.match(regEmail);
    }
  }
});

extend('confirmed', {
  ...confirmed,
  message: 'Senhas não conferem'
});

extend('cep', {
  message: 'CEP inválido',
  validate: (value) => {
    return Service.removeCharacter(value).length === 8;
  }
});

extend('checkboxRequired', {
  message: 'Campo obrigatório',
  validate: (value) => {
    return value;
  }
});

extend('phone', {
  message: 'Número inválido',
  validate: (value) => {
    const cleanedValue = Service.removeCharacter(value);
    const regex = /^(1[1-9]|[1-9]{2})?\s?9\d{4}-?\d{4}$/;

    return regex.test(cleanedValue);
  }
});

extend('cardExpirationData', {
  message: 'Data inválido',
  validate: (value) => {
    let formattedDate = Service.removeCharacter(value);

    if (formattedDate.length == 4) {
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear().toString().substr(-2);

      let validateMonth = formattedDate.toString().substr(0, 2);
      let validateYear = formattedDate.toString().substr(-2);

      if (
        (validateMonth >= currentMonth && validateYear >= currentYear) ||
        validateYear > currentYear
      ) {
        return moment(formattedDate, 'MM-YY').isValid();
      }
    } else {
      return false;
    }
  }
});

extend('futureDate', {
  message: 'Data futura inválida',
  validate: (value) => {
    if (value.length == 10) {
      return moment(Service.dateFormat(value, 'DD/MM/YYYY')).isAfter(
        Service.currentDateEN()
      );
    }
  }
});

extend('maxYearDate', {
  message: (_, args) => 'Vencimento máximo ' + args[0] + ' anos',
  validate: (value, args) => {
    if (value.length == 10) {
      let currentDate = Service.currentDateEN();
      let futureArgsYear = moment(currentDate)
        .add(args[0], 'y')
        .format('YYYY-MM-DD');

      return moment(Service.dateFormat(value, 'DD/MM/YYYY')).isBefore(
        futureArgsYear
      );
    }
  }
});

extend('maxEqualMoney', {
  message: (_, args) =>
    'Valor máximo ' + Service.formatBrlAmountSymbol(args[0].split(':')[0]),
  validate: (_, args) => {
    let splitString = args[0].split(':');

    return parseFloat(splitString[1]) <= parseFloat(splitString[0]);
  }
});

extend('maxMoney', {
  message: (_, args) =>
    'Valor máximo ' + Service.formatBrlAmountSymbol(args[0].split(':')[0]),
  validate: (_, args) => {
    let splitString = args[0].split(':');

    return parseFloat(splitString[1]) < parseFloat(splitString[0]);
  }
});

extend('passwordCombination', {
  message: () => 'Combinação de senha inválida',
  validate: (value) => {
    return !!value.match(/^(?=.*\d(?=.*\d))(?=.*[a-zA-Z](?=.*[a-zA-Z])).{6,}$/);
  }
});
