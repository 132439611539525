<template>
  <simple-default-view-component class="simple-default-view-component">
    <template v-slot:header>
      <div class="title">
        <h2>Nova venda</h2>
      </div>

      <div class="icon-container">
        <i class="icon-close" @click="close"/>
      </div>
    </template>

    <template v-slot:content>
      <div class="content">
        <img src="@/assets/images/check-image.png" />

        <h2>{{ title }}</h2>

        <p v-if="text">
          {{ text }}
        </p>

        <div v-if="hasSaleUrl" class="link-container">
          <p ref="linkValue">
            {{ getUrl }}
          </p>

          <div class="icon-container">
            <i v-if="isCopied" class="icon-check-circle" />
            <i v-else class="icon-copy" @click="copy" />
          </div>
        </div>

        <button-component
          class="button-component"
          text="Minhas vendas"
          iconRight="icon-arrow-right"
          v-ripple="$theme.whiteColorTransparent"
          @click.native="toSalesReceivablesView"
        />

        <p class="redirect" @click="toNewSale">
          Nova venda
        </p>
      </div>
    </template>
  </simple-default-view-component>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SimpleDefaultView from '@/components/Layout/SimpleDefaultView';
  import Button from '@/components/Base/Button';

  export default {
    name: 'NewSaleSuccessView',

    data() {
      return {
        isCopied: false,
        hasSaleUrl: true,
        title: '',
        text: '',
      };
    },

    components: {
      'simple-default-view-component': SimpleDefaultView,
      'button-component': Button
    },

    computed: {
      ...mapGetters({
        getConfettiConfig: 'getConfettiConfig',
        getCurrentSellerSaleStatus: 'getCurrentSellerSaleStatus',
        isSaleProgress: 'newSaleModule/getProgressStatus',
        getSaleData: 'newSaleModule/getData',
        getUrl: 'newSaleModule/getUrl'
      })
    },

    created() {
      if (!this.isSaleProgress || !this.$route.params?.hasNewSale || !this.getCurrentSellerSaleStatus) {
        this.close();
      } else {
        this.$confetti.start(this.getConfettiConfig);

        switch (this.$service.toUpperCase(this.getSaleData.paymentMethod)) {
          case 'CHECKOUT':
            this.title = 'Link avulso gerado com sucesso!';
            this.text = 'Copie e envie o link ao seu cliente. Ele deverá preencher com os dados do cartão de crédito ou escanear o QR Code para que a venda seja efetivada.';
            break;
          case 'BANK_SLIP':
            this.title = 'Boleto gerado com sucesso!';
            this.text = 'Copie e envie o link ao seu cliente. Você poderá acompanhar o status do pagamento na seção minhas vendas.';
            break;
          default:
            this.hasSaleUrl = false;

            this.title = 'Venda gerada com sucesso!';
        }

        setTimeout(() => {
          this.$confetti.stop();
        }, 2500);
      }
    },

    beforeDestroy() {
      this.$confetti.remove();
    },

    methods: {
      close() {
        this.$store.commit('newSaleModule/resetData');
        this.$router.push('/dashboard');
      },

      toNewSale() {
        this.$store.commit('newSaleModule/resetData');
        this.$router.push('/sales/new');
      },

      toSalesReceivablesView() {
        this.$store.commit('newSaleModule/resetData');
        this.$router.push('/sales-receivables');
      },

      copy() {
        this.isCopied = true;

        setTimeout(() => {
          this.isCopied = false;
        }, 2500);

        this.$copyText(this.$refs.linkValue.innerText);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .simple-default-view-component {
    .title {
      display: flex;
      align-items: center;

      h2 {
        color: #333333;
      }
    }

    .content {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 1.5rem;
      height: 100%;

      img {
        width: 175px;
        height: 175px;
        margin-bottom: 2.5rem;
      }

      h2 {
        color: #333;
        margin-bottom: 1rem;
        text-align: center;
      }

      p {
        max-width: 625px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #006EFF;
      }

      .link-container {
        padding: 0 1rem;
        margin-top: 2.5rem;
        border: 2px solid rgb(8, 185, 120, 0.05);
        background: rgb(8, 185, 120, 0.05);
        border-radius: 6px;
        height: 56px;
        display: flex;
        max-width: 450px;
        width: 100%;
        align-items: center;
        margin-bottom: 1.5rem;

        p {
          font-size: 16px;
          font-weight: 400;
          color: #333;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon-container {
          margin-left: auto;

          i {
            font-size: 1.4rem;
            color: #8E8E8E;
            cursor: pointer;
            margin-left: 1rem;

            &:hover {
              color: #707070;
            }

            &.icon-check-circle {
              color: #08b978;
              cursor: not-allowed;
              font-size: 1.2rem;
            }
          }
        }
      }

      .button-component {
        height: 48px;
        width: 170px;
        margin-top: 1rem;
      }

      .redirect {
        margin-top: 1rem;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 945px) {
    .simple-default-view-component {
      .title {
        h2 {
          font-size: 20px;
        }
      }

      .content {
        img {
          width: 120px;
          height: 120px;
        }

        h2 {
          font-size: 18px;
        }

        p {
          font-size: 12px;
        }

        .link-container {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
</style>