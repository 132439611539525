import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import service from '../services/service';
import * as Sentry from '@sentry/vue';
import formbricks from '@formbricks/js/app';
import { imPayInstance, instance } from '../config/axios';
import { encryptStorage } from '../config/encryptStorage';

import bankAccountModule from './modules/bankAccount';
import accountCustomizationModule from './modules/accountCustomization';
import newSaleModule from './modules/newSale';
import authModule from './modules/auth';
import paymentModule from './modules/payment';
import receivableModule from './modules/receivable';
import accreditationModule from './modules/accreditation';
import serviceModule from './modules/service';
import productModule from './modules/product';
import metricModule from './modules/metric';
import customerModule from './modules/customer';
import supplierModule from './modules/supplier';
import expenseModule from './modules/expense';
import saleModule from './modules/sale';
import appointmentModule from './modules/appointment';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: encryptStorage
});

function initialState() {
  return {
    isAgreedTerms: false,

    accountBalance: null,
    subBalance: null,
    dashboardBalanceValueState: false,

    hasModalUserEdit: false,
    hasModalIndividualEdit: false,
    hasModalAddressEdit: false,
    hasModalCompanyEdit: false,
    hasModalOwnerEdit: false,
    hasModalOwnerAddressEdit: false,
    isOwnerAddressEdit: false,

    mcc: [],
    bankCodes: null,

    sellers: [],
    sellerData: {},

    currentSellerId: '',
    currentSellerStatus: '',
    currentSellerType: '',

    isCurrentSellerSaleEnabled: false,
    isCurrentSellerBankingEnabled: false,

    workspaceData: {
      id: '',
      name: '',
    },

    userData: {
      name: '',
      firstName: '',
      taxpayerId: '',
      email: '',
      phone: ''
    },

    extractFilter: {
      type: '',
      startDate: null,
      endDate: null
    },

    confettiConfig: {
      particles: [
        {
          type: 'rect',
        }
      ],
      defaultColors: [
        '#78CDEA',
        '#FEC500',
        '#08B978',
        '#FF8B00'
      ],
      particlesPerFrame: 0.75
    },

    hasExtractPerTransactionFilter: false,
    hasExtractPerDayFilter: false,

    saleNavigationData: {
      current: '',
      items: [
        {
          label: 'Vendas',
          isActive: false,
          name: 'transactions',
          icon: 'icon-charges'
        },

        {
          label: 'Link avulso',
          isActive: false,
          name: 'checkouts',
          icon: 'icon-link'
        }
      ]
    },

    sidebar: {
      hideSideBar: false,
      disableToggleSidebar: false,
      sections: [
        {
          label: 'Painel',
          icon: 'icon-dashboard',
          path: '/dashboard',
          state: true,
        },

        {
          label: 'Conta digital',
          value: 'digital',
          icon: 'icon-digitalpay',
          state: true,
          hasActive: false,
          openSection: false,
          hideSection: false,
          items: [
            {
              label: 'Extrato e saldo',
              icon: 'icon-statement',
              path: '/extract',
              state: false,
            },

            {
              label: 'Recebíveis',
              icon: 'icon-receivables',
              path: '/receivables',
              state: false,
            },

            {
              label: 'Saques',
              icon: 'icon-transfer',
              path: '/transfers',
              state: false,
            }
          ]
        },

        {
          label: 'Gestão',
          icon: 'icon-services',
          value: 'management',
          state: true,
          hasActiveItem: false,
          openSection: false,
          hideSection: false,
          items: [
            {
              label: 'Clientes',
              icon: 'icon-client',
              path: '/customers',
              state: false,
            },

            {
              label: 'Fornecedores',
              icon: 'icon-toolbox',
              path: '/suppliers',
              state: false,
            },

            {
              label: 'Produtos',
              icon: 'icon-price-tag',
              path: '/products',
              state: false,
            },

            {
              label: 'Serviços',
              icon: 'icon-products',
              path: '/services',
              state: false,
            },

            {
              label: 'Fluxo de caixa',
              icon: 'icon-receivable',
              path: '/cash-flow',
              state: false,
            },

            {
              label: 'Vendas',
              icon: 'icon-sales',
              path: '/sales-receivables',
              state: false,
            },

            {
              label: 'Despesas',
              icon: 'icon-expense',
              path: '/expenses',
              state: false,
            },

            {
              label: 'Agenda',
              icon: 'icon-schedule',
              path: '/appointments',
              state: false,
            }
          ]
        }
      ]
    }
  };
}

const actions = {
  setAuthorization({ commit }, data) {
    if (data.status) encryptStorage.setItem('token', data.token);

    commit('setWorkspaceData', {
      id: data.workspaceId,
      name: data.workspaceName,
    });
  },

  postLogin({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().post('/auth/token', data.user)
        .then(response => {
          let res = response.data;
          let token = res.data.access_token;
          let workspaceName = res.data.workspaces[0].name;
          let workspaceId = res.data.workspaces[0].id;
          let tokenExpiresIn = res.data.expires_in + 's';
          let status = data.status;

          dispatch('setAuthorization', { token, workspaceName, workspaceId, tokenExpiresIn, status });

          resolve(response);
        })
        .catch(err => {
          encryptStorage.removeItem('token');

          reject(err.response);
        });
    });
  },

  postSignUp({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().post('/users', data.user)
        .then(response => {
          let res = response.data;
          let token = res.data.access_token;
          let workspaceName = res.data.workspace_name;
          let workspaceId = res.data.workspace_id;
          let tokenExpiresIn = res.data.access_token_expires_in + 's';
          let status = true;

          dispatch('setAuthorization', { token, workspaceName, workspaceId, tokenExpiresIn, status });

          resolve(res);
        })
        .catch(err => {
          encryptStorage.removeItem('token');

          reject(err.response);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      encryptStorage.removeItem('token');

      Sentry.setUser(null);

      commit('setLogOut');
      resolve();
    });
  },

  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      imPayInstance().get('/users/self')
        .then(response => {
          const res = response.data;
          const workspaceIds = res.data.workspaces.map(workspace => workspace.id);
          const workspaceIdsString = workspaceIds.length > 1 ? workspaceIds.join(' | ') : workspaceIds[0];

          Sentry.setUser({
            email: res.data.email,
            name: res.data.name,
            workspaces: workspaceIdsString
          });

          commit('setUserData', res.data);
          commit('authModule/setIsResetPassword', res.data.is_reset_password);

          if (process.env.VUE_APP_FORMBRICKS_ENV_ID && process.env.VUE_APP_FORMBRICKS_HOST) {
            if (window.formbricks) {
              formbricks.logout();
            }

            formbricks.init({
              environmentId: process.env.VUE_APP_FORMBRICKS_ENV_ID,
              apiHost: process.env.VUE_APP_FORMBRICKS_HOST,
              userId: res.data.email,
            });

            formbricks.setEmail(res.data.email);
            formbricks.setAttribute('name', res.data.name);
          }

          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  putUser(data) {
    return new Promise((resolve, reject) => {
      imPayInstance().get('/users/self', data.formData)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().post('/sellers/companies', data.company)
        .then(response => {
          let res = response.data;

          commit('setCurrentSellerId', res.data.id);

          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postIndividual({ commit }, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().post('/sellers/companies', data.individual)
        .then(response => {
          let res = response.data;

          commit('setCurrentSellerId', res.data.id);

          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getMerchantCategoriesCode({ commit }) {
    return new Promise((resolve, reject) => {
      imPayInstance().get('/merchant-categories')
        .then(response => {
          let res = response.data;

          commit('setMerchantCategoriesCode', res.data);

          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAddress(_, postalCode) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/postal-codes/${postalCode}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllSellers() {
    return new Promise((resolve, reject) => {
      instance().get('/sellers?limit=500')
        .then(res => {
          const resData = res.data;
          var data = [];

          data = resData.items.map(seller => {
            return {
              id: seller.remote_seller_id,
              label: seller.label
            };
          });

          resolve({ data });
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  putIndividualSeller({ state }, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().put(`/sellers/individuals/${state.currentSellerId}`, data.formData)
        .then(res => {
          resolve(res.data.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  putCompanySeller({ state }, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().put(`/sellers/companies/${state.currentSellerId}`, data.formData)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSellerId({ commit, state }) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}`)
        .then(res => {
          let response = res.data;

          commit('setCurrentSeller', response.data);
          commit('setCurrentSellerType', response.data.type);
          commit('setCurrentSellerSaleStatus', response.data.is_sale_enabled);
          commit('setCurrentSellerBankingStatus', response.data.is_banking);
          commit('setCurrentSellerStatus');

          resolve(response);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getBankCodes() {
    return new Promise((resolve, reject) => {
      imPayInstance().get('/banks?limit=500')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getExtract({ state }, params) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/reports/account-history` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAccountOperation({ state }, params) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/reports/account-operation` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getReceivablesTransaction({ state }, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/transactions/${data.transactionId}/receivables`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSellerCheckouts({ state }, params) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/checkouts` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSellerBalances({ state, commit }) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/balances`)
        .then(res => {
          let resData = res.data;

          commit('setAccountBalance', resData.data.account_balance);
          commit('setSubBalance', resData.data.sub_balance);

          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSellerSalesPlan({ state }) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/sales-plan`)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.response);
      });
    });
  },

  getTransactions({ state }, params) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/transactions` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getTransactionCancelledLetter({ state }, transactionId) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/transactions/${transactionId}/cancelled-letter`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postTransactionRefund({ state }, transaction) {
    return new Promise((resolve, reject) => {
      imPayInstance().post(`/sellers/${state.currentSellerId}/transactions/${transaction.id}/refund`, transaction.data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getTransactionId({ state }, transactionId) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/transactions/${transactionId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postTransaction({ state }, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().post(`/sellers/${state.currentSellerId}/transactions`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postWithdraw({ state }, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().post(`/sellers/${state.currentSellerId}/withdraw`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllBankTransfers({ state }, params) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/bank-transfers` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllBankTransferId({ state }, transferId) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/bank-transfers/${transferId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllBankTransferHistoryId({ state }, transferId) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/bank-transfers/${transferId}/history`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getReceipt({ state }, transactionId) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${state.currentSellerId}/transactions/${transactionId}/receipt`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

const getters = {
  getAccountBalance(state) {
    return state.accountBalance;
  },

  getSubBalance(state) {
    return state.subBalance;
  },

  getBankCodesData(state) {
    return state.bankCodes;
  },

  getAgreedTerms(state) {
    return state.isAgreedTerms;
  },

  getConfettiConfig(state) {
    return state.confettiConfig;
  },

  getUserData(state) {
    return state.userData;
  },

  getCurrentSellerData(state) {
    return state.sellerData;
  },

  getCurrentSellerStatus(state) {
    return state.currentSellerStatus;
  },

  getIsSellerActive(state) {
    return service.toUpperCase(state.currentSellerStatus) == 'ACTIVE'
      || service.toUpperCase(state.currentSellerStatus) == 'ENABLED';
  },

  getIsSellerPending(state) {
    return service.toUpperCase(state.currentSellerStatus) == 'PENDING';
  },

  getCurrentSellerSaleStatus(state) {
    return state.isCurrentSellerSaleEnabled;
  },

  getCurrentSellerBankingStatus(state) {
    return state.isCurrentSellerBankingEnabled;
  },

  getCurrentSellerType(state) {
    return state.currentSellerType;
  },

  getCurrentSellerId(state) {
    return state.currentSellerId;
  },

  getDashboardBalanceValueState(state) {
    return state.dashboardBalanceValueState;
  },

  getExtractFilter(state) {
    return state.extractFilter;
  },

  getSellers(state) {
    return state.sellers;
  },

  getSideBarState(state) {
    return state.sidebar;
  },

  getExtractPerTransactionFilter(state) {
    return state.hasExtractPerTransactionFilter;
  },

  getSaleNavigationData(state) {
    return state.saleNavigationData;
  },

  getWorkspaceData(state) {
    return state.workspaceData;
  }
};

const mutations = {
  setExtractPerTransactionFilter(state, status) {
    state.hasExtractPerTransactionFilter = status;
  },

  toggleDashboardBalanceValueState(state) {
    state.dashboardBalanceValueState = !state.dashboardBalanceValueState;
  },

  setAgreedTerms(state, status) {
    state.isAgreedTerms = status;
  },

  setAccountBalance(state, amount) {
    state.accountBalance = amount;
  },

  setSubBalance(state, amount) {
    state.subBalance = amount;
  },

  setMerchantCategoriesCode(state, mcc) {
    state.mcc = mcc;
  },

  setCurrentSellerBankingStatus(state, status) {
    state.isCurrentSellerBankingEnabled = status;
  },

  setCurrentSellerSaleStatus(state, status) {
    state.isCurrentSellerSaleEnabled = status;
  },

  setCurrentSellerStatus(state) {
    state.currentSellerStatus = state.sellerData.status;
  },

  resetSaleNavigationData(state) {
    state.saleNavigationData.current = '';
    state.saleNavigationData.items.map(item => item.isActive = false);
  },

  setSaleNavigationByName(state, name) {
    let section = state.saleNavigationData.items.find(item => item.name == name);

    if (section) {
      state.saleNavigationData.current = section.name;
      section.isActive = true;
    }
  },

  setCurrentSellerType(state, type) {
    state.currentSellerType = type;
  },

  setToggleModalUserEdit(state) {
    state.hasModalUserEdit = !state.hasModalUserEdit;
  },

  setToggleModalIndividualEdit(state) {
    state.hasModalIndividualEdit = !state.hasModalIndividualEdit;
  },

  setToggleModalCompanyEdit(state) {
    state.hasModalCompanyEdit = !state.hasModalCompanyEdit;
  },

  setToggleModalAddressEdit(state) {
    state.hasModalAddressEdit = !state.hasModalAddressEdit;
  },

  setToggleModalOwnerAddressEdit(state) {
    state.hasModalOwnerAddressEdit = !state.hasModalOwnerAddressEdit;
  },

  setToggleModalOwnerEdit(state) {
    state.hasModalOwnerEdit = !state.hasModalOwnerEdit;
  },

  setOwnerAddressEditStatus(state, status) {
    state.isOwnerAddressEdit = status;
  },

  closeModalEdit(state) {
    state.hasModalUserEdit = false;
    state.hasModalIndividualEdit = false;
    state.hasModalAddressEdit = false;
    state.hasModalCompanyEdit = false;
    state.hasModalOwnerEdit = false;
    state.hasModalOwnerAddressEdit = false;
  },

  setCurrentSellerId(state, id) {
    state.currentSellerId = id;
  },

  setUserData(state, data) {
    state.userData.name = data.name;
    state.userData.firstName = data.name.split(' ')[0];
    state.userData.email = data.email;
    state.userData.taxpayerId = data.taxpayer_id;
    state.userData.phone = data.phone_number;
    state.userData.isNewMerchantEnabled = data.is_new_merchant_enabled;
  },

  setAllSellers(state, sellers) {
    state.sellers = sellers;
  },

  setCurrentSeller(state, data) {
    state.sellerData = data;
  },

  setWorkspaceData(state, data) {
    state.workspaceData.id = data.id;
    state.workspaceData.name = data.name;
  },

  setBankCodes(state, data) {
    state.bankCodes = data;
  },

  setToggleDigitalSection(state) {
    state.hideDigitalSection = !state.hideDigitalSection;
  },

  setToggleServiceSection(state) {
    state.hideServiceSection = !state.hideServiceSection;
  },

  setExtractFilter(state, filter) {
    state.extractFilter = filter;
  },

  setLogOut(state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const store = new Vuex.Store({
  state: initialState(),
  actions,
  getters,
  mutations,
  modules: {
    accreditationModule,
    bankAccountModule,
    newSaleModule,
    authModule,
    paymentModule,
    accountCustomizationModule,
    receivableModule,
    serviceModule,
    productModule,
    metricModule,
    customerModule,
    supplierModule,
    expenseModule,
    saleModule,
    appointmentModule
  },
  plugins: [
    vuexLocal.plugin
  ]
});

export default store;