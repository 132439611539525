<template>
  <div class="accreditation-container" :class="{ 'has-modal': isShowTermsModal }">
    <terms-modal v-if="isShowTermsModal" @close="disagreeTerms" @agree="agreeTerms" />

    <snackbar-component ref="snackbar" />

    <navbar-component :hasLogo="true" />

    <div class="accreditation">
      <div v-if="hasFetchRequest" class="loader-container">
        <spinner-component color="#FFFFFF" />
      </div>

      <div v-else class="description">
        <h1>
          Oi

          <span>
            {{ formattedUserData }},
          </span>

          <img src="@/assets/icons/hand.svg">
        </h1>

        <h2>Seu cadastro foi realizado com sucesso!</h2>

        <p v-if="!hasPreAccreditation">Sua conta <b>{{ $service.getPlatformTitle() }}</b> está em processo de verificação por nossa equipe, e em cerca de <b>48 horas</b> seu acesso será liberado.</p>

        <p v-else>Para sua conta <b>{{ $service.getPlatformTitle() }}</b> ser ativa, será necessário cadastrar uma <b>conta bancária para saques</b>.</p>

        <p>Em caso de dúvida consulte <b>nosso suporte</b>!</p>

        <div v-if="hasPreAccreditation" class="button-container">
          <button-component
            class="button-component"
            text="Meu App"
            iconRight="icon-arrow-right"
            :hasLoader="hasSyncRequest"
            :isDisabled="hasSyncRequest"
            v-ripple="$theme.whiteColorTransparent"
            @click.native="createSyncAccreditation"
          />
        </div>
      </div>
    </div>

    <floating-button-component
      icon="icon-wpp"
      bgColor="#25d366"
      @click="redirectToWpp"
    />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import NavBar from '@/components/Layout/NavBar';
  import Button from '@/components/Base/Button';
  import SnackBar from '@/components/Base/SnackBar';
  import Spinner from '@/components/Base/Spinner';
  import TermsModal from '@/components/Modal/TermsModal';
  import FloatingButton from '@/components/Base/FloatingButton';

  export default {
    name: 'NewAccreditationView',

    data() {
      return {
        hasPreAccreditation: false,
        hasFetchRequest: false,
        hasSyncRequest: false,
        isShowTermsModal: false,

        formattedUserData: '',
      };
    },

    components: {
      'navbar-component': NavBar,
      'button-component': Button,
      'terms-modal': TermsModal,
      'snackbar-component': SnackBar,
      'spinner-component': Spinner,
      'floating-button-component': FloatingButton
    },

    computed: {
      ...mapGetters({
        getConfettiConfig: 'getConfettiConfig',
        getCurrentSellerId: 'getCurrentSellerId',
        getAgreedTerms: 'getAgreedTerms',
        getAccreditations: 'accreditationModule/getAccreditations',
      }),

      ...mapState({
        userData: state => state.userData,
        sellers: state => state.sellers,
      }),
    },

    created() {
      this.fetchAccreditationData();
      this.fetchSellersData();

      this.isShowTermsModal = !this.getAgreedTerms;
    },

    mounted() {
      this.formattedUserData = this.splitName(this.userData.firstName);
    },

    methods: {
      async fetchAccreditationData() {
        this.hasFetchRequest = true;

        await this.$store.dispatch('accreditationModule/getAllAccreditations')
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao buscar informações', '#FF5A5A');
          });

        this.hasPreAccreditation = this.getAccreditations.length > 0;
        this.hasFetchRequest = false;
      },

      async createSyncAccreditation() {
        this.hasSyncRequest = true;

        const accreditationId = this.getAccreditations[0].id;

        var hasSyncError = false;

        await this.$store.dispatch('accreditationModule/createSellerByAccreditation', accreditationId)
          .catch(() => {
            hasSyncError = true;

            this.$refs?.snackbar?.show('Erro ao criar negócio', '#FF5A5A');
          });

        if (!hasSyncError) {
          await this.$store.dispatch('accreditationModule/createSellerSync', this.getCurrentSellerId)
            .catch(() => {
              this.$refs?.snackbar?.show('Erro ao sincronizar negócio', '#FF5A5A');
            });

          await this.fetchSellersData();

          this.$router.push('/dashboard');
        }

        this.hasSyncRequest = false;
      },

      async fetchSellersData() {
        await this.$store.dispatch('getAllSellers')
          .then(res => {
            this.$store.commit('setAllSellers', res.data);

            if (res.data.length > 0) {
              let currentSeller = res.data[res.data.length - 1];

              this.$store.commit('setCurrentSellerId', currentSeller.id);
            }
        })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao buscar negócios', '#FF5A5A');
          });
      },

      splitName(completedName) {
        return completedName.split(' ')[0];
      },

      closeTermsModal() {
        this.isShowTermsModal = false;
      },

      agreeTerms() {
        this.closeTermsModal();
        this.confettiSetup();
        this.$store.commit('setAgreedTerms', true);
      },

      disagreeTerms() {
        this.closeTermsModal();
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/');
          });
      },

      confettiSetup() {
        this.$confetti.start(this.getConfettiConfig);
        setTimeout(() => {
          this.$confetti.stop();
        }, 2500);
      },

      redirectToWpp() {
        const text = `Olá, estou tendo dúvidas sobre o ${this.$service.getPlatformTitle()}. Podem me ajudar?`;
        this.$service.openWpp(text);
      },
    },

    beforeDestroy() {
      this.$confetti.remove();
    },
  };
</script>

<style lang="scss" scoped>
  .accreditation-container {
    background: linear-gradient(305.95deg, $first-color 0%, $first-color-dark-3 92.25%);
    min-height: 100vh;

    &.has-modal {
      overflow: hidden;
      position: fixed;
      width: 100vw;
      min-width: 320px;
    }

    &.has-modal > *:not(.modal) {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    .accreditation {
      color: $white-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;

      .loader-container {
        height: 200px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .description {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        h1 {
          display: flex;
          justify-content: center;
          margin-bottom: 1rem;

          span {
            margin-left: 0.5rem;
            text-transform: capitalize;
          }

          img {
            margin-left: 0.7rem;
          }
        }

        h2 {
          font-size: 20px;
        }

        p {
          margin-top: 1rem;
          font-size: 16px;
          width: 600px;
        }

        .button-container {
          display: flex;
          justify-content: center;
          margin-top: 2rem;

          .button-component {
            width: 150px;
            height: 48px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .accreditation-container {
      .accreditation {
        .description {
          padding: 0 1rem;
          p {
            width: 100%;
          }
        }
      }
    }
  }
</style>