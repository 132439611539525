<template>
  <div class="services-view-container">
    <default-view-component :hasModal="isShowServiceModal">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <service-modal-component
          v-if="isShowServiceModal"
          @close="isShowServiceModal = false"
          @snackbarNotification="showSnackBar"
          @finishRequest="fetchData"
        />

        <confirm-modal-component
          v-if="isShowStatusModal"
          :data="{
            title: 'Atenção',
            icon: 'icon-warning',
            text: 'Deseja realmente alterar o status do serviço?',
            buttonText:
              currentService?.type == 'disable' ? 'Desativar' : 'Ativar',
            buttonColor: '#2196f3'
          }"
          :hasRequest="hasRequest"
          @close="isShowStatusModal = false"
          @click="updateStatusProduct(currentService?.id, currentService?.type)"
        />
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component :data="header" />

          <div class="header-options">
            <button-component
              class="button-component"
              text="Novo serviço"
              :isDisabled="hasRequest"
              v-ripple="$theme.whiteColorTransparent"
              @click.native="showModal"
            />
          </div>

          <table-header-component
            v-if="tableData.rows.length != 0"
            class="table-header-component"
            :hasRequest="hasRequest && !paginationData.totalCountItems"
            :data="[
              {
                label: `${paginationData.totalCountItems}
                      ${$service.formatStringPlural(
                        paginationData.totalCountItems,
                        'cadastrado'
                      )}`,
                hasLastChild: false
              }
            ]"
          />

          <table-component
            class="table-component"
            :hasRequest="hasRequest && !isMoreData"
            :table="tableData"
            @actionClick="showModal"
          />

          <pagination-table-component
            v-if="hasTableRows()"
            class="pagination-table-button-component"
            :isDisabled="!haveMoreData()"
            :data="paginationData"
            :hasRequest="hasRequest"
            @onClick="moreData"
          />
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import Header from '@/components/Layout/Header';
  import Button from '@/components/Base/Button';
  import TableHeader from '@/components/Table/TableHeader';
  import Table from '@/components/Table/Table';
  import ServiceModal from '@/components/Modal/ServiceModal';
  import ConfirmModal from '@/components/Modal/ConfirmModal';
  import SnackBar from '@/components/Base/SnackBar';
  import PaginationTableButton from '@/components/Table/PaginationTableButton';

  export default {
    name: 'ServiceView',

    data() {
      return {
        header: {
          title: 'Serviços',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        currentService: {},

        hasRequest: false,
        isShowServiceModal: false,
        isShowStatusModal: false,

        isMoreData: false,
        allRows: [],

        tableData: this.initialTableData(),
        paginationData: this.initialPaginationData()
      };
    },

    components: {
      'default-view-component': DefaultView,
      'header-component': Header,
      'button-component': Button,
      'table-component': Table,
      'table-header-component': TableHeader,
      'service-modal-component': ServiceModal,
      'confirm-modal-component': ConfirmModal,
      'pagination-table-component': PaginationTableButton,
      'snackbar-component': SnackBar
    },

    computed: {
      ...mapGetters(['getCurrentSellerId', 'getCurrentSellerData'])
    },

    created() {
      this.fetchData();
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
        this.paginationData = this.initialPaginationData();
        this.tableData = this.initialTableData();
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.fetchData();
        }
      }
    },

    methods: {
      async fetchData() {
        this.hasRequest = true;

        if (!this.isMoreData) {
          this.allRows = [];
          this.tableData.rows = [];
        }

        const queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'offset',
            param: this.allRows.length
          }
        ];

        await this.$store
          .dispatch(
            'serviceModule/getAllServices',
            this.$service.formatQueryParam(queryParams)
          )
          .then((res) => {
            const items = res.items;

            this.paginationData.totalCountItems = res.total_count;

            this.allRows = this.allRows.concat(items);
            this.tableData.rows = this.allRows.map((item) => {
              return {
                referenceId: item.reference_id || '-',
                description: item.description,
                status: this.parseStatusChipTableData(item.status),
                price: this.$service.formatBrlAmountSymbol(item.price),
                actions: {
                  showAction: false,
                  icon: 'icon-more',
                  items: [
                    {
                      id: item.id,
                      type: item.status == 'active' ? 'disable' : 'enable',
                      icon:
                        item.status == 'active' ? 'icon-close-circle-outline' : 'icon-check-circle-outline',
                      label: item.status == 'active' ? 'Desativar' : 'Ativar'
                    }
                  ]
                }
              };
            });

            this.paginationData.countItems = this.allRows.length;
          })
          .catch(() => {
            this.showSnackBar('Erro ao carregar informações', '#FF5A5A');
          });

        this.isMoreData = false;
        this.hasRequest = false;
      },

      async updateStatusProduct(id, status) {
        this.hasRequest = true;

        const action = status === 'enable' ? 'enableService' : 'disableService';

        await this.$store
          .dispatch(`serviceModule/${action}`, id)
          .then(() => {
            this.showSnackBar(
              'Status do serviço alterado com sucesso',
              '#08B978'
            );
          })
          .catch(() => {
            this.showSnackBar('Erro ao alterar status do serviço', '#FF5A5A');
          })
          .finally(() => {
            this.isShowStatusModal = false;
          });

        this.fetchData();
      },

      moreData() {
        if (!this.haveMoreData()) {
          this.isMoreData = true;

          this.fetchData();
        }
      },

      haveMoreData() {
        return (
          this.paginationData.countItems == this.paginationData.totalCountItems
        );
      },

      showModal(item) {
        this.currentService = item;

        if (item.type == 'enable' || item.type == 'disable') {
          this.isShowStatusModal = true;
        } else {
          this.isShowServiceModal = true;
        }
      },

      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },

      parseStatusChipTableData(status) {
        var chipData = {
          background: '',
          textColor: '',
          label: ''
        };

        switch (this.$service.toUpperCase(status)) {
          case 'ACTIVE':
            chipData.background = '#08B9781C';
            chipData.textColor = '#08B978';
            chipData.label = 'Ativo';
            break;
          case 'DISABLED':
            chipData.background = '#3333331C';
            chipData.textColor = '#333333';
            chipData.label = 'Inativo';
            break;
          default:
            chipData.background = '#3333331C';
            chipData.textColor = '#333333';
            chipData.label = 'Outro';
        }

        return chipData;
      },

      hasTableRows() {
        return this.tableData.rows.length != 0;
      },

      initialPaginationData() {
        return {
          countItems: 0,
          totalCountItems: 0
        };
      },

      initialTableData() {
        return {
          hasIcon: false,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Cód. de referência',
                field: 'referenceId',
                align: 'center'
              },

              {
                label: 'Descrição',
                field: 'description',
                align: 'center'
              },

              {
                label: 'Valor de venda',
                field: 'price',
                align: 'center'
              },

              {
                label: 'Status',
                field: 'status',
                align: 'center',
                isChip: true
              },

              {
                label: 'Ações',
                align: 'center',
                field: 'actions',
                isAction: true
              }
            ]
          },

          rows: []
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .services-view-container {
    .section {
      padding: 1.5rem;

      .header-options {
        display: flex;
        margin-bottom: 1.5rem;

        .button-component {
          margin-left: auto;
          height: 48px;
          max-width: 160px;
        }
      }

      .table-component {
        margin-top: 1.5rem;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .pagination-table-button-component {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
    }
  }

  @media (max-width: 605px) {
    .services-view-container {
      .section {
        .header-options {
          flex-direction: column-reverse;
          margin-bottom: 1rem;

          .button-component {
            height: 32px;
          }
        }
      }
    }
  }
</style>
