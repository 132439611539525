<template>
  <div class="expenses-view-container">
    <default-view-component :hasModal="isShowModal">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <expense-modal-component
          v-if="isShowModal"
          :expenseId="currentExpenseId"
          @close="isShowModal = false"
          @finishRequest="fetchData"
          @snackbarNotification="showSnackBar"
        />
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
          />

          <div class="header-options">
            <button-component
              class="button-component"
              text="Nova despesa"
              v-ripple="$theme.whiteColorTransparent"
              :isDisabled="hasRequest"
              @click.native="showModal"
            />
          </div>

          <table-header-component
            v-if="tableData.rows.length != 0"
            class="table-header-component"
            :hasRequest="hasRequest && !paginationData.totalCountItems"
            :data="[{
              label: `${paginationData.totalCountItems}
                      ${$service.formatStringPlural(paginationData.totalCountItems, 'cadastrado')}`,
              hasLastChild: false
            }]"
          />

          <table-component
            class="table-component"
            :hasRequest="hasRequest && !isMoreData"
            :table="tableData"
            @actionClick="showModal"
          />

          <pagination-table-component
            v-if="hasTableRows()"
            class="pagination-table-button-component"
            :isDisabled="!haveMoreData()"
            :data="paginationData"
            :hasRequest="hasRequest"
            @onClick="moreData"
          />
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import Header from '@/components/Layout/Header';
  import Button from '@/components/Base/Button';
  import TableHeader from '@/components/Table/TableHeader';
  import Table from '@/components/Table/Table';
  import SnackBar from '@/components/Base/SnackBar';
  import ExpenseModal from '@/components/Modal/ExpenseModal';
  import PaginationTableButton from '@/components/Table/PaginationTableButton';

  export default {
    name: 'ServiceView',

    data() {
      return {
        header: {
          title: 'Despesas',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        currentExpenseId: false,

        hasRequest: false,
        isShowModal: false,

        isMoreData: false,
        allRows: [],

        tableData: this.initialTableData(),
        paginationData: this.initialPaginationData()
      };
    },

    components: {
      'default-view-component': DefaultView,
      'header-component': Header,
      'button-component': Button,
      'table-component': Table,
      'table-header-component': TableHeader,
      'pagination-table-component': PaginationTableButton,
      'expense-modal-component': ExpenseModal,
      'snackbar-component': SnackBar
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerId',
        'getCurrentSellerData',
      ])
    },

    created() {
      this.fetchData();
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
        this.paginationData = this.initialPaginationData();
        this.tableData = this.initialTableData();
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.fetchData();
        }
      }
    },

    methods: {
      async fetchData() {
        this.hasRequest = true;

        if (!this.isMoreData) {
          this.allRows = [];
          this.tableData.rows = [];
        }

        const queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'offset',
            param: this.allRows.length
          }
        ];

        await this.$store.dispatch('expenseModule/getAllExpenses', this.$service.formatQueryParam(queryParams))
          .then(res => {
            const items = res.items;

            this.paginationData.totalCountItems = res.total_count;

            this.allRows = this.allRows.concat(items);
            this.tableData.rows = this.allRows.map(item => {
              return {
                description: item.description,
                supplier: item.supplier.name,
                paymentType: this.parsePaymentType(item.payment_type),
                dueDate: this.$service.dateFormat(item.due_date, 'YYYY-MM-DD', 'DD/MM/YYYY'),
                amount: this.$service.formatBrlAmountSymbol(item.amount),
                paidAmount: item.paid_amount ? this.$service.formatBrlAmountSymbol(item.paid_amount) : '-',
                status: this.parseStatusChipTableData(item.status),
                actions: {
                  showAction: false,
                  icon: 'icon-more',
                  isDisabled: this.$service.toUpperCase(item.status) == 'PAID',
                  items: [
                    {
                      id: item.id,
                      type: 'update',
                      icon: 'icon-pencil',
                      label: 'Baixar',
                    }
                  ]
                }
              };
            });

            this.paginationData.countItems = this.allRows.length;
          })
          .catch(() => {
            this.showSnackBar('Erro ao carregar informações', '#FF5A5A');
          });

        this.isMoreData = false;
        this.hasRequest = false;
      },

      moreData() {
        if (!this.haveMoreData()) {
          this.isMoreData = true;

          this.fetchData();
        }
      },

      haveMoreData() {
        return this.paginationData.countItems == this.paginationData.totalCountItems;
      },

      showModal(item) {
        this.currentExpenseId = item.id;
        this.isShowModal = true;
      },

      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },

      hasTableRows() {
        return this.tableData.rows.length != 0;
      },

      initialPaginationData() {
        return {
          countItems: 0,
          totalCountItems: 0
        };
      },

      initialTableData() {
        return {
          hasIcon: false,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Descrição',
                field: 'description',
                align: 'center'
              },

              {
                label: 'Fornecedor',
                field: 'supplier',
                align: 'center'
              },

              {
                label: 'Forma',
                field: 'paymentType',
                align: 'center'
              },

              {
                label: 'Valor',
                field: 'amount',
                align: 'center'
              },


              {
                label: 'Valor pago',
                field: 'paidAmount',
                align: 'center'
              },

              {
                label: 'Vencimento',
                field: 'dueDate',
                align: 'center'
              },

              {
                label: 'Status',
                field: 'status',
                align: 'center',
                isChip: true
              },

              {
                label: 'Ações',
                align: 'center',
                field: 'actions',
                isAction: true
              }
            ]
          },

          rows: []
        };
      },

      parseStatusChipTableData(status) {
        var chipData = {
          background: '',
          textColor: '',
          label: ''
        };

        switch (this.$service.toUpperCase(status)) {
          case 'PAID':
            chipData.background = '#08B9781C';
            chipData.textColor = '#08B978';
            chipData.label = 'Pago';
            break;
          case 'PENDING':
            chipData.background = '#BA8C031C';
            chipData.textColor = '#BA8C03';
            chipData.label = 'Pendente';
            break;
          default:
            chipData.background = '#3333331C';
            chipData.textColor = '#333333';
            chipData.label = 'Outro';
        }

        return chipData;
      },

      parsePaymentType(type) {
        let label = '';

        switch (this.$service.toUpperCase(type)) {
          case 'BANK_SLIP':
            label = 'Boleto';
            break;
          case 'MONEY':
            label = 'Dinheiro';
            break;
          case 'CREDIT':
            label = 'Crédito';
            break;
          case 'DEBIT':
            label = 'Débito';
            break;
          case 'PIX':
            label = 'Pix';
            break;
          default:
            label = 'Outro';
        }

        return label;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .expenses-view-container {
    .section {
      padding: 1.5rem;

      .header-options {
        display: flex;
        margin-bottom: 1.5rem;

        .button-component {
          margin-left: auto;
          height: 48px;
          max-width: 160px;
        }
      }

      .table-component {
        margin-top: 1.5rem;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .pagination-table-button-component {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
    }
  }

  @media (max-width: 605px) {
    .expenses-view-container {
      .section {
        .header-options {
          flex-direction: column-reverse;
          margin-bottom: 1rem;

          .button-component {
            height: 32px;
          }
        }
      }
    }
  }
</style>