<template>
  <div class="metrics-card-container">
    <div class="content">
      <h2 class="title">{{ title }}</h2>

      <skeleton-loader-component
        v-if="hasRequest"
        class="skeleton-loader-component"
      />

      <h2 v-else>{{ isMoney ? $service.formatBrlAmountSymbol(animatedValue) : animatedValue }}</h2>
    </div>
  </div>
</template>

<script>
  import SkeletonLoader from '@/components/Base/SkeletonLoader';

  export default {
    name: 'DashboardMetricsCard',

    data() {
      return {
        animatedValue: 0,
      };
    },

    props: {
      hasRequest: {
        type: Boolean,
        default: false
      },

      title: {
        type: String,
        default: 'Title'
      },

      value: {
        type: Number,
        default: 0
      },

      isMoney: {
        type: Boolean,
        default: false
      }
    },

    components: {
      'skeleton-loader-component': SkeletonLoader,
    },

    watch: {
      hasRequest() {
        if (!this.hasRequest) {
          this.startAnimation();
        }
      }
    },

    methods: {
      startAnimation() {
        const duration = 1500;
        const endValue = this.value;
        const startTime = performance.now();

        const animate = (timestamp) => {
          const progress = timestamp - startTime;
          const completionRatio = Math.min(progress / duration, 1);
          this.animatedValue = Math.round(completionRatio * endValue);

          if (progress < duration) {
            requestAnimationFrame(animate);
          } else {
            this.animatedValue = endValue;
          }
        };

        requestAnimationFrame(animate);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .metrics-card-container {
    border: 1px solid #EFEFEF;
    border-radius: 6px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      padding: 1rem;
      text-align: center;

      .skeleton-loader-component {
        margin-top: 5px;
        height: 20px;
      }

      .title {
        color: #333;
        font-size: 16px;
      }

      h2 {
        font-size: 18px;
        color: #8E8E8E
      }
    }
  }

  @media (max-width: 980px) {
    .metrics-card-container {
      .content {
        .title {
          font-size: 14px;
        }

        h2 {
          font-size: 16px;
        }
      }
    }
  }
</style>