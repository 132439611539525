<template>
  <div
    v-if="hasNoticeCard()"
    class="notice-card-container"
    :class="{ 'has-sidebar': !getSideBarState.hideSideBar }"
    :style="{ 'background': options.backgroundColor, 'color': options.textColor }"
  >
    <div class="text-container">
      <div class="icon-container">
        <i :class="options.iconName"/>
      </div>

      <div class="text">
        <b v-if="options.title">{{ options.title }}</b>

        {{ options.text }}
      </div>
    </div>

    <div v-if="options.button" class="button-container">
      <button-component
        v-ripple="$theme.whiteColorTransparent"
        :text="options.button.text"
        @click.native="click"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Button from '@/components/Base/Button';

  export default {
    name: 'NoticeCard',

    components: {
      'button-component': Button
    },

    props: {
      options: {
        type: Object,
        default() {
          return {
            backgroundColor: '#000',
            textColor: '#FFF',
            iconName: 'icon-dashboard',
            title: 'Title.',
            text: 'Text',
            button: {
              action: '/',
              text: 'Button'
            }
          };
        }
      }
    },

    computed: {
      ...mapGetters({
        getSideBarState: 'getSideBarState'
      }),
    },

    methods: {
      hasNoticeCard() {
        return !this.$service.isEmptyObject(this.options);
      },

      click() {
        this.$emit('click', this.options.button?.action);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .notice-card-container {
    min-height: 50px;
    padding: 5px 15px;
    width: 100%;
    font-size: 16px;

    justify-content: center;
    align-items: center;
    display: flex;

    .button-container {
      height: 40px;
      width: 120px;
      display: flex;
      align-items: center;
    }

    i {
      margin-right: 0.5rem;
    }

    .text-container {
      font-size: 16px;
      display: flex;
      justify-content: center;

      .icon-container {
        margin-top: 4px;
      }

      .text {
        margin-right: 1rem;

        b {
          font-weight: 400;
        }
      }
    }

    a {
      text-decoration: none;
      color: #1261cc;
    }
  }

  @media (max-width: 1005px) {
    .notice-card-container {
      flex-direction: column;
      align-items: flex-start;

      .text-container {
        .text {
          margin: 0;
        }
      }

      .button-container {
        margin-left: 1.5rem;
        margin-top: 0.5rem;
      }
    }
  }

  @media (max-width: 767px) {
    .notice-card-container {
      padding: 10px 15px;

      .text-container {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 480px) {
    .notice-card-container {
      &.has-sidebar {
        display: none;
      }
    }
  }
</style>