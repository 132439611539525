<template>
  <div class="account-customization-view-container">
    <default-view-component :hasModal="isModalOpen">
      <template v-slot:alert>
        <snack-bar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <modal
          ref="modal"
          title="Editar logo e cor"
          :showModal="isModalOpen"
          @close="closeModal"
        >
          <template v-slot:body>
            <div class="body">
              <div class="section">
                <h3>Logo</h3>

                <div class="logo-instructions-container">
                  <div class="logo-instructions-content" v-for="(item, index) in logoInstructions" :key="index">
                    <p class="label">{{ item.label }}</p>
                    <p>{{ item.value }}</p>
                  </div>
                </div>

                <input-file-field
                  ref="fileField"
                  class="file-field-component"
                  accept="image/png"
                  v-model="logoFile"
                />
              </div>

              <divider-component class="divider" />

              <div class="section">
                <h3>Cor primária</h3>

                <div class="center">
                  <color-picker-component v-model="colors" />
                </div>
              </div>
            </div>
          </template>

          <template v-slot:footer>
            <div class="footer-slot-container">
              <div class="button-container">
                <button-component
                  class="button-component"
                  styles="ghost"
                  text="Fechar"
                  :isDisabled="hasRequest"
                  v-ripple="$theme.secondColorDarkTransparent"
                  @click.native="closeModal"
                />

                <button-component
                  class="button-component"
                  text="Salvar"
                  :hasLoader="hasRequest"
                  :isDisabled="hasRequest"
                  v-ripple="$theme.whiteColorTransparent"
                  @click.native="submit"
                />
              </div>
            </div>
          </template>
        </modal>
      </template>

      <template v-slot:content>
        <div class="wrapper">
          <header-component
            class="header-component"
            :data="header"
          />

          <div class="center">
            <default-card-component
              class="card-container"
              :data="cardData"
            />

            <div class="content">
              <div class="header">
                <div class="title">Sua cor e logo</div>

                <div class="edit-button-container" >
                  <div v-show="!hasRequest" class="edit-button" v-ripple="'rgba(18, 97, 204, 0.2)'" @click="isModalOpen = true">
                    <i class="icon-pencil" />

                    Editar
                  </div>
                </div>
              </div>

              <div v-if="!hasRequest" class="preview-container" :style="{ 'background': previewColor }">
                <div class="preview-content" :class="$service.hexColorBrightness(previewColor)" :style="{ 'background': previewColor }">
                  <img v-if="!getLogoUrl" class="logo default" :src="$service.hexColorBrightness(previewColor) == 'dark' ? $service.getPlatformImagesPath('logo-dark.svg') : $service.getPlatformImagesPath('logo-light.svg')" alt="logo estabelecimento">
                  <img v-else class="logo" :src="getLogoUrl" alt="logo estabelecimento">
                </div>
              </div>

              <skeleton-loader-component v-else class="default-data" />
            </div>
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Chrome } from 'vue-color';
  import DefaultView from '@/components/Layout/DefaultView';
  import Header from '@/components/Layout/Header';
  import Modal from '@/components/Modal/Base';
  import SkeletonLoader from '@/components/Base/SkeletonLoader';
  import AccountDefaultCard from '@/components/AccountDefaultCard';
  import Button from '@/components/Base/Button';
  import InputFileField from '@/components/Base/InputFileField';
  import Divider from '@/components/Base/Divider';
  import SnackBar from '@/components/Base/SnackBar';

  export default {
    name: 'AccountCustomizationView',

    data() {
      return {
        hasRequest: false,
        isModalOpen: false,

        logoFile: null,

        header: {
          title: 'Personalização',
          to: '/account',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        logoInstructions: [
          {
            label: 'Formato',
            value: 'PNG'
          },

          {
            label: 'Dimensões',
            value: '180px x 75px'
          },

          {
            label: 'Tamanho máximo',
            value: '10kb'
          },
        ],

        cardData: {
          icon: 'icon-default',
          title: 'Personalização',
          text: 'Personalize nosso link de pagamento com a sua marca',
        },

        colors: {
          hex: this.$theme.primaryColor
        },

        previewColor: this.$theme.primaryColor,
      };
    },

    components: {
      'modal': Modal,
      'skeleton-loader-component': SkeletonLoader,
      'default-view-component': DefaultView,
      'header-component': Header,
      'default-card-component': AccountDefaultCard,
      'color-picker-component': Chrome,
      'input-file-field': InputFileField,
      'divider-component': Divider,
      'button-component': Button,
      'snack-bar-component': SnackBar
    },

    computed: {
      ...mapGetters({
        getCurrentSellerData: 'getCurrentSellerData',
        getCurrentSellerId: 'getCurrentSellerId',
        getDefaultColor: 'accountCustomizationModule/getDefaultColor',
        getLogoUrl: 'accountCustomizationModule/getLogoUrl',
        getCurrentSellerSaleStatus: 'getCurrentSellerSaleStatus'
      })
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          if (this.getCurrentSellerSaleStatus) {
            this.fetchSellerCustomization();
          } else {
            this.$router.push('/account');
          }
        }
      },

      getDefaultColor(value) {
        this.changeColor(value);
      }
    },

    mounted() {
      this.fetchSellerCustomization();

      this.changeColor(this.getDefaultColor);
    },

    methods: {
      closeModal() {
        this.isModalOpen = false;
      },

      async fetchSellerCustomization() {
        this.hasRequest = true;

        await this.$store.dispatch('accountCustomizationModule/getCustomization')
          .catch(() => {});

        this.hasRequest = false;
      },

      changeColor(hexColor) {
        this.colors.hex = hexColor ?? this.$theme.primaryColor;
        this.previewColor = hexColor ?? this.$theme.primaryColor;
      },

      async submit() {
        this.hasRequest = true;

        let hasError = false;

        if (this.colors.hex != this.getDefaultColor) {
          const data = {
            default_color: this.colors.hex
          };

          await this.$store.dispatch('accountCustomizationModule/postColor', JSON.stringify(data))
            .catch(() => {
              hasError = true;

              this.$refs?.snackbar?.show('Cor incorreta. Tente novamente!', '#FF5A5A');
            });
        }

        if (this.logoFile && !hasError) {
          let formData = new FormData();
          formData.append('file', this.logoFile);

          await this.$store.dispatch('accountCustomizationModule/postLogo', formData)
            .catch(() => {
              hasError = true;

              this.logoFile = null;
              this.$refs.fileField.removeFile();

              this.$refs?.snackbar?.show('Tamanho e/ou dimensões incorreto(s)', '#FF5A5A');
            });
        }

        if (!hasError) {
          this.$refs?.snackbar?.show('Configurações salvas como sucesso!', '#08B978');

          this.closeModal();
        }

        this.hasRequest = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .account-customization-view-container {
    .body {
      margin-top: 1.5rem;

      .divider {
        margin: 1rem 0;
      }

      .section {
        width: 100%;

        .center {
          display: flex;
          justify-content: center;
        }

        h3 {
          margin-bottom: 1rem;
        }

        .logo-instructions-container {
          justify-content: space-between;
          display: flex;
          width: 100%;
          margin-bottom: 1rem;

          .logo-instructions-content {
            p {
              color: #979797;

              &.label {
                color: #333;
                font-weight: 400;
              }
            }
          }
        }

        .file-field-component {
          width: 100%;
        }
      }
    }

    .footer-slot-container {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);

      .button-container {
        display: flex;

        .button-component {
          width: 100px;
          height: 48px;
          margin-left: 1rem;
        }
      }
    }

    .wrapper {
      padding: 1.5rem;

      .header-component {
        margin-bottom: 3rem;
      }

      .center {
        display: flex;
        justify-content: center;

        .card-container {
          margin-right: 3rem;
        }

        .content {
          max-width: 40rem;
          width: 100%;

          .header {
            display: flex;
            margin-bottom: 1rem;

            .title {
              color: #333;
              font-weight: 600;
              font-size: 16px;
            }

            .edit-button-container {
              margin-left: auto;

              .edit-button {
                display: flex;
                user-select: none;
                padding: 0.3rem 1rem;
                align-items: center;
                color: #8E8E8E;
                cursor: pointer;
                border-radius: 6px;
                font-weight: 500;

                i {
                  margin-right: 0.5rem;
                  color: #0052CC;
                }
              }
            }
          }

          .preview-container {
            padding: 12px;
            border-radius: 8px;
            height: 180px;

            .preview-content {
              display: flex;
              align-items: center;
              flex-direction: column;
              border: 1.5px dashed #DADADA;
              position: relative;
              height: 100%;

              &.light {
                border: 1.5px dashed #DADADA;
              }

              &.dark {
                border: 1.5px dashed #333;
              }

              .logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &.default {
                  width: 200px;
                }
              }
            }
          }

          .default-data {
            height: 180px;
          }
        }
      }
    }
  }

  @media (max-width: 976px) {
    .account-customization-view-container {
      .wrapper {
        .center {
          display: unset;

          .card-container {
            display: none;
          }
        }
      }
    }
  }
</style>