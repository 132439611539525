<template>
  <div class="login-view-container">
    <image-view-component hasCokieConsent :showImage="$service.isDefaultPlatform()" :image="$service.getPlatformImagesPath('image-login.png')">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:form>
        <ValidationObserver
          tag="div"
          class="form"
          ref="form"
          v-slot="{ passes }"
        >
          <form @submit.prevent="passes(login)">
            <h3>Acessar</h3>

            <input-field
              name="username"
              rules="required"
              :isDisabled="hasRequest"
              :maxLength="hasTaxPayerId ? 14 : 100"
              placeholder="CPF ou E-mail"
              @keydown.native.space.prevent
              v-model="data.username"
            />

            <input-field
              name="password"
              placeholder="Senha"
              rules="required|min:6"
              :isDisabled="hasRequest"
              v-model="data.password"
              type="password"
            />

            <button-component
              text="Acessar"
              styles="primary"
              size="large"
              :hasLoader="hasRequest"
              :isDisabled="hasRequest"
              v-ripple="$theme.whiteColorTransparent"
            />
          </form>
        </ValidationObserver>
      </template>

      <template v-slot:options>
        <div class="options">
          <div class="top">
            <!-- <div class="checkbox-container">
              <checkbox-component
                class="checkbox-component"
                v-model="loggedInStatus"
              />

              Continuar conectado
            </div> -->

            <a @click="redirectToWpp">
              Problemas ao acessar ?
            </a>
          </div>

          <!-- <divider-component class="divider" color="#DADADA" />

          <div class="bottom">
            <p>Ainda não tem uma conta?</p>

            <button-component
              text="Abra sua conta"
              styles="ghost"
              size="large"
              pathView="/signup"
              v-ripple="$theme.secondColorDarkTransparent"
            />
          </div> -->
        </div>
      </template>
    </image-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ValidationObserver } from 'vee-validate';
  import ImageView from '@/components/Layout/ImageView';
  import InputField from '@/components/Base/InputField';
  import Button from '@/components/Base/Button';
  import SnackBar from '@/components/Base/SnackBar';
  // import Checkbox from '@/components/Base/Checkbox';
  // import Divider from '@/components/Base/Divider';

  export default {
    name: 'LoginView',

    data() {
      return {
        data: {
          username: '',
          password: '',
        },

        hasTaxPayerId: false,
        loggedInStatus: true,
        hasRequest: false,
      };
    },

    components: {
      ValidationObserver,
      'image-view-component': ImageView,
      'button-component': Button,
      'input-field': InputField,
      'snackbar-component': SnackBar,
      // 'checkbox-component': Checkbox,
      // 'divider-component': Divider
    },

    computed: {
      ...mapGetters({
        getRedirectPath: 'authModule/getRedirectPath',
      })
    },

    watch: {
      'data.username'(value) {
        let regNumber = /[0-9]/;

        if (value != '') {
          this.hasTaxPayerId = regNumber.test(this.$service.removeCharacter(value))
            && this.$service.removeCharacter(value).length === 11;

          if (this.hasTaxPayerId) {
            this.data.username = this.$service.formatTaxpayerId(value);
          }
        }
      }
    },

    mounted() {
      if (this.getRedirectPath) {
        this.showSnackBar('Sessão expirada, entre novamente!', '#006EFF');
      }
    },

    methods: {
      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },

      async login() {
        let status = this.loggedInStatus;
        let isAuthenticated = false;
        let user = JSON.stringify({
          username: this.data.username,
          password: this.data.password,
        });

        this.hasRequest = true;

        this.$store.dispatch('logout');

        await this.$store.dispatch('postLogin', { user, status })
          .then(() => {
            isAuthenticated = true;
          })
          .catch(() => {
            this.$refs.form.setErrors({
              username: 'Usuário ou senha incorretos',
            });
          });

        if (isAuthenticated) {
          await this.fetchData();
        }

        this.hasRequest = false;
      },

      async fetchData() {
        await this.$store.dispatch('getUser')
          .catch(() => {
            this.logout();
            this.setOtherError();
          });

        await this.$store.dispatch('getAllSellers')
          .then(res => {
            this.$store.commit('setAllSellers', res.data);

            if (res.data.length > 0) {
              const currentSeller = res.data[0];
              this.$store.commit('setCurrentSellerId', currentSeller.id);

              const redirectPath = this.getRedirectPath;
              if (redirectPath) {
                this.$router.push(redirectPath);
              } else {
                this.$router.push('/dashboard');
              }

              this.$store.commit('authModule/setRedirectPath', null);
            } else {
              this.$router.push('/accreditation');
            }
          })
          .catch(() => {
            this.logout();
            this.setOtherError();
          });
      },

      // toForgotView() {
      //   this.$router.push('/forgot');
      // },

      redirectToWpp() {
        const text = `Olá, estou com problemas ao entrar na minha conta do ${this.$service.getPlatformTitle()}.`;
        this.$service.openWpp(text);
      },

      setOtherError() {
        this.$refs.form.setErrors({
          username: 'Ocorreu um erro. Tente novamente',
        });
      },

      logout() {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/').catch(() => {});
          });
      }
    }
  };
</script>

<style style lang="scss" scoped>
  .login-view-container {
    .form {
      color: #333;
      width: 100%;
      max-width: 395px;
      align-items: center;
      display: flex;

      form {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;

        h3 {
          margin-bottom: 16px;
        }
      }
    }

    .options {
      width: 100%;
      color: #333;
      margin-top: 2rem;
      max-width: 395px;

      .top {
        display: flex;
        margin-bottom: 2rem;
        font-size: 14px;

        .checkbox-container {
          display: flex;
          align-items: center;
        }

        a {
          cursor: pointer;
          margin-left: auto;
          color: #0052CC;
          text-decoration: underline;
        }
      }

      .bottom {
        margin-top: 2rem;
        text-align: center;

        p {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .login-view-container {
      .options {
        margin-top: 1rem;

        .top {
          flex-direction: column;
          align-items: center;

          a {
            margin-top: 0.5rem;
            margin-left: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
</style>