<template>
  <div
    class="card-container"
    :class="{ 'active': data.isActive, 'disabled': isDisabled }"
    :style="styleObject"
    @click="onClick"
  >
    <div class="icon-container">
      <i :class="data.icon"/>
    </div>

    <div class="wrapper">
      <p class="status">{{ data.status }}</p>

      <p class="value">{{ formattedValue }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TransactionTableCard',

    data() {
      return {
        formattedValue: 'R$ 0,00'
      };
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            baseColor: '#FF5A5A',
            icon: 'icon-arrow-down-circle',
            status: 'Saídas',
            value: 'R$ 200,00',
            valueIsNegative: true,
            isActive: false,
          };
        }
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    watch: {
      'data.value'(value) {
        this.formattedValue = this.$service.formatBrlAmountSymbol(value);
      }
    },

    computed: {
      styleObject: function () {
        return {
          '--color': this.data.baseColor
        };
      }
    },

    methods: {
      onClick() {
        if (!this.isDisabled) {
          this.$emit('onClick');
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card-container {
    display: flex;
    border-radius: 6px;
    border: 1px solid #DADADA;
    align-items: center;
    padding: 0.5rem 1rem;
    font-weight: 600;
    cursor: pointer;
    justify-content: center;

    &.disabled {
      background: #EFEFEF;
      cursor: not-allowed;
      opacity: 0.6;

      .icon-container {
        i {
          color: #6D6F6F !important;
        }
      }

      p {
        color: #6D6F6F !important;
      }

      &.active {
        background: #DADADA;
        border: 1px solid #DADADA;
      }
    }

    .wrapper {
      display: flex;
    }

    .status {
      font-weight: 400;
    }

    .value {
      white-space: nowrap;
    }

    &.active {
      background: var(--color);
      border: 1px solid var(--color);

      .icon-container {
        i {
          color: $white-color;
        }
      }

      p {
        &.status {
          color: $white-color;
        }

        &.value {
          color: $white-color;
        }
      }
    }

    .icon-container {
      i {
        font-size: 1.2rem;
        color: var(--color);
      }
    }

    p {
      margin-left: 0.5rem;

      &.status {
        color: #6D6F6F;
      }

      &.value {
        display: flex;
        font-weight: 600;
        color: var(--color);

        span {
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .card-container {
      width: 100%;
      padding: 0.5rem 0.6rem;

      .wrapper {
        flex-direction: column;
        justify-content: unset;
      }
    }
  }
</style>