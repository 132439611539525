<template>
  <div class="appointment-calendar-container">
    <div v-if="hasRequest" class="spinner-container">
      <spinner-component class="spinner" color="#DADADA"/>
    </div>

    <v-date-picker
      v-show="!hasRequest"
      :masks="masks"
      ref="calendar"
      :attributes="attributes"
      :first-day-of-week="1"
      is-range
      @update:from-page="pageChange"
      v-model="range"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="day-details" @click="setDay(day)">
          <p class="day">{{ day.day }}</p>

          <div class="appointment-container">
            <div v-for="(attr, index) in attributes" :key="index">
              <div v-if="attr.customData && attr.customData.length > 0">
                <div v-for="(data, index) in attr.customData" :key="index">
                  <div class="appointment-content">
                    <div class="tag" :style="{ background: data.color }"></div>

                    <p>
                      <span>{{ data.hour }}</span> {{ data.label }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
  import Spinner from '@/components/Base/Spinner';

  export default {
    name: 'AppointmentCalendar',

    data() {
      return {
        masks: {
          title: 'MMMM/YYYY',
          weekdays: 'WWW'
        },

        range: {
          start: '',
          end: ''
        }
      };
    },

    props: {
      value: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      },

      hasRequest: {
        type: Boolean,
        default: false
      },

      attributes: {
        type: Array,
        require: false
      }
    },

    components: {
      'spinner-component': Spinner
    },

    watch: {
      value: {
        handler() {
          this.range = this.value;
        },
        deep: true
      },
    },

    async mounted() {
      this.range = this.value;

      if (this.range.start) {
        await this.$refs.calendar?.move(this.range.start);
      }
    },

    methods: {
      pageChange() {
        if (!this.$service.isUndefined(this.$refs.calendar)) {
          let date = this.$refs.calendar.$refs.calendar.lastPage.year + '-' + this.$refs.calendar.$refs.calendar.lastPage.month;

          this.$emit('changePage', {
            start: this.$service.getStartDateMonth(date, 'YYYY-MM'),
            end: this.$service.getEndDateMonth(date, 'YYYY-MM')
          });
        }
      },

      setDay(day) {
        if (!this.$service.isEqual(day.id, this.$service.dateFormat(this.range.start))) {
          this.range = {
            start: this.$service.toDate(day.id),
            end: this.$service.toDate(day.id)
          };

          this.$emit('input', this.range);
          this.$emit('changeDay', this.range);
        }
      }
    }
  };
</script>

<style lang="scss">
  .appointment-calendar-container {
    .spinner-container {
      min-width: 750px;
      display: flex;
      justify-content: center;

      .spinner {
        margin-top: 1rem;
      }
    }

    .vc-container {
      font-family: 'Poppins', sans-serif;
      min-width: 750px;

      .vc-pane-container {
        .vc-header {
          padding: 0.5rem;
          border-bottom: 1px solid #DADADA;

          .vc-title {
            color: #333;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
          }
        }

        .vc-weeks {
          padding: 0;

          .vc-weekday {
            text-transform: capitalize;
            color: #6D6F6F;
            font-weight: 400;
            border-bottom: 1px solid #DADADA;
            padding: 0.5rem;
          }

          .vc-day {
            color: #6D6F6F;
            height: 100px;
            width: 110px;
            background: transparent;

            &.vc-day-box-center-center {
              display: block;
            }

            .vc-highlights {
              .vc-day-layer {
                .vc-highlight {
                  height: 100%;
                  width: 100%;
                  background-color: transparent !important;
                  border-radius: unset !important;
                  border: 2px solid $second-color !important;

                  &.vc-highlight-base-start {
                    width: 100% !important;
                  }

                  &.vc-highlight-base-end {
                    width: 100% !important;
                  }

                  &.vc-highlight-base-middle {
                    background-color: $second-color-light-1 !important;
                    border-radius: 0 !important;
                    border: none !important;
                  }
                }
              }
            }

            .day-details {
              height: 100%;
              cursor: pointer;

              .day {
                border-radius: unset;
                font-size: 16px;
                font-weight: 400 !important;
                width: 100%;
                background: transparent;
                display: block;
                padding: 0.5rem;
                color: #6D6F6F !important;
              }

              .appointment-container {
                height: 55px;
                overflow-y: auto;
                scrollbar-width: thin;

                &::-webkit-scrollbar {
                  width: 0;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: transparent;
                }

                .appointment-content {
                  padding: 2px 5px;
                  display: flex;
                  align-items: center;

                  .tag {
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    flex-shrink: 0;
                  }

                  p {
                    margin-left: 4px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 10px;
                    color: #333;

                    span {
                      color: #6D6F6F;
                    }
                  }
                }
              }
            }

            &:not(.on-bottom) {
              border-bottom: 1px solid #DADADA;

              &.weekday-1 {
                border-bottom: 1px solid #DADADA;
              }
            }

            &:not(.on-right) {
              border-right: 1px solid #DADADA;
            }
          }
        }
      }
    }
  }
</style>