<template>
  <div>
    <simple-default-view-component v-if="!hasRequest" :hasModal="cancelSaleModal || receiptModal" class="simple-default-view-component">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <modal
          v-if="cancelSaleModal"
          ref="modal"
          class="modal cancel"
          title="Cancelar venda ?"
          :showModal="cancelSaleModal"
          @close="closeCancelSaleModal"
        >
          <template v-slot:body>
            <ValidationObserver
              v-slot="{ passes }"
              ref="form"
            >
              <form class="body" @submit.prevent="passes(submitRefund)">
                <p>Valor da venda <b>{{ $service.formatBrlAmountSymbol(currentTransactionAmount) }}</b> - Valor do estorno <b>{{ $service.formatBrlAmountSymbol(cancelAmount) }}</b></p>

                <div class="amount-input">
                  <input-field-component
                    class="input-filed-component"
                    type="money"
                    :rules="inputMoneyRules()"
                    placeholder="Valor"
                    name="amount"
                    ref="input"
                    :maxLength="12"
                    v-model="cancelAmount"
                  />

                  <div class="total-amount-button" @click="setTotalCancelAmount">
                    Valor total
                  </div>
                </div>

                <button-component
                  class="button-component"
                  text="Cancelar venda"
                  :isDisabled="hasRefundRequest"
                  :hasLoader="hasRefundRequest"
                />
              </form>
            </ValidationObserver>
          </template>
        </modal>

        <modal
          v-if="receiptModal"
          ref="modal"
          class="modal"
          title="Recibo"
          :showModal="receiptModal"
          @close="closeReceiptModal"
        >
          <template v-slot:body>
            <div class="body">
              <div class="content" ref="receiptPrint">
                <img src="@/assets/images/logo-mono-positive.png">

                <pre>
                  {{ receiptDataSrc }}
                </pre>
              </div>
            </div>
          </template>

          <template v-slot:footer>
            <div class="footer">
              <button-component
                class="button-component"
                text="Baixar recibo"
                iconLeft="icon-print"
                :isDisabled="hasReceiptDownload"
                :hasLoader="hasReceiptDownload"
                @click.native="downloadReceipt"
              />
            </div>
          </template>
        </modal>
      </template>

      <template v-slot:header>
        <div class="title">
          <h2>Detalhes da venda</h2>

          <div class="more-details">
            <i class="icon-circle"/>

            <p class="type">{{ data.header.method }}</p>

            <i class="icon-circle"/>

            <p>Data <span>{{ data.header.createdDate }}</span></p>

            <div v-if="data.header.authorizationCode" class="authorization-code">
              <i class="icon-circle"/>

              <p>Cód. de autorização <span>{{ data.header.authorizationCode }}</span></p>
            </div>
          </div>
        </div>

        <div class="icon-container">
          <i class="icon-close" @click="close"/>
        </div>
      </template>

      <template v-slot:content>
        <div class="content">
          <div class="column detail">
            <div v-if="!hideReceiptButton" class="buttons-container">
              <button-component
                class="button-component"
                text="Recibo"
                :isDisabled="hasReceiptRequest"
                :hasLoader="hasReceiptRequest"
                @click.native="showReceiptModal"
              />
            </div>

            <div class="details-card-container">
              <div class="details-card">
                <div class="details-option">
                  <transaction-status-component :status="data.content.status" />

                  <i v-if="dropdownOptionsData.options.length > 0" class="icon-more" @click="openDropdown"/>

                  <div class="dropdown-component-container">
                    <dropdown-component class="dropdown-component" :data="dropdownOptionsData" @newOption="showCancelSaleModal" />
                  </div>
                </div>

                <div class="details-header">
                  <p>{{ data.content.paymentMethod }}</p>

                  <a v-if="transactionType == 'BOLETO'" :href="getBoletoUrl()" target="_blank">
                    <i class="icon-eye" />

                    Segunda via
                  </a>

                  <p>{{ data.content.amount }}</p>
                </div>

                <div class="line"></div>

                <div class="items-container">
                  <div v-for="(column, index) in data.content.columns" :key="index" class="items-content">
                    <div v-for="(row, index) in column.rows" :key="index">
                      <div v-if="!!row.value" class="item">
                        <div class="label">
                          <i v-if="row.icon" :class="row.icon"></i>
                          {{ row.label }}
                        </div>

                        <div class="value">
                          {{ row.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column table">
            <div class="section-container">
              <h3>Histórico da venda</h3>

              <table-component class="table-component" :table="historyTableData" />
            </div>

            <div class="section-container">
              <h3>Transferência do valor líquido da venda</h3>

              <table-component class="table-component" :table="receivablesTableData" />
            </div>
          </div>
        </div>
      </template>
    </simple-default-view-component>

    <div v-else class="spinner">
      <spinner-component color="#DADADA"/>
    </div>
  </div>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import { mapGetters } from 'vuex';
  import SimpleDefaultView from '@/components/Layout/SimpleDefaultView';
  import TransactionStatus from '@/components/TransactionStatus';
  import Dropdown from '@/components/Base/Dropdown';
  import InputField from '@/components/Base/InputField';
  import Table from '@/components/Table/Table';
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';
  import Spinner from '@/components/Base/Spinner';
  import SnackBar from '@/components/Base/SnackBar';

  export default {
    name: 'TransactionDetail',

    data() {
      return {
        cancelSaleModal: false,
        receiptModal: false,
        cancelSale: false,
        hasRequest: false,
        hasReceiptRequest: false,
        hasRefundRequest: false,
        hideReceiptButton: false,
        receiptData: {},
        transactionData: {},
        receiptDataSrc: '',
        receiptImage: '',
        hasReceiptDownload: false,
        cancelAmount: 0,
        currentTransactionAmount: 0,
        receivablesData: {},
        hasCancelledLetter: false,
        transactionType: '',

        customerId: '',
        customerName: '',

        data: {
          header: {
            createdDate: '',
            method: '',
            authorizationCode: ''
          },

          content: {
            status: '',
            amount: '',
            columns: []
          }
        },

        dropdownOptionsData: {
          show: false,
          hasActive: false,
          options: []
        },

        historyTableData: {
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Data',
                field: 'date',
                align: 'left',
              },

              {
                label: 'Status',
                field: 'status',
                align: 'center',
                isStatus: true
              },

              {
                label: 'Valor',
                field: 'amount',
                align: 'right',
              }
            ]
          },

          rows: []
        },

        receivablesTableData: {
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Data prevista',
                field: 'expectedDate',
                align: 'left'
              },

              {
                label: 'Status',
                field: 'status',
                align: 'center',
                isStatus: true
              },

              {
                label: 'Parcela',
                field: 'installment',
                align: 'center'
              },

              {
                label: 'Valor bruto',
                field: 'grossAmount',
                align: 'center'
              },

              {
                label: 'Taxa de venda',
                field: 'fee',
                align: 'center'
              },

              {
                label: 'Taxa de antecipação',
                field: 'anticipationFee',
                align: 'center'
              },

              {
                label: 'Valor líquido',
                field: 'amount',
                align: 'right'
              }
            ]
          },

          rows: []
        }
      };
    },

    components: {
      ValidationObserver,
      'simple-default-view-component': SimpleDefaultView,
      'transaction-status-component': TransactionStatus,
      'input-field-component': InputField,
      'table-component': Table,
      'button-component': Button,
      'spinner-component': Spinner,
      'dropdown-component': Dropdown,
      'modal': Modal,
      'snackbar-component': SnackBar,
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerData',
        'getSubBalance'
      ])
    },

    props: {
      detailId: {
        type: String,
        required: true
      }
    },

    created() {
      this.initFetchData();
    },

    mounted() {
      history.pushState({}, '', this.$router.history.current.path);

      window.onpopstate = () => {
        this.close();
      };
    },

    methods: {
      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },

      async downloadReceipt() {
        this.hasReceiptDownload = true;

        const a = document.createElement('a');
        const options = {
          type: 'dataURL'
        };

        this.receiptImage = '';
        this.receiptImage = await this.$html2canvas(this.$refs.receiptPrint, options);

        a.href = this.receiptImage;
        a.download = 'recibo-impay-' + this.receiptData.id;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.hasReceiptDownload = false;
      },

      downloadCancelledLetter() {
        this.$store.dispatch('getTransactionCancelledLetter', this.detailId)
          .then(res => {
            const a = document.createElement('a');
            a.href = res.data.url;
            a.download = 'carta-cancelamento-impay-' + this.detailId;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch(err => {
            console.log(err);
          });
      },

      openDropdown() {
        this.dropdownOptionsData.show = true;
      },

      inputMoneyRules() {
        let rules = 'required|maxEqualMoney:' + this.currentTransactionAmount + ':' + this.cancelAmount;

        return rules;
      },

      setTotalCancelAmount() {
        this.cancelAmount = this.currentTransactionAmount;
      },

      async submitRefund() {
        this.hasRefundRequest = true;
        var isAllSucceeded = this.receivablesData.every(item => this.$service.toUpperCase(item.status) == 'SUCCEEDED');
        var hasError = false;

        if (this.cancelAmount <= 0) {
          hasError = true;

          this.$refs.form.setErrors({
            amount: 'Valor maior que R$ 0,00'
          });
        }

        if (isAllSucceeded) {
          if (parseFloat(this.getSubBalance) < parseFloat(this.amount)) {
            hasError = true;

            this.showSnackBar('Erro ao cancelar, entre em contato com o suporte.', '#FF5A5A');
            this.closeCancelSaleModal();
          }
        }

        if (!hasError) {
          var refundData = JSON.stringify({
            amount: this.cancelAmount * 100
          });

          await this.$store.dispatch('postTransactionRefund', { id: this.detailId, data: refundData })
            .then(() => {
              this.receiptDataSrc = '';
              this.fetchTransaction();
              this.fetchReceivablesTransaction();

              this.showSnackBar('Cancelamento realizado com sucesso!', '#08B978');
            })
            .catch(() => {
              this.showSnackBar('Erro ao cancelar, entre em contato com o suporte.', '#FF5A5A');
            });

            this.closeCancelSaleModal();
        }

        this.hasRefundRequest = false;
      },

      async initFetchData() {
        this.hasRequest = true;

        await this.fetchTransaction();
        if (this.transactionData?.payment) {
          await this.fetchReceivablesTransaction();

          // Sync customer
          if (this.customerId) {
            await this.$store.dispatch('customerModule/getCustomerId', this.customerId)
              .then(res => {
                const { first_name, last_name } = res.data;

                this.customerName = `${first_name} ${last_name ?? ''}`;
              })
              .catch(err => {
                console.log(err);
              });
          }

          this.data = this.formatData(this.transactionData);
        }

        this.hasRequest = false;
      },

      async fetchTransaction() {
        await this.$store.dispatch('getTransactionId', this.detailId)
          .then(res => {
            this.transactionData = res.data;
            this.hasCancelledLetter = this.transactionData.history.some(item => this.$service.toUpperCase(item.operation_type) == 'VOID') && this.$service.toUpperCase(this.transactionData.payment.method) == 'CREDITCARD';
            this.currentTransactionAmount = this.transactionData.amount;
            this.historyTableData.rows = this.formatHistoryTableRows(this.transactionData);
            this.cancelSale = this.hasCancelSale(this.transactionData);
            this.transactionType = this.$service.toUpperCase(this.transactionData.payment.method);
            this.hideReceiptButton = this.$service.toUpperCase(this.transactionData.status) != 'SUCCEEDED' || this.transactionType == 'BOLETO';
            this.dropdownOptionsData.options = this.formatDropdownOptions();
            this.customerId = this.transactionData.customer_id;
          })
          .catch(err => {
            console.log(err);

            this.$emit('close');
          });
      },

      formatDropdownOptions() {
        var formattedOptions = [];

        if (this.cancelSale) {
          formattedOptions.push({
            label: 'Cancelar venda',
            value: 'cancel_sale',
            icon: 'icon-close-circle-outline',
            isActive: false
          });
        }

        //! The cancellation letter not working in sandbox/development environment
        if (this.hasCancelledLetter && this.$service.toUpperCase(process.env.NODE_ENV) == 'PRODUCTION') {
          formattedOptions.push({
            label: 'Carta de cancelamento',
            value: 'cancelled_letter',
            icon: 'icon-file',
            isActive: false
          });
        }

        return formattedOptions;
      },

      async fetchReceivablesTransaction() {
        await this.$store.dispatch('getReceivablesTransaction', { transactionId: this.detailId })
          .then(res => {
            this.receivablesData = res.data;
            this.receivablesTableData.rows = this.formatReceivablesTableRows(res.data);
          })
          .catch(err => {
            console.log(err);
          });
      },

      formatData(data) {
        var paymentMethod = data.payment.method;
        var installments;

        if (data.payment.credit_card && data.payment.credit_card.installments.count > 1) {
          paymentMethod = 'CREDITCARD_INSTALLMENT';
          installments = data.payment.credit_card.installments.count;
        }

        return {
          header: {
            createdDate: this.$service.dateFormat(data.created_at, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YY - HH:mm'),
            method: this.formatPaymentMethodHeader(data.payment),
            authorizationCode: this.formatHistoryAuthorizationCode(data.history, data.payment.method),
          },

          content: {
            status: data.status,
            amount: this.$service.formatBrlAmountSymbol(data.amount),
            paymentMethod: this.$service.formatPaymentMethodName(paymentMethod, installments),
            columns: this.formatColumns(data, data.payment.method)
          }
        };
      },

      formatPaymentMethodHeader(payment) {
        let method;

        switch (this.$service.toUpperCase(payment.method)) {
          case 'PIX':
            method = 'Pix';
            break;
          case 'BOLETO':
            method = 'Boleto';
            break;
          case 'CREDITCARD':
            method = 'Maquininha';

            if (this.$service.toUpperCase(payment.point_of_sale.entry_mode) === 'MANUALLY_KEYED') {
              method = 'Online';
            }
            break;
          case 'DEBIT':
            method = 'Maquininha';
            break;
          case 'COMMISSION':
            method = 'Comissão';
            break;
          default:
            method = 'Outro';
        }

        return method;
      },

      formatHistoryAuthorizationCode(history, method) {
        let formattedAuthorizationCode = null;

        history.forEach(item => {
          if (this.$service.toUpperCase(method) == 'CREDITCARD' || this.$service.toUpperCase(method) == 'DEBIT'
            && this.$service.toUpperCase(item.operation_type) == 'AUTHORIZATION' && this.$service.toUpperCase(item.status) == 'SUCCEEDED') {
            formattedAuthorizationCode = item.authorization_nsu;
          } else if (this.$service.toUpperCase(method) == 'PIX' && this.$service.toUpperCase(item.operation_type) == 'PAID' && this.$service.toUpperCase(item.status) == 'SUCCEEDED' ) {
            formattedAuthorizationCode = item.authorization_code;
          }
        });

        return formattedAuthorizationCode;
      },

      formatColumns(data, paymentMethod) {
        let formattedColumns;

        switch (this.$service.toUpperCase(paymentMethod)) {
          case 'CREDITCARD':
            formattedColumns = this.formatCardDetailsColumns(data);
            break;
          case 'PIX':
            formattedColumns = this.formatPixDetailsColumns(data);
            break;
          case 'BOLETO':
            formattedColumns = this.formatBoletoDetailsColumns(data);
            break;
          case 'DEBIT':
            formattedColumns = this.formatDebitDetailsColumns(data);
            break;
        }

        return formattedColumns;
      },

      formatDebitDetailsColumns(data) {
        return [
          {
            rows: [
              {
                icon: 'icon-credit-card',
                label: data.payment.debit.brand,
                value: 'Nº do cartão ' + data.payment.debit.first_4_digits + ' **** **** ' + data.payment.debit.last_4_digits
              },

              {
                label: 'Titular do cartão',
                value: !this.$service.isNull(data.payment.debit.holder_name) ? this.$service.toCapitalize(data.payment.debit.holder_name) : ''
              },

              {
                label: 'ID da transação',
                value: data.id
              },

              {
                label: 'Modo',
                value: this.$service.formatPaymentTypeName(data.payment.point_of_sale.entry_mode, data.payment.method)
              }
            ]
          },

          {
            rows: [
              {
                label: 'Validade',
                value: this.$service.dateFormat(data.payment.debit.expiration_month + '/' + data.payment.debit.expiration_year, 'M/YYYY', 'MM/YY')
              }
            ]
          }
        ];
      },

      formatPixDetailsColumns(data) {
        return [
          {
            rows: [
              {
                label: 'Chave aleatória',
                value: data.payment.pix.key.value
              },

              {
                label: 'ID da transação',
                value: data.id
              },

              {
                label: 'Provedor',
                value: data.payment.pix.provider
              },

              {
                label: 'Modo',
                value: this.$service.formatPaymentTypeName(data.payment.point_of_sale.entry_mode, data.payment.method)
              }
            ]
          },

          {
            rows: [
              {
                label: 'Tipo',
                value: this.formatPixType(data.payment.pix.type)
              }
            ]
          }
        ];
      },

      formatCardDetailsColumns(data) {
        return [
          {
            rows: [
              {
                icon: 'icon-credit-card',
                label:  data.payment.credit_card.brand,
                value: 'Nº do cartão ' + data.payment.credit_card.first_4_digits + ' **** **** ' + data.payment.credit_card.last_4_digits
              },

              {
                label: 'Titular do cartão',
                value: !this.$service.isNull(data.payment.credit_card.holder_name) ? this.$service.toCapitalize(data.payment.credit_card.holder_name) : ''
              },

              {
                label: 'ID da transação',
                value: data.id
              },

              {
                label: 'Modo',
                value: this.$service.formatPaymentTypeName(data.payment.point_of_sale.entry_mode, data.payment.method)
              }
            ]
          },

          {
            rows: [
              {
                label: 'Validade',
                value: this.$service.dateFormat(data.payment.credit_card.expiration_month + '/' + data.payment.credit_card.expiration_year, 'M/YYYY', 'MM/YY')
              }
            ]
          }
        ];
      },

      formatBoletoDetailsColumns(data) {
        return [
          {
            rows: [
              {
                label: 'Vencimento',
                value: this.$service.dateFormat(data.payment.boleto.due_date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YY')
              },

              {
                label: 'ID da transação',
                value: data.id
              },

              {
                label: 'ID de referência',
                value: data.reference_id
              },

              {
                label: 'Modo',
                value: this.$service.formatPaymentTypeName(data.payment.point_of_sale.entry_mode, data.payment.method)
              },

              {
                label: 'Cliente',
                value: this.customerName
              }
            ]
          },

          {
            rows: [
              {
                label: 'Nº documento',
                value: data.payment.boleto.reference_number
              }
            ]
          }
        ];
      },

      showCancelSaleModal() {
        const currentActiveOption = this.dropdownOptionsData.options.filter(item => item.isActive)[0];

        switch (this.$service.toUpperCase(currentActiveOption.value)) {
          case 'CANCELLED_LETTER':
            this.downloadCancelledLetter();
            break;
          case 'CANCEL_SALE':
            this.cancelSaleModal = true;
            break;
        }

        this.dropdownOptionsData.show = false;
        this.dropdownOptionsData.options.map(item => item.isActive = false);
      },

      async showReceiptModal() {
        var succeeded = false;
        this.hasReceiptRequest = true;

        if (this.$service.isBlank(this.receiptDataSrc)) {
          await this.$store.dispatch('getReceipt', this.detailId)
            .then(res => {
              this.receiptData = res.data;
              let salesReceiptCardHolder = this.receiptData.original_receipt.sales_receipt_cardholder.split('@');

              salesReceiptCardHolder.forEach(item => {
                this.receiptDataSrc += item + '\n';
              });

              succeeded = true;
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          succeeded = true;
        }

        if (succeeded) {
          this.receiptModal = true;
        }

        this.hasReceiptRequest = false;
      },

      closeCancelSaleModal() {
        this.cancelSaleModal = false;

        this.cancelAmount = 0;
      },

      closeReceiptModal() {
        this.receiptModal = false;
      },

      formatPixType(type) {
        let formattedType = '';

        switch (this.$service.toUpperCase(type)) {
          case 'DYNAMIC':
            formattedType = 'Dinâmico';
            break;
          default:
            formattedType = 'Outro';
        }

        return formattedType;
      },

      formatReceivablesTableRows(data) {
        let formattedReceivablesArr = [];

        data.forEach(item => {
          formattedReceivablesArr.push({
            expectedDate: this.$service.dateFormat(item.expected_on, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YY - HH:mm'),
            status: item.status,
            installment: item.installment ? item.installment : '-',
            grossAmount: this.$service.formatBrlAmountSymbol(item.gross_amount),
            fee: this.$service.formatBrlAmountSymbol(item.gross_amount - item.amount),
            anticipationFee: item.anticipation_fee ? this.$service.formatBrlAmountSymbol(item.anticipation_fee) : '-',
            amount: this.$service.formatBrlAmountSymbol(item.amount)
          });
        });

        formattedReceivablesArr.sort((a, b) => a.installment - b.installment);
        if (formattedReceivablesArr.length) formattedReceivablesArr[formattedReceivablesArr.length - 1].noBorder = true;

        return formattedReceivablesArr;
      },

      formatHistoryTableRows(data) {
        let formattedHistoryArr = [];

        data.history.forEach(item => {
          formattedHistoryArr.push({
            date: this.$service.dateFormat(item.created_at, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YY - HH:mm'),
            status: 'CUSTOM',
            customStatus: {
              label: this.formatHistoryStatus(item),
              background: '#FFFFFF',
              color: '#333333',
              borderColor: '#DADADA'
            },
            amount: this.$service.formatBrlAmountSymbol(item.amount),
          });
        });

        formattedHistoryArr.push({
          date: 'Status atual',
          status: data.status,
          amount: this.$service.formatBrlAmountSymbol(data.amount),
          isBold: true,
          noBorder: true
        });

        return formattedHistoryArr;
      },

      formatHistoryStatus(history) {
        let formattedStatus;

        switch (this.$service.toUpperCase(history.operation_type)) {
          case 'CREATED':
            formattedStatus = 'Criada';
            break;
          case 'AUTHORIZATION':
            formattedStatus = 'Não autorizada';

            if (this.$service.toUpperCase(history.status) == 'SUCCEEDED') {
              formattedStatus = 'Autorizada';
            }
            break;
          case 'EXPIRATION':
            formattedStatus = 'Expirada';
            break;
          case 'PROPOSAL':
            formattedStatus = 'Proposta';
            break;
          case 'VOID':
            formattedStatus = 'Cancelamento Parcial';

            if (parseFloat(history.amount) == parseFloat(this.transactionData.amount) && this.$service.toUpperCase(this.transactionData.status) == 'CANCELED') {
              formattedStatus = 'Cancelamento Total';
            }
            break;
          default:
            formattedStatus = 'Em análise';
        }

        return formattedStatus;
      },

      hasCancelSale(data) {
        let lastDate = this.$service.dateFormat(data.updated_at, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD');
        let currentDate = this.$service.currentDateEN();
        let intervalDate = this.$service.dateIntervalDays(lastDate, currentDate, 'YYYY-MM-DD');
        let payment = data.payment;
        let cancelSale = false;

        if (this.$service.toUpperCase(data.status) == 'PENDING' || this.$service.toUpperCase(data.status) == 'SUCCEEDED') {
          switch (this.$service.toUpperCase(payment.method)) {
            case 'PIX':
              if (intervalDate < 90) cancelSale = true;
              break;
            case 'CREDITCARD':
              if (this.$service.toUpperCase(payment.point_of_sale.entry_mode) === 'MANUALLY_KEYED') {
                if (intervalDate < 90) cancelSale = true;
              } else {
                if (intervalDate < 30) cancelSale = true;
              }
              break;
            case 'DEBIT':
              if (intervalDate < 7) cancelSale = true;
              break;
          }
        }

        return cancelSale;
      },

      getBoletoUrl() {
        return this.transactionData.payment.boleto.url;
      },

      close() {
        var currentTransaction = {
          id: this.transactionData.id,
          amount: this.transactionData.amount,
          fee: this.transactionData.payment.fees_amount,
          status: this.transactionData.status,
          customerName: this.customerName
        };

        this.$emit('close', currentTransaction);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .spinner {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .simple-default-view-component {
    .button-component {
      width: 155px;
      height: 40px;
    }

    .buttons-container {
      display: flex;

      .button-component {
        margin-right: 1rem;
        background: $first-color;

        &:hover {
          background: #304051;
        }

        &.void {
          margin-left: auto;
          margin-right: 0;
          background: #FF5A5A;

          &:hover {
            background: #e53c3c;
          }
        }
      }
    }

    .modal {
      &.cancel {
        .button-component {
          background: #FF5A5A;

          &:hover {
            background: #e53c3c;
          }
        }
      }

      .body {
        display: flex;
        align-items: center;
        flex-direction: column;

        .amount-input {
          display: flex;
          margin-bottom: 2rem;

          .total-amount-button {
            margin-left: 1rem;
            display: flex;
            align-items: center;
            border: 1px solid #DADADA;
            padding: 1rem;
            height: 48px;
            border-radius: 5px;
            font-size: 14px;
            cursor: pointer;
            text-align: center;

            &:hover {
              background: #8E8E8E47;
            }
          }
        }

        .content {
          margin-top: 1rem;
          background: #f5ebba91;
          border-radius: 5px;
          flex-direction: column;
          align-items: center;

          img {
            width: 150px;
          }
        }

        p {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        .input-filed-component {
          width: 200px;
        }

        h3 {
          color: #333;
        }

        p {
          text-align: center;

          b {
            font-weight: 500;
          }
        }

        i {
          font-size: 3.2rem;
          color: #8E8E8E;
          margin-top: 2rem;
          margin-bottom: 2rem;
        }

        &.receipt {
          margin-top: 1rem;
        }
      }

      .footer {
        padding: 1rem;
        box-shadow: 0px -1px 6px rgb(0 0 0 / 8%);

        .button-component {
          margin-left: auto;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      h2 {
        color: #333;
      }

      .more-details {
        display: flex;
        align-items: center;

        .authorization-code {
          display: flex;
          align-items: center;
        }

        i {
          font-size: 0.3rem;
          padding: 0 0.5rem;
          color: #DADADA
        }

        p {
          color: #6D6F6F;
          font-weight: 500;
          font-size: 16px;

          &.type {
            font-size: 14px;
          }

          span {
            color: #333;
          }
        }
      }
    }

    .content {
      padding: 1.5rem;
      display: flex;

      .column {
        .buttons-container {
          margin-bottom: 1rem;
        }

        &.table {
          width: 100%;
          overflow-x: hidden;

          .section-container {
            overflow-x: hidden;

            .table-component {
              overflow-x: auto;
              overflow-y: hidden;
            }
          }
        }

        .details-card {
          border: 1px solid #EFEFEF;
          padding: 1rem;
          border-radius: 8px;
          width: 420px;

          .details-option {
            display: flex;
            align-items: center;

            i {
              margin-left: auto;
              font-size: 1.2rem;
              color: #6D6F6F;
              cursor: pointer;
              padding: 0.2rem;

              &:hover {
                border-radius: 100%;
                background: #E4E4E4;
              }
            }

            .dropdown-component-container {
              position: relative;
              margin-bottom: 1.5rem;

              .dropdown-component {
                right: 0;
                min-width: 230px;
              }
            }
          }

          .details-header {
            display: flex;
            padding: 0.7rem 0;
            margin-bottom: 0.3rem;

            p {
              font-weight: 600;
              color: #333;

              &:last-child {
                margin-left: auto;
              }
            }

            a {
              display: flex;
              align-items: center;
              text-decoration: none;
              font-size: 14px;
              margin-left: 0.5rem;
              color: #0052cc;

              i {
                margin-right: 0.2rem;
              }
            }
          }

          .line {
            border-bottom: 1px solid #EFEFEF;
          }

          .items-container {
            display: flex;

            .items-content {
              .item {
                padding: 1rem 0;

                .label {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-weight: 400;
                  color: #8E8E8E;

                  img {
                    margin-right: 0.2rem;
                    width: 35px;
                  }

                  i {
                    margin-right: 0.5rem;
                  }
                }

                .value {
                  margin-top: 0.3rem;
                  font-size: 14px;
                  color: #333;
                }
              }

              &:last-child {
                margin-left: auto;
                text-align: end;

                .label {
                  display: block;
                }
              }
            }
          }
        }

        .section-container {
          h3 {
            color: #333;
            margin-bottom: 0.5rem;
          }

          &:last-child {
            margin-top: 2rem;
          }
        }

        &:last-child {
          margin-left: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .simple-default-view-component {
      .wrapper {
        .header {
          .title {
            h2 {
              font-size: 16px;
            }

            .more-details {
              p {
                font-size: 14px;
              }
            }
          }
        }

        .content {
          flex-direction: column;

          .column {
            &.detail {
              margin-bottom: 2rem;

              .details-card-container {
                display: flex;
                justify-content: center;

                .details-card {
                  width: 100%;
                  border: none;
                  padding: 0;
                }
              }
            }

            &.table {
              margin-left: unset;
            }
          }
        }
      }
    }
  }

  @media (max-width: 795px) {
    .simple-default-view-component {
      .wrapper {
        .header {
          .title {
            .more-details {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .simple-default-view-component {
      .content {
        .column {
          .section-container {
            h3 {
              font-size: 14px;
            }
          }

          &.detail {
            .details-card-container {
              .details-card {
                .details-header {
                  p {
                    font-size: 12px;
                  }
                }

                .items-container {
                  .items-content {
                    .item {
                      .label {
                        font-size: 12px;
                      }

                      .value {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 457px) {
    .simple-default-view-component {
      .content {
        .column {
          &.detail {
            .details-card {
              width: 100%;

              .items-container {
                flex-direction: column;

                .items-content {
                  .item {
                    word-wrap: break-word;
                  }

                  &:last-child {
                    margin-left: unset;
                    text-align: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
