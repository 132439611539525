<template>
  <div class="filter-transaction-container" v-click-outside="close">
    <filter-button-component
      @open="open"
      :data="filterButtonData"
      :isDisabled="isDisabled"
    />

    <div v-if="data.isActive" class="option-container">
      <div class="header">
        <h3>{{ data.title }}</h3>

        <div class="icon-container">
          <i class="icon-close" @click="close"/>
        </div>
      </div>

      <div v-if="data.filterType === 'transactionType'" class="content" :class="data.filterType">
        <div v-for="(item, index) in data.items" :key="index" class="filter-option-container">
          <toggle-switch-component
            :value="item.isChecked"
            @click.native="toggleSwitchValue(index)"
          />

          <div v-if="item.icon" class="icon-container" :class="item.color">
            <i :class="item.icon"/>
          </div>

          <div class=label>
            {{ item.label }}
          </div>
        </div>
      </div>

      <div v-else-if="data.filterType === 'date'">
        <div class="content" :class="data.filterType">
          <div class="period-container">
            <div class="date-container" @click="toggleSelectPeriod">
              {{ periodDate }}
            </div>

            <div v-if="selectPeriodIsActive" class="select-period-container">
              <div class="title-container">
                <p>{{ periodDate }}</p>
              </div>

              <calendar-component
                v-model="range"
              />
            </div>
          </div>

          <div v-for="(item, index) in data.items" :key="index" class="filter-option-container">
            <radio
              :value="item.isChecked"
              v-model="item.isChecked"
              :label="item.label"
              @click.native="toggleRadioValue(index)"
            />
          </div>
        </div>

        <div class="footer">
          <div class="button-container">
            <div class="reset-button" @click="reset">
              <i class="icon-trash"/>

              <p>Limpar</p>
            </div>

            <button-component
              class="button"
              text="Aplicar"
              scale="normal"
              v-ripple="$theme.whiteColorTransparent"
              @click.native="applyFilter"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ToggleSwitch from '@/components/Base/ToggleSwitch';
  import Button from '@/components/Base/Button';
  import Radio from '@/components/Base/Radio';
  import Calendar from '@/components/Calendar/CustomCalendar';
  import FilterButton from '@/components/FilterButton';
  import { mapGetters } from 'vuex';

  export default {
    name: 'FilterTransactionCard',

    data() {
      return {
        selectPeriodIsActive: false,
        periodDate: '-',
        resetRadio: true,

        range: {
          start: null,
          end: null
        },

        formatRange: {
          startDate: null,
          endDate: null
        },

        columnAttr: {
          default: 2
        },

        rowAttr: {
          default: 1
        },

        filterButtonData: {
          icon: this.data.icon,
          text: this.data.text
        }
      };
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            icon: 'icon-align',
            text: 'Filtrar',
            title: 'Lorem ipsum',
            isActive: false,
            type: 'transaction',
            items: [
              {
                label: 'default',
                icon: 'icon-close',
                color: 'orange',
                isChecked: false,
                type: 'default'
              }
            ]
          };
        }
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    components: {
      'toggle-switch-component': ToggleSwitch,
      'button-component': Button,
      'radio': Radio,
      'calendar-component': Calendar,
      'filter-button-component': FilterButton
    },

    computed: {
      ...mapGetters([
        'getExtractFilter'
      ])
    },

    mounted() {
      this.onResize();

      window.addEventListener('resize', () => this.onResize());

      this.setDateLabel();
    },

    watch: {
      'range.end'() {
        this.closeSelectPeriod();

        this.formatRange.startDate = this.$service.dateFormat(this.range.start);
        this.formatRange.endDate = this.$service.dateFormat(this.range.end);
        this.periodDate = this.$service.dateFormat(this.formatRange.startDate, 'YYYY-MM-DD', 'DD/MM/YYYY') + ' - ' + this.$service.dateFormat(this.formatRange.endDate, 'YYYY-MM-DD', 'DD/MM/YYYY');

        if (this.data.type == 'filterType') {
          this.resetRadioButton();
        }
      }
    },

    methods: {
      open() {
        this.setDateLabel();

        if (!this.$service.isEmptyArray(this.data.items)) {
          // eslint-disable-next-line vue/no-mutating-props
          this.data.isActive = true;
        }

        this.range.start = this.$service.toDate(this.getExtractFilter.startDate);
        this.range.end = this.$service.toDate(this.getExtractFilter.endDate);

        this.resetRadio = this.getExtractFilter.type == 'allMonth';
      },

      close() {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.isActive = false;

        this.closeSelectPeriod();
      },

      reset() {
        let currentMonth = this.$service.currentMonth();

        this.$store.commit('setExtractFilter', {
          type: 'allMonth',
          startDate: this.$service.getStartDateMonth(currentMonth, 'M'),
          endDate: this.$service.getEndDateMonth(currentMonth, 'M')
        });

        this.data.items.forEach(item => {
          item.isChecked = false;
        });

        // eslint-disable-next-line vue/no-mutating-props
        this.data.isActive = false;
      },

      applyFilter() {
        this.$store.commit('setExtractFilter', {
          type: 'date',
          startDate: this.formatRange.startDate,
          endDate: this.formatRange.endDate
        });

        this.close();
      },

      toggleSwitchValue(index) {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.items[index].isChecked = !this.data.items[index].isChecked;

        this.$store.commit('setExtractPerTransactionFilter', true);
      },

      toggleRadioValue(index) {
        let currentDate = this.$service.currentDateEN();

        this.formatRange.startDate = this.$service.getDateDay(currentDate, this.data.items[index].value);
        this.formatRange.endDate = currentDate;
        this.periodDate = this.$service.dateFormat(this.formatRange.startDate, 'YYYY-MM-DD', 'DD/MM/YYYY') + ' - ' + this.$service.dateFormat(this.formatRange.endDate, 'YYYY-MM-DD', 'DD/MM/YYYY');

        this.data.items.map(item => item.isChecked = false);

        // eslint-disable-next-line vue/no-mutating-props
        this.data.items[index].isChecked = true;
      },

      closeSelectPeriod() {
        this.selectPeriodIsActive = false;
      },

      toggleSelectPeriod() {
        this.selectPeriodIsActive = !this.selectPeriodIsActive;
      },

      onResize() {
        if (window.innerWidth < 720) {
          this.columnAttr.default = 1;
          this.rowAttr.default = 2;
        } else {
          this.columnAttr.default = 2;
          this.rowAttr.default = 1;
        }
      },

      setDateLabel() {
        this.periodDate = this.$service.dateFormat(this.getExtractFilter.startDate, 'YYYY-MM-DD', 'DD/MM/YYYY') + ' - ' + this.$service.dateFormat(this.getExtractFilter.endDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
      },

      resetRadioButton() {
        if (this.resetRadio) {
          this.data.items.forEach(item => {
            item.isChecked = false;
          });
        }

        this.resetRadio = true;
      },

      beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filter-transaction-container {
    .filter-transaction {
      border: 1px solid #DADADA;
      border-radius: 6px;
      height: 56px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      width: 90px;
      cursor: pointer;

      &.disabled {
        background: #EFEFEF !important;
        cursor: not-allowed;
        opacity: 0.6;
      }

      &:hover {
        background: #8E8E8E47;
      }

      .icon-container {
        color: #8E8E8E;
        text-align: center;

        i {
          font-size: 1.2rem;

          &.icon-align {
            font-size: 0.8rem;
          }
        }
      }

      p {
        color: #6D6F6F;
        font-size: 12px;
        margin-top: 0.3rem;
        font-weight: 400;
      }
    }

    .option-container {
      position: absolute;
      background: $white-color;
      right: 1.5rem;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      min-width: 400px;
      top: 6rem;

      .header {
        display: flex;
        align-items: center;
        color: #333;
        padding: 0 1.5rem;
        margin-top: 1.5rem;

        .icon-container {
          margin-left: auto;

          i {
            font-size: 0.9rem;
            color: #979797;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 0 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        &.date {
          p {
            margin-left: 2rem;
          }

          .period-container {
            .date-container {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #DADADA;
              border-radius: 6px;
              height: 50px;
              color: $second-color;
              font-weight: 600;
              font-size: 14px;
              cursor: pointer;
            }

            .select-period-container {
              position: absolute;
              background: $white-color;
              right: 1.5rem;
              top: 4.5rem;
              z-index: 1;
              padding: 1rem;
              box-shadow: 0px 0px 30px rgb(0 0 0 / 25%);
              border-radius: 6px;

              .title-container {
                border-bottom: 1px solid #DADADA;
                margin-bottom: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                  margin-bottom: 1rem;
                  color: $second-color;
                  font-weight: 600;
                }
              }

              .vc-container {
                font-family: 'Poppins', sans-serif;
                border-color: transparent;
                background-color: transparent;
                width: 100%;
              }
            }
          }

        }

        .filter-option-container {
          display: flex;
          align-items: center;
          margin-top: 1rem;

          .icon-container {
            margin-left: 1.5rem;
            &.orange {
              color: $second-color;
            }

            &.red {
              color: #FF5A5A;
            }

            i {
              font-size: 1.3rem;
            }
          }

          .label {
            margin-left: 1.5rem;
            font-size: 14px;
            font-weight: 300;
          }
        }
      }

      .footer {
        box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);
        padding: 1rem 1.5rem;

        .button-container {
          width: 100%;
          display: flex;

          .reset-button {
            display: flex;
            align-items: center;
            color: #8E8E8E;
            cursor: pointer;

            p {
              margin-left: 0.5rem;
              font-weight: 600;
            }
          }

          .button {
            height: 40px;
            margin-left: auto;
          }
        }
      }
    }
  }

  @media(max-width: 835px) {
    .filter-transaction-container {
      .option-container {
        z-index: 100;
        right: unset;
        left: 6rem;

        .period-container {
          .select-period-container {
            right: unset !important;
          }
        }
      }
    }
  }


  @media (max-width: 520px) {
    .filter-transaction-container {
      .option-container {
        min-width: 365px;
      }
    }
  }

  @media (max-width: 480px) {
    .filter-transaction-container {
      .option-container {
        left: 0;
        top: 0;
        z-index: 100;
        width: 100%;
        min-width: 320px;

        .content {
          &.date {
            .period-container {
              .select-period-container {
                width: 100%;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
</style>