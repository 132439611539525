<template>
  <div class="mobile-appointment-calendar-container">
    <div v-if="hasRequest" class="spinner-container">
      <spinner-component class="spinner" color="#DADADA"/>
    </div>

    <v-date-picker
      v-show="!hasRequest"
      :masks="masks"
      ref="calendar"
      :attributes="attributes"
      :first-day-of-week="1"
      is-expanded
      is-required
      @update:from-page="pageChange"
      @dayclick="setDay"
      v-model="range.start"
    />
  </div>
</template>

<script>
  import Spinner from '@/components/Base/Spinner';

  export default {
    name: 'MobileAppointmentCalendar',

    data() {
      return {
        masks: {
          title: 'MMMM/YYYY',
          weekdays: 'W'
        },

        range: {
          start: '',
          end: ''
        },
      };
    },

    props: {
      value: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      },

      hasRequest: {
        type: Boolean,
        default: false
      },

      attributes: {
        type: Array,
        require: false
      }
    },

    components: {
      'spinner-component': Spinner
    },

    watch: {
      value: {
        async handler() {
          this.range = this.value;

          await this.$refs.calendar?.move(this.range.start);
        },
        deep: true
      }
    },

    async mounted() {
      this.range = this.value;

      if (this.range.start) {
        await this.$refs.calendar?.move(this.range.start);
      }
    },

    methods: {
      pageChange() {
        if (!this.$service.isUndefined(this.$refs.calendar)) {
          let date = this.$refs.calendar.$refs.calendar.lastPage.year + '-' + this.$refs.calendar.$refs.calendar.lastPage.month;

          this.$emit('changePage', {
            start: this.$service.getStartDateMonth(date, 'YYYY-MM'),
            end: this.$service.getEndDateMonth(date, 'YYYY-MM')
          });
        }
      },

      setDay() {
        this.range = {
          start: this.range.start,
          end: this.range.start
        };

        this.$emit('input', this.range);
        this.$emit('changeDay', this.range);
      },
    }
  };
</script>

<style lang="scss">
  .mobile-appointment-calendar-container {
    width: 100%;

    .spinner-container {
      display: flex;
      justify-content: center;

      .spinner {
        margin-top: 1rem;
      }
    }

    .vc-container {
      font-family: 'Poppins', sans-serif;
      border: 1px solid #DADADA;

      .vc-pane-container {
        .vc-header {
          .vc-title {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
          }
        }

        .vc-weeks {
          .vc-weekday {
            color: #6D6F6F;
            font-weight: 400;
          }

          .vc-day {
            .vc-day-content  {
              background-color: unset;
            }

            .vc-highlights {
              .vc-day-layer {
                &.vc-day-box-center-center {
                  .vc-highlight {
                    border: 2px solid $second-color !important;
                    color: $white-color !important;
                    background-color: $second-color-light-1 !important;
                    border-radius: 0.3rem !important;

                    &.vc-highlight-base-middle {
                      background-color: $second-color-light-1 !important;
                      border-radius: 0 !important;
                      border: none !important;
                    }
                  }
                }

                &.vc-day-box-right-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }

                &.vc-day-box-left-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }
              }
            }

            span {
              font-weight: 300 !important;
              font-size: 14px !important;
              color: #333 !important;
            }
          }
        }
      }
    }
  }
</style>