import { instance } from '../../config/axios';
import store from '../index';

const actions = {
  postProduct(_, data) {
    return new Promise((resolve, reject) => {
      instance().post(`/sellers/${store.state.currentSellerId}/products`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllProducts(_, params) {
    return new Promise((resolve, reject) => {
      instance().get(`/sellers/${store.state.currentSellerId}/products` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  patchProductInventory(_, product) {
    return new Promise((resolve, reject) => {
      instance().patch(`/sellers/${store.state.currentSellerId}/products/${product.id}/inventory`, product.data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  disableProduct(_, productId) {
    return new Promise((resolve, reject) => {
      instance().put(`/sellers/${store.state.currentSellerId}/products/${productId}/disable`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  enableProduct(_, productId) {
    return new Promise((resolve, reject) => {
      instance().put(`/sellers/${store.state.currentSellerId}/products/${productId}/active`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
};

export default {
  namespaced: true,
  actions
};