<template>
  <div class="floating-button-container" :style="{ 'bottom': bottom, 'right': right }">
    <div
      class="floating-button-content"
      :style="{ 'font-size': iconSize, 'background': bgColor }"
      v-ripple="$theme.whiteColorTransparent"
      @click="click"
    >
      <i :class="icon" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FloatingButton',

    props: {
      icon: {
        type: String,
        default: 'icon-keyboard-arrow-up',
      },

      iconSize: {
        type: String,
        default: '24px',
      },

      bgColor: {
        type: String,
        default: '#000',
      },

      bottom: {
        type: String,
        default: '15px',
      },

      right: {
        type: String,
        default: '15px',
      },
    },

    methods: {
      click() {
        this.$emit('click');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .floating-button-container {
    position: fixed;

    .floating-button-content {
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
      color: $white-color;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: filter 0.3s ease;

      &:hover {
        filter: brightness(0.85);
      }
    }
  }
</style>