<template>
  <modal
    ref="modal"
    class="customer-modal"
    title="Perfil do cliente"
    :showModal="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <ValidationObserver
        v-slot="{ passes }"
        ref="form"
      >
        <form v-if="!hasFetchRequest" id="form" @submit.prevent="passes(submit)">
          <div class="body">
            <div class="section">
              <div class="title">
                <h3>Perfil</h3>

                <div v-if="isUpdate" class="edit-button-container" :class="{ 'disable': hasFetchRequest, 'active': isCreateOrEdit }" @click="activeCreateOrEdit">
                  <i class="icon-pencil" />

                  Editar
                </div>
              </div>
            </div>

            <div class="section">
              <div class="cards-container">
                <div
                  v-for="(item, index) in customerTypeData"
                  :key="index"
                  class="card"
                  :class="{ 'active': item.isActive, 'disable': item.isDisabled || hasUpdateOrCreateRequest }"
                  v-ripple="$theme.whiteColorTransparent"
                  @click="changeCustomerType(item.value)"
                >
                  <p>
                    {{ item.label }}
                  </p>
                </div>
              </div>

              <div class="fields">
                <div class="field">
                  <input-field-component
                    class="input-field-component large"
                    placeholder="Nome completo"
                    rules="required"
                    :isDisabled="isUpdate || hasUpdateOrCreateRequest"
                    v-model="data.name"
                  />
                </div>

                <div class="field">
                  <input-field-component
                    class="input-field-component"
                    name="tin"
                    :class="isIndividualCustomerType() ? 'small' : 'medium'"
                    :placeholder="isIndividualCustomerType() ? 'CPF' : 'CNPJ'"
                    :mask="isIndividualCustomerType()  ? '###.###.###-##' : '##.###.###/####-##'"
                    :rules="isIndividualCustomerType() ? 'required|taxpayerId' : 'required|ein'"
                    :isDisabled="isUpdate || hasUpdateOrCreateRequest"
                    v-model="data.tin"
                  />
                </div>
              </div>
            </div>

            <divider-component class="divider" />

            <div class="section">
              <h3>Contato</h3>

              <div class="fields">
                <div class="field">
                  <input-field-component
                    class="input-field-component"
                    placeholder="E-mail"
                    rules="required|mail"
                    :isDisabled="hasRequestOrIsCreateOrEdit()"
                    v-model="data.email"
                  />

                  <input-field-component
                    class="input-field-component small"
                    placeholder="Celular"
                    rules="required|phone"
                    :mask="['(##) ####-####', '(##) #####-####']"
                    :isDisabled="hasRequestOrIsCreateOrEdit()"
                    v-model="data.phone"
                  />
                </div>
              </div>
            </div>

            <divider-component class="divider" />

            <div class="section">
              <h3>Endereço</h3>

              <div class="fields">
                <div class="field">
                  <input-field-component
                    class="input-field-component small"
                    placeholder="CEP"
                    mask="#####-###"
                    @blur="requestPostalCode"
                    name="postalCode"
                    rules="required"
                    :isDisabled="hasRequestOrIsCreateOrEdit()"
                    v-model="data.postalCode"
                  />
                </div>

                <div class="field">
                  <input-field-component
                    class="input-field-component"
                    placeholder="Rua"
                    rules="required"
                    :isDisabled="hasRequestOrIsCreateOrEdit() || isAddressNeighborhoodLine1FieldsDisabled"
                    v-model="data.line1"
                  />

                  <input-field-component
                    class="input-field-component small"
                    placeholder="Número"
                    rules="required"
                    :isDisabled="hasRequestOrIsCreateOrEdit() || isAddressFieldsDisabled"
                    v-model="data.line2"
                  />
                </div>

                <div class="field">
                  <input-field-component
                    class="input-field-component medium"
                    placeholder="Cidade"
                    rules="required"
                    :isDisabled="isAddressCityStateFieldsDisabled"
                    :value="data.city"
                  />

                  <input-field-component
                    class="input-field-component medium"
                    placeholder="Bairro"
                    rules="required"
                    :isDisabled="hasRequestOrIsCreateOrEdit() || isAddressNeighborhoodLine1FieldsDisabled"
                    v-model="data.neighborhood"
                  />
                </div>

                <div class="field">
                  <input-field-component
                    class="input-field-component small"
                    placeholder="Estado"
                    rules="required"
                    :isDisabled="isAddressCityStateFieldsDisabled"
                    :value="data.state"
                  />

                  <input-field-component
                    class="input-field-component"
                    placeholder="Complemento"
                    :isDisabled="hasRequestOrIsCreateOrEdit() || isAddressFieldsDisabled"
                    v-model="data.line3"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <div v-else class="body has-request">
          <spinner-component color="#DADADA" />
        </div>
      </ValidationObserver>
    </template>

    <template v-slot:footer>
      <div class="footer-slot-container">
        <div class="button-container">
          <button-component
            class="button-component"
            styles="ghost"
            text="Fechar"
            :isDisabled="hasUpdateOrCreateRequest"
            @click.native="closeModal"
            v-ripple="$theme.secondColorDarkTransparent"
          />

          <button-component
            class="button-component"
            text="Salvar"
            form="form"
            :isDisabled="hasUpdateOrCreateRequest"
            :hasLoader="hasUpdateOrCreateRequest"
            v-ripple="$theme.whiteColorTransparent"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';
  import Divider from '@/components/Base/Divider';
  import Spinner from '@/components/Base/Spinner';
  import InputField from '@/components/Base/InputField';

  export default {
    name: 'CustomerModal',

    data() {
      return {
        isAddressFieldsDisabled: true,
        isAddressCityStateFieldsDisabled: true,
        isAddressNeighborhoodLine1FieldsDisabled: true,

        hasUpdateOrCreateRequest: false,
        hasFetchRequest: false,

        isCreateOrEdit: false,
        isUpdate: false,

        customerTypeData: [
          {
            label: 'Pessoa física',
            isActive: true,
            isDisabled: false,
            value: 'individual'
          },

          {
            label: 'Pessoa jurídica',
            isActive: false,
            isDisabled: false,
            value: 'company'
          }
        ],

        data: {
          tin: '',
          name: '',
          email: '',
          phone: '',
          postalCode: '',
          state: '',
          line1: '',
          line2: '',
          line3: '',
          neighborhood: '',
          city: '',
        },
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'button-component': Button,
      'divider-component': Divider,
      'input-field-component': InputField,
      'spinner-component': Spinner
    },

    props: {
      customerId: {
        type: String,
        required: false
      },
    },

    watch: {
      'data.postalCode'(value) {
        if (value.length == 0) {
          this.resetAddressFields();
        }
      },
    },

    created() {
      this.isUpdate = !!this.customerId;

      if (this.isUpdate) {
        this.fetchData();
      } else {
        this.activeCreateOrEdit();
      }
    },

    methods: {
      hasRequestOrIsCreateOrEdit() {
        return !this.isCreateOrEdit || this.hasUpdateOrCreateRequest;
      },

      activeCreateOrEdit() {
        this.isCreateOrEdit = true;
      },

      async fetchData() {
        this.hasFetchRequest = true;

        await this.$store.dispatch('customerModule/getCustomerId', this.customerId)
          .then(res => {
            this.data.tin = res.tin;
            this.data.name = res.name;
            this.data.email = res.email;
            this.data.phone = res.phone_number;
            this.data.postalCode = res.address.postal_code;
            this.data.line1 = res.address.line1;
            this.data.line2 = res.address.line2;
            this.data.line3 = res.address.line3;
            this.data.state = res.address.state_code;
            this.data.neighborhood = res.address.neighborhood;
            this.data.city = res.address.city;

            this.customerTypeData.map(item => {
              item.isActive = false;
              item.isDisabled = true;
            });

            if (this.$service.isTaxpayerId(res.tin)) {
              this.customerTypeData.find(item => item.value == 'individual').isActive = true;
            } else {
              this.customerTypeData.find(item => item.value == 'company').isActive = true;
            }
          })
          .catch(() => {
            this.snackbarNotification('Erro ao buscar cliente', '#FF5A5A');
            this.closeModal();
          });

        this.hasFetchRequest = false;
      },

      async submit() {
        this.hasUpdateOrCreateRequest = true;

        let closeModal = true;

        const data = JSON.stringify({
          tin: this.$service.removeCharacter(this.data.tin),
          name: this.data.name,
          email: this.data.email,
          phone_number: this.$service.removeCharacter(this.data.phone),
          address: {
            line1: this.data.line1,
            line2: this.data.line2,
            line3: this.data.line3,
            neighborhood: this.data.neighborhood,
            city: this.data.city,
            state_code: this.data.state,
            postal_code: this.$service.removeCharacter(this.data.postalCode),
            country_code: 'BR'
          }
        });

        if (this.isUpdate) {
          await this.$store.dispatch('customerModule/patchCustomer', { id: this.customerId, data })
            .catch(() => {
              this.snackbarNotification('Erro ao atualizar cliente', '#FF5A5A');
            });
        } else {
          await this.$store.dispatch('customerModule/postCustomer', { data })
            .catch(err => {
              closeModal = false;

              if (err.status === 409) {
                this.$refs.form.setErrors({
                  tin: 'Cliente já cadastrado',
                });
              } else {
                this.snackbarNotification('Erro ao cadastrar cliente', '#FF5A5A');
              }
            });
        }

        if (closeModal) {
          this.closeModal();
          this.$emit('finishRequest');
        }

        this.hasUpdateOrCreateRequest = false;
      },

      changeCustomerType(type) {
        let current = this.customerTypeData.find(item => item.value === type);

        if (!current.isDisabled && !this.hasUpdateOrCreateRequest) {
          this.customerTypeData.map(item => item.isActive = false);
          current.isActive = true;
        }
      },

      isIndividualCustomerType() {
        return this.customerTypeData.find(item => item.value === 'individual').isActive;
      },

      fetchNewPostalCode(postalCode) {
        this.isAddressFieldsDisabled = true;
        this.isAddressCityStateFieldsDisabled = true;
        this.isAddressNeighborhoodLine1FieldsDisabled = true;
        this.resetAddressFields();

        this.$store.dispatch('getAddress', this.$service.removeCharacter(postalCode))
          .then(res => {
            let { address_line_1, address_line_2, neighborhood, city, state_code } = res.data;

            this.data.line1 = address_line_1;
            this.data.line2 = address_line_2;
            this.data.neighborhood = neighborhood;
            this.data.city = city;
            this.data.state = state_code;

            if (this.$service.isBlank(neighborhood) || this.$service.isBlank(address_line_1)) {
              this.isAddressNeighborhoodLine1FieldsDisabled = false;
            }
          })
          .catch(() => {
            this.isAddressCityStateFieldsDisabled = false;
            this.isAddressNeighborhoodLine1FieldsDisabled = false;

            this.$refs.form.setErrors({
              postalCode: 'CEP não encontrado',
            });
          })
          .finally(() => {
            this.isAddressFieldsDisabled = false;
          });
      },

      requestPostalCode() {
        if (this.data.postalCode != '' && this.$service.removeCharacter(this.data.postalCode).length == 8) {
          this.fetchNewPostalCode(this.data.postalCode);
        }
      },

      resetAddressFields() {
        this.data.state = '';
        this.data.city = '';
        this.data.line1 = '';
        this.data.line2 = '';
        this.data.line3 = '';
        this.data.neighborhood = '';
      },

      closeModal() {
        this.$emit('close');
      },

      snackbarNotification(text, color) {
        this.$emit('snackbarNotification', text, color);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .customer-modal {
    .body {
      padding: 0 1.5rem;
      margin-top: 1.5rem;
      height: 500px;
      width: 560px;
      overflow-y: auto;

      &.has-request {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }

      .section {
        padding: 0.5rem;

        .title {
          display: flex;
          align-items: center;

          .edit-button-container {
            margin-left: auto;
            font-size: 14px;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #8E8E8E;
            cursor: pointer;
            padding: 0.5rem;

            &.disable {
              cursor: not-allowed;
              color: #DADADA;

              i {
                color: #DADADA;
              }
            }

            &.active {
              color: #0052CC;
            }

            i {
              margin-right: 0.7rem;
              color: #0052CC;
            }
          }
        }

        .cards-container {
          display: flex;

          .card {
            height: 48px;
            padding: 1rem;
            margin-right: 1rem;
            border-radius: 6px;
            border: 1px solid #DADADA;
            display: flex;
            align-items: center;
            color: #333;
            cursor: pointer;
            text-align: center;

            &:last-child {
              margin-right: 0;
            }

            &.disable {
              cursor: not-allowed;
              background: #F9F9F9;
              opacity: 0.8;
            }

            &.active {
              border: 1px solid $second-color;
              background: $second-color;
              color: $white-color;
              opacity: 1.0;

              p {
                font-weight: 400;
              }
            }
          }
        }

        .fields {
          margin-top: 1rem;

          .field {
            display: flex;
            gap: 0.5rem;

            .input-field-component {
              width: 100%;

              &.large {
                max-width: 350px;
              }

              &.medium {
                max-width: 250px;
              }

              &.small {
                max-width: 175px;
              }
            }
          }
        }
      }
    }

    .footer-slot-container {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);

      .button-container {
        display: flex;

        .button-component {
          width: 100px;
          height: 48px;
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .customer-modal {
      form {
        .body {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .customer-modal {
      form {
        .body {
          height: 400px;

          .section {
            .cards-container {
              .card {
                p {
                  font-size: 12px;
                }
              }
            }

            .fields {
              .field {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
</style>