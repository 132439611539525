<template>
  <transition name="fade">
    <div class="modal" v-if="showModal" :class="{ 'top': isTopOnMobile }">
      <div class="dialog" v-click-outside="close">
        <div class="content">
          <div class="icon">
            <slot name="icon"/>
          </div>

          <div class="body-container">
            <div v-if="title" class="header-container">
              <h2>{{ title }}</h2>

              <i class="icon-close" @click="close" />
            </div>

            <slot name="body"/>
          </div>
        </div>

        <div class="footer-container">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'CustomModal',

    props: {
      showModal: {
        type: Boolean,
        default: false,
      },

      title: {
        type: String,
        required: false
      },

      isTopOnMobile: {
        type: Boolean,
        default: false,
      }
    },

    methods: {
      close() {
        this.$emit('close');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .modal {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 15;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    justify-content: center;
    align-items: center;
    display: flex;

    .dialog {
      background: $white-color;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      min-width: 560px;
      z-index: 1;

      .content {
        .icon {
          float: right;
          color: #979797;
          cursor: pointer;
        }

        .header-container {
          display: flex;
          align-items: center;
          justify-content: center;

          h2 {
            color: #333;
            white-space: nowrap;
            position: absolute;
          }

          i {
            color: #979797;
            padding: 0.5rem;
            cursor: pointer;
            margin-left: auto;

            &:hover {
              border-radius: 100%;
              background: #E4E4E4;
            }
          }
        }

        .body-container {
          color: #333;
          padding: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .modal {
      justify-content: left;

      &.top {
        .dialog {
          top: 0;
          bottom: unset;
          border-radius: 0px 0px 20px 20px;
        }
      }

      .dialog {
        bottom: 0;
        position: fixed;
        max-height: 100vh;
        min-width: 320px;
        width: 100%;
        border-radius: 20px 20px 0px 0px;
      }
    }
  }

  @media (max-width: 480px) {
    .modal {
      .dialog {
        .content {
          .body-container {
            .header-container {
              h2 {
                font-size: 16px;
              }
            }

            padding: 1rem;
          }
        }
      }
    }
  }
</style>