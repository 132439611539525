<template>
  <div class="mobile-receivable-calendar-container">
    <div v-if="hasRequest" class="spinner-container">
      <spinner-component class="spinner" color="#DADADA"/>
    </div>

    <v-date-picker
      v-show="!hasRequest"
      :masks="masks"
      ref="calendar"
      :first-day-of-week="1"
      :attributes="attributes"
      @update:from-page="pageChange"
      @dayclick="setDay"
      v-model="range"
      is-required
      is-expande
    />
  </div>
</template>

<script>
  import Spinner from '@/components/Base/Spinner';

  export default {
    name: 'MobileReceivableCalendar',

    data() {
      return {
        masks: {
          title: 'MMMM/YYYY',
          weekdays: 'W'
        },

        range: {
          start: '',
          end: ''
        },

        attributes: [
          {
            key: 0,
            dot: {
              style: {
                'background-color': '#0000FF'
              }
            },
            dates: this.$service.toDate('2021-08-19'),
          },
        ]
      };
    },

    props: {
      value: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      },

      hasRequest: {
        type: Boolean,
        default: false
      },

      data: {
        type: Array,
        require: false
      }
    },

    components: {
      'spinner-component': Spinner
    },

    watch: {
      hasRequest(value) {
        if (!value) {
          this.attributes = this.formatAttributes(this.data);
        }
      }
    },

    mounted() {
      this.attributes = this.formatAttributes(this.data);

      this.range = this.value;
    },

    methods: {
      pageChange() {
        if (!this.$service.isUndefined(this.$refs.calendar)) {
          let date = this.$refs.calendar.$refs.calendar.lastPage.year + '-' + this.$refs.calendar.$refs.calendar.lastPage.month;

          this.$emit('changePage', {
            start: this.$service.getStartDateMonth(date, 'YYYY-MM'),
            end: this.$service.getEndDateMonth(date, 'YYYY-MM')
          });
        }
      },

      formatAttributes(data) {
        let attributesArr = [];

        if (!this.$service.isEmptyArray(data)) {
          data.forEach((item, index) => {
            attributesArr.push({
              key: index,
              dot: {
                style: {
                  'background-color': this.formatStatusCalendar(item.status)
                }
              },
              dates: this.$service.toDate(item.date),
            });
          });
        }

        return attributesArr;
      },

      setDay(day) {
        if (!this.$service.isEqual(day.id, this.$service.dateFormat(this.range.start))) {
          this.range = {
            start: this.$service.toDate(day.id),
            end: this.$service.toDate(day.id)
          };

          this.$emit('input', this.range);
          this.$emit('changeDay', this.range);
        }
      },

      formatStatusCalendar(status) {
        let color;

        switch (this.$service.toUpperCase(status)) {
          case 'PAID':
            color = '#08B978';
            break;
          case 'PENDING':
            color = '#BA8C03';
            break;
          case 'SCHEDULED':
            color = 'FF8B00';
            break;
          case 'CANCELED':
            color = '#FF5A5A';
            break;
          case 'REFUNDED':
            color = '#006EFF';
            break;
          default:
            color = '#1B87B1';
        }

        return color;
      }
    }
  };
</script>

<style lang="scss">
  .mobile-receivable-calendar-container {
    width: 100%;

    .spinner-container {
      display: flex;
      justify-content: center;

      .spinner {
        margin-top: 1rem;
      }
    }

    .vc-container {
      font-family: 'Poppins', sans-serif;
      border: 1px solid #DADADA;

      .vc-pane-container {
        .vc-header {
          .vc-title {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
          }
        }

        .vc-weeks {
          .vc-weekday {
            color: #6D6F6F;
            font-weight: 400;
          }

          .vc-day {
            .vc-day-content  {
              background-color: unset;
            }

            .vc-highlights {
              .vc-day-layer {
                &.vc-day-box-center-center {
                  .vc-highlight {
                    border: 2px solid $second-color !important;
                    color: $white-color !important;
                    background-color: $second-color-light-1 !important;
                    border-radius: 0.3rem !important;

                    &.vc-highlight-base-middle {
                      background-color: $second-color-light-1 !important;
                      border-radius: 0 !important;
                      border: none !important;
                    }
                  }
                }

                &.vc-day-box-right-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }

                &.vc-day-box-left-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }
              }
            }

            span {
              font-weight: 300 !important;
              font-size: 14px !important;
              color: #333 !important;
            }
          }
        }
      }
    }
  }
</style>