<template>
  <ValidationProvider
    tag="div"
    ref="select"
    :name="name"
    :rules="mountedRules"
    v-slot="{ errors }"
  >
    <div class="select-container" :class="{ 'disabled': isDisabled }" v-click-outside="close" v-scroll-outside="checkScroll">
      <div class="field" :class="{ 'open': isOpen, 'error': !!errors[0] }" @click="toggleState" >
        <div class="label-container">
          <label :class="{ 'placeholder': !$service.isEmptyObject(selected) }">{{ placeholder }}</label>

          <label v-if="!isOpen && selected" class="option-selected">{{ selected.label }}</label>
        </div>

        <div class="icon-container">
          <i :class="isOpen ? 'icon-arrow-up-filled' : 'icon-arrow-down-filled'" />
        </div>
      </div>

      <div v-show="isOpen" class="option-container">
        <div class="option-wrapper">
          <div class="option-content">
            <div v-if="hasSearch" class="search">
              <input
                type="text"
                placeholder="Procurar..."
                :disabled="hasRequest"
                v-model="searchInput"
                @keydown.enter="sendFetchEvent()"
              >

              <div class="icon-container">
                <i
                  :class="!searchInput ? 'icon-search' : 'icon-close'"
                  @click="cleanSearchInput"
                />
              </div>
            </div>

            <div class="content">
              <div v-if="hasRequest" class="option no-data">
                <spinner-component color="#DADADA" fontSize="1.6rem"/>
              </div>

              <div v-else-if="!options || options.length == 0" class="option no-data">
                Nenhum resultado
              </div>

              <div
                v-else
                v-for="(option, index) in options"
                :key="index"
                class="option"
                :class="{ 'actived': option.isActive }"
                @click="activeOption(option)"
              >
                {{ option.label }}
              </div>
            </div>

            <div v-if="!hasRequest && hasButton" class="button-container" @click="addButton">
              <i class="icon-add" />

              {{ buttonText }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isOpen && !isDisabled" class="errors">
        <span>{{ errors[0] }}</span>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import Spinner from '@/components/Base/Spinner';

  export default {
    name: 'SelectFetchField',

    data() {
      return {
        selected: {},
        mountedRules: '',
        timer: 0,

        lastScrollTop: 0,

        searchInput: '',
        isOpen: false
      };
    },

    props: {
      placeholder: {
        type: String,
        required: 'placeholder',
      },

      options: {
        type: Array,
        required: false,
      },

      rules: {
        type: String,
        default: '',
      },

      name: {
        type: String,
        default: '',
      },

      hasSearch: {
        type: Boolean,
        default: false
      },

      hasFetch: {
        type: Boolean,
        default: false
      },

      value: {
        type: String,
        required: false,
      },

      hasRequest: {
        type: Boolean,
        default: false
      },

      isDisabled: {
        type: Boolean,
        default: false
      },

      hasButton: {
        type: Boolean,
        default: false
      },

      buttonText: {
        type: String,
        default: ''
      }
    },

    components: {
      ValidationProvider,
      'spinner-component': Spinner,
    },

    created() {
      this.mountedRules = this.rules;

      this.options.map(item => item.isActive = false);
      this.setSelectedOption();
    },

    watch: {
      value(newValue) {
        this.setSelectedOption(newValue);
      },

      isOpen() {
        this.searchInput = '';
      },

      searchInput(value, oldValue) {
        if (this.hasFetch && value != oldValue) {
          clearTimeout(this.timer);

          this.timer = setTimeout(() => {
            this.sendFetchEvent();
          }, value == '' ? 450 : 750);
        }
      },

      selected() {
        this.checkRules();
      }
    },

    methods: {
      checkScroll(event) {
        const currentScrollTop = event.target.scrollTop;

        if (currentScrollTop < this.lastScrollTop) {
          this.close();
        }

        this.lastScrollTop = currentScrollTop;
      },

      setSelectedOption(value = null) {
        const selectedValue = value || this.value;

        this.options.forEach(item => {
          item.isActive = item.value === selectedValue;
        });

        this.selected = this.options.find(item => item.value === selectedValue) || {};
      },

      checkRules() {
        if (this.selected?.value) {
          this.$refs.select.reset();
          this.mountedRules = '';
        } else {
          this.mountedRules = 'required';
        }
      },

      toggleState() {
        if (!this.isDisabled) {
          this.isOpen = !this.isOpen;

          if (this.isOpen) {
            this.setSelectedOption();
          }
        }
      },

      activeOption(option) {
        if (!this.selected || this.selected && this.selected.value != option.value) {
          this.options.map(item => item.isActive = false);
          this.selected = option;
          option.isActive = true;

          this.$emit('input', this.selected.value);
        }

        this.close();
      },

      cleanSearchInput() {
        this.searchInput = '';

        this.$emit('fetch', this.searchInput);
      },

      close() {
        this.isOpen = false;
      },

      sendFetchEvent() {
        clearTimeout(this.timer);

        this.$emit('fetch', this.searchInput);
      },

      addButton() {
        this.$emit('buttonClick');

        this.close();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .select-container {
    margin-bottom: 1rem;
    width: 100%;
    cursor: pointer;

    &.disabled {
      background: #F9F9F9;
      opacity: 0.8;
      cursor: not-allowed;
    }

    .field {
      height: 48px;
      color: #333333;
      border: 1px solid #DADADA;
      align-items: center;
      font-size: 14px;
      display: flex;
      border-radius: 6px;
      padding: 0 1rem;
      user-select: none;

      &.error {
        border-color: $red-color;
        box-shadow: 0 0 0 1px $red-color, 0 0 0 3px $red-color-light;
      }

      .label-container {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #333333;
        overflow: hidden;

        label {
          transition: 0.2s ease all;
          -moz-transition: 0.2s ease all;
          -webkit-transition: 0.2s ease all;

          &.placeholder {
            font-size: 12px;
            color: #8E8E8E;
          }

          &.option-selected {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .icon-container {
        align-items: center;
        margin-left: auto;
        display: flex;

        i {
          font-size: 0.3rem;
          color: #979797;
        }
      }

      &.open {
        border-bottom: 0;
        border-radius: 6px 6px 0 0;
        border-top: 2px solid $second-color;
        border-right: 2px solid $second-color;
        border-left: 2px solid $second-color;
        box-shadow: none;
        position: relative;
        z-index: 100;
        background: $white-color;

        label {
          &.placeholder {
            top: 15px;
            color: #333;
            font-size: 14px;
          }
        }
      }
    }

    .option-container {
      color: #333333;
      position: relative;

      .option-wrapper {
        position: absolute;
        width: 100%;

        .option-content {
          border-left: 2px solid $second-color;
          border-right: 2px solid $second-color;
          border-bottom: 2px solid $second-color;
          border-radius: 0 0 6px 6px;
          flex-direction: column;
          position: relative;
          background: $white-color;
          display: flex;
          z-index: 10;
          margin-bottom: 2rem;

          .search {
            padding: 0 0.9rem;
            margin-bottom: -10px;

            input {
              width: 100%;
              height: 48px;
              padding: 0.5rem 2rem 0.5rem 0.6rem;
              border-radius: 6px;
              border: 1px solid #DADADA;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;

              &::-webkit-input-placeholder {
                color: #DADADA;
              }

              &:-moz-placeholder {
                color: #DADADA;
              }

              &::-moz-placeholder {
                color: #DADADA;
              }

              &:-ms-input-placeholder {
                color: #DADADA;
              }
            }

            .icon-container {
              position: relative;
              float: right;
              right: 10px;
              bottom: 32px;

              i {
                font-size: 16px;
                color: #979797;
                cursor: text;

                &.icon-close {
                  cursor: pointer;
                }
              }
            }
          }

          .content {
            font-size: 14px;
            max-height: 180px;
            overflow-x: hidden;
            overflow-y: auto;
            background: $white-color;
            cursor: pointer;
            z-index: 10;

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
            }

            .option {
              padding: 16px 1rem;
              cursor: pointer;

              &.no-data {
                justify-content: center;
                display: flex;
                cursor: not-allowed;
                font-weight: 500;

                &:hover {
                  background: unset;
                }
              }

              &:hover {
                background: #F6F7F7;
              }

              &.disable {
                cursor: default;
                font-weight: 600;

                &:hover {
                  background: $white-color;
                }
              }

              &.actived {
                background: $second-color;
                color: $white-color;
              }
            }
          }

          .button-container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            font-weight: 400;
            color: $second-color;
            user-select: none;
            font-size: 14px;

            i {
              margin-right: 8px;
            }
          }
        }
      }
    }

    .errors {
      span {
        color: $red-color;
        font-size: 12px;
        margin-top: 0.5rem;
        font-weight: 400;
      }
    }
  }

  @media (max-width: 440px) {
    .select-container {
      .field {
        .label-container {
          .option-selected {
            max-width: 200px;
          }
        }
      }
    }
  }
</style>