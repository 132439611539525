import { instance } from '../../config/axios';
import store from '../index';

const actions = {
  postSale(_, data) {
    return new Promise((resolve, reject) => {
      instance().post(`/sellers/${store.state.currentSellerId}/sales`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllSales(_, params) {
    return new Promise((resolve, reject) => {
      instance().get(`/sellers/${store.state.currentSellerId}/sales` + `${params ?? ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  patchSale(_, sale) {
    return new Promise((resolve, reject) => {
      instance().patch(`/sellers/${store.state.currentSellerId}/sales/${sale.id}`, sale.data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  cancelSale(_, id) {
    return new Promise((resolve, reject) => {
      instance().put(`/sellers/${store.state.currentSellerId}/sales/${id}/cancel`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
};

export default {
  namespaced: true,
  actions
};