<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="inputName"
    :rules="rules"
    class="input-field-container"
    :class="{ 'disable-focus': disableFocusStyle }"
    v-slot="{ errors }"
  >
    <div>
      <v-date-picker
        :min-date="minDate"
        locale="pt-BR"
        :first-day-of-week="1"
        :popover="{ visibility: isDisabled ? 'hide' : 'hover' }"
        :masks="{
          input: 'DD/MM/YYYY'
        }"
        is-range
        @input="changeRange"
        v-model="range"
      >
        <template v-slot="{ inputEvents }">
          <div v-on="inputEvents.start" class="input-range-date-container" :class="{ 'error': errors[0] }">
            <p>
              {{ customPlaceholder() }}
            </p>

            <i class="icon-calendar" />
          </div>
        </template>
      </v-date-picker>

      <div v-if="!isDisabled" class="errors">
        <span>{{ errors[0] }}</span>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'InputRangeDateField',

    data() {
      return {
        range: {
          start: null,
          end: null
        },

        inputName: '',
      };
    },

    props: {
      type: {
        type: String,
        default: 'date',
        validator(value) {
          return [
            'date'
          ].includes(value);
        },
      },

      vid: {
        type: String,
        default: undefined
      },

      isDisabled: {
        type: Boolean,
        default: false
      },

      placeholder: {
        type: String,
        default: 'Placeholder'
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      value: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      },

      maxLength: {
        type: Number,
        required: false
      },

      disableFocusStyle: {
        type: Boolean,
        default: false
      },

      minDate: {
        type: [String, Date],
        requried: false
      },
    },

    components: {
      ValidationProvider
    },

    created() {
      if (this.value) {
        this.range = this.value;
      }
    },

    methods: {
      customPlaceholder() {
        let start = this.$service.dateFormat(this.range.start, 'YYYY-MM-DD', 'DD/MM/YY') || 'Nenhum';
        let end = this.$service.dateFormat(this.range.end, 'YYYY-MM-DD', 'DD/MM/YY') || 'Nenhum';

        return this.range.start && this.range.end ? `${start} - ${end}` : 'Nenhum selecionado';
      },

      changeRange() {
        this.$emit('input', this.range);
        this.$emit('changeRange');
      },
    }
  };
</script>

<style lang="scss" scoped>
  .input-field-container {
    &.disable-focus {
      .input {
        &:focus {
          outline: none;
          border-color: #DADADA;
          box-shadow: none;
        }
      }
    }

    .input-range-date-container {
      border: 1px solid #DADADA;
      border-radius: 6px;
      padding: 0.3rem 1rem;
      display: flex;
      align-items: center;
      color: #333;
      cursor: pointer;
      height: 48px;
      gap: 0.5rem;
      max-width: 225px;
      width: 100%;

      &:focus {
        outline: none;
        border-color: $second-color;
        box-shadow: 0 0 0 1px $second-color, 0 0 0 3px $second-color-light-1;
      }

      &.error {
        border-color: $red-color;
        box-shadow: 0 0 0 1px $red-color, 0 0 0 3px $red-color-light;
      }

      i {
        margin-left: auto;
        font-size: 1.3rem;
        color: #979797;
      }
    }

    .vc-container {
      font-family: 'Poppins', sans-serif;
      border-color: transparent;
      background-color: transparent;
      z-index: 10000;
      background: $white-color;

      .vc-pane-container {
        .vc-header {
          .vc-title {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
          }
        }

        .vc-weeks {
          .vc-weekday {
            color: #333;
            font-weight: 600;
            padding: 1rem;
            font-size: 12px;
          }

          .vc-day {
            min-height: 40px;

            .vc-day-content  {
              background-color: unset;
            }

            .vc-highlights {
              .vc-day-layer {
                &.vc-day-box-center-center {
                  .vc-highlight {
                    background-color: $second-color !important;
                    border: none !important;

                    &.vc-highlight-base-middle {
                      background-color: $second-color-light-1 !important;
                    }
                  }
                }

                &.vc-day-box-right-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }

                &.vc-day-box-left-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }
              }
            }

            span {
              font-weight: 400 !important;
              font-size: 12px !important;
            }
          }
        }
      }
    }

    .errors {
      span {
        color: $red-color;
        font-size: 12px;
        margin-top: 0.5rem;
        font-weight: 400;
      }
    }
  }
</style>