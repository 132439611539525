import { imPayInstance } from '../../config/axios';
import { encryptStorage } from '../../config/encryptStorage';
import store from '../index';
import axios from 'axios';

function initialState() {
  return {
    defaultColor: null,
    logoUrl: null,
  };
}

const actions = {
  getCustomization({commit}) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${store.state.currentSellerId}/customizations`)
        .then(res => {
          const resData = res.data;

          commit('setDefaultColor', resData.data.default_color);
          commit('setLogoUrl', resData.data.logo.url);

          resolve(resData);
        })
        .catch(err => {
          commit('setDefaultColor', null);
          commit('setLogoUrl', null);

          reject(err.response);
        });
    });
  },

  postColor({commit}, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().post(`/sellers/${store.state.currentSellerId}/customizations`, data)
        .then(res => {
          const resData = res.data;

          commit('setDefaultColor', resData.data.default_color);

          resolve(resData);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postLogo({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_IMPAY_API_URL}/sellers/${store.state.currentSellerId}/customizations/logo`,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Access-Token': encryptStorage.getItem('token')
        }
      })
        .then(res => {
          const resData = res.data;

          commit('setLogoUrl', resData.data.url);

          resolve(resData);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

const getters = {
  getDefaultColor(state) {
    return state.defaultColor;
  },

  getLogoUrl(state) {
    return state.logoUrl;
  }
};

const mutations = {
  setDefaultColor(state, color) {
    state.defaultColor = color;
  },

  setLogoUrl(state, url) {
    state.logoUrl = url;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations
};