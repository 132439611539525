import { imPayInstance } from '../../config/axios';
import store from '../index';

function initialState() {
  return {
    sellerBankAccount: null,
    receivingPolicy: null,
  };
}

const actions = {
  postReceivingPolicy({commit}, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().post(`/sellers/${store.state.currentSellerId}/receiving-policies`, data)
        .then(res => {
          let resData = res.data;

          commit('setReceivingPolicy', resData.data.transfer_enabled);

          resolve(resData);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getOneReceivingPolicy({commit}) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${store.state.currentSellerId}/receiving-policies`)
        .then(res => {
          let resData = res.data;

          commit('setReceivingPolicy', resData.data.transfer_enabled);

          resolve(resData);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postBankAccount(_, data) {
    return new Promise((resolve, reject) => {
      imPayInstance().post(`/sellers/${store.state.currentSellerId}/bank-accounts`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllBankAccount({commit}) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${store.state.currentSellerId}/bank-accounts`)
        .then(res => {
          let resData = res.data;

          commit('setBankAccountData', resData.data);

          resolve(resData);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getOneBankAccount(_, id) {
    return new Promise((resolve, reject) => {
      imPayInstance().get(`/sellers/${store.state.currentSellerId}/bank-accounts/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  deleteBankAccount(_, id) {
    return new Promise((resolve, reject) => {
      imPayInstance().delete(`/sellers/${store.state.currentSellerId}/bank-accounts/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

const getters = {
  getBankAccountData(state) {
    return state.sellerBankAccount;
  },

  getReceivingPolicy(state) {
    return state.receivingPolicy;
  }
};

const mutations = {
  setBankAccountData(state, account) {
    state.sellerBankAccount = account;
  },

  setReceivingPolicy(state, receivingPolicy) {
    state.receivingPolicy = receivingPolicy;
  },

  resetState(state) {
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations
};