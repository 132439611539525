<template>
  <div class="select-container" :class="{ 'open': open }" v-click-outside="close">
    <div class="select" :class="{ 'open': open }" @click="open = !open">
      <div class="selected">
        <label :class="selected ? 'placeholder' : ''">{{ placeholder }}</label>

        <label v-if="!open" class="option-selected">{{ selected.label }}</label>
      </div>

      <div class="icon-container">
        <i v-if="open" class="icon-arrow-up-filled"/>
        <i v-else class="icon-arrow-down-filled"/>
      </div>
    </div>

    <div class="options-container" :class="{ 'hide': !open }">
      <div class="options">
        <div
          v-for="(item, index) in options"
          class="option"
          :class="{ 'actived': item.isActive }"
          :key="index"
          @click="selectItem(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'SelectSellerField',

    props: {
      placeholder: {
        type: String,
        required: true,
      },

      options: {
        type: Array,
        required: false,
      },

      optionId: {
        type: String,
        required: false,
      },
    },

    data() {
      return {
        oldItem: {},
        currentValue: null,
        open: false,
        selected: ''
      };
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerId'
      ])
    },

    watch: {
      selected(_, oldValue) {
        if (oldValue) {
          this.$store.dispatch('getSellerId');
        }
      },

      options(value) {
        value.forEach(option => {
          if (option.id == this.selected.id) {
            option.isActive = true;

            this.selected.isActive = true;
          }
        });
      }
    },

    created() {
      this.options.forEach(item => {
        item.isActive = false;

        if (this.optionId == item.id) {
          this.selected = item;
          this.selected.isActive = true;
          this.oldItem = this.selected;
        }
      });
    },

    methods: {
      selectItem(item) {
        if (item.id != this.oldItem.id) {
          this.options.forEach(item => {
            item.isActive = false;
          });

          this.$store.commit('setCurrentSellerId', item.id);
          this.selected = item;
          this.selected.isActive = true;
          this.oldItem = item;
          this.$emit('input', item.id);
          this.close();
        }
      },

      close() {
        this.open = false;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .select-container {
    position: relative;
    cursor: pointer;
    width: 100%;
    z-index: 2;

    .select {
      height: 48px;
      background: #EFEFEF;
      color: #333333;
      align-items: center;
      font-size: 16px;
      display: flex;
      border-radius: 6px;
      padding: 0 1rem;
      cursor: pointer;
      user-select: none;

      .selected {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;

        label {
          transition: 0.2s ease all;
          -moz-transition: 0.2s ease all;
          -webkit-transition: 0.2s ease all;
          font-weight: 400;

          &.placeholder {
            position: absolute;
            top: 5px;
            color: #8E8E8E;
            font-size: 12px;
            z-index: 10;
          }

          &.option-selected {
            font-size: 14px;
            top: 22px;
            position: absolute;
            width: calc(100% - 53px);
            overflow: hidden;
            font-weight: 500;
            color: #6D6F6F;
          }
        }
      }

      .icon-container {
        align-items: center;
        margin-left: auto;
        display: flex;
        margin-left: 0.4rem;

        i {
          font-size: 0.3rem;
          color: #979797;
        }
      }

      &.error {
        border-color: $red-color;
        box-shadow: 0 0 0 1px $red-color, 0 0 0 3px $red-color-light;
      }

      &.open {
        border-bottom: 0;
        border-radius: 6px 6px 0 0;
        box-shadow: none;

        label {
          &.placeholder {
            top: 15px;
            color: #8E8E8E;
            font-size: 14px;
          }
        }
      }
    }

    .options-container {
      position: absolute;
      background: #EFEFEF;
      width: 100%;
      border-radius: 0 0 6px 6px;

      .options {
        margin-bottom: 10px;
        max-height: 164px;
        overflow-y: auto;
        width: 100%;

        &::-webkit-scrollbar-track {
          background: #0000;
        }

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: #979797;
          border-radius: 10px;
          height: 35px;
        }

        .option {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0.5rem 1rem;
          font-weight: 500;
          color: #6D6F6F;

          &:hover {
            background: #E0E0E0;
          }

          &.actived {
            background: $second-color;
            color: $white-color;
          }
        }
      }

      &.hide {
        display: none;
      }
    }

    span {
      color: #FF5A5A;
      font-size: 12px;
      margin-top: 0.5rem;
    }
  }
</style>