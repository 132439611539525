<template>
  <modal
    ref="modal"
    class="sale-receivable-modal"
    title="Gerenciar venda"
    :showModal="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <ValidationObserver
        v-slot="{ passes }"
        ref="form"
      >
        <form id="form" @submit.prevent="passes(submit)">
          <div class="body">
            <div class="section">
              <div class="fields">
                <div class="field">
                  <input-currency-field
                    class="input-field-component"
                    placeholder="Valor pago"
                    rules="required"
                    type="money"
                    v-model="data.paidAmount"
                  />

                  <input-date-field
                    class="input-field-component"
                    placeholder="Data pagamento"
                    type="date"
                    :maxDate="new Date()"
                    :rules="data.paidAmount > 0 ? 'required' : ''"
                    v-model="data.paidAt"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template v-slot:footer>
      <div class="footer-slot-container">
        <div class="button-container">
          <button-component
            class="button-component"
            styles="ghost"
            text="Fechar"
            :isDisabled="hasRequest"
            @click.native="closeModal"
            v-ripple="$theme.secondColorDarkTransparent"
          />

          <button-component
            class="button-component"
            text="Salvar"
            form="form"
            :hasLoader="hasRequest"
            :isDisabled="hasRequest"
            v-ripple="$theme.whiteColorTransparent"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import Modal from '@/components/Modal/Base';
  import InputCurrencyField from '@/components/Base/InputCurrencyField';
  import Button from '@/components/Base/Button';
  import InputDateField from '@/components/Base/InputDateField';

  export default {
    name: 'SaleReceivableModal',

    data() {
      return {
        hasRequest: false,

        data: {
          paidAmount: '',
          paidAt: ''
        }
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'input-currency-field': InputCurrencyField,
      'input-date-field': InputDateField,
      'button-component': Button,
    },

    props: {
      saleId: {
        type: String,
        required: false
      },
    },

    methods: {
      async submit() {
        this.hasRequest = true;

        const data = {
          paid_amount: this.data.paidAmount,
          paid_at: this.data.paidAt,
        };

        await this.$store.dispatch('saleModule/patchSale', { id: this.saleId, data })
          .then(() => {
            this.snackbarNotification('Venda baixada com sucesso', '#08B978');

            this.$emit('finishRequest');
          })
          .catch(() => {
            this.snackbarNotification('Erro ao baixar venda', '#FF5A5A');
          });

        this.closeModal();

        this.hasRequest = false;
      },

      closeModal() {
        this.$emit('close');
      },

      snackbarNotification(text, color) {
        this.$emit('snackbarNotification', text, color);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .sale-receivable-modal {
    .body {
      margin-top: 1.5rem;
      padding: 0 1.5rem;
      width: 560px;

      .section {
        padding: 0.5rem;

        .fields {
          margin-top: 1rem;

          .field {
            display: flex;
            gap: 0.5rem;

            .input-field-component {
              width: 100%;

              &.medium {
                max-width: 250px;
              }

              &.small {
                max-width: 175px;
              }
            }
          }
        }
      }

    }

    .footer-slot-container {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);

      .button-container {
        display: flex;

        .button-component {
          width: 100px;
          height: 48px;
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .sale-receivable-modal {
      .body {
        width: 100%;
      }
    }
  }

  @media (max-width: 520px) {
    .sale-receivable-modal {
      .body {
        .fields {
          .field {
            flex-direction: column;
            margin-bottom: 1rem;
            gap: unset !important;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>