<template>
  <modal
    ref="modal"
    class="modal"
    title="Filtros"
    :showModal="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <div class="content">
        <h3>Data</h3>

        <div class="field">
          <div class="day-container">
            Últimos:

            <simple-card-component
              class="simple-card-component"
              :data="lastDateFilter"
              @new="setLastDateFilter"
            />
          </div>

          <input-range-date-field
            class="input-date-field"
            v-model="data.dateRange"
            @click.native="openCalendarField"
          />
        </div>

        <divider-component class="divider" />

        <h3>Valor bruto</h3>

        <div class="field">
          <input-currency-field
            class="input-field"
            placeholder="Mínimo (R$)"
            name="min"
            type="money"
            v-model="data.amountRange.min"
          />

          <input-currency-field
            class="input-field"
            placeholder="Máximo (R$)"
            name="max"
            type="money"
            v-model="data.amountRange.max"
          />
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="footer-slot-container">
        <div class="reset-button" @click="resetModalFilter">
          <i class="icon-trash"/>

          <p>
            Limpar
          </p>
        </div>

        <button-component
          class="button-component"
          text="Filtrar"
          @click.native="applyFilter"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modal/Base';
  import Button from '@/components/Base/Button';
  import InputCurrencyField from '@/components/Base/InputCurrencyField';
  import SimpleCard from '@/components/Table/SimpleCard';
  import InputRangeDateField from '@/components/Base/InputRangeDateField';
  import Divider from '@/components/Base/Divider';

  export default {
    name: 'filterModal',

    data() {
      return {
        lastDateFilter: this.initialLastDateFilterData(),
        showCalendarInputField: false,

        data: {
          amountRange: {
            min: 0,
            max: 0
          },
          dateRange: {
            start: null,
            end: null
          }
        }
      };
    },

    components: {
      'modal': Modal,
      'button-component': Button,
      'input-currency-field': InputCurrencyField,
      'simple-card-component': SimpleCard,
      'input-range-date-field': InputRangeDateField,
      'divider-component': Divider
    },

    created() {
      this.resetModalFilter();
    },

    methods: {
      closeModal() {
        this.$emit('close');
      },

      initialLastDateFilterData() {
        return {
          isUnique: true,
          items: [
            {
              label: '3 dias',
              value: -3,
              isActive: false
            },

            {
              label: '7 dias',
              value: -7,
              isActive: false
            },

            {
              label: '30 dias',
              value: -30,
              isActive: false
            },

            {
              label: '45 dias',
              value: -45,
              isActive: false
            }
          ]
        };
      },

      setLastDateFilter() {
        let currentItem = this.lastDateFilter.items.filter(item => item.isActive)[0];

        this.data.dateRange.end = this.$service.toDate(this.$service.currentDateEN());
        this.data.dateRange.start = this.$service.toDate(this.$service.getDateDay(this.data.dateRange.end, currentItem.value));
      },

      openCalendarField() {
        this.showCalendarInputField = true;
      },

      closeCalendarField() {
        this.showCalendarInputField = false;
      },

      initialRangeDateFilterData() {
        return {
          start: null,
          end: null
        };
      },

      applyFilter() {
        this.$emit('clickApplyFilter', this.data);
        this.closeModal();
      },

      resetModalFilter() {
        this.data.amountRange.min = 0;
        this.data.amountRange.max = 0;
        this.data.dateRange.start = null;
        this.data.dateRange.end = null;

        this.lastDateFilter = this.initialLastDateFilterData();
      },
    }
  };
</script>

<style lang="scss" scoped>
  .modal {
    .content {
      margin-top: 1.5rem;

      .divider {
        margin: 1.5rem 0;
      }

      .field {
        display: flex;
        margin-top: 1rem;
        gap: 1rem;


        .input-date-field {
          margin-left: auto;
        }
      }
    }

    .footer-slot-container {
      display: flex;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgb(0 0 0 / 8%);


      .reset-button {
        display: flex;
        align-items: center;
        color: #8E8E8E;
        cursor: pointer;
        margin-right: 3rem;

        p {
          margin-left: 0.5rem;
          font-weight: 600;
        }
      }

      .button-component {
        height: 48px;
        width: 110px;
        margin-left: auto;
      }
    }
  }

  @media (max-width: 635px) {
    .modal {
      .content {
        .field {
          flex-direction: column;

          .input-date-field {
            margin-top: 1rem;
            margin-left: unset;
          }
        }
      }
    }
  }
</style>